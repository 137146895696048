import React from "react";
import { Field } from "formik";

const LeadMeeting = ({
  values,
  errors,
  touched,
  showmeetedit,
  meetdate,
  meetassignbde,
  meetstarttime,
  meetendttime,
  meetloc,
  meetlink,
  meetdesc,
  updmeetlink,
  updmeetloc,
  bdeassignOption,
}) => {
  return (
    <>
      {!showmeetedit && (
        <div className="contact_info_">
          <div className="row">
            <div className="col-md-6">
              <p className="m-0 admin_type_heading">Due Date</p>
              <span className="admin_type_value">
                {meetdate === ""
                  ? "-"
                  : meetdate.slice(0, 10).split("-").reverse().join("-")}
              </span>
            </div>

            <div className="col-md-6">
              <p className="m-0 admin_type_heading">Assign to</p>
              <span className="admin_type_value">
                {meetassignbde === "" ? "-" : meetassignbde}
              </span>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-md-6">
              <p className="m-0 admin_type_heading">Meeting From</p>
              <span className="admin_type_value">
                {meetstarttime === "" ? "-" : meetstarttime}
              </span>
            </div>

            <div className="col-md-6">
              <p className="m-0 admin_type_heading">Meeting To</p>
              <span className="admin_type_value">
                {meetendttime === "" ? "-" : meetendttime}
              </span>
            </div>
          </div>

          <div className="pt-3">
            {meetlink !== "" ? (
              <div>
                <p className="m-0 admin_type_heading">Meeting Link</p>
                <div className="meeting_link">
                  <div style={{ width: "60%", padding: "10px" }}>
                    <p className="admin_type_value">
                      {meetlink === "" ? "-" : meetlink}
                    </p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <a
                      href={meetlink}
                      type="button"
                      className="btn_join_meeting"
                      target="_blank"
                      style={{ lineHeight: "25px" }}
                    >
                      Join Meeting
                    </a>
                  </div>
                </div>
              </div>
            ) : meetloc !== "" ? (
              <div>
                <p className="m-0 admin_type_heading">Meeting Location</p>
                <span className="admin_type_value">
                  {meetloc === "" ? "-" : meetloc}
                </span>
              </div>
            ) : (
              "-"
            )}
          </div>

          <div className="pt-3">
            <p className="m-0 admin_type_heading">Meeting Description</p>
            <span className="admin_type_value">
              {meetdesc === "" ? "-" : meetdesc}
            </span>
          </div>
        </div>
      )}
      {/* TO EDIT START */}
      {showmeetedit && (
        <div className="contact_info_admin">
          <div className="input-group-custom">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="due_date" className="label">
                  Due Date
                </label>
                <br />
                <Field
                  type="date"
                  className="input-area"
                  //   required
                  id="due_date"
                  name="meeting_date"
                  value={values.meeting_date}
                  //   onChange={(e) => {
                  //     setmeetdate(e.target.value);
                  //   }}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="assign_bde" className="label">
                  Assign To
                </label>
                <br />
                <Field
                  as="select"
                  style={{ width: "100%", height: "26px" }}
                  id="assign_bde"
                  className="input-area"
                  name="assign_bde"
                  //   onChange={(e) => {
                  //     setmeetassignbde(e.target.value);
                  //   }}
                  value={values.assign_bde}
                >
                  {/* <option selected value="">
                                      Select BDE
                                    </option> */}
                  {bdeassignOption != ""
                    ? bdeassignOption.map((item, i) => {
                        return (
                          <option key={i} value={item.Name}>
                            {item.Name}
                          </option>
                        );
                      })
                    : "No Data"}
                </Field>
              </div>
            </div>
          </div>

          <div className="input-group-custom mt-3">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="meet_start_time" className="label">
                  Meeting From
                </label>
                <br />
                <Field
                  type="time"
                  className="input-area"
                  style={{ width: "100%" }}
                  //   required
                  id="meet_start_time"
                  name="meeting_from_time"
                  value={values.meeting_from_time}
                  //   onChange={(e) => {
                  //     setmeetstarttime(e.target.value);
                  //   }}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="meet_end_time" className="label">
                  Meeting To
                </label>
                <br />
                <Field
                  type="time"
                  style={{ width: "100%" }}
                  className="input-area"
                  //   required
                  id="meet_end_time"
                  name="meeting_to_time"
                  value={values.meeting_to_time}
                  //   onChange={(e) => {
                  //     setmeetendttime(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>

          <div className="input-group-custom mt-3">
            {updmeetlink !== "" ? (
              <div>
                <label htmlFor="meet_link" className="label">
                  Meeting Link
                </label>
                <br />
                <Field
                  type="text"
                  className="input-area"
                  //   required
                  id="meet_link"
                  name="meeting_link"
                  value={values.meeting_link}
                  style={{ width: "100%" }}
                  //   onChange={(e) => {
                  //     setmeetlink(e.target.value);
                  //   }}
                />
              </div>
            ) : updmeetloc !== "" ? (
              <div>
                <label htmlFor="meet_loc" className="label">
                  Meeting Location
                </label>
                <br />
                <Field
                  type="text"
                  className="input-area"
                  //   required
                  id="meet_loc"
                  name="meeting_location"
                  value={values.meeting_location}
                  style={{ width: "100%" }}
                  //   onChange={(e) => {
                  //     setmeetloc(e.target.value);
                  //   }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="input-group-custom mt-3">
            <label htmlFor="meet_loc" className="label">
              Meeting Description
            </label>
            <br />
            <Field
              type="text"
              className="input-area"
              required
              id="meet_loc"
              name="meeting_description"
              style={{ width: "100%" }}
              value={values.meeting_description}
              //   onChange={(e) => {
              //     setmeetdesc(e.target.value);
              //   }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LeadMeeting;
