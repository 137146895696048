import React, { useEffect, useState } from "react";
import "../Dashboard/dashboard.css";
import AdminNav from "../AdminNavBar/AdminNav";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Formik, Form, Field, useField } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Footer from "../Dashboard/Footer";
// Pagination
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Helpers/Loader";
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import { startOfDay, endOfDay, subMonths, subYears } from "date-fns";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const AdminReports = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  let history = useHistory();
  const [isloading, setisloading] = useState(true);
  const [btnloader, setbtnloader] = useState(false);
  // Paganiation
  const { SearchBar } = Search;
  const [Page, setPage] = useState(1);
  const [PerPage, setPerPage] = useState(50);
  const [searchcount, setsearchcount] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  //   Date
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [adminReport, setadminReport] = useState("");

  // OnChange Function for Date Range Picker
  const onChange = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start > end) {
        setStartDate(end);
        setEndDate(start);
      } else {
        setStartDate(start);
        setEndDate(end);
      }
    } else {
      //  console.log("empty");
      setStartDate("");
      setEndDate("");
    }
  };

  // Converting it to format dd/mm/yyyy
  // const starting = startDate.toISOString().slice(0, 10);
  // const ending = endDate.toISOString().slice(0, 10);

  const Ranges = [
    {
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Month",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
    },
    {
      label: "3 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
    },
    {
      label: "6 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
    },
    {
      label: "Year",
      value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
    },
  ];

  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }
    getUsers();
  }, []);

  const getUsers = () => {
    axios
      .get(`${SERVER_URL}/getusers`)
      .then((resusers) => {
        // console.log("userdataaa", resusers.data.Users);
        setadminReport(resusers.data.Users);
        setisloading(false);
      })
      .catch((err) => alert(err));
  };

  const reset = () => {
    setStartDate("");
    setEndDate("");
  };

  const sendAdminReport = (data) => {
    setbtnloader(true);
    const senddata = {
      role: data.role,
      from: startDate,
      to: endDate,
    };

    // console.log("data", senddata);

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/salesadminreport`, senddata, {
        headers,
      })
      .then((res) => {
        // console.log("ffff", res);
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#Add_Ref_popup").modal("hide");
          setbtnloader(false);
          setPage(1);
          setadminReport(res.data.Users);
          // remove
          if (res.data.Users.length !== 0) {
            var number = document.getElementsByClassName(
              "react-bootstrap-table-page-btns-ul"
            )[0];
            var firstnum = number.querySelectorAll("li, a")[1];
            firstnum.click();
          }
        } else {
          setbtnloader(false);
          toast.error("Something Went Wrong...try Again!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setadminReport("");
        }
      });
  };

  // New Pagination
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "User Name",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "user_code",
      text: "User Code",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "userId",
      text: "User ID",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "password",
      text: "Password",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "role",
      text: "Role",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "doj",
      text: "Date Of Joining",
      formatter: (cell) => (!cell ? "-" : cell),
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    // remove these two
    sizePerPage: 50,
    hideSizePerPage: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ],
  };

  return (
    <div>
      {isloading === false ? (
        <div>
          <div>
            <AdminNav />
          </div>
          <div className="main">
            <div className="content-tabs">
              <ToolkitProvider
                keyField="_id"
                data={adminReport}
                columns={columns}
                search={{ afterSearch }}
              >
                {(props) => (
                  <div>
                    <div>
                      <div className="top_menu_bar">
                        <div style={{ width: "90%" }}>
                          {/* <div style={{ width: "80%" }}> */}
                          <span className="CS_head_txt">
                            Sales Admin Reports
                          </span>
                        </div>
                        <div>
                          <button
                            className="add_btn"
                            data-toggle="modal"
                            data-target=".Add_Ref_popup"
                          >
                            <FilterListIcon className="search_icons" />
                            Filter
                          </button>
                        </div>
                        {/* <div className="d-flex" style={{ gap: "15px" }}>
                <div>
                  <button
                    className="add_btn"
                    data-toggle="modal"
                    data-target=".Add_Ref_popup"
                  >
                    <FilterListIcon className="search_icons" />
                    Filter
                  </button>
                </div>

                <div>
                  <button className="add_btn" data-toggle="modal">
                    <OutboxRoundedIcon className="search_icons" />
                    Export
                  </button>
                </div>
              </div> */}

                        <div>
                          <div
                            id="Add_Ref_popup"
                            className="modal fade Add_Ref_popup"
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div
                                className="modal-content modal_content_whole"
                                style={{ background: "white" }}
                              >
                                <div
                                  className="modal-body"
                                  style={{ background: "#FFFFFF" }}
                                >
                                  <div className="pb-3">
                                    <span className="add_hot_lead_label">
                                      Filters
                                    </span>
                                    <span
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      id="CloseAddHotLeadPopup"
                                      style={{
                                        background: "transparent",
                                        float: "right",
                                        color: "red",
                                      }}
                                    >
                                      <span aria-hidden="true">X Close</span>
                                    </span>
                                  </div>
                                  <Formik
                                    initialValues={{
                                      role: "",
                                    }}
                                    // validationSchema={validate}
                                    onSubmit={(values, { resetForm }) => {
                                      sendAdminReport(values);
                                    }}
                                  >
                                    {(formik) => (
                                      <div>
                                        <Form>
                                          <div className="row">
                                            <div className="col-12 col-md-6">
                                              <div className="input-group_add_hot_lead">
                                                <label className="filter_input_head_txt pb-3">
                                                  Date Range
                                                </label>
                                                {/* RSUITE */}
                                                <DateRangePicker
                                                  placeholder="Select Date Range"
                                                  format="dd/MM/yyyy"
                                                  character="-"
                                                  onChange={onChange}
                                                  ranges={Ranges}
                                                />
                                                {/* RSUITE */}
                                              </div>
                                            </div>

                                            <div className="col-12 col-md-6">
                                              <label className="filter_input_head_txt pb-3">
                                                User Type
                                              </label>
                                              <div className="input-group_add_hot_lead ">
                                                <Field
                                                  as="select"
                                                  className="field_input_txt"
                                                  name="role"
                                                  defaultValue={"select"}
                                                >
                                                  <option selected value="">
                                                    Select User
                                                  </option>
                                                  <option value="IS">
                                                    Inside Sales
                                                  </option>
                                                  <option value="CS">
                                                    Channel Sales
                                                  </option>
                                                </Field>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="row pt-5">
                                            <div className="col-12 col-md-6">
                                              <button
                                                type="reset"
                                                className="btn btn-lg btn_resetall"
                                                onClick={reset}
                                              >
                                                Reset All
                                              </button>
                                            </div>
                                            <div className="col-12 col-md-6">
                                              <button
                                                type="submit"
                                                className="btn btn-lg btn_apply_filter"
                                              >
                                                {btnloader === true ? (
                                                  <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                  ></span>
                                                ) : (
                                                  ""
                                                )}
                                                Apply Filter
                                              </button>
                                            </div>
                                          </div>
                                        </Form>
                                      </div>
                                    )}
                                  </Formik>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="table_div">
                        {adminReport != "" ? (
                          <div>
                            <BootstrapTable
                              {...props.baseProps}
                              noDataIndication={emptyDataMessage}
                              pagination={paginationFactory(paginationoptions)}
                              filter={filterFactory()}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div>
                              <WarningRoundedIcon className="no_data_alert" />
                            </div>
                            <div>
                              <h3>No records found for Customers</h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default AdminReports;
