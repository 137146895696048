import React from "react";
import Download from "../../Assets/Download.svg";
import { Field } from "formik";
import axios from "axios";
import fileDownload from "js-file-download";

const PartnerAgrement = ({
  values,
  errors,
  touched,
  agreement,
  Persfamt,
  showAgreement,
  Setagreement,
}) => {
  const img_url = process.env.REACT_APP_IMAGES_URL;

  const handleDownload = (e, url, filename) => {
    e.preventDefault();
    // working old
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });

    // trying new
    // var FileSaver = require("file-saver");
    // axios
    //   .get(url, {
    //     responseType: "blob",
    //   })
    //   .then((res) => {
    //     FileSaver.saveAs(res.data, filename);
    //   });
  };

  return (
    <>
      {!showAgreement && (
        <div className="viewing_details">
          <div className="row">
            <div className="col-6 col-md-6">
              {agreement !== "" ? (
                <div className="btn_download_pdf">
                  <button
                    className="d-flex"
                    style={{
                      gap: "10px",
                      background: "none",
                    }}
                    onClick={(e) => {
                      handleDownload(
                        e,
                        `${img_url}/${agreement}`,
                        `${agreement}`
                        // `${img_url}/${agreement}`,
                        // `test-download.${agreement.slice(
                        //   agreement.length - 4
                        // )}`
                      );
                    }}
                  >
                    <img src={Download} />
                    {/* {agreement} */}
                    Agreement
                  </button>
                </div>
              ) : (
                "-"
              )}
            </div>

            <div className="col-6 col-md-6">
              <div>
                <p className="m-0 admin_type_heading">Per Sq.Ft. Amt.</p>
                <span className="admin_type_value">
                  {Persfamt === "" ? "-" : Persfamt}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAgreement && (
        <div className="edit_details">
          <div className="row">
            <div className="col-6 col-md-6">
              <div className="input-group-custom">
                <label htmlFor="source" className="label">
                  Agreement
                </label>
                <input
                  type="file"
                  className="input-area admin_partner_det_input"
                  required
                  id="source"
                  name="agreement"
                  // value={agreement}
                  onChange={(e) => {
                    Setagreement(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="col-6 col-md-6">
              <div className="input-group-custom">
                <label htmlFor="source" className="label">
                  Per Sq.Ft. Amt.
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="source"
                  name="persfamt"
                  value={values.persfamt}
                  //   onChange={(e) => {
                  //     SetPersfamt(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PartnerAgrement;
