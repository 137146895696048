import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
const floorData = [];
function Specs({ floorNo, handleAreaChange, indx, getvalues }) {
  const [Packnew, SetPacknew] = useState("");
  const [SecondStepSizenew, SetSecondStepSizenew] = useState("");
  const [SecondStepDescnew, SetSecondStepDescnew] = useState("");
  //   const [usedArea, setUsedArea] = useState();
  var special = [
    "Ground Floor",
    "First Floor",
    "Second Floor",
    "Third Floor",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];
  function stringifyNumber(n) {
    if (n < 20) return special[n];
  }

  const savenextfloordetails = (specs, size, description) => {
    if (
      Packnew === "" ||
      SecondStepSizenew === "" ||
      SecondStepDescnew === ""
    ) {
      alert("please fill all the floor details before proceeding");
    } else {
      floorData.push({
        // specs: specs,
        // areaSize: size,
        // desc: description,

        // floorNo: stringifyNumber(floorNo),

        floor_name: stringifyNumber(floorNo),
        floor_number: floorNo,
        specs: specs,
        area: size,
        description: description,
      });

      localStorage.setItem("AllFloordata", JSON.stringify(floorData));
      console.log(floorData);
      // ---------------------------------
      // setFloorDetails((prev) => [
      //   ...prev,
      //   {
      //     specs: specs,
      //     areaSize: size,
      //     desc: description,
      //   },
      // ]);

      // console.log("Floor Wise Details", floorDetails);
      // -----------------------

      // NextFloorDetails.push({
      //   Specifi: specs,
      //   Arsize: size,
      //   Descript: description,
      // });
      // // console.log("Next Floor Details", NextFloorDetails);

      // addnewvalues(NextFloorDetails);

      // -------------------------------

      // sizearray.push({
      //   size,
      // });

      // calval = sizearray.reduce(function (sum, current) {
      //   return parseInt(sum) + parseInt(current.size);
      // }, 0);

      //   setUsedArea((prevUsedArea) => prevUsedArea + +size);

      handleAreaChange(size, floorNo);
      // total builtup = 830

      // totalbuiltip = totalbuiltup - size
      // 30
      // array of totl size 1000
      // remaining = total size
      // remaining = remaining size(1000) - size

      // localStorage.removeItem("AllFloordata");
    }
  };
  return (
    <div>
      <div className="row d-flex justify-content-between align-items-center p-3">
        <div className="col-2 col-md-3">
          <select
            className="input_fields_quoteg"
            name="package"
            label="Package"
            onChange={(e) => {
              SetPacknew(e.target.value);
            }}
          >
            <option selected disabled value="">
              Select Specs
            </option>
            {floorNo === 0 ? (
              <>
                <option value="1 Car Parking">1 Car Parking</option>
                <option value="2 Car Parking">2 Car Parking</option>
                <option value="Parking Area">Parking Area</option>
                <option value="Bike Area">Bike Parking</option>
                <option value="Parking Allowance">Parking Allowance</option>
                <option value="1 BHK">1 BHK</option>
                <option value="2 BHK">2 BHK</option>
                <option value="3 BHK">3 BHK</option>
                <option value="other">Other</option>
              </>
            ) : (
              ""
            )}
            {floorNo > 0 ? (
              <>
                <option value="1 BHK">1 BHK</option>
                <option value="2 BHK">2 BHK</option>
                <option value="3 BHK">3 BHK</option>
                <option value="Balcony">Balcony</option>
                <option value="other">Other</option>
              </>
            ) : (
              ""
            )}
          </select>
        </div>
        <div className="col-2 col-md-3">
          <input
            type="number"
            className="input_fields_quoteg"
            placeholder="Size In Sq.ft."
            onChange={(e) => {
              SetSecondStepSizenew(e.target.value);
            }}
          />
        </div>
        {/* {Packnew === "Car Parking" ? (
          <div className="col-2 col-md-1">
            <input
              type="number"
              className="input_fields_quoteg"
              placeholder="No"
              onChange={(e) => {
                SetSecondStepSizenew(e.target.value);
              }}
            />
          </div>
        ) : (
          ""
        )} */}

        <div className="col-2 col-md-5">
          <input
            type="text"
            className="input_fields_quoteg"
            placeholder="Extra Description"
            onChange={(e) => {
              SetSecondStepDescnew(e.target.value);
            }}
          />
        </div>
        <div className="col-2 col-md-1">
          <button
            className="submit_cont_btn w-100"
            onClick={() => {
              savenextfloordetails(
                Packnew,
                SecondStepSizenew,
                SecondStepDescnew
              );
              getvalues();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default Specs;
