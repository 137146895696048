import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import axios from "axios";
// import NoData from "./NoData";

import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import { startOfDay, endOfDay, subMonths, subYears } from "date-fns";

const ContactUploadedvsDeal = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [convertedLeads, setconvertedLeads] = useState("");
  const [notconvertedLeads, setnotconvertedLeads] = useState("");
  const [convertedleadspercentage, setconvertedleadspercentage] = useState("");
  const [notconvertedleadspercentage, setnotconvertedleadspercentage] =
    useState("");
  //   Date
  var date = new Date();
  let dateValue = [
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ];

  // const [startDate, setStartDate] = useState(dateValue[0].toLocaleDateString());
  // const [endDate, setEndDate] = useState(dateValue[1].toLocaleDateString());

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    getContactData();
  }, [startDate, endDate]);

  const getContactData = () => {
    axios
      .get(`${SERVER_URL}/lgsLogs?from_date=${startDate}&to_date=${endDate}`)
      .then((res) => {
        // console.log("res", res);
        setconvertedLeads(res.data.numberdata.converted);
        setnotconvertedLeads(res.data.numberdata.not_converted);
        setconvertedleadspercentage(res.data.percentdata.converted);
        setnotconvertedleadspercentage(res.data.percentdata.not_converted);
      });
    // .catch((err) => alert(err));
  };

  // OnChange Function for Date Range Picker
  const onChange = (dates) => {
    // console.log("dates", dates);
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");
      if (start < end) {
        setStartDate(start);
        setEndDate(end);
      } else {
        setStartDate(end);
        setEndDate(start);
      }
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const Ranges = [
    {
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Month",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
    },
    {
      label: "3 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
    },
    {
      label: "6 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
    },
    {
      label: "Year",
      value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
    },
  ];

  const data = [
    {
      name: "Converted",
      value: convertedLeads,
      percentage: convertedleadspercentage,
    },
    {
      name: "Not Converted",
      value: notconvertedLeads,
      percentage: notconvertedleadspercentage,
    },
  ];

  // console.log("data", data);

  const COLORS = ["rgba(60,214,93)", "rgba(239,59,59)"];
  //   const CustomTooltip = ({ active, payload }) => {
  //     console.log("paylod", payload);
  //     // if (active) {
  //     return (
  //       <div className="subscribers-by-channel-tooltip">
  //         <p className="subscribers-by-channel-tooltip-title">
  //           {payload[0].name}
  //         </p>
  //         <p className="subscribers-by-channel-tooltip-data">
  //           {`Value : ${payload[0].value}`}
  //         </p>
  //         <p className="subscribers-by-channel-tooltip-data">
  //           {`Percentage : ${payload[0].payload.payload.percentage}`}
  //         </p>
  //       </div>
  //     );
  //     // }
  //   };
  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div
          className="subscribers-by-channel-tooltip"
          style={{
            background: "#fff",
            color: "#000",
            padding: "5px",
            border: "1px solid #000",
          }}
        >
          <p className="subscribers-by-channel-tooltip-title mb-0 mt-0">
            {payload[0].name}
          </p>
          <p className="subscribers-by-channel-tooltip-label mb-0 mt-0">{`Value : ${payload[0].value}`}</p>
          <p className="subscribers-by-channel-tooltip-intro mb-0 mt-0">
            {" "}
            {`Percentage : ${payload[0].payload.payload.percentage}`}
          </p>
        </div>
      );
    }

    return null;
  };
  return (
    <div>
      <div className="p-4 d-flex justify-content-between align-items-center">
        <span className="pie_graph_head_txt">
          Contacts Uploaded vs Deal Converted
        </span>
        <div className="date_range-picker">
          <DateRangePicker
            // style={{ left: "780px" }}
            name="start"
            placeholder="Select Date Range"
            format="dd/MM/yyyy"
            character="-"
            placement="bottomEnd"
            onChange={onChange}
            ranges={Ranges}
            // defaultValue={dateValue}
            // cleanable={false}
          />
        </div>
      </div>
      <hr className="mt-0 mb-0" />
      {convertedLeads != "" || notconvertedLeads != "" ? (
        <ResponsiveContainer width={"90%"} height={280}>
          <PieChart height={280}>
            <Pie
              data={data}
              //   label={renderCustomizedLabel}
              cx="50%"
              cy="50%"
              labelLine={false}
              // label={renderCustomizedLabel}
              // outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              startAngle={90}
              endAngle={-270}
              height={280}
              width={280}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>

            <Tooltip content={<CustomTooltip />} />

            <Legend
              align="right"
              verticalAlign="middle"
              layout="vertical"
              formatter={(value) => (
                <span className="text-color-class">{value}</span>
              )}
            />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <div>{/* <NoData /> */}</div>
      )}
    </div>
  );
};

export default ContactUploadedvsDeal;
