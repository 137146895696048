import React, { useEffect, useState } from "react";
import IS_SideNav from "./IS_SideNav";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { CgArrowRightR } from "react-icons/cg";
import Footer from "../Dashboard/Footer";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const ISRPReferedPartner = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [leaddata, Setleaddata] = useState([]);
  const [searchterm, Setsearchterm] = useState("");
  const { Id } = useParams();
  // console.log("REDD", Id);

  let history = useHistory();
  useEffect(() => {
    getrps();
  }, []);

  const getrps = () => {
    const valid = localStorage.getItem("ISName");
    if (valid === null) {
      history.push("/");
    }

    axios.get(`${SERVER_URL}/getrefpartner?_id=${Id}`).then((respon) => {
      Setleaddata(respon.data.Referral_partners);
      // console.log("lead data", respon);
    });
  };

  return (
    <div>
      <div>
        <IS_SideNav />
      </div>
      <div className="main">
        <div className="content-tabs">
          <div>
            <div className="top_menu_bar">
              <div>
                {/* <Link
                  to={`/ispartnerdetails/${Id}`}
                  className="partner_back_btn"
                >
                  <span>
                    <ArrowBackIosNewIcon />
                    Back
                  </span>
                </Link> */}

                <Link
                  to={`/ispartnerdetails/${Id}`}
                  className="partner_back_btn"
                >
                  <div>
                    <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                    <span>Back</span>
                  </div>
                </Link>
              </div>
              <div>
                <div className="d-flex justify-content-around">
                  <div className="search_div" style={{ width: "100%" }}>
                    <SearchIcon />
                    <input
                      type="text"
                      className="top_menu_search"
                      placeholder="Search Here.."
                      onChange={(event) => {
                        Setsearchterm(event.target.value);
                      }}
                    />
                  </div>

                  {/* <div>
                    <AddCSReferalReferedRP ids={Id} />
                  </div> */}
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="table_div">
                <table className="table leaderboard_table">
                  <tbody>
                    {leaddata != "" ? (
                      <tr>
                        <th>Partner Name</th>
                        <th>Mobile No.</th>
                        <th>Partner ID</th>
                        <th>Email</th>

                        <th className="text-center">Action</th>
                      </tr>
                    ) : (
                      ""
                    )}

                    {leaddata != "" ? (
                      leaddata
                        .filter((cust) => {
                          if (searchterm == "") {
                            return cust;
                          } else if (
                            cust.fullname
                              .toLowerCase()
                              .includes(searchterm.toLowerCase())
                          ) {
                            return cust;
                          } else if (
                            cust.referral_ID
                              .toLowerCase()
                              .includes(searchterm.toLowerCase())
                          ) {
                            return cust;
                          }
                        })
                        .map((cust) => {
                          return (
                            <tr>
                              <td>
                                {cust.fullname === "" ? "-" : cust.fullname}
                              </td>
                              <td>
                                {cust.contact_no === "" ? "-" : cust.contact_no}
                              </td>
                              <td>
                                {cust.referral_ID === ""
                                  ? "-"
                                  : cust.referral_ID}
                              </td>
                              <td>{cust.email === "" ? "-" : cust.email}</td>

                              <td>
                                <Link
                                  to={`/isrpreferedrpdetails/${cust._id}`}
                                  className="viewmore"
                                >
                                  <CgArrowRightR />
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <div className="d-flex">
                        <div>
                          <WarningRoundedIcon className="no_data_alert" />
                        </div>
                        <div>
                          <h3>No records found for Partners </h3>
                        </div>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default ISRPReferedPartner;
