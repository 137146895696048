import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import "./settings.css";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import Popup from "reactjs-popup";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "../../Fields/TextField";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
export default function EditDocument({
  idnt,
  docname,
  closepop,
  filenamedoc,
  getalldocsfunc,
}) {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [docs, Setdocs] = useState("");

  //   validations
  function validatePhoto(value) {
    let error;
    if (docs === null) {
      error = "Document is Required";
    }
    return error;
  }
  function validateDocumentname(value) {
    let error;
    if (!value) {
      error = "Document name is Required";
    }
    return error;
  }
  const updatedocuments = (data, resetForm) => {
    const formdata = new FormData();
    formdata.append("document_name", data.document_name);
    formdata.append("document", docs);
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${SERVER_URL}/updateDocuments?_id=${idnt}`, formdata, {
        headers,
      })
      .then((reps) => {
        let Status = reps.data.status;
        if (Status === "success") {
          toast.success("Package Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.$("#popup_addPack").modal("hide");
          document.getElementById("closematerialpopup2").click();

          resetForm({ data: "" });
        } else {
          window.$("#popup_addPack").modal("hide");
          resetForm({ data: "" });
          toast.error("Something Went wrong try after some Time!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  //   to delete docs
  const deletedocs = () => {
    axios.post(`${SERVER_URL}/deletePackagesDocs?_id=${idnt}`).then((reps) => {
      let Status = reps.data.status;
      if (Status === "success") {
        toast.success("Document Deleted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.$("#popup_addPack").modal("hide");
        document.getElementById("closematerialpopup2").click();
      } else {
        window.$("#popup_addPack").modal("hide");

        toast.error("Something Went wrong try after some Time!!!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const changedocument = (e) => {
    Setdocs(e.target.files[0]);
  };

  return (
    <div>
      <div className="modal-body">
        <div>
          <div className="pb-3">
            <div className="d-flex justify-content-between form_head_txt">
              <div className="">
                <span>Document Details</span>
              </div>
              <div type="button" onClick={closepop} aria-label="Close">
                <CloseIcon />
              </div>
            </div>

            <Formik
              initialValues={{
                document_name: docname,
                document: "",
              }}
              onSubmit={(values, { resetForm }) => {
                updatedocuments(values, resetForm);
              }}
            >
              {({ errors, touched }) => (
                <div>
                  <Form>
                    <div className="row pt-3">
                      <div className="col-12 col-md-12">
                        <label className="filter_input_head_txt pb-3">
                          Document Name
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            className="field_input_txt"
                            name="document_name"
                            validate={validateDocumentname}
                            placeholder="Enter Document Name"
                          />
                          {errors.document_name && touched.document_name && (
                            <div className="pt-2 error">
                              {errors.document_name}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12 col-md-12">
                        <label className="filter_input_head_txt pb-3">
                          Upload Document
                        </label>
                        <div className="">
                          <input
                            type="file"
                            className="field_input_txt"
                            name="document"
                            validate={validatePhoto}
                            onChange={(e) => changedocument(e)}
                          />
                          <p>{filenamedoc}</p>
                          {errors.document && touched.document && (
                            <div className="pt-2 error">{errors.document}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="pt-3">
                      <button
                        type="submit"
                        className="btn btn-lg save_changes_btn"
                      >
                        Save Changes
                      </button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
            <button
              className="btn btn-lg remove_btn mt-4"
              style={{
                width: "46%",
                position: "absolute",
                top: "15.1em",
                left: "18em",
              }}
              onClick={() => {
                deletedocs();
              }}
            >
              <DeleteIcon className="delpackage_btn" />
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
