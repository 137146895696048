import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AdminNav from "../AdminNavBar/AdminNav";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import whatsApp from "../../Assets/logos_whatsapp.svg";
import Footer from "../Dashboard/Footer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactTooltip from "react-tooltip";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field, useField } from "formik";
import LeadPersonal from "../../BasicUI/LeadDetails/LeadPersonal";
import LeadContact from "../../BasicUI/LeadDetails/LeadContact";
import LeadStage1Details from "../../BasicUI/LeadDetails/LeadStage1Details";
import LeadStage2Details from "../../BasicUI/LeadDetails/LeadStage2Details";
import LeadStage3Details from "../../BasicUI/LeadDetails/LeadStage3Details";
import LeadDealDetails from "../../BasicUI/LeadDetails/LeadDealDetails";
import LeadMeeting from "../../BasicUI/LeadDetails/LeadMeeting";
import {
  validateMeetingDate,
  validateBDEAssigne,
  validateFromTime,
  validateToTime,
  validateMeetingLink,
  validateMeetingLocation,
} from "../Helpers/validation";
import DynamicInputFields from "../ChannelSales/DyamicInputFields";
import DynamicInputFieldDisplay from "../ChannelSales/DynamicInputFieldsDisplay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function LeadDetailsAllLeads() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [btnloader, setbtnloader] = useState(false);
  const [CreditedStatus, SetCreditedStatus] = useState(false);
  const [show, toggleShow] = useState(false);
  const [showedit, toggleShowedit] = useState(false);
  const [showleadproedit, toggleshowleadproedit] = useState(false);
  const [showeditkyc, toggleShoweditkyc] = useState(false);
  const [showtarget, toggleShowtarget] = useState(false);
  const [showtarget2, toggleShowtarget2] = useState(false);
  const [showtarget3, toggleShowtarget3] = useState(false);
  const [showref, toggleShowref] = useState(false);
  const [showprofilepic, toggleShowProfilepic] = useState(false);
  const [showtimepic, toggleshowtimepic] = useState(false);
  const [profilepic, setprofilepic] = useState("");
  const [showcmt, toggleShowcomment] = useState("");
  const [showmeetedit, toggleshowmeetedit] = useState(false);
  // SQFT
  const [showeditsqft, toggleshoweditsqft] = useState(false);
  const [Persfamt, SetPersfamt] = useState([50]);
  const [refplotnumber, Setrefplotnumber] = useState("");
  const { Id } = useParams();
  const { Page } = useParams();
  // console.log("id", Id);

  let history = useHistory();
  // console.log("total earning id", total_earning);
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  // state to set all personal info values of partners
  const [refnames, Setrefnames] = useState("");
  const [leadpackagename, Setleadpackagename] = useState("");
  const [reflocation, Setreflocation] = useState("");
  const [refsqft, Setrefsqft] = useState("");

  const [leadbookingamt, Setleadbookingamt] = useState("");
  const [leaddpaymentstage, Setleaddpaymentstage] = useState([]);
  const [paymentdate, Setpaymentdate] = useState([]);
  // const [paymentmode, Setpaymentmode] = useState([]);
  const [paystage, Setpaystage] = useState([]);

  const [refconstructiondate, Setrefconstructiondate] = useState("");
  const [leadprestatusvalue, Setleadprestatusvalue] = useState([]);
  const [leadpoststatusvalue, Setleadpoststatusvalue] = useState([]);

  // state to set contact info
  const [refemail, Setrefemail] = useState("");
  const [refmobile, Setrefmobile] = useState("");

  const [leadconstructionstage, Setleadconstructionstage] = useState([]);
  const [tdsvalue, Settdsvalue] = useState([]);

  // stage payments
  const [stage1netearning, Setstage1netearning] = useState([]);
  const [stage2netearning, Setstage2netearning] = useState([]);
  const [stage3netearning, Setstage3netearning] = useState([]);
  const [stage2leadpaymentdatefrom, Setstage2leadpaymentdatefrom] = useState(
    []
  );
  const [stage2leadpaymentdateto, Setstage2leadpaymentdateto] = useState([]);
  const [stage3leadpaymentdatefrom, Setstage3leadpaymentdatefrom] = useState(
    []
  );
  const [approvedvalue, Setapprovedvalue] = useState([]);
  const [stage3leadpaymentdateto, Setstage3leadpaymentdateto] = useState([]);

  // ----------------------------For payment stages ----------------------------
  const [paymentstage1, Setpaymentstage1] = useState("");
  const [paymentstage2, Setpaymentstage2] = useState("");
  const [paymentstage3, Setpaymentstage3] = useState("");

  const [releaserangefrom1, Setreleaserangefrom1] = useState("");
  const [releaserangefrom2, Setreleaserangefrom2] = useState("");
  const [releaserangefrom3, Setreleaserangefrom3] = useState("");

  const [releaserangeto1, Setreleaserangeto1] = useState("");
  const [releaserangeto2, Setreleaserangeto2] = useState("");
  const [releaserangeto3, Setreleaserangeto3] = useState("");

  const [paymentdate1, Setpaymentdate1] = useState("");
  const [paymentdate2, Setpaymentdate2] = useState("");
  const [paymentdate3, Setpaymentdate3] = useState("");

  const [paymentmode1, Setpaymentmode1] = useState("");
  const [paymentmode2, Setpaymentmode2] = useState("");
  const [paymentmode3, Setpaymentmode3] = useState("");

  const [stage1, Setstage1] = useState("");
  const [stage2, Setstage2] = useState("");
  const [stage3, Setstage3] = useState("");

  const [creditedstat1, Setcreditedstat1] = useState("");
  const [creditedstat2, Setcreditedstat2] = useState("");
  const [creditedstat3, Setcreditedstat3] = useState("");

  const [constStage1, SetconstStage1] = useState("");
  const [constStage2, SetconstStage2] = useState("");
  const [constStage3, SetconstStage3] = useState("");

  const [CustomerStatus, SetCustomerStatus] = useState([]);

  const [commentvalue, Setcommentvalue] = useState([]);
  const [partnerid, Setpartnerid] = useState("");

  const [comments, Setcomments] = useState([]);
  const [CommentsData, SetCommentsData] = useState([]);

  // Call Status
  const [callStatus, setcallStatus] = useState("");
  // Meeting
  const [meetdate, setmeetdate] = useState("");
  const [meetstarttime, setmeetstarttime] = useState("");
  const [meetendttime, setmeetendttime] = useState("");
  const [meetassignbde, setmeetassignbde] = useState("");
  const [meetlink, setmeetlink] = useState("");
  const [meetdesc, setmeetdesc] = useState("");
  const [meetloc, setmeetloc] = useState("");
  const [updmeetloc, setupdmeetloc] = useState("");
  const [updmeetlink, setupdmeetlink] = useState("");
  const [developmentName, SetdevelopmentName] = useState("");
  const [nooffloor, Setnooffloor] = useState("");
  const [source, setsource] = useState("");
  const [referredBy, setreferredBy] = useState("");
  const [plotlocation, setplotlocation] = useState("");
  const [projectValue, SetprojectValue] = useState("");
  const [leadbudget, setleadbudget] = useState("");
  // bde Assign
  const [bdeassignOption, setbdeassignOption] = useState([]);

  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }

    getLeadData();

    getStagePaymentDetails();

    // Deductions
    axios
      .get(`${SERVER_URL}/getearningdetails?_id=${Id}`)
      .then((resultvalue) => {
        // console.log("Apprr val", resultvalue.data.Approved_value);
        Setapprovedvalue(resultvalue.data.Approved_value);
      });

    // To Fetch Assign To Options DropDown for BDE
    axios.get(`${SERVER_URL}/bdlist`).then((response) => {
      // console.log("Cust", response);
      setbdeassignOption(response.data.Assignees);
      // console.log("Customer", Customer);
    });
  }, []);

  const getLeadData = () => {
    // To get data of lead by their ID's
    axios.get(`${SERVER_URL}/leadbyid?_id=${Id}`).then((responsedata) => {
      // console.log("data", responsedata);
      // Personal info
      Setpartnerid(responsedata.data.listofcustomers.partner_id);
      Setrefplotnumber(responsedata.data.listofcustomers.plot_number);
      Setrefnames(responsedata.data.listofcustomers.name);
      Setleadpackagename(responsedata.data.listofcustomers.packagename);
      Setreflocation(responsedata.data.listofcustomers.location);
      Setrefsqft(responsedata.data.listofcustomers.area);
      Setleadbookingamt(responsedata.data.listofcustomers.booking_amt);
      Setrefconstructiondate(responsedata.data.listofcustomers.start_date);
      Setleadprestatusvalue(responsedata.data.listofcustomers.prestatus);
      // sqft
      SetPersfamt(responsedata.data.listofcustomers.persfamt);
      Setleadpoststatusvalue(responsedata.data.listofcustomers.poststatus);
      Setleadconstructionstage(
        responsedata.data.listofcustomers.construction_stage
      );
      // Call Status
      setcallStatus(responsedata.data.listofcustomers.call_status);
      SetdevelopmentName(responsedata.data.listofcustomers.development_name);
      SetprojectValue(responsedata.data.listofcustomers.project_value);
      Setnooffloor(responsedata.data.listofcustomers.no_of_floors);
      setsource(responsedata.data.listofcustomers.lead_source);
      setreferredBy(responsedata.data.listofcustomers.partner_name);
      setplotlocation(responsedata.data.listofcustomers.plot_location);
      setleadbudget(responsedata.data.listofcustomers.lead_budget);
      setmeetdate(responsedata.data.listofcustomers.meeting_date);
      setmeetstarttime(responsedata.data.listofcustomers.meeting_from_time);
      setmeetendttime(responsedata.data.listofcustomers.meeting_to_time);
      setmeetassignbde(responsedata.data.listofcustomers.assigned_bde);
      setmeetlink(responsedata.data.listofcustomers.meeting_link);
      setmeetdesc(responsedata.data.listofcustomers.meeting_description);
      setmeetloc(responsedata.data.listofcustomers.meeting_location);
      setupdmeetlink(responsedata.data.listofcustomers.meeting_link);
      setupdmeetloc(responsedata.data.listofcustomers.meeting_location);
      SetCustomerStatus(responsedata.data.listofcustomers.customerstatus);
      // console.log(
      //   "CUSTOMSTATUS",
      //   responsedata.data.listofcustomers.customerstatus
      // );
      Setcommentvalue(responsedata.data.listofcustomers.comments);
      // Contact info
      Setrefemail(responsedata.data.listofcustomers.email);
      Setrefmobile(responsedata.data.listofcustomers.contact_no);
      const len = responsedata.data.listofcustomers.stages.length - 1;
      // console.log("looo", len);
      if (len >= 0) {
        Setleaddpaymentstage(
          responsedata.data.listofcustomers.stages[len].stage
        );
      } else {
        // Setleaddpaymentstage("NA");
        // Setleaddpaymentstagefrom("NA");
      }
      Setcomments(responsedata.data.listofcustomers.comments);
      setCounter1(responsedata.data.listofcustomers.comments.length);
    });
  };

  const [counter, setCounter] = useState(1);
  const [counter1, setCounter1] = useState(1);

  const handleClick = (e) => {
    e.preventDefault();
    if (counter1 < 14) {
      setCounter((prevcounter) => prevcounter + 1);
      setCounter1((prevcounter1) => prevcounter1 + 1);
    }
  };

  const getStagePaymentDetails = () => {
    axios
      .get(`${SERVER_URL}/getstagedetails?_id=${Id}`)
      .then((responsedatas) => {
        // console.log("resstage", responsedatas);
        responsedatas.data.Stages.map((stagerecord) => {
          if (stagerecord.stage === "Stage 1") {
            Setpaymentmode1(stagerecord.payment_mode);
            Setpaymentstage1(stagerecord.net_earning);
            Setreleaserangefrom1(stagerecord.payment_date_from);
            Setreleaserangeto1(stagerecord.payment_date_to);
            Setpaymentdate1(stagerecord.payment_date);
            Setstage1(stagerecord.stage);
            Setcreditedstat1(stagerecord.credited);
            SetconstStage1(stagerecord.construction_stage);
          }

          if (stagerecord.stage === "Stage 2") {
            Setpaymentmode2(stagerecord.payment_mode);
            Setpaymentstage2(stagerecord.net_earning);
            Setreleaserangefrom2(stagerecord.payment_date_from);
            Setreleaserangeto2(stagerecord.payment_date_to);
            Setpaymentdate2(stagerecord.payment_date);
            Setstage2(stagerecord.stage);
            Setcreditedstat2(stagerecord.credited);
            SetconstStage2(stagerecord.construction_stage);
          }
          if (stagerecord.stage === "Stage 3") {
            Setpaymentmode3(stagerecord.payment_mode);
            Setpaymentstage3(stagerecord.net_earning);
            Setreleaserangefrom3(stagerecord.payment_date_from);
            Setreleaserangeto3(stagerecord.payment_date_to);
            Setpaymentdate3(stagerecord.payment_date);
            Setstage3(stagerecord.stage);
            Setcreditedstat3(stagerecord.credited);
            SetconstStage3(stagerecord.construction_stage);
          }
        });
      });
  };

  const changeprofile = (e) => {
    setprofilepic(e.target.files[0]);
    // console.log("setting state", e.target.files[0]);
  };

  const saveprofilepic = () => {
    const formdata = new FormData();
    formdata.append("profile_pic", profilepic);
    axios
      .post(`${SERVER_URL}/uploadcustprofile?_id=${Id}`, formdata)
      .then((repos) => {
        console.log("newyear", repos);
      });
  };

  // update Comments
  const updatecmt = (e) => {
    // console.log("CREDDited", CreditedStatus);
    e.preventDefault();
    const cmtdata = {
      comment: commentvalue,
    };
    // console.log("Cmt sending", cmtdata);
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/updatecomments?_id=${Id}`, cmtdata, {
        //pure-wave-48602.herokuapp.com/updatecomments?_id=61bdbda07d7faf9c345647a8
        headers,
      })
      .then((resultz) => {
        let Status = resultz.data.status;
        if (Status === "success") {
          toast.success("Comments edited successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getLeadData();
        } else if (Status === "failed") {
          // alert("Error Occured");
          toast.error("Comments are already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Errorss", err);
        // toast.error(err, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      });
  };

  // TO delete the lead by id
  const deleteleaddetails = () => {
    axios.delete(`${SERVER_URL}/deletecustomer?_id=${Id}`).then((res) => {
      // console.log("Deleted record", res);
      let Status = res.data.status;
      if (Status === "success") {
        window.$("#deletePartner").modal("hide");
        window.$("#PartnerAddedSuccessfully").modal("show");
        window.setTimeout(function () {
          window.$(".modal-backdrop").remove();
          history.push(`/allcustomers/${Page}`);
        }, 3000);
      } else {
        toast.error("Internal Error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // history.push(`/allcustomers`);
    });
  };

  const ScheduleMeeting = (e) => {
    e.preventDefault();

    let meeting = e.target.value;
    if (meeting === "Online") {
      window.$("#Schedule_Online_meeting").modal("show");
    } else if (meeting === "Offline") {
      window.$("#Schedule_Offline_meeting").modal("show");
    }
  };

  const commentsData = (comment_date, call_status, comment, index) => {
    const labarray = CommentsData;
    labarray.splice(index, 1, {
      comment_date: comment_date,
      call_status: call_status,
      comment: comment,
    });

    SetCommentsData([]);
    SetCommentsData(labarray);
  };

  const saveComments = () => {
    if (CommentsData.length > 0) {
      const comment = {
        data: CommentsData,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(`${SERVER_URL}/updatecomments?_id=${Id}`, comment, {
          headers,
        })
        .then((res) => {
          let Status = res.data.status;
          if (Status === "success") {
            toast.success("Comments are Added successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getLeadData();
            // setCounter(1);
            // setCounter1(1);
            window.setTimeout(function () {
              window.location.reload();
            }, 100);
          } else if (Status === "failed") {
            toast.error("Comments are Already Exists", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Some Internal Error", err);
        });
    } else {
      alert("please fill all the details");
    }
  };

  return (
    <div>
      <div>
        <AdminNav />
      </div>

      <div className="main">
        <div className="content-tabs">
          <div>
            <div className="top_menu_bar">
              <div>
                <div>
                  {/* <button
                    onClick={() => history.goBack()}
                    className="partner_back_btn"
                  >
                    <span>
                      <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                      <span>Back</span>
                    </span>
                  </button> */}
                  <Link
                    to={`/allcustomers/${Page}`}
                    className="partner_back_btn"
                  >
                    <span>
                      <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                      <span>Back</span>
                    </span>
                  </Link>
                </div>
              </div>

              <div>
                <div
                  className="d-flex justify-content-around"
                  style={{ gap: "20px" }}
                >
                  <div>
                    {meetlink !== "" || meetloc !== "" ? (
                      ""
                    ) : callStatus === "Meeting Fixed" ? (
                      <div>
                        {/* <div className="d-flex justify-content-around"> */}
                        <select
                          onChange={(e) => {
                            ScheduleMeeting(e);
                          }}
                          style={{ border: "none", height: "47px" }}
                          className="form-select select-form"
                        >
                          <option disabled selected>
                            Schedule Meeting
                          </option>
                          <option value="Online">Online Meeting</option>
                          <option value="Offline">Offline Meeting</option>
                        </select>

                        {/* </div> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      className="delete_btn"
                      data-toggle="modal"
                      data-target=".deletelead"
                    >
                      <DeleteIcon className="del_icons" />
                      Delete
                    </button>

                    <div
                      id="deletePartner"
                      className="modal fade deletelead"
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-sm modal-dialog-centered ">
                        <div
                          className="modal-content modal-sm modal_content_whole"
                          style={{ background: "white" }}
                        >
                          <div className="modal-body">
                            <div>
                              <div className="popup_icon_center">
                                <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                              </div>
                              <div className="pt-2 popup_text_center">
                                <span className="popup_addbankdetails_text">
                                  Are you sure want to Delete the Lead ?
                                </span>
                              </div>
                              <div className="pt-4 m-0 d-flex justify-content-center">
                                <div
                                  style={{ width: "50%", textAlign: "center" }}
                                >
                                  <button
                                    type="button"
                                    className="popup_btn_unlinkaccount"
                                    onClick={deleteleaddetails}
                                    // data-dismiss="modal"
                                  >
                                    Delete
                                  </button>
                                </div>
                                <div
                                  style={{ width: "50%", textAlign: "center" }}
                                >
                                  <button
                                    // type="button"
                                    className="popup_btn_close"
                                    data-dismiss="modal"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Sucuesfully deleted */}

                    <div
                      className="modal leaddeletedsucess"
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                      id="PartnerAddedSuccessfully"
                    >
                      <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div
                          className="modal-content modal-sm modal_content_whole"
                          style={{ background: "white" }}
                        >
                          <div className="modal-body">
                            <div>
                              <div className="popup_icon_center">
                                <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                              </div>
                              <div className="popup_text_center">
                                <p className="pt-2 popup_addbankdetails_text">
                                  Lead Deleted Successfully
                                </p>
                                <div className="pt-2 m-0 d-flex justify-content-center">
                                  <div
                                    style={{
                                      width: "50%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <button
                                      className="popup_btn_linkaccount"
                                      data-dismiss="modal"
                                      onClick={() => {
                                        history.push(`/allcustomers/${Page}`);
                                      }}
                                    >
                                      OK
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="pt-2 popup_text_center">
                                <span className="popup_addbankdetails_text">
                                  Lead Deleted Successfully
                                </span>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="partner_details_edit_sec">
              <div className="row">
                <div className="col-md-8">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      name: refnames,
                      packagename: leadpackagename,
                      area: refsqft,
                      plot_number: refplotnumber,
                      development_name: developmentName,
                      no_of_floors: nooffloor,
                      start_date: refconstructiondate,
                      //  status: CustomerStatus,
                      plot_location: plotlocation,
                      lead_budget: leadbudget,
                    }}
                    onSubmit={(values) => {
                      // console.log("val", values);
                      const headers = {
                        "Content-Type": "application/json",
                      };

                      axios
                        .post(
                          `${SERVER_URL}/updatecustomer?_id=${Id}`,
                          values,
                          {
                            headers,
                          }
                        )
                        .then((resul) => {
                          let Status = resul.data.status;
                          if (Status === "success") {
                            toast.success(
                              "Profile Details are edited sucessfully",
                              {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                            getLeadData();
                            toggleShow(false);
                            // window.setTimeout(function () {
                            //   window.location.reload();
                            // }, 100);
                          } else if (Status === "failed") {
                            toggleShow(false);
                            // alert("Profile Details are already exists");
                            toast.error("Profile Details Already Exists", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }
                        })
                        .catch((err) => {
                          alert("Some Internal Error", err);
                        });
                    }}
                  >
                    {({ errors, touched, handleSubmit, values }) => (
                      <Form>
                        <div className="p-3 admin_patrner_personal_info">
                          <div className="d-flex justify-content-between head_txt head_txt_margin">
                            <p>Lead Personal Info</p>

                            <div
                              className="edit_icon"
                              onClick={() => toggleShow(!show)}
                            >
                              {!show && (
                                <div>
                                  <EditIcon className="search_icons" />
                                  <span> Edit</span>
                                </div>
                              )}
                              {show && (
                                <div>
                                  <button
                                    type="submit"
                                    className="edit_icon"
                                    onClick={handleSubmit}
                                  >
                                    Save
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <LeadPersonal
                            values={values}
                            errors={errors}
                            touched={touched}
                            show={show}
                            refnames={refnames}
                            leadpackagename={leadpackagename}
                            refsqft={refsqft}
                            refplotnumber={refplotnumber}
                            developmentName={developmentName}
                            nooffloor={nooffloor}
                            refconstructiondate={refconstructiondate}
                            callStatus={callStatus}
                            source={source}
                            referredBy={referredBy}
                            plotlocation={plotlocation}
                            leadbudget={leadbudget}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="col-md-4">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      email: refemail,
                      contact_no: refmobile,
                      location: reflocation,
                    }}
                    onSubmit={(values) => {
                      const headers = {
                        "Content-Type": "application/json",
                      };

                      axios
                        .post(
                          `${SERVER_URL}/updatecustomer?_id=${Id}`,
                          values,
                          {
                            headers,
                          }
                        )
                        .then((resul) => {
                          let Status = resul.data.status;
                          if (Status === "success") {
                            toast.success(
                              "Lead Contact Details are edited sucessfully",
                              {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                            getLeadData();
                            toggleShowedit(false);
                            // window.setTimeout(function () {
                            //   window.location.reload();
                            // }, 100);
                          } else if (Status === "failed") {
                            toggleShowedit(false);
                            // alert("Profile Details are already exists");
                            toast.error("Lead Contact Details Already Exists", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }
                        })
                        .catch((err) => {
                          alert("Some Internal Error", err);
                        });
                    }}
                  >
                    {({ errors, touched, handleSubmit, values }) => (
                      <Form>
                        <div className="p-3 admin_patrner_personal_info">
                          <div className="d-flex justify-content-between head_txt head_txt_margin">
                            <p>Lead Contact Info</p>
                            <div
                              className="edit_icon"
                              onClick={() => toggleShowedit(!showedit)}
                            >
                              {!showedit && (
                                <div>
                                  <EditIcon className="search_icons" />
                                  <span> Edit</span>
                                </div>
                              )}
                              {showedit && (
                                <div>
                                  <button
                                    type="submit"
                                    className="edit_icon"
                                    onClick={handleSubmit}
                                  >
                                    Save
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <LeadContact
                            values={values}
                            errors={errors}
                            touched={touched}
                            showedit={showedit}
                            refemail={refemail}
                            refmobile={refmobile}
                            reflocation={reflocation}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>

              {source === "DM" || source === "Others" ? (
                <div className="row mt-3">
                  <div className="col-md-8">
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Comments</p>
                        <div
                          className="edit_icon"
                          onClick={() => toggleShowcomment(!showcmt)}
                        >
                          {!showcmt && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span> Edit</span>
                            </div>
                          )}
                          {showcmt && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={saveComments}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!showcmt && (
                        <div className="viewing_details">
                          {comments != "" ? (
                            comments.map((cust) => {
                              return (
                                <div className="pt-2 row">
                                  <div className="col-12 col-md-4">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Date
                                      </p>
                                      <span className="admin_type_value">
                                        {cust.comment_date
                                          .slice(0, 10)
                                          .split("-")
                                          .reverse()
                                          .join("-")}
                                        {/* {comment_date === "" ? "NA" : comment_date} */}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-4">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Call Status
                                      </p>
                                      <span className="admin_type_value">
                                        {cust.call_status}
                                        {/* {comment_date === "" ? "NA" : comment_date} */}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-4">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Comments
                                      </p>
                                      <span className="admin_type_value">
                                        {cust.comment}
                                        {/* {remark === "" ? "NA" : remark} */}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div>
                              <div>NA</div>
                            </div>
                          )}
                        </div>
                      )}

                      {showcmt && (
                        <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                          {comments.length != 0 ? (
                            <div>
                              <DynamicInputFieldDisplay comments={comments} />
                            </div>
                          ) : (
                            ""
                          )}

                          {Array.from(Array(counter)).map((c, counter) => {
                            return (
                              <DynamicInputFields
                                data={commentsData}
                                counter={counter}
                              />
                            );
                          })}

                          <div className="pt-4">
                            {/* <button>Add Comments</button> */}
                            <button
                              className="add_btn btn_add_comments_inc"
                              onClick={handleClick}
                            >
                              <AddIcon className="search_icons" />
                              Add Comment
                            </button>
                          </div>

                          {/* <div className="input-group-custom">
                              <label htmlFor="loc" className="label">
                                Comments
                              </label>
                              <br />

                              <textarea
                                className="input-area"
                                width="100%"
                                rows="5"
                                cols="20"
                                name="email"
                                value={remark}
                                onChange={(e) => {
                                  Setremark(e.target.value);
                                }}
                              ></textarea>
                            </div> */}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            project_value: projectValue,
                            booking_amt: leadbookingamt,
                            persfamt: Persfamt,
                          }}
                          onSubmit={(values) => {
                            const headers = {
                              "Content-Type": "application/json",
                            };

                            axios
                              .post(
                                `${SERVER_URL}/updatecustomer?_id=${Id}`,
                                values,
                                {
                                  headers,
                                }
                              )
                              .then((res) => {
                                // console.log("Hello Don", res);
                                let Status = res.data.status;
                                if (Status === "success") {
                                  // alert("Profile Details are edited sucessfully");
                                  toast.success(
                                    "Deal Details are edited sucessfully",
                                    {
                                      position: "top-right",
                                      autoClose: 5000,
                                      hideProgressBar: true,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                  // window.setTimeout(function () {
                                  //   window.location.reload();
                                  // }, 100);
                                  getLeadData();
                                  toggleShowref(false);
                                } else if (Status === "failed") {
                                  toggleShowref(false);
                                  // alert("Profile Details are already exists");
                                  toast.error("Deal Details Already Exists", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                }
                              })
                              .catch((err) => {
                                alert("Some Internal Error", err);
                              });
                          }}
                        >
                          {({ errors, touched, handleSubmit, values }) => (
                            <Form>
                              <div className="p-3 admin_patrner_personal_info">
                                <div className="d-flex justify-content-between head_txt head_txt_margin">
                                  <p>Deal Details</p>
                                  <div
                                    className="edit_icon"
                                    onClick={() => toggleShowref(!showref)}
                                  >
                                    {!showref && (
                                      <div>
                                        <EditIcon className="search_icons" />
                                        <span> Edit</span>
                                      </div>
                                    )}
                                    {showref && (
                                      <div>
                                        <button
                                          type="submit"
                                          className="edit_icon"
                                          onClick={handleSubmit}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <LeadDealDetails
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  showref={showref}
                                  Persfamt={Persfamt}
                                  projectValue={projectValue}
                                  leadbookingamt={leadbookingamt}
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>

                    <div className="row mt-3">
                      {updmeetlink !== "" || updmeetloc !== "" ? (
                        <div className="col-12 col-md-12">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              meeting_date: meetdate,
                              assign_bde: meetassignbde,
                              // meeting_time: meettime,
                              meeting_from_time: meetstarttime,
                              meeting_to_time: meetendttime,
                              meeting_location: meetloc,
                              meeting_link: meetlink,
                              meeting_description: meetdesc,
                            }}
                            onSubmit={(values) => {
                              // console.log("val", values);
                              const headers = {
                                "Content-Type": "application/json",
                              };

                              axios
                                .post(
                                  `${SERVER_URL}/updatemeeting?_id=${Id}`,
                                  values,
                                  {
                                    headers,
                                  }
                                )
                                .then((res) => {
                                  // console.log("Hello Don", res);
                                  let Status = res.data.status;
                                  if (Status === "success") {
                                    // alert("Profile Details are edited sucessfully");
                                    toast.success(
                                      "Meeting Details are Updated successfully",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                    getLeadData();
                                    toggleshowmeetedit(false);
                                    // window.setTimeout(function () {
                                    //   window.location.reload();
                                    // }, 100);
                                  } else if (Status === "failed") {
                                    toggleshowmeetedit(false);
                                    // alert("Profile Details are already exists");
                                    toast.error(
                                      "Some Internal Error Try after Some Time...",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                  }
                                });
                            }}
                          >
                            {({ errors, touched, handleSubmit, values }) => (
                              <Form>
                                <div className="p-3 admin_patrner_personal_info">
                                  <div className="d-flex justify-content-between head_txt head_txt_margin">
                                    <p>Meeting Details</p>
                                    {/* Toggle State to edit and save start */}
                                    <div
                                      className="edit_icon"
                                      onClick={() =>
                                        toggleshowmeetedit(!showmeetedit)
                                      }
                                    >
                                      {!showmeetedit && (
                                        <div>
                                          <EditIcon className="search_icons" />
                                          <span> Edit</span>
                                        </div>
                                      )}
                                      {showmeetedit && (
                                        <div>
                                          <button
                                            type="submit"
                                            className="edit_icon"
                                            onClick={handleSubmit}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    {/* Toggle State to edit and save end */}
                                  </div>

                                  <LeadMeeting
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    showmeetedit={showmeetedit}
                                    meetdate={meetdate}
                                    meetassignbde={meetassignbde}
                                    meetstarttime={meetstarttime}
                                    meetendttime={meetendttime}
                                    meetloc={meetloc}
                                    meetlink={meetlink}
                                    meetdesc={meetdesc}
                                    updmeetlink={updmeetlink}
                                    updmeetloc={updmeetloc}
                                    bdeassignOption={bdeassignOption}
                                  />
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row mt-4">
                    <div className="col-md-8">
                      <div className="content-tabs_dashboard">
                        <div
                          className={
                            toggleState === 1
                              ? "content1  active-content1"
                              : "content1"
                          }
                        >
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              credited: creditedstat1,
                              amount: paymentstage1,
                              stage: stage1,
                              payment_date_from: releaserangefrom1,
                              payment_date_to: releaserangeto1,
                              payment_date: paymentdate1,
                              payment_mode: paymentmode1,
                              construction_stage: constStage1,
                            }}
                            onSubmit={(values) => {
                              // alert("hello");
                              // console.log("val", values);
                              const headers = {
                                "Content-Type": "application/json",
                              };

                              axios
                                .post(
                                  `${SERVER_URL}/addpaymentstages?_id=${Id}`,
                                  values,
                                  {
                                    headers,
                                  }
                                )
                                .then((resul) => {
                                  // console.log("Hello Don", resul);
                                  let Status = resul.data.status;
                                  if (Status === "success") {
                                    // alert("Profile Details are edited sucessfully");
                                    toast.success(
                                      "Stage-1 payment are edited sucessfully",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                    getStagePaymentDetails();
                                    toggleShowtarget(false);
                                    // window.setTimeout(function () {
                                    //   window.location.reload();
                                    // }, 100);
                                  } else if (Status === "failed") {
                                    toggleShowtarget(false);
                                    // alert("Profile Details are already exists");
                                    toast.error(
                                      "Stage-1 payment Already Exists",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                  }
                                })
                                .catch((err) => {
                                  // console.log(err);
                                  alert("Some Internal Errors", err);
                                });
                            }}
                          >
                            {({ errors, touched, handleSubmit, values }) => (
                              <Form>
                                <div className="p-3 admin_patrner_personal_info">
                                  <div className="d-flex justify-content-between head_txt head_txt_margin">
                                    <p>Stage-1 Payment Details</p>
                                    <div
                                      className="edit_icon"
                                      onClick={() =>
                                        toggleShowtarget(!showtarget)
                                      }
                                    >
                                      {!showtarget && (
                                        <div>
                                          <EditIcon className="search_icons" />
                                          <span> Edit</span>
                                        </div>
                                      )}
                                      {showtarget && (
                                        <div>
                                          <button
                                            type="submit"
                                            className="edit_icon"
                                            onClick={handleSubmit}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <LeadStage1Details
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    showtarget={showtarget}
                                    creditedstat1={creditedstat1}
                                    paymentstage1={paymentstage1}
                                    stage1={stage1}
                                    releaserangefrom1={releaserangefrom1}
                                    releaserangeto1={releaserangeto1}
                                    paymentdate1={paymentdate1}
                                    paymentmode1={paymentmode1}
                                    constStage1={constStage1}
                                  />
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>

                        <div
                          className={
                            toggleState === 2
                              ? "content1  active-content1"
                              : "content1"
                          }
                        >
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              credited: creditedstat2,
                              amount: paymentstage2,
                              stage: stage2,
                              payment_date_from: releaserangefrom2,
                              payment_date_to: releaserangeto2,
                              payment_date: paymentdate2,
                              payment_mode: paymentmode2,
                              construction_stage: constStage2,
                            }}
                            onSubmit={(values) => {
                              // alert("hello");
                              // console.log("val", values);
                              const headers = {
                                "Content-Type": "application/json",
                              };

                              axios
                                .post(
                                  `${SERVER_URL}/addpaymentstages?_id=${Id}`,
                                  values,
                                  {
                                    headers,
                                  }
                                )
                                .then((resul) => {
                                  // console.log("Hello Don", resul);
                                  let Status = resul.data.status;
                                  if (Status === "success") {
                                    // alert("Profile Details are edited sucessfully");
                                    toast.success(
                                      "Stage-2 payment are edited sucessfully",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                    getStagePaymentDetails();
                                    toggleShowtarget2(false);
                                    // window.setTimeout(function () {
                                    //   window.location.reload();
                                    // }, 100);
                                  } else if (Status === "failed") {
                                    toggleShowtarget2(false);
                                    // alert("Profile Details are already exists");
                                    toast.error(
                                      "Stage-2 payment Already Exists",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                  }
                                })
                                .catch((err) => {
                                  // console.log(err);
                                  alert("Some Internal Errors", err);
                                });
                            }}
                          >
                            {({ errors, touched, handleSubmit, values }) => (
                              <Form>
                                <div className="p-3 admin_patrner_personal_info">
                                  <div className="d-flex justify-content-between head_txt head_txt_margin">
                                    <p>Stage-2 Payment Details</p>
                                    <div
                                      className="edit_icon"
                                      onClick={() =>
                                        toggleShowtarget2(!showtarget2)
                                      }
                                    >
                                      {!showtarget2 && (
                                        <div>
                                          <EditIcon className="search_icons" />
                                          <span> Edit</span>
                                        </div>
                                      )}
                                      {showtarget2 && (
                                        <div>
                                          <button
                                            type="submit"
                                            className="edit_icon"
                                            onClick={handleSubmit}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <LeadStage2Details
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    showtarget2={showtarget2}
                                    creditedstat2={creditedstat2}
                                    paymentstage2={paymentstage2}
                                    stage2={stage2}
                                    releaserangefrom2={releaserangefrom2}
                                    releaserangeto2={releaserangeto2}
                                    paymentdate2={paymentdate2}
                                    paymentmode2={paymentmode2}
                                    constStage2={constStage2}
                                  />
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>

                        <div
                          className={
                            toggleState === 3
                              ? "content1  active-content1"
                              : "content1"
                          }
                        >
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              credited: creditedstat3,
                              amount: paymentstage3,
                              stage: stage3,
                              payment_date_from: releaserangefrom3,
                              payment_date_to: releaserangeto3,
                              payment_date: paymentdate3,
                              payment_mode: paymentmode3,
                              construction_stage: constStage3,
                            }}
                            onSubmit={(values) => {
                              // alert("hello");
                              // console.log("val", values);
                              const headers = {
                                "Content-Type": "application/json",
                              };

                              axios
                                .post(
                                  `${SERVER_URL}/addpaymentstages?_id=${Id}`,
                                  values,
                                  {
                                    headers,
                                  }
                                )
                                .then((resul) => {
                                  // console.log("Hello Don", resul);
                                  let Status = resul.data.status;
                                  if (Status === "success") {
                                    // alert("Profile Details are edited sucessfully");
                                    toast.success(
                                      "Stage-3 payment are edited sucessfully",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                    getStagePaymentDetails();
                                    toggleShowtarget3(false);
                                    // window.setTimeout(function () {
                                    //   window.location.reload();
                                    // }, 100);
                                  } else if (Status === "failed") {
                                    toggleShowtarget3(false);
                                    // alert("Profile Details are already exists");
                                    toast.error(
                                      "Stage-3 payment Already Exists",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                  }
                                })
                                .catch((err) => {
                                  // console.log(err);
                                  alert("Some Internal Errors", err);
                                });
                            }}
                          >
                            {({ errors, touched, handleSubmit, values }) => (
                              <Form>
                                <div className="p-3 admin_patrner_personal_info">
                                  <div className="d-flex justify-content-between head_txt head_txt_margin">
                                    <p>Stage-3 Payment Details</p>
                                    <div
                                      className="edit_icon"
                                      onClick={() =>
                                        toggleShowtarget3(!showtarget3)
                                      }
                                    >
                                      {!showtarget3 && (
                                        <div>
                                          <EditIcon className="search_icons" />
                                          <span> Edit</span>
                                        </div>
                                      )}
                                      {showtarget3 && (
                                        <div>
                                          <button
                                            type="submit"
                                            className="edit_icon"
                                            onClick={handleSubmit}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <LeadStage3Details
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    showtarget3={showtarget3}
                                    creditedstat3={creditedstat3}
                                    paymentstage3={paymentstage3}
                                    stage3={stage3}
                                    releaserangefrom3={releaserangefrom3}
                                    releaserangeto3={releaserangeto3}
                                    paymentdate3={paymentdate3}
                                    paymentmode3={paymentmode3}
                                    constStage3={constStage3}
                                  />
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                      <div
                        className="stepper-wrapper"
                        style={{ paddingTop: "0px" }}
                      >
                        <div className="stepper-item completed">
                          <div
                            className={toggleState === 1 ? " step-counter" : ""}
                            onClick={() => toggleTab(1)}
                            style={{ cursor: "pointer" }}
                          >
                            1
                          </div>
                          {/* <div className="step-name">First</div> */}
                        </div>
                        <div className="stepper-item completed">
                          <div
                            className={
                              toggleState === 2 ? " step-counter" : " "
                            }
                            onClick={() => toggleTab(2)}
                            style={{ cursor: "pointer" }}
                          >
                            2
                          </div>
                          {/* <div className="step-name">Second</div> */}
                        </div>
                        <div className="stepper-item completed">
                          <div
                            className={toggleState === 3 ? " step-counter" : ""}
                            onClick={() => toggleTab(3)}
                            style={{ cursor: "pointer" }}
                          >
                            3
                          </div>
                          {/* <div className="step-name">Third</div> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-12">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              project_value: projectValue,
                              booking_amt: leadbookingamt,
                              persfamt: Persfamt,
                            }}
                            onSubmit={(values) => {
                              const headers = {
                                "Content-Type": "application/json",
                              };

                              axios
                                .post(
                                  `${SERVER_URL}/updatecustomer?_id=${Id}`,
                                  values,
                                  {
                                    headers,
                                  }
                                )
                                .then((res) => {
                                  // console.log("Hello Don", res);
                                  let Status = res.data.status;
                                  if (Status === "success") {
                                    // alert("Profile Details are edited sucessfully");
                                    toast.success(
                                      "Deal Details are edited sucessfully",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                    // window.setTimeout(function () {
                                    //   window.location.reload();
                                    // }, 100);
                                    getLeadData();
                                    toggleShowref(false);
                                  } else if (Status === "failed") {
                                    toggleShowref(false);
                                    // alert("Profile Details are already exists");
                                    toast.error("Deal Details Already Exists", {
                                      position: "top-right",
                                      autoClose: 5000,
                                      hideProgressBar: true,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    });
                                  }
                                })
                                .catch((err) => {
                                  alert("Some Internal Error", err);
                                });
                            }}
                          >
                            {({ errors, touched, handleSubmit, values }) => (
                              <Form>
                                <div className="p-3 admin_patrner_personal_info">
                                  <div className="d-flex justify-content-between head_txt head_txt_margin">
                                    <p>Deal Details</p>
                                    <div
                                      className="edit_icon"
                                      onClick={() => toggleShowref(!showref)}
                                    >
                                      {!showref && (
                                        <div>
                                          <EditIcon className="search_icons" />
                                          <span> Edit</span>
                                        </div>
                                      )}
                                      {showref && (
                                        <div>
                                          <button
                                            type="submit"
                                            className="edit_icon"
                                            onClick={handleSubmit}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <LeadDealDetails
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    showref={showref}
                                    Persfamt={Persfamt}
                                    projectValue={projectValue}
                                    leadbookingamt={leadbookingamt}
                                  />
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                        {/* <div className="col-md-12 mt-4">
                      <div className="p-3 admin_patrner_personal_info">
                        <div className="d-flex justify-content-between">
                          <p className="head_txt">Profile Pic</p>
                          <div
                            className="edit_icon"
                            onClick={() =>
                              toggleshowleadproedit(!showleadproedit)
                            }
                          >
                            {!showleadproedit && (
                              <div>
                                <EditIcon className="search_icons" />
                                <span> Edit</span>
                              </div>
                            )}
                            {showleadproedit && (
                              <div>
                                <button
                                  type="submit"
                                  className="edit_icon"
                                  onClick={saveprofilepic}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        {!showleadproedit && (
                          <div className="viewing_details">
                            <div className="row">
                              <div className="col-6 col-md-6">
                                <button className="download_btn">
                                  <DownloadIcon />
                                  Photo
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {showleadproedit && (
                          <div className="edit_details">
                            <div className="row">
                              <div className="">
                                <div className="input-group-custom">
                                  <input
                                    type="file"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="inputName"
                                    name="profile_pic"
                                    //   value={name}
                                    onChange={(e) => {
                                      changeprofile(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-8">
                      <div className="p-3 admin_patrner_personal_info">
                        <div className="d-flex justify-content-between head_txt head_txt_margin">
                          <p>Comments</p>
                          <div
                            className="edit_icon"
                            onClick={() => toggleShowcomment(!showcmt)}
                          >
                            {!showcmt && (
                              <div>
                                <EditIcon className="search_icons" />
                                <span> Edit</span>
                              </div>
                            )}
                            {showcmt && (
                              <div>
                                <button
                                  type="submit"
                                  className="edit_icon"
                                  onClick={saveComments}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        {!showcmt && (
                          <div className="viewing_details">
                            {comments != "" ? (
                              comments.map((cust) => {
                                return (
                                  <div className="pt-2 row">
                                    <div className="col-12 col-md-4">
                                      <div>
                                        <p className="m-0 admin_type_heading">
                                          Date
                                        </p>
                                        <span className="admin_type_value">
                                          {cust.comment_date
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")}
                                          {/* {comment_date === "" ? "NA" : comment_date} */}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                      <div>
                                        <p className="m-0 admin_type_heading">
                                          Call Status
                                        </p>
                                        <span className="admin_type_value">
                                          {cust.call_status}
                                          {/* {comment_date === "" ? "NA" : comment_date} */}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                      <div>
                                        <p className="m-0 admin_type_heading">
                                          Comments
                                        </p>
                                        <span className="admin_type_value">
                                          {cust.comment}
                                          {/* {remark === "" ? "NA" : remark} */}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div>
                                <div>NA</div>
                              </div>
                            )}
                          </div>
                        )}

                        {showcmt && (
                          <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                            {comments.length != 0 ? (
                              <div>
                                <DynamicInputFieldDisplay comments={comments} />
                              </div>
                            ) : (
                              ""
                            )}

                            {Array.from(Array(counter)).map((c, counter) => {
                              return (
                                <DynamicInputFields
                                  data={commentsData}
                                  counter={counter}
                                />
                              );
                            })}

                            <div className="pt-4">
                              {/* <button>Add Comments</button> */}
                              <button
                                className="add_btn btn_add_comments_inc"
                                onClick={handleClick}
                              >
                                <AddIcon className="search_icons" />
                                Add Comment
                              </button>
                            </div>

                            {/* <div className="input-group-custom">
                              <label htmlFor="loc" className="label">
                                Comments
                              </label>
                              <br />

                              <textarea
                                className="input-area"
                                width="100%"
                                rows="5"
                                cols="20"
                                name="email"
                                value={remark}
                                onChange={(e) => {
                                  Setremark(e.target.value);
                                }}
                              ></textarea>
                            </div> */}
                          </div>
                        )}
                      </div>
                    </div>

                    {updmeetlink !== "" || updmeetloc !== "" ? (
                      <div className="col-md-4">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            meeting_date: meetdate,
                            assign_bde: meetassignbde,
                            // meeting_time: meettime,
                            meeting_from_time: meetstarttime,
                            meeting_to_time: meetendttime,
                            meeting_location: meetloc,
                            meeting_link: meetlink,
                            meeting_description: meetdesc,
                          }}
                          onSubmit={(values) => {
                            // console.log("val", values);
                            const headers = {
                              "Content-Type": "application/json",
                            };

                            axios
                              .post(
                                `${SERVER_URL}/updatemeeting?_id=${Id}`,
                                values,
                                {
                                  headers,
                                }
                              )
                              .then((res) => {
                                // console.log("Hello Don", res);
                                let Status = res.data.status;
                                if (Status === "success") {
                                  // alert("Profile Details are edited sucessfully");
                                  toast.success(
                                    "Meeting Details are Updated successfully",
                                    {
                                      position: "top-right",
                                      autoClose: 5000,
                                      hideProgressBar: true,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                  getLeadData();
                                  toggleshowmeetedit(false);
                                  // window.setTimeout(function () {
                                  //   window.location.reload();
                                  // }, 100);
                                } else if (Status === "failed") {
                                  toggleshowmeetedit(false);
                                  // alert("Profile Details are already exists");
                                  toast.error(
                                    "Some Internal Error Try after Some Time...",
                                    {
                                      position: "top-right",
                                      autoClose: 5000,
                                      hideProgressBar: true,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                }
                              });
                          }}
                        >
                          {({ errors, touched, handleSubmit, values }) => (
                            <Form>
                              <div className="p-3 admin_patrner_personal_info">
                                <div className="d-flex justify-content-between head_txt head_txt_margin">
                                  <p>Meeting Details</p>
                                  {/* Toggle State to edit and save start */}
                                  <div
                                    className="edit_icon"
                                    onClick={() =>
                                      toggleshowmeetedit(!showmeetedit)
                                    }
                                  >
                                    {!showmeetedit && (
                                      <div>
                                        <EditIcon className="search_icons" />
                                        <span> Edit</span>
                                      </div>
                                    )}
                                    {showmeetedit && (
                                      <div>
                                        <button
                                          type="submit"
                                          className="edit_icon"
                                          onClick={handleSubmit}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                  {/* Toggle State to edit and save end */}
                                </div>

                                <LeadMeeting
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  showmeetedit={showmeetedit}
                                  meetdate={meetdate}
                                  meetassignbde={meetassignbde}
                                  meetstarttime={meetstarttime}
                                  meetendttime={meetendttime}
                                  meetloc={meetloc}
                                  meetlink={meetlink}
                                  meetdesc={meetdesc}
                                  updmeetlink={updmeetlink}
                                  updmeetloc={updmeetloc}
                                  bdeassignOption={bdeassignOption}
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}

              {/* <div className="pt-2 row">
                  <div className="col-md-10">
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="">Real Time Construction Status</p>
                        <div
                          className="edit_icon"
                          onClick={() => toggleshowtimepic(!showtimepic)}
                        >
                          {!showtimepic && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span> Edit</span>
                            </div>
                          )}
                          {showtimepic && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                // onClick={mydetails}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!showtimepic && (
                        <div className="viewing_details mt-3">
                          <div className="row">
                            <div className="col-6 col-md-2">
                              <button className="download_btn">
                                <DownloadIcon />
                                Bedroom
                              </button>
                            </div>
                            <div className="col-6 col-md-2">
                              <button className="download_btn">
                                <DownloadIcon />
                                Kitchen
                              </button>
                            </div>
                            <div className="col-6 col-md-2">
                              <button className="download_btn">
                                <DownloadIcon />
                                Living Room
                              </button>
                            </div>
                            <div className="col-6 col-md-2">
                              <button className="download_btn">
                                <DownloadIcon />
                                Bathroom
                              </button>
                            </div>
                            <div className="col-6 col-md-2">
                              <button className="download_btn">
                                <DownloadIcon />
                                Dining
                              </button>
                            </div>
                            <div className="col-6 col-md-2">
                              <button className="download_btn">
                                <DownloadIcon />
                                Staircase
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {showtimepic && (
                        <div className="edit_details">
                          <div className="row">
                            <div className="col-6 col-md-2">
                              <div className="input-group-custom">
                                Bedroom
                                <input
                                  type="file"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputName"
                                  name="name"
                                  //   value={name}
                                  onChange={(e) => {
                                    // setName(e.target.value);
                                  }}
                                />
                                Upload Photo
                              </div>
                            </div>
                            <div className="col-6 col-md-2">
                              <div className="input-group-custom">
                                Kitchen
                                <input
                                  type="file"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputName"
                                  name="name"
                                  //   value={name}
                                  onChange={(e) => {
                                    // setName(e.target.value);
                                  }}
                                />
                                Upload Photo
                              </div>
                            </div>
                            <div className="col-6 col-md-2">
                              <div className="input-group-custom">
                                Living Room
                                <input
                                  type="file"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputName"
                                  name="name"
                                  //   value={name}
                                  onChange={(e) => {
                                    // setName(e.target.value);
                                  }}
                                />
                                Upload Photo
                              </div>
                            </div>
                            <div className="col-6 col-md-2">
                              <div className="input-group-custom">
                                Bathroom
                                <input
                                  type="file"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputName"
                                  name="name"
                                  //   value={name}
                                  onChange={(e) => {
                                    // setName(e.target.value);
                                  }}
                                />
                                Upload Photo
                              </div>
                            </div>
                            <div className="col-6 col-md-2">
                              <div className="input-group-custom">
                                Dining
                                <input
                                  type="file"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputName"
                                  name="name"
                                  //   value={name}
                                  onChange={(e) => {
                                    // setName(e.target.value);
                                  }}
                                />
                                Upload Photo
                              </div>
                            </div>
                            <div className="col-6 col-md-2">
                              <div className="input-group-custom">
                                Staircase
                                <input
                                  type="file"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputName"
                                  name="name"
                                  //   value={name}
                                  onChange={(e) => {
                                    // setName(e.target.value);
                                  }}
                                />
                                Upload Photo
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>

      {/* Pop Up Schedule Online Meeting Start*/}
      <div
        id="Schedule_Online_meeting"
        className="modal fade Schedule_Online_meeting"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body" style={{ background: "#FFFFFF" }}>
              <div className="pb-3">
                <span className="add_hot_lead_label">
                  Schedule Online Meeting
                </span>
                <span
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="CloseAddHotLeadPopup"
                  style={{
                    background: "transparent",
                    float: "right",
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
              <Formik
                initialValues={{
                  meeting_date: "",
                  assign_bde: "",
                  meeting_from_time: "",
                  meeting_to_time: "",
                  meeting_link: "",
                  meeting_description: "",
                }}
                // validationSchema={validate}
                onSubmit={(values, { resetForm }) => {
                  setbtnloader(true);
                  // console.log("val", values);
                  const headers = {
                    "Content-Type": "application/json",
                  };
                  axios
                    .post(`${SERVER_URL}/onlinemeeting?_id=${Id}`, values, {
                      headers,
                    })
                    .then((res) => {
                      let Status = res.data.status;
                      if (Status === "success") {
                        window.$("#Schedule_Online_meeting").modal("hide");
                        setbtnloader(false);
                        toast.success(
                          "Online Meeting Scheduled Successfully...!!",
                          {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          }
                        );
                        getLeadData();
                        // getLeadData();
                      } else {
                        setbtnloader(false);
                        toast.error("Something Went Wrong...try Again!!!", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    });
                }}
              >
                {({ errors, touched }) => (
                  <div>
                    <Form>
                      <div className="row pt-3">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting Date
                          </label>
                          <div>
                            <Field
                              // as="date"
                              type="date"
                              className="field_input_txt"
                              name="meeting_date"
                              validate={validateMeetingDate}
                              placeholder="Meeting Date"
                            />
                          </div>
                          {errors.meeting_date && touched.meeting_date && (
                            <div className="pt-2 error">
                              {errors.meeting_date}
                            </div>
                          )}
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Assign To
                          </label>
                          <div className="">
                            <Field
                              as="select"
                              className="field_input_txt"
                              name="assign_bde"
                              validate={validateBDEAssigne}
                              defaultValue={"select"}
                            >
                              <option selected value="">
                                Select BDE
                              </option>
                              {bdeassignOption != ""
                                ? bdeassignOption.map((item, i) => {
                                    return (
                                      <option key={i} value={item.Name}>
                                        {item.Name}
                                      </option>
                                    );
                                  })
                                : "No Data"}
                            </Field>
                            {errors.assign_bde && touched.assign_bde && (
                              <div className="pt-2 error">
                                {errors.assign_bde}
                              </div>
                            )}
                            {/* Assignees */}
                          </div>
                        </div>
                      </div>

                      <div className="row pt-4">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting From
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_from_time"
                              validate={validateFromTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_from_time &&
                              touched.meeting_from_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_from_time}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting To
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_to_time"
                              validate={validateToTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_to_time &&
                              touched.meeting_to_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_to_time}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Link
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            className="field_input_txt"
                            name="meeting_link"
                            validate={validateMeetingLink}
                            placeholder="Post The Meeting Link Here"
                          />
                          {errors.meeting_link && touched.meeting_link && (
                            <div className="pt-2 error">
                              {errors.meeting_link}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Description(If Any)
                        </label>
                        <div className="">
                          <Field
                            style={{ height: "100%" }}
                            as="textarea"
                            rows="5"
                            // type="text"
                            className="field_input_txt"
                            name="meeting_description"
                            placeholder="Write Description Here...."
                          />
                        </div>
                      </div>

                      <div className="pt-4">
                        <button
                          type="submit"
                          className="btn btn-lg btn_apply_filter"
                        >
                          {btnloader === true ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                          Schedule Meeting
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up Schedule Online Meeting End*/}

      {/* Pop Up Schedule Offline Meeting Start*/}
      <div
        id="Schedule_Offline_meeting"
        className="modal fade Schedule_Offline_meeting"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body" style={{ background: "#FFFFFF" }}>
              <div className="pb-3">
                <span className="add_hot_lead_label">
                  Schedule Offline Meeting
                </span>
                <span
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="CloseAddHotLeadPopup"
                  style={{
                    background: "transparent",
                    float: "right",
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
              <Formik
                initialValues={{
                  meeting_date: "",
                  assign_bde: "",
                  meeting_from_time: "",
                  meeting_to_time: "",
                  meeting_location: "",
                  meeting_description: "",
                }}
                // validationSchema={validate}
                onSubmit={(values, { resetForm }) => {
                  setbtnloader(true);
                  // console.log("val", values);
                  const headers = {
                    "Content-Type": "application/json",
                  };
                  axios
                    .post(`${SERVER_URL}/offlinemeeting?_id=${Id}`, values, {
                      headers,
                    })
                    .then((res) => {
                      // console.log("online meeting", res);
                      let Status = res.data.status;
                      if (Status === "success") {
                        window.$("#Schedule_Offline_meeting").modal("hide");
                        setbtnloader(false);
                        toast.success(
                          "Offline Meeting Scheduled Successfully...!!",
                          {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          }
                        );
                        getLeadData();
                      } else {
                        setbtnloader(false);
                        toast.error("Something Went Wrong...try Again!!!", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    });
                }}
              >
                {({ errors, touched }) => (
                  <div>
                    <Form>
                      <div className="row pt-3">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting Date
                          </label>
                          <div className="">
                            <Field
                              // as="date"
                              type="date"
                              className="field_input_txt"
                              name="meeting_date"
                              validate={validateMeetingDate}
                              placeholder="Meeting Date"
                            />
                            {errors.meeting_date && touched.meeting_date && (
                              <div className="pt-2 error">
                                {errors.meeting_date}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Assign To
                          </label>
                          <div className="">
                            <Field
                              as="select"
                              className="field_input_txt"
                              name="assign_bde"
                              validate={validateBDEAssigne}
                              defaultValue={"select"}
                            >
                              <option selected value="">
                                Select BDE
                              </option>
                              {bdeassignOption != ""
                                ? bdeassignOption.map((item, i) => {
                                    return (
                                      <option key={i} value={item.Name}>
                                        {item.Name}
                                      </option>
                                    );
                                  })
                                : "No Data"}
                            </Field>
                            {errors.assign_bde && touched.assign_bde && (
                              <div className="pt-2 error">
                                {errors.assign_bde}
                              </div>
                            )}
                            {/* Assignees */}
                          </div>
                        </div>
                      </div>

                      <div className="row pt-4">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting From
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_from_time"
                              validate={validateFromTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_from_time &&
                              touched.meeting_from_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_from_time}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting To
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_to_time"
                              validate={validateToTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_to_time &&
                              touched.meeting_to_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_to_time}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Location
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            className="field_input_txt"
                            name="meeting_location"
                            validate={validateMeetingLocation}
                            placeholder="Enter Full Location"
                          />
                          {errors.meeting_location &&
                            touched.meeting_location && (
                              <div className="pt-2 error">
                                {errors.meeting_location}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Description(If Any)
                        </label>
                        <div className="">
                          <Field
                            style={{ height: "100%" }}
                            as="textarea"
                            rows={5}
                            // type="text"
                            className="field_input_txt"
                            name="meeting_description"
                            placeholder="Write Description Here...."
                          />
                        </div>
                      </div>

                      <div className="pt-4">
                        <button
                          type="submit"
                          className="btn btn-lg btn_apply_filter"
                        >
                          {btnloader === true ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                          Schedule Meeting
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up Schedule Offline Meeting End*/}
    </div>
  );
}
