import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminNav from "../AdminNavBar/AdminNav";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import whatsApp from "../../Assets/logos_whatsapp.svg";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import $ from "jquery";
import Footer from "../Dashboard/Footer";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function RpReferedrpdetails({ props }) {
  // console.log("9999", props.aboutProps);
  const SERVER_URL = process.env.REACT_APP_BASE_URL;

  // var DownloadButton = require('downloadbutton/es5')
  const [show, toggleShow] = useState(false);
  const [showedit, toggleShowedit] = useState(false);
  const [showeditcmt, toggleShoweditcmt] = useState(false);
  const [showprofilepic, toggleShowProfilepic] = useState(false);

  const { Id } = useParams();
  const { Number } = useParams();
  const { Page } = useParams();

  // var DownloadButton = require('downloadbutton/es5');
  let history = useHistory();

  // state to set all personal info values of partners
  const [refnames, Setrefnames] = useState("");
  const [refjoiningdate, Setrefjoiningdate] = useState("");
  const [refcompname, Setrefcompname] = useState("");
  const [reftotalexp, Setreftotalexp] = useState([]);
  const [refdob, Setrefdob] = useState("");
  const [refdesg, Setrefdesg] = useState("");
  const [reftype, Setreftype] = useState("");
  const [refworkloc, Setrefworkloc] = useState("");

  // state to set contact info
  const [refemail, Setrefemail] = useState("");
  const [refmobile, Setrefmobile] = useState("");

  // Bank details
  const [refbankdetails, Setrefbankdetails] = useState("");
  const [refpatname, Setrefpatname] = useState("");
  const [refbankname, Setrefbankname] = useState("");
  const [refbranchname, Setrefbranchname] = useState("");
  const [refaccno, Setrefaccno] = useState("");
  const [refifsccode, Setrefifsccode] = useState("");
  const [refbankacctype, Setrefbankacctype] = useState("");

  // To set KYC Details
  const [refpanno, Setrefpanno] = useState("");
  const [refaadharno, Setrefaadharno] = useState("");
  const [refdobnew, Setrefdobnew] = useState("");

  const [photo, setSelectedPhoto] = useState("");
  const [panphoto, setSelectedPanPhoto] = useState("");
  const [aadhaarphoto, setSelectedAadharPhoto] = useState("");
  // const [isFilePicked, setIsFilePicked] = useState(false);

  const [msg, Setmsg] = useState("");
  const [referalcode, Setreferalcode] = useState([]);
  const [profilepic, setprofilepic] = useState("");

  // setaadharpic;

  const [verifaction, SetVerification] = useState("");

  // Upload docs
  const changeProfilepic = (e) => {
    setSelectedPhoto(e.target.files[0]);
    // console.log("profile", e.target.files[0]);
  };
  const changePanPhoto = (e) => {
    setSelectedPanPhoto(e.target.files[0]);
    // console.log("pan", e.target.files[0]);
  };

  const changeAadhaarPhoto = (e) => {
    setSelectedAadharPhoto(e.target.files[0]);
    // console.log("aadhar", e.target.files[0]);
  };
  const changeprofile = (e) => {
    setprofilepic(e.target.files[0]);
    // console.log("setting state", e.target.files[0]);
  };
  const saveprofilepic = () => {
    const formdata = new FormData();
    formdata.append("profile_pic", profilepic);
    axios
      .post(`${SERVER_URL}/uploadprofile?_id=${Id}`, formdata)
      .then((repos) => {
        console.log("newyear", repos);
      });
  };

  // Target
  const [targetdetails, Settargetdetails] = useState([]);
  const [noofdays, Setnoofdays] = useState([]);
  const [targettobeachieved, Settargettobeachieved] = useState([]);
  const [tdsvalue, Settdsvalue] = useState(1);
  const [Persfamt, SetPersfamt] = useState(50);
  const [Perprojectamt, SetPerprojectamt] = useState(100000);
  const [remark, Setremark] = useState("");
  const [partnerids, Setpartnerids] = useState("");
  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }

    getrefPartner();

    // to fetch bank details api
    axios.get(`${SERVER_URL}/getbankdetailsbyid?_id=${Id}`).then((res) => {
      // Bank Detais
      // var impdata = res.data.length;
      // console.log("rrrr", res.data);
      var bankdetailslength = res.data;
      var lengthofbank = res.data.documents.length;
      // console.log("dssd", lengthofbank);
      localStorage.setItem("lengthofbank", lengthofbank);
      if (lengthofbank > 0 && lengthofbank != "") {
        Setrefbankdetails(res.data.documents[0]);
        Setrefpatname(res.data.documents[0].partnerName);
        Setrefbankname(res.data.documents[0].bankName);
        Setrefbranchname(res.data.documents[0].branch);
        Setrefaccno(res.data.documents[0].accountNumber);
        Setrefifsccode(res.data.documents[0].ifscCode);
        Setrefbankacctype(res.data.documents[0].accountType);
        Setrefpanno(res.data.documents[0].panNumber);
        Setrefaadharno(res.data.documents[0].adharNumber);
        Setrefdobnew(res.data.documents[0].dob);
        // console.log("NEWDOBB", res.data.documents[0].dob);
      } else {
        Setrefbankdetails("");
        Setrefpatname("");
        Setrefbankname("");
        Setrefbranchname("");
        Setrefaccno("");
        Setrefifsccode("");
        Setrefbankacctype("");
        Setrefpanno("");
        Setrefaadharno("");
      }
    });

    axios.get(`${SERVER_URL}/target?_id=${Id}`).then((respons) => {
      // console.log("TARGGGEET IZZ", respons.data);
      Settargetdetails(respons.data);
      Setnoofdays(respons.data.noOfdays);
      Settargettobeachieved(respons.data.noOfTarget);
      // console.log("s", respons.data.noOfdays);
    });

    // TDS Value API

    axios.get(`${SERVER_URL}/tdsbyid?_id=${Id}`).then((resultds) => {
      Setmsg(resultds.data.msg);
      // console.log("TDS", resultds);
    });

    // Fetch Referral Code
    axios.get(`${SERVER_URL}/getrefcode?_id=${Id}`).then((resultrefcode) => {
      Setreferalcode(resultrefcode.data.referralcode);
      // console.log("REF COde", resultrefcode);
    });
  }, []);

  const getrefPartner = () => {
    axios.get(`${SERVER_URL}/getrefpartnerbyid?_id=${Id}`).then((reps) => {
      // console.log("resp", reps);
      //localhost:3030/getrefpartnerbyid?_id=61c58468f829985a088be996
      // Personal
      Setpartnerids(reps.data.Referral_partners[0].partner_id);
      Setrefnames(reps.data.Referral_partners[0].fullname);
      Setrefjoiningdate(reps.data.Referral_partners[0].doj);
      Setrefcompname(reps.data.Referral_partners[0].companyname);
      Setreftotalexp(reps.data.Referral_partners[0].experience);
      Setrefdob(reps.data.Referral_partners[0].dob);
      Setrefdesg(reps.data.Referral_partners[0].designation);
      Setreftype(reps.data.Referral_partners[0].partnerType);
      Setrefworkloc(reps.data.Referral_partners[0].location);

      // Contact info
      Setrefemail(reps.data.Referral_partners[0].email);
      Setrefmobile(reps.data.Referral_partners[0].contact_no);

      // Verification
      SetVerification(reps.data.Referral_partners[0].qualified);

      SetPersfamt(reps.data.Referral_partners[0].persfamt);
      SetPerprojectamt(reps.data.Referral_partners[0].perprojectamt);
      Setremark(reps.data.Referral_partners[0].comments);
      // console.log("Persfamt", reps.data.Referral_partners[0].persfamt);
    });
  };

  // Update Referral code

  const deletepartnerdetails = () => {
    axios.delete(`${SERVER_URL}/deleterefpartner?_id=${Id}`).then((res) => {
      // console.log("KK", res);
      // $(".modal-backdrop").remove();
      // history.push(`/rpreferedrp/${partnerids}`);
      let Status = res.data.status;
      if (Status === "success") {
        window.$("#deletePartner").modal("hide");
        window.$("#PartnerAddedSuccessfully").modal("show");
        window.setTimeout(function () {
          $(".modal-backdrop").remove();
          history.push(`/rpreferedrp/${partnerids}/${Number}/${Page}`);
        }, 3000);
      } else {
        toast.error("Internal Error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // To update the details
  const mydetails = (e) => {
    e.preventDefault();
    const upddata = {
      fullname: refnames,
      doj: refjoiningdate,
      companyname: refcompname,
      experience: reftotalexp,
      dob: refdob,
      designation: refdesg,
      partnertype: reftype,
      location: refworkloc,
      email: refemail,
      contact_no: refmobile,
      noOfdays: targetdetails.noOfdays,
      noOfTarget: targetdetails.noOfTarget,
      persfamt: Persfamt,
      perprojectamt: Perprojectamt,
      //   remarks:
      comments: remark,
    };
    // console.log("data", upddata);
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/updaterefpartner?_id=${Id}`, upddata, {
        //pure-wave-48602.herokuapp.com/updaterefpartner?_id=61bb2b3d9ac710448ad1126f
        headers,
      })
      .then((res) => {
        // console.log("Hello Don", res);
        let Status = res.data.status;
        if (Status === "success") {
          // alert("Profile Details are edited sucessfully");
          toast.success("Profile Details are edited sucessfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // window.setTimeout(function () {
          //   window.location.reload();
          // }, 100);
        } else if (Status === "failed") {
          // alert("Profile Details are already exists");
          toast.error("Profile Details Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  return (
    <div>
      <div>
        <AdminNav />
      </div>

      <div className="main">
        <div className="content-tabs">
          <div>
            <div className="top_menu_bar">
              <div className="">
                <Link
                  to={`/rpreferedrp/${partnerids}/${Number}/${Page}`}
                  className="partner_back_btn"
                >
                  <div>
                    <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                    <span>Back</span>
                  </div>
                </Link>
              </div>

              <div>
                <div
                  className="d-flex justify-content-around"
                  style={{ gap: "20px" }}
                >
                  <div className="dropdown verify_btn">
                    {verifaction === false ? (
                      <div>
                        <button
                          className="add_btn"
                          type="button"
                          id="dropdownMenuButton"
                          style={{ padding: "10px 10px" }}
                        >
                          <CancelRoundedIcon className="not_verify_icons" />
                          Not Verified
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="add_btn"
                          type="button"
                          id="dropdownMenuButton"
                        >
                          <CheckCircleIcon className="verify_icons" />
                          Verified
                        </button>
                      </div>
                    )}
                  </div>

                  <button
                    className="delete_btn"
                    data-toggle="modal"
                    data-target=".partner_details_delete"
                  >
                    <DeleteIcon className="del_icons" />
                    Delete
                  </button>

                  <div
                    id="deletePartner"
                    className="modal fade partner_details_delete"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog model-sm modal-dialog-centered ">
                      <div
                        className="modal-content model-sm modal_content_whole"
                        style={{ background: "white" }}
                      >
                        <div className="modal-body">
                          <div>
                            <div className="popup_icon_center">
                              <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                            </div>
                            <div className="pt-2 popup_text_center">
                              <span className="popup_addbankdetails_text">
                                Are you sure want to Delete this Partner?
                              </span>
                            </div>
                            <div className="pt-2 m-0 d-flex justify-content-center">
                              <div
                                style={{ width: "50%", textAlign: "center" }}
                              >
                                <button
                                  type="button"
                                  className="popup_btn_unlinkaccount"
                                  onClick={deletepartnerdetails}
                                  // data-toggle="modal"
                                  // data-target=".partner-details-deleted-success"
                                  // data-dismiss="modal"
                                >
                                  Delete
                                </button>
                              </div>
                              <div
                                style={{ width: "50%", textAlign: "center" }}
                              >
                                <button
                                  // type="button"
                                  className="popup_btn_close"
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Sucuesfully deleted */}

                  <div
                    id="PartnerAddedSuccessfully"
                    className="modal fade bd-lead-added-successfully"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                      <div
                        className="modal-content modal-sm modal_content_whole"
                        style={{ background: "white" }}
                      >
                        <div className="modal-body">
                          <div>
                            <div className="popup_icon_center">
                              <CheckCircleIcon className="popup_addbankdetails_icon" />
                            </div>
                            <div className="popup_text_center">
                              <p className="pt-2 popup_addbankdetails_text">
                                Partner Deleted Successfully
                              </p>
                              <div className="pt-2 m-0 d-flex justify-content-center">
                                <div
                                  style={{
                                    width: "50%",
                                    textAlign: "center",
                                  }}
                                >
                                  <button
                                    // type="button"
                                    className="popup_btn_linkaccount"
                                    data-dismiss="modal"
                                    onClick={() => {
                                      history.push(
                                        `/rpreferedrp/${partnerids}/${Number}/${Page}`
                                      );
                                    }}
                                  >
                                    OK
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="partner_details_edit_sec">
              <form>
                <div className="row">
                  <div className="col-md-8">
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Partner Personal Info</p>
                        <div
                          className="edit_icon"
                          onClick={() => toggleShow(!show)}
                        >
                          {!show && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span> Edit</span>
                            </div>
                          )}
                          {show && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={mydetails}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!show && (
                        <div className="viewing_details">
                          <div className="row">
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Full Name
                                </p>
                                <span className="admin_type_value">
                                  {refnames === "" ? "-" : refnames}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Joining Date
                                </p>
                                <span className="admin_type_value">
                                  {refjoiningdate === ""
                                    ? "-"
                                    : refjoiningdate
                                        .slice(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("-")}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Company Name
                                </p>
                                <span className="admin_type_value">
                                  {refcompname === "" ? "-" : refcompname}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Total Experience
                                </p>
                                <span className="admin_type_value">
                                  {reftotalexp === "" ? "-" : reftotalexp}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Date of Birth
                                </p>
                                <span className="admin_type_value">
                                  {refdob === ""
                                    ? "-"
                                    : refdob
                                        .slice(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("-")}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Designation
                                </p>
                                <span className="admin_type_value">
                                  {refdesg === "" ? "-" : refdesg}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Type of Partner
                                </p>
                                <span className="admin_type_value">
                                  {reftype === "" ? "-" : reftype}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Work Location
                                </p>
                                <span className="admin_type_value">
                                  {refworkloc === "" ? "-" : refworkloc}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {show && (
                        <div className="edit_details">
                          <div className="row">
                            <div className="col-6 col-md-3">
                              <div className="input-group-custom">
                                <label htmlFor="inputName" className="label">
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputName"
                                  name="name"
                                  value={refnames}
                                  onChange={(e) => {
                                    Setrefnames(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div className="input-group-custom">
                                <label htmlFor="date" className="label">
                                  Joining Date
                                </label>
                                <input
                                  type="date"
                                  className="input-area admin_partner_det_input"
                                  name="joining_date"
                                  value={refjoiningdate}
                                  onChange={(e) => {
                                    Setrefjoiningdate(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div className="input-group-custom">
                                <label htmlFor="comp" className="label">
                                  Company Name
                                </label>
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="comp"
                                  name="company_name"
                                  value={refcompname}
                                  onChange={(e) => {
                                    Setrefcompname(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div className="input-group-custom">
                                <label htmlFor="exp" className="label">
                                  Total Experience
                                </label>
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="exp"
                                  name="total_expernice"
                                  value={reftotalexp}
                                  onChange={(e) => {
                                    Setreftotalexp(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-6 col-md-3">
                              <div className="input-group-custom">
                                <label htmlFor="dob" className="label">
                                  Date of Birth
                                </label>
                                <input
                                  type="date"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="dob"
                                  name="dob"
                                  defaultValue={refdob}
                                  onChange={(e) => {
                                    Setrefdob(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div className="input-group-custom">
                                <label htmlFor="des" className="label">
                                  Designation
                                </label>
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="des"
                                  name="designation"
                                  value={refdesg}
                                  onChange={(e) => {
                                    Setrefdesg(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div className="input-group-custom">
                                <label htmlFor="type" className="label">
                                  Type of Partner
                                </label>
                                {/* <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="type"
                                    name="type_of_partner"
                                    value={reftype}
                                    onChange={(e) => {
                                      Setreftype(e.target.value);
                                    }}
                                  /> */}
                                {/* defaultValue={"Select"}
                                  value={values.partnertype}> */}
                                <select
                                  className="input-area admin_partner_det_input"
                                  value={reftype}
                                  onChange={(e) => {
                                    Setreftype(e.target.value);
                                  }}
                                >
                                  <option disabled selected value="">
                                    Select
                                  </option>
                                  <option value="RP">RP</option>
                                  <option value="CP">CP</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div className="input-group-custom">
                                <label htmlFor="loc" className="label">
                                  Work Location
                                </label>
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="loc"
                                  name="work_location"
                                  value={refworkloc}
                                  onChange={(e) => {
                                    Setrefworkloc(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="p-3 admin_patrner_personal_info"
                      style={{ height: "100%" }}
                    >
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Partner Contact Info</p>
                        <div
                          className="edit_icon"
                          onClick={() => toggleShowedit(!showedit)}
                        >
                          {!showedit && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span> Edit</span>
                            </div>
                          )}
                          {showedit && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={mydetails}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!showedit && (
                        <div className="contact_info_">
                          <div>
                            <p className="m-0 admin_type_heading">Email</p>
                            <span className="admin_type_value">{refemail}</span>
                          </div>
                          <div className="ml-4 mt-3">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Mobile No.
                                </p>
                                <span className="admin_type_value">
                                  {refmobile === "" ? "-" : refmobile}
                                </span>
                              </div>
                              {refmobile !== "" ? (
                                <div className="whats_app_div">
                                  <a
                                    href={`https://wa.me/${refmobile}`}
                                    className="whatsapp_float"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img src={whatsApp} />
                                  </a>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {showedit && (
                        <div className="contact_info_admin">
                          <div className="input-group-custom">
                            <label htmlFor="loc" className="label">
                              Email
                            </label>
                            <br />
                            <input
                              type="email"
                              className="input-area"
                              required
                              id="email"
                              name="email"
                              value={refemail}
                              onChange={(e) => {
                                Setrefemail(e.target.value);
                              }}
                              // value={MyPersonalDetails.email}
                              // onChange={handleChange("email")}
                            />
                          </div>
                          <div className="input-group-custom mt-3">
                            <label htmlFor="mob" className="label">
                              Mobile No
                            </label>
                            <br />
                            <input
                              type="text"
                              className="input-area"
                              required
                              id="mob"
                              name="contact_no"
                              value={refmobile}
                              onChange={(e) => {
                                Setrefmobile(e.target.value);
                              }}
                              // value={MyPersonalDetails.mobile_no}
                              // onChange={handleChange("mobile_no")}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mt-3">
                    <div
                      className="p-3 admin_patrner_personal_info"
                      style={{ height: "100%" }}
                    >
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Comments</p>
                        <div
                          className="edit_icon"
                          onClick={() => toggleShoweditcmt(!showeditcmt)}
                        >
                          {!showeditcmt && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span> Edit</span>
                            </div>
                          )}
                          {showeditcmt && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={mydetails}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!showeditcmt && (
                        <div className="contact_info_">
                          <div>
                            <span
                              className="admin_type_value"
                              style={{
                                fontSize: "13px",
                                lineHeight: "0.1rem",
                              }}
                            >
                              {remark}
                            </span>
                          </div>
                        </div>
                      )}
                      {showeditcmt && (
                        <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                          <div className="input-group-custom">
                            {/* <label htmlFor="loc" className="label">
                                Comments
                              </label> */}
                            <br />
                            {/* <input
                                type="email"
                                className="input-area"
                                required
                                id="email"
                                name="email"
                                value={refemail}
                                // onChange={(e) => {
                                //   Setrefemail(e.target.value);
                                // }}
                                // value={MyPersonalDetails.email}
                                // onChange={handleChange("email")}
                              /> */}
                            <textarea
                              className="input-area"
                              width="100%"
                              rows="5"
                              cols="20"
                              name="email"
                              value={remark}
                              onChange={(e) => {
                                Setremark(e.target.value);
                              }}
                            ></textarea>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-4 mt-3">
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between">
                        <p className="head_txt">Profile Pic</p>
                        <div
                          className="edit_icon"
                          onClick={() => toggleShowProfilepic(!showprofilepic)}
                        >
                          {!showprofilepic && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span> Edit</span>
                            </div>
                          )}
                          {showprofilepic && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={saveprofilepic}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!showprofilepic && (
                        <div className="viewing_details">
                          <div className="row">
                            <div className="col-6 col-md-6">
                              <button className="download_btn">
                                <DownloadIcon />
                                Photo
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {showprofilepic && (
                        <div className="edit_details">
                          <div className="row">
                            <div className="">
                              <input
                                type="file"
                                className="file"
                                name="profile_pic"
                                onChange={(e) => {
                                  changeprofile(e);
                                }}
                              />
                              Upload Photo
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
}
