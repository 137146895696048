import {
  startOfDay,
  endOfDay,
  subMonths,
  subYears,
  addMonths,
  addYears,
} from "date-fns";

export function validateName(value) {
  let error;
  if (!value) {
    error = "Name is Required";
  } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
    error = "Enter a Valid Name It should contain only Aplhabets";
  } else if (value.length > 20) {
    error = "Name Should not more than 20";
  } else if (value.length < 3) {
    error = "Name Should not less than 3 Characters";
  }
  return error;
}

export function validateEmail(value) {
  let error;
  if (!value) {
    error = "Email is Required";
  } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
}

function validatePackage(value) {
  let error;
  if (!value) {
    error = "Package is Required";
  } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
    error = "Enter a Valid Package It should contain only Aplhabets";
  } else if (value.length > 15) {
    error = "Package Should not more than 15";
  } else if (value.length < 3) {
    error = "Package Should not less than 3 Characters";
  }
  return error;
}

export function validateMobileNumber(value) {
  let error;
  if (!value) {
    error = "Mobile Number is Required";
  } else if (value.length > 10) {
    error = "Mobile Number Should not more than 10";
  } else if (value.length < 10) {
    error = "Mobile Number Should not less than 10 ";
  } else if (
    !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
  ) {
    error = "Invalid Mobile Number...Enter Numeric";
  }
  return error;
}

export function validatePlotSize(value) {
  let error;
  if (!value) {
    error = "Plot Size is Required";
  } else if (!/^\(?([0-9]{4})$/i.test(value)) {
    error = "Invalid Plot Size";
  }
  return error;
}

export function validatePlotNumber(value) {
  let error;
  if (!value) {
    error = "Plot Number is Required";
  }
  return error;
}

export function validateStage(value) {
  let error;
  if (!value) {
    error = "Stage is Required";
  }
  return error;
}

export function validateBookingAmount(value) {
  let error;
  if (!value) {
    error = "Booking Amount is Required";
  } else if (value.length > 6) {
    error = "Booking amount Should not more than 100000";
  } else if (value.length < 4) {
    error = "Booking amount not less than 1000";
  } else if (!/^[0-9]{4,6}$/i.test(value)) {
    error = "Invalid Booking Amount";
  }
  return error;
}

export function validateLocation(value) {
  let error;
  if (!value) {
    error = "Location is Required";
  } else if (!/^[0-9a-zA-Z\s]+$/i.test(value)) {
    error = "Enter a Valid Location";
  } else if (value.length > 15) {
    error = "Location Should not more than 15";
  } else if (value.length < 3) {
    error = "Location Should not less than 3 Characters";
  }
  return error;
}

export function validateConstructionStart(value) {
  let error;
  if (!value) {
    error = "Date of Construction is Required";
  }
  return error;
}

export function validateCompanyName(value) {
  let error;
  if (!value) {
    error = "Company Name is Required";
  } else if (!/^[0-9a-zA-Z\s]+$/i.test(value)) {
    error = "Enter a Valid Company Name";
  } else if (value.length > 15) {
    error = "Company Name Should not more than 15";
  } else if (value.length < 3) {
    error = "Company Name Should not less than 3 Characters";
  }
  return error;
}

export function validateExperience(value) {
  let error;
  if (!value) {
    error = "Experience is Required";
  } else if (!/^\(?([0-9]{1,2})$/i.test(value)) {
    error = "Enter a Valid Experience ";
  }
  return error;
}

export function validateDesignation(value) {
  let error;
  if (!value) {
    error = "Designation is Required";
  } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
    error = "Enter a Valid Designation It should contain only Aplhabets";
  } else if (value.length > 15) {
    error = "Designation Should not more than 15";
  } else if (value.length < 3) {
    error = "Designation Should not less than 3 Characters";
  }
  return error;
}

export function validatePartner(value) {
  let error;
  if (!value) {
    error = "Partner Type is Required";
  }
  return error;
}

export function validateDOB(value) {
  let error;
  if (!value) {
    error = "Date of Birth is Required";
  }
  return error;
}

export function validateDOJ(value) {
  let error;
  if (!value) {
    error = "Date of Joining is Required";
  }
  return error;
}

// Validatin for Assign Leads
export function validatePartnerName(value) {
  let error;
  if (!value) {
    error = "Partner Name is Required";
  }
  return error;
}

export function validateAssigne(value) {
  let error;
  if (!value) {
    error = "Assign Name is Required";
  }
  return error;
}

export function LeadsFromRange(value) {
  let error;
  if (!value) {
    error = "Leads From Range is Required";
  }
  return error;
}

export function LeadsToRange(value) {
  let error;
  if (!value) {
    error = "Leads To Range is Required";
  }
  return error;
}

export function validateMeetingDate(value) {
  let error;
  if (!value) {
    error = "Meeting Date is Required";
  }
  return error;
}

export function validateBDEAssigne(value) {
  let error;
  if (!value) {
    error = "Bde Name is Required";
  }
  return error;
}

export function validateFromTime(value) {
  let error;
  if (!value) {
    error = "Meeting From Time is Required";
  }
  return error;
}

export function validateToTime(value) {
  let error;
  if (!value) {
    error = "Meeting To Time is Required";
  }
  return error;
}

export function validateMeetingLink(value) {
  let error;
  if (!value) {
    error = "Meeting Link is Required";
  }
  return error;
}

export function validateMeetingLocation(value) {
  let error;
  if (!value) {
    error = "Meeting Link is Required";
  }
  return error;
}

// Custom Filters Ranges
// Past
export const LastActivityDate = [
  {
    label: "today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: "Month",
    value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
  },
  {
    label: "3 Months",
    value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
  },
  {
    label: "6 Months",
    value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
  },
  {
    label: "Year",
    value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
  },
];

export const JoiningDate = [
  {
    label: "today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: "Month",
    value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
  },
  {
    label: "3 Months",
    value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
  },
  {
    label: "6 Months",
    value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
  },
  {
    label: "Year",
    value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
  },
];

// Fucture
export const ConstructionDate = [
  {
    label: "today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: "Month",
    value: [startOfDay(addMonths(new Date(), 1)), endOfDay(new Date())],
  },
  {
    label: "3 Months",
    value: [startOfDay(addMonths(new Date(), 3)), endOfDay(new Date())],
  },
  {
    label: "6 Months",
    value: [startOfDay(addMonths(new Date(), 6)), endOfDay(new Date())],
  },
  {
    label: "Year",
    value: [startOfDay(addYears(new Date(), 1)), endOfDay(new Date())],
  },
];
