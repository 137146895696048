import React from "react";
import PieChartIcon from "@mui/icons-material/PieChart";
import GroupIcon from "@mui/icons-material/Group";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import InsightsSharpIcon from "@mui/icons-material/InsightsSharp";
import SettingsIcon from "@mui/icons-material/Settings";

export default function AdminNav() {
  let history = useHistory();
  let email = localStorage.getItem("email");

  function logout() {
    localStorage.removeItem("email");
    localStorage.clear();
    history.push("/");
  }

  const profile = () => {
    history.push("/profileadmin");
  };

  return (
    <div>
      <div className="sidenav" style={{ marginTop: "0px" }}>
        <div>
          <div className="d-flex">
            <div style={{ cursor: "pointer" }}>
              <img
                src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                className="profile_pic_sidenav"
                onClick={profile}
                alt="profile"
              />
            </div>
            <div className="p-2 profile_details">
              <span>Sales Admin</span>
              <p className="mb-0">{email}</p>
            </div>
          </div>
          <div className="pt-3">
            <NavLink
              to="/dash"
              className="side_menu_dashboardbtn1"
              style={{ justifyContent: "start" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <PieChartIcon />
                </div>
                <div>
                  <span>Dashboard</span>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="mt-3">
            <div className="dropdown">
              <button
                className="btn side_menu_partnersbtn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <GroupIcon />
                Lead Source
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ background: "#f4f4f4" }}
              >
                <NavLink to="/rplist/1" className="">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Referral Partners
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/chlist/1" className="">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Channel Partners
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/adotleads/1" className="">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Other
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            {/* <KeyboardArrowDownIcon /> */}
          </div>

          {/* <div className="pt-3">
            <Link to="/fselist" className="d-flex side_menu_dashboardbtn1">
              <div className="row">
                <div className="col-3">
                  <GroupIcon />
                </div>
                <div className="col-9 align-self-center">
                  <span className="p-2">FSE{""}</span>
                </div>
              </div>
            </Link>
          </div> */}

          <div className="pt-3">
            <NavLink
              to="/allcustomers/1"
              className="side_menu_dashboardbtn1"
              style={{ justifyContent: "start" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <GroupIcon />
                </div>
                <div>
                  <span>All Leads</span>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="pt-3">
            <NavLink
              to="/manageuser/1"
              className="side_menu_dashboardbtn1"
              style={{ justifyContent: "start" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <PersonAddAltRoundedIcon />
                </div>
                <div>
                  <span>Manage Users</span>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="pt-3">
            <div className="dropdown">
              <button
                className="btn dropdown-toggle side_menu_partnersbtn"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <UploadFileRoundedIcon />
                Reports
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenu2"
                style={{ background: "#f4f4f4" }}
              >
                <NavLink to="/userreport" className="dropdown-item">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      User Report
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/partnerreport" className="dropdown-item">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Partners Report
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/leadreport" className="dropdown-item">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Leads Report
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/paymentreport" className="dropdown-item">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Payments Report
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/adminreport" className="dropdown-item">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Sales Admin Report
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>

          {/* <div className="pt-3">
            <Link to="/dmlist" className="d-flex side_menu_dashboardbtn1">
              <div className="row">
                <div className="col-3">
                  <InsightsSharpIcon />
                </div>
                <div className="col-9 align-self-center">
                  <span>Digital Marketing</span>
                </div>
              </div>
            </Link>
          </div> */}

          <div className="mt-3">
            <div className="dropdown">
              <button
                className="btn dropdown-toggle side_menu_partnersbtn"
                type="button"
                id="dropdownDM"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <InsightsSharpIcon />
                Digital Marketing
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownDM"
                style={{ background: "#f4f4f4" }}
              >
                <NavLink to="/dmpartnerlist" className="">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Digital Marketing Partner
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/dmleadslist/1" className="">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Digital Marketing Leads
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>

          {/* <div className="pt-3">
            <Link to="/manageuser" className="d-flex side_menu_dashboardbtn1">
              <div className="row">
                <div className="col-3">
                  <SettingsIcon />
                </div>
                <div className="col-9 align-self-center">
                  <span>Settings</span>
                </div>
              </div>
            </Link>
          </div> */}

          <div className="pt-3">
            <NavLink
              to="/settings"
              className="side_menu_dashboardbtn1"
              style={{ justifyContent: "start" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <SettingsIcon />
                </div>
                <div>
                  <span>Settings</span>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="pt-3">
            <div
              className="d-flex side_menu_dashboardbtn1"
              data-toggle="modal"
              data-target=".partner_details_deleter"
              style={{ justifyContent: "start" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <LogoutIcon />
                </div>
                <div>
                  <span>Logout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade partner_details_deleter"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered ">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                </div>
                <div className="popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Logout?
                  </span>
                </div>
                <div className="pt-3 d-flex">
                  <div style={{ width: "50%", textAlign: "center" }}>
                    <button
                      type="button"
                      className="popup_btn_unlinkaccount"
                      // onClick={deletepartnerdetails}
                      data-toggle="modal"
                      data-target=".partner-details-deleted-success"
                      data-dismiss="modal"
                      onClick={logout}
                    >
                      Logout
                    </button>
                  </div>
                  <div style={{ width: "50%", textAlign: "center" }}>
                    <button
                      // type="button"
                      className="popup_btn_close"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
