import React from "react";
import LoaderImage from "../../Assets/loader.gif";

const Loader = () => {
  return (
    <div
      className="App d-flex justify-content-center align-items-center"
      style={{
        width: "100%",
        textAlign: "center",
        height: "100vh",
        background: "#f9f9f9",
      }}
    >
      <div>
        <h3 className="text-dark d-flex flex-column">
          <div>
            <img src={LoaderImage} width="50%" />
          </div>
          <div>Loading...</div>
        </h3>
      </div>
    </div>
  );
};

export default Loader;
