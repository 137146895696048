import React from "react";
import NoDataGif from "../../Assets/No_data.gif";

const NoData = () => {
  return (
    <div className="text-center">
      <img
        className="rounded img-responsive"
        src={NoDataGif}
        style={{ height: "350px" }}
      />
    </div>
  );
};

export default NoData;
