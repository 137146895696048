import React, { useEffect, useState } from "react";
import IS_SideNav from "../IS_SideNav";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Filter from "../../../Assets/Filter.svg";
import { Link } from "react-router-dom";
import { ConstructionDate, LastActivityDate } from "../../Helpers/validation";
import { useHistory } from "react-router-dom";
import { CgArrowRightR } from "react-icons/cg";
import { useParams } from "react-router";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Footer from "../../Dashboard/Footer";
// Pagination
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../../Helpers/Loader";
import { Formik, Form, Field } from "formik";
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const ISOtherLeads = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [leaddata, Setleaddata] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [btnloader, setbtnloader] = useState(false);
  const [btnbulkloader, setbtnbulkloader] = useState(false);
  const { Id } = useParams();
  const { PageNo } = useParams();
  // console.log("REDD", Id);

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [bulkleaddata, Setbulkleaddata] = useState([]);

  const { SearchBar } = Search;
  const [Page, setPage] = useState(PageNo ? PageNo : 1);
  const [PerPage, setPerPage] = useState(50);
  const [searchcount, setsearchcount] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [JoiningDateStart, setJoiningDateStart] = useState("");
  const [JoiningDateEnd, setJoiningDateEnd] = useState("");

  const valid = localStorage.getItem("ISName");

  let history = useHistory();
  useEffect(() => {
    const valid = localStorage.getItem("ISName");
    if (valid === null) {
      history.push("/");
    }

    getrps();

    getbluck();
  }, []);

  const getrps = () => {
    axios
      .get(`${SERVER_URL}/getISotherleads?is_name=${valid}`)
      .then((respon) => {
        Setleaddata(respon.data.Leads);
        setisloading(false);
      });
  };

  const getbluck = () => {
    axios.get(`${SERVER_URL}/getisleadbulk?is_name=${valid}`).then((respon) => {
      Setbulkleaddata(respon.data.Leads);
      setisloading(false);
      //   console.log("data", respon);
    });
  };

  // OnChange Function for Date Range Picker of Last Activity Date
  const onChange = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);

      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start > end) {
        setStartDate(end);
        setEndDate(start);
      } else {
        setStartDate(start);
        setEndDate(end);
      }
    } else {
      // console.log("empty");
      setStartDate("");
      setEndDate("");
    }
  };

  const handleJoining = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];

      // Converting it to format dd/mm/yyyy
      // setJoiningDateStart(start.toISOString().slice(0, 10));
      // setJoiningDateEnd(end.toISOString().slice(0, 10));
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start < end) {
        setJoiningDateStart(start);
        setJoiningDateEnd(end);
      } else {
        setJoiningDateStart(end);
        setJoiningDateEnd(start);
      }
    } else {
      // console.log("empty");
      setJoiningDateStart("");
      setJoiningDateEnd("");
    }
  };

  const AllLeadFilters = (data) => {
    setbtnloader(true);
    const sendData = {
      package: data.package,
      area_from: data.area_from,
      area_to: data.area_to,
      construct_from_date: JoiningDateStart,
      construct_to_date: JoiningDateEnd,
      persf_from: data.persf_from,
      persf_to: data.persf_to,
      is_name: data.is_name,
      project_from: data.project_from,
      project_to: data.project_to,
      source: data.source,
      booking_from: data.booking_from,
      booking_to: data.booking_to,
      call_status: data.call_status,
      activity_from: startDate,
      activity_to: endDate,
      location: data.location,
      development_name: data.development_name,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(
        `${SERVER_URL}/filteronleads?lead_source=Others&user_name=${valid}`,
        sendData,
        {
          headers,
        }
      )
      .then((res) => {
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#Add_Filter_popup").modal("hide");
          setPage(1);
          Setleaddata(res.data.Leads);
          setbtnloader(false);
          // remove
          if (res.data.Leads.length !== 0) {
            var number = document.getElementsByClassName(
              "react-bootstrap-table-page-btns-ul"
            )[0];
            var firstnum = number.querySelectorAll("li, a")[1];
            firstnum.click();
          }
        } else {
          toast.error("Something Went Wrong...try Again!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          Setleaddata("");
          setbtnloader(false);
        }
      });
  };

  // New
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const leadCallStatus = (cell) => {
    switch (cell) {
      case "Not Connected":
        return (
          <div>
            <button className="call_not_connected">Not Connected</button>
          </div>
        );
      case "Follow Up":
        return (
          <div>
            <button className="call_follow_up">Follow Up</button>
          </div>
        );
      case "DNR":
        return (
          <div>
            <button className="call_dnr">DNR</button>
          </div>
        );
      case "Interested":
        return (
          <div>
            <button className="call_intersted">Interested</button>
          </div>
        );
      case "Meeting Fixed":
        return (
          <div>
            <button className="call_meeting">Meeting Fixed</button>
          </div>
        );
      case "Qualified":
        return (
          <div>
            <button className="call_qualified">Qualified</button>
          </div>
        );
      case "Not Intersted":
        return (
          <div>
            <button className="call_not_intersted">Not Intersted</button>
          </div>
        );
      case "Meeting Done":
        return (
          <div>
            <button className="call_meet_done">Meeting Done</button>
          </div>
        );
      case "Deal Lost":
        return (
          <div>
            <button className="call_deal_lost">Deal Lost</button>
          </div>
        );
      case "Booking Amt. Received":
        return (
          <div>
            <button className="call_book_amount">Booking Amt. Received</button>
          </div>
        );
      case "Quotation Sent":
        return (
          <div>
            <button className="call_quotation">Quotation Sent</button>
          </div>
        );
      case "Dummy":
        return (
          <div>
            <button className="call_dummy">Dummy</button>
          </div>
        );
      case "Out of scope":
        return (
          <div>
            <button className="call_out_of_scope">Out of scope</button>
          </div>
        );
      default:
        return "-";
    }
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "Lead Name",
      formatter: (cell) => (!cell ? "-" : cell),
      // sort: true,
    },
    {
      dataField: "lead_id",
      text: "Lead ID",
      // sort: true,
      formatter: (cell) => (!cell ? "-" : cell),
      // searchable: false,
    },
    {
      dataField: "handled_by",
      text: "Inside Sales",
      formatter: (cell) => (!cell ? "-" : cell),
      // sort: true,
    },
    {
      dataField: "contact_no",
      text: "Mobile Number",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "call_status",
      text: "Status",
      // formatter: (cell) => (!cell ? "-" : cell),
      formatter: leadCallStatus,
    },
    {
      dataField: "lead_source",
      text: "Source",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "partner_name",
      text: "Referred by",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "activityDateTime",
      text: "Last Activity Date",
      formatter: (cell) => (!cell ? "-" : cell),
      sort: true,
    },
    {
      dataField: "start_date",
      text: " Construction Start",
      formatter: (cell) =>
        !cell ? "-" : cell.slice(0, 10).split("-").reverse().join("-"),
      searchable: false,
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell) => (
        <Link
          to={`/isotleaddetails/${cell}/${Page}`}
          // target="_blank" // remove afterwards
          className="viewmore"
        >
          <CgArrowRightR />
        </Link>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "activityDateTime",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log("row", row);
      // old
      history.push(`/isotleaddetails/${row._id}/${Page}`);
      //new
      // window.open(`/isotleaddetails/${row._id}/${Page}`, "_blank");
    },
  };

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    page: parseInt(Page),
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    // remove these two
    sizePerPage: 50,
    hideSizePerPage: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const splitImage = (cell, row) => {
    let ImageSpilt = row.bulk_image.split(".").pop();
    return <span>{ImageSpilt}</span>;
  };

  const formatOpsButtons = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Link
        to={`/isleadimage/${row.bulk_image}/${row.image_status}/${cell}/${Page}`}
        // target="_blank" // remove afterwards
        className="viewmore"
      >
        <CgArrowRightR />
      </Link>
    );
  };

  const rowEventsBulkImage = {
    onClick: (e, row, rowIndex) => {
      // console.log("row", row);
      // old
      history.push(
        `/isleadimage/${row.bulk_image}/${row.image_status}/${row._id}/${Page}`
      );
      //new
      // window.open(
      //   `/isleadimage/${row.bulk_image}/${row.image_status}/${row._id}/${Page}`,
      //   "_blank"
      // );
    },
  };

  // Bulk Image
  const bulkImageColumns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "bulk_image",
      text: "Document Name",
      formatter: (cell) => (!cell ? "-" : cell),
      // sort: true,
    },
    {
      dataField: "bulk_image",
      text: "Document Type",
      // sort: true,
      formatter: splitImage,
      searchable: false,
    },
    {
      dataField: "image_status",
      text: "Status",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: formatOpsButtons,
    },
  ];

  return (
    <div>
      {isloading === false ? (
        <div>
          <div>
            <IS_SideNav />
          </div>
          <div className="main">
            <div className="content-tabs">
              {toggleState === 1 ? (
                <ToolkitProvider
                  keyField="_id"
                  data={leaddata}
                  columns={columns}
                  search={{ afterSearch }}
                  defaultSortDirection={{ defaultSorted }}
                >
                  {(props) => (
                    <div>
                      <div className="top_menu_bar">
                        <div>
                          <div>
                            <span className="CS_head_txt">
                              List of Other Leads
                            </span>
                          </div>

                          <div>
                            {toggleState === 1 ? (
                              <span className="total_count_txt">
                                (
                                {searchcount === 0
                                  ? searchcount
                                  : searchcount > 0
                                  ? searchcount
                                  : leaddata.length}{" "}
                                leads)
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div>
                          <div
                            className="d-flex justify-content-around"
                            style={{ gap: "15px" }}
                          >
                            <div
                              className="search_div"
                              style={{ width: "65%" }}
                            >
                              <SearchIcon />
                              <SearchBar
                                {...props.searchProps}
                                className="top_menu_search"
                                placeholder="Search Here..."
                                // style={{ width: "400px", height: "40px" }}
                              />
                              {/* <input
                                type="text"
                                className="top_menu_search"
                                placeholder="Search Here.."
                                onChange={(event) => {
                                  Setsearchterm(event.target.value);
                                }}
                              /> */}
                            </div>

                            <div>
                              <button
                                className="add_btn"
                                data-toggle="modal"
                                data-target=".Add_Filter_popup"
                              >
                                <img src={Filter} />
                                Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <div className="pt-5">
                          <div className="d-flex tabs_start">
                            <div className="tab_lead_data_start">
                              <button
                                className={
                                  toggleState === 1
                                    ? "tab_lead_data active_tab_lead_data"
                                    : "tab_lead_data"
                                }
                                onClick={() => toggleTab(1)}
                              >
                                Lead Data
                              </button>
                            </div>

                            <div className="tab_lead_data_start">
                              <button
                                className={
                                  toggleState === 2
                                    ? "tab_lead_data active_tab_lead_data"
                                    : "tab_lead_data"
                                }
                                onClick={() => toggleTab(2)}
                              >
                                Lead Bulk Data
                              </button>
                            </div>
                          </div>

                          <div className="pt-3">
                            <div className="table_div">
                              {leaddata != "" ? (
                                <BootstrapTable
                                  {...props.baseProps}
                                  noDataIndication={emptyDataMessage}
                                  pagination={paginationFactory(
                                    paginationoptions
                                  )}
                                  filter={filterFactory()}
                                  rowEvents={rowEvents}
                                  // classes={"scroll_table"}
                                  wrapperClasses="table-responsive"
                                />
                              ) : (
                                <div className="d-flex">
                                  <div>
                                    <WarningRoundedIcon className="no_data_alert" />
                                  </div>
                                  <div>
                                    <h3>No records found for Customers</h3>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              ) : (
                <ToolkitProvider
                  keyField="_id"
                  data={bulkleaddata}
                  columns={bulkImageColumns}
                  search={{ afterSearch }}
                >
                  {(props) => (
                    <div>
                      <div className="top_menu_bar">
                        <div>
                          <div>
                            <span className="CS_head_txt">
                              List of Other Leads
                            </span>
                          </div>

                          <div>
                            {toggleState === 1 ? (
                              ""
                            ) : (
                              <span className="total_count_txt">
                                (
                                {searchcount === 0
                                  ? searchcount
                                  : searchcount > 0
                                  ? searchcount
                                  : bulkleaddata.length}{" "}
                                images)
                              </span>
                            )}
                          </div>
                        </div>

                        <div>
                          <div
                            className="d-flex justify-content-around"
                            style={{ gap: "15px" }}
                          >
                            <div
                              className="search_div"
                              style={{ width: "100%", padding: "6px" }}
                            >
                              <SearchIcon />
                              <SearchBar
                                {...props.searchProps}
                                className="top_menu_search"
                                placeholder="Search Here..."
                                // style={{ width: "400px", height: "40px" }}
                              />
                              {/* <input
                                type="text"
                                className="top_menu_search"
                                placeholder="Search Here.."
                                onChange={(event) => {
                                  Setsearchterm(event.target.value);
                                }}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <div className="pt-5">
                          <div className="d-flex tabs_start">
                            <div className="tab_lead_data_start">
                              <button
                                className={
                                  toggleState === 1
                                    ? "tab_lead_data active_tab_lead_data"
                                    : "tab_lead_data"
                                }
                                onClick={() => toggleTab(1)}
                              >
                                Lead Data
                              </button>
                            </div>

                            <div className="tab_lead_data_start">
                              <button
                                className={
                                  toggleState === 2
                                    ? "tab_lead_data active_tab_lead_data"
                                    : "tab_lead_data"
                                }
                                onClick={() => toggleTab(2)}
                              >
                                Lead Bulk Data
                              </button>
                            </div>
                          </div>

                          <div className="pt-3">
                            <div className="table_div">
                              {bulkleaddata != "" ? (
                                <BootstrapTable
                                  {...props.baseProps}
                                  noDataIndication={emptyDataMessage}
                                  // pagination={paginationFactory(
                                  //   paginationoptions
                                  // )}
                                  filter={filterFactory()}
                                  rowEvents={rowEventsBulkImage}
                                  // classes={"scroll_table"}
                                  wrapperClasses="table-responsive"
                                />
                              ) : (
                                <div className="d-flex">
                                  <div>
                                    <WarningRoundedIcon className="no_data_alert" />
                                  </div>
                                  <div>
                                    <h3>No records found for Customers</h3>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>

          {/* Filter Pop Up start */}
          <div>
            <div
              id="Add_Filter_popup"
              className="modal fade Add_Filter_popup"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div
                  className="modal-content modal_content_whole"
                  style={{ background: "white" }}
                >
                  <div className="modal-body" style={{ background: "#FFFFFF" }}>
                    <div className="pb-3">
                      <span className="add_hot_lead_label">Apply Filters</span>
                      <span
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="CloseAddHotLeadPopup"
                        style={{
                          background: "transparent",
                          float: "right",
                          color: "#221e1f",
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                    <Formik
                      initialValues={{
                        package: "",
                        area_from: "",
                        area_to: "",
                        persf_from: "",
                        persf_to: "",
                        project_from: "",
                        project_to: "",
                        source: "Others",
                        booking_from: "",
                        booking_to: "",
                        call_status: "",
                        location: "",
                        development_name: "",
                      }}
                      // validationSchema={validate}
                      onSubmit={(values, { resetForm }) => {
                        AllLeadFilters(values);
                      }}
                    >
                      {(formik) => (
                        <div>
                          <Form>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Package
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="package"
                                  >
                                    <option disabled selected value="">
                                      Select Package
                                    </option>
                                    <option value="Standard">Standard</option>
                                    <option value="Cendrol Plus">
                                      Cendrol Plus
                                    </option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Area(SQ.FT)
                                  </label>
                                  <div className="d-flex">
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="0sq.ft"
                                        id="from"
                                        name="area_from"
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="align-self-center to_txt">
                                      to
                                    </div>
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="100sq.ft"
                                        id="from"
                                        name="area_to"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Construction Start Date
                                  </label>
                                  <DateRangePicker
                                    placeholder="Select Date Range"
                                    format="dd/MM/yyyy"
                                    character="-"
                                    onChange={handleJoining}
                                    ranges={ConstructionDate}
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Per SQ.FT. Amount
                                  </label>
                                  <div className="d-flex">
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 0"
                                        id="from"
                                        name="persf_from"
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="align-self-center to_txt">
                                      to
                                    </div>
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 100"
                                        id="from"
                                        name="persf_to"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Plot Location
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    type="text"
                                    className="field_input_txt"
                                    placeholder="Enter Plot Location"
                                    id="from"
                                    name="location"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Project Value
                                  </label>
                                  <div className="d-flex">
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 0"
                                        id="from"
                                        name="project_from"
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="align-self-center to_txt">
                                      to
                                    </div>
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 100"
                                        id="from"
                                        name="project_to"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Lead Source
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    // as="select"
                                    className="field_input_txt"
                                    name="source"
                                    value={"Others"}
                                  >
                                    {/* <option disabled selected value="">
                                  Select User
                                </option>
                                <option value="RP">RP</option>
                                <option value="CP">CP</option>
                                <option value="DM">DM</option>
                                <option value="Others">Others</option> */}
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Booking Amount
                                </label>
                                <div className="d-flex">
                                  <div>
                                    <Field
                                      type="text"
                                      className="field_input_txt"
                                      placeholder="Rs 0"
                                      id="from"
                                      name="booking_from"
                                      autoComplete="off"
                                    />
                                  </div>
                                  <div className="align-self-center to_txt">
                                    to
                                  </div>
                                  <div>
                                    <Field
                                      type="text"
                                      className="field_input_txt"
                                      placeholder="Rs 100"
                                      id="from"
                                      name="booking_to"
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Call Status
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="call_status"
                                  >
                                    <option disabled selected value="">
                                      Select Call Status
                                    </option>
                                    <option value="Not Connected">
                                      Not Connected
                                    </option>
                                    <option value="Follow Up">Follow Up</option>
                                    <option value="DNR">DNR</option>
                                    <option value="Interested">
                                      Interested
                                    </option>
                                    <option value="Meeting Fixed">
                                      Meeting Fixed
                                    </option>
                                    <option value="Qualified">Qualified</option>
                                    <option value="Not Intersted">
                                      Not Intersted
                                    </option>
                                    <option value="Dummy">Dummy</option>
                                    <option value="Out of scope">
                                      Out of scope
                                    </option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Last Activity Date
                                  </label>
                                  <DateRangePicker
                                    placeholder="Select Date Range"
                                    format="dd/MM/yyyy"
                                    character="-"
                                    onChange={onChange}
                                    ranges={LastActivityDate}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-12">
                                <label className="filter_input_head_txt pb-3">
                                  Development Name
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    type="text"
                                    className="field_input_txt"
                                    placeholder="Enter Development Name"
                                    id="from"
                                    name="development_name"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row pt-4">
                              <div className="col-12 col-md-6">
                                <button
                                  type="reset"
                                  className="btn btn-lg btn_resetall"
                                >
                                  Reset All
                                </button>
                              </div>
                              <div className="col-12 col-md-6">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn_apply_filter"
                                >
                                  {btnloader === true ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    ""
                                  )}
                                  Apply Filter
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Filter Pop Up End */}
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ISOtherLeads;
