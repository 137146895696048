import React from "react";
import "./dashboard.css";

const Footer = () => {
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <span className="copy_right_txt">Copyright © 2022-2022</span>{" "}
          <span className="cendrol_refappadmin">Cendrol.com</span>{" "}
          <span className="copy_right_txt">All rights reserved.</span>
        </div>
        <div>
          <span className="copy_right_txt">Version</span>{" "}
          <span className="cendrol_refappadmin">0.3</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
