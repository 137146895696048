import React from "react";
import { Field } from "formik";

const PartnerKyc = ({ values, errors, touched, showeditkyc, refpanno }) => {
  return (
    <>
      {!showeditkyc && (
        <div>
          <div className="row">
            <div className="col-12 col-md-12">
              <div>
                <p className="m-0 admin_type_heading">PAN Number</p>
                <span className="admin_type_value">
                  {refpanno === "" ? "-" : refpanno}
                </span>
              </div>
            </div>

            {/* <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Dob</p>
                <span className="admin_type_value">{refdobnew}</span>
              </div>
            </div> */}

            {/* <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Aadhar Number</p>
                <span className="admin_type_value">{refaadharno}</span>
              </div>
            </div> */}

            {/* <div className="col-6 col-md-6">
              <div>
                <p className="m-0 admin_type_heading">Referral Code</p>
                <span className="admin_type_value">
                  {FSECode === "" ? "-" : FSECode}
                </span>
              </div>
            </div> */}
          </div>

          {/* <div className="row mt-3">
            <div className="col-md-4">
              <button className="download_btn">
                <DownloadIcon />
                Photo
              </button>
            </div>
            <div className="col-md-4">
              <button className="download_btn">
                <DownloadIcon />
                Pan Card
              </button>
            </div>
            <div className="col-md-4">
              <button className="download_btn">
                <DownloadIcon />
                Aadhar Card
              </button>
            </div>
          </div> */}
        </div>
      )}
      {showeditkyc && (
        <div className="d-flex justify-content-between flex-wrap contact_info_admin">
          <div className="row">
            <div className="col-md-12">
              <div className="input-group-custom">
                <label htmlFor="type" className="label kyc_detail_title">
                  Pan Number
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="type"
                  name="panNumber"
                  value={values.panNumber}
                  //   onChange={(e) => {
                  //     Setrefpanno(e.target.value);
                  //   }}
                />
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="input-group-custom">
                <label htmlFor="date" className="label kyc_detail_title">
                  Dob
                </label>
                <input
                  type="date"
                  className="input-area admin_partner_det_input"
                  required
                  id="type"
                  name="dob"
                  value={refdob}
                  onChange={(e) => {
                    Setrefdob(e.target.value);
                  }}
                />
              </div>
            </div> */}
            {/* <div className="col-md-3">
              <div className="input-group-custom">
                <label htmlFor="type" className="label kyc_detail_title">
                  Aadhar Number
                </label>
                <input
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="type"
                  name="adharNumber"
                  value={refaadharno}
                  onChange={(e) => {
                    Setrefaadharno(e.target.value);
                  }}
                />
              </div>
            </div> */}

            {/* <div className="col-md-6">
              <div className="input-group-custom">
                <label htmlFor="type" className="label kyc_detail_title">
                  Referral Code
                </label>
                <input
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="type"
                  name="ifscCode"
                  value={FSECode}
                  onChange={(e) => {
                    SetFSECode(e.target.value);
                  }}
                />
              </div>
            </div> */}
          </div>
          {/* <div className="row mt-3" style={{}}>
            <div className="col-md-4">
              <div>
                <input
                  type="file"
                  className="file"
                  name="profilePic"
                  onChange={(e) => {
                    changeProfilepic(e);
                  }}
                />
                Upload Photo
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <input
                  type="file"
                  className="file"
                  name="panCard"
                  onChange={(e) => {
                    changePanPhoto(e);
                  }}
                />
                Upload Pan
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <input
                  type="file"
                  className="file"
                  name="adharCard"
                  onChange={(e) => {
                    changeAadhaarPhoto(e);
                  }}
                />
                Upload Aadhar
              </div>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default PartnerKyc;
