import React, { useEffect, useState } from "react";
import CS_SideNav from "../ChannelSales/CS_SideNav";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import axios from "axios";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Footer from "../Dashboard/Footer";
// Pagination
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Helpers/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const DMCSList = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [partnerdata, Setpartnerdata] = useState([]);

  const [isloading, setisloading] = useState(true);
  // Paganiation
  const { SearchBar } = Search;
  const [Page, setPage] = useState(1);
  const [PerPage, setPerPage] = useState(30);
  const [searchcount, setsearchcount] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  let verify = localStorage.getItem("user_code");
  const { Id } = useParams();
  let history = useHistory();
  // console.log("REDD", Id);
  useEffect(() => {
    const valid = localStorage.getItem("CSName");
    if (valid === null) {
      history.push("/");
    }

    axios
      .get(`${SERVER_URL}/getcsdmpartner?assigned_to=${valid}`)
      .then((responselistpat) => {
        Setpartnerdata(responselistpat.data.partners);
        setisloading(false);
        // console.log("Customer data", responselistpat);
      });
  }, []);

  // For Assigning To
  const setassignes = (e, ids) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };

    // SetVerifystat(e.target.value);
    let selectt = e.target.value;
    const updstatus = {
      dmstatus: selectt,
    };

    // console.log("id", ids);
    // console.log("statverified", statverified);

    axios
      .post(`${SERVER_URL}/dmstatus?_id=${ids}`, updstatus, {
        headers,
      })
      .then((resultsverified) => {
        // console.log("verified orr", resultsverified);
        let Status = resultsverified.data.status;
        if (Status === "success") {
          // alert("Partner Assigned Successfully");
          toast.success("Partner Status Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  // New Pagination
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "fullname",
      text: "Partner Name",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "creationdate",
      text: "Creation Date",
      formatter: (cell) =>
        !cell ? "-" : cell.slice(0, 10).split("-").reverse().join("-"),
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ],
  };

  return (
    <div>
      {isloading === false ? (
        <div>
          <CS_SideNav />
          <div className="main">
            <div className="content-tabs">
              <ToolkitProvider
                keyField="_id"
                data={partnerdata}
                columns={columns}
                search={{ afterSearch }}
              >
                {(props) => (
                  <div>
                    <div className="top_menu_bar">
                      <div>
                        <div>
                          <span className="CS_head_txt">
                            List Of Referral Partners
                          </span>
                        </div>
                        <span className="total_count_txt">
                          (
                          {searchcount === 0
                            ? searchcount
                            : searchcount > 0
                            ? searchcount
                            : partnerdata.length}{" "}
                          partners)
                        </span>
                      </div>

                      <div>
                        <div className="d-flex" style={{ gap: "22px" }}>
                          <div
                            className="search_div"
                            style={{ width: "100%", padding: "6px" }}
                          >
                            <SearchIcon className="search_icon" />
                            <SearchBar
                              {...props.searchProps}
                              className="top_menu_search"
                              placeholder="Search Here..."
                              // style={{ width: "400px", height: "40px" }}
                            />
                            {/* <input
                              type="text"
                              className="top_menu_search"
                              placeholder="Search Here.."
                              onChange={(event) => {
                                Setsearchterms(event.target.value);
                              }}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="table_div">
                        {partnerdata != "" ? (
                          <div>
                            <BootstrapTable
                              {...props.baseProps}
                              noDataIndication={emptyDataMessage}
                              pagination={paginationFactory(paginationoptions)}
                              filter={filterFactory()}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div>
                              <WarningRoundedIcon className="no_data_alert" />
                            </div>
                            <div>
                              <h3>No records found for Customers</h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default DMCSList;
