import React, { useEffect, useState } from "react";
import CS_SideNav from "./CS_SideNav";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Pagination } from "antd";
import { CgArrowRightR } from "react-icons/cg";
import "antd/dist/antd.css";
// Pop Up content
// Pagination
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Helpers/Loader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Formik, Form, Field, useField } from "formik";
import { TextField } from "../Fields/TextField";
import { SelectField } from "../Fields/SelectField";
import AddIcon from "@mui/icons-material/Add";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Footer from "../Dashboard/Footer";
// RP Referred rp csv
import "../RPList/file.css";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const CSRPReferedPartner = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [leaddata, Setleaddata] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [btnloader, setbtnloader] = useState(false);

  const { Id } = useParams();
  const { Number } = useParams();
  const { PageNo } = useParams();

  let history = useHistory();

  // Paganiation
  const { SearchBar } = Search;
  const [Page, setPage] = useState(PageNo ? PageNo : 1);
  const [PerPage, setPerPage] = useState(50);
  const [searchcount, setsearchcount] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  useEffect(() => {
    const valid = localStorage.getItem("user_code");
    if (valid === null) {
      history.push("/");
    }

    getrps();
  }, []);

  const getrps = () => {
    axios.get(`${SERVER_URL}/getrefpartner?_id=${Id}`).then((respon) => {
      Setleaddata(respon.data.Referral_partners);
      setisloading(false);
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    setPerPage(pageSize);
  };

  // Add Referral Popup conetnet start
  const [Rpprofile, setRpprofile] = useState("");

  const updaterpdetails = (data, resetForm) => {
    setbtnloader(true);
    const formdata = new FormData();
    formdata.append("fullname", data.fullname);
    formdata.append("email", data.email);
    formdata.append("contact_no", data.contact_no);
    formdata.append("companyname", data.companyname);
    formdata.append("experience", data.experience);
    formdata.append("designation", data.designation);
    formdata.append("location", data.location);
    formdata.append("dob", data.dob);
    formdata.append("doj", data.doj);
    formdata.append("partnertype", data.partnertype);
    formdata.append("profile_pic", Rpprofile);

    let verify = localStorage.getItem("user_code");
    // console.log("verify", verify);

    // console.log("Data", formdata);

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/addreferralpartner?_id=${Id}`, formdata, {
        headers,
      })
      .then((res) => {
        // console.log("res", res.data.status);
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#Add_Ref_popup").modal("hide");
          window.$("#PartnerAddedSuccessfully").modal("show");
          resetForm({ data: "" });
          // window.$("#lead_added_successfully").modal("show");
          getrps();
          setbtnloader(false);
          // history.push("/rplist");
        } else {
          // resetForm({ data: "" });
          // window.$("#Add_Ref_popup").modal("hide");
          // alert("Details are already Exists");
          setbtnloader(false);
          toast.error("Partner Details Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const changeprofilepic = (e) => {
    setRpprofile(e.target.files[0]);
    console.log("rpprofileee", e.target.files[0]);
  };

  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is Required";
    } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
      error = "Enter a Valid Name It should contain only Aplhabets";
    } else if (value.length > 20) {
      error = "Name Should not more than 20";
    } else if (value.length < 3) {
      error = "Name Should not less than 3 Characters";
    }
    return error;
  }

  // function validateEmail(value) {
  //   let error;
  //   if (!value) {
  //     error = "Email is Required";
  //   } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value)) {
  //     error = "Invalid email address";
  //   }
  //   return error;
  // }

  function validateMobileNumber(value) {
    let error;
    if (!value) {
      error = "Mobile Number is Required";
    } else if (value.length > 10) {
      error = "Mobile Number Should not more than 10";
    } else if (value.length < 10) {
      error = "Mobile Number Should not less than 10 ";
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
    ) {
      error = "Invalid Mobile Number...Enter Numeric";
    }
    return error;
  }

  // function validateCompanyName(value) {
  //   let error;
  //   if (!value) {
  //     error = "Company Name is Required";
  //   } else if (!/^[0-9a-zA-Z\s]+$/i.test(value)) {
  //     error = "Enter a Valid Company Name";
  //   } else if (value.length > 15) {
  //     error = "Company Name Should not more than 15";
  //   } else if (value.length < 3) {
  //     error = "Company Name Should not less than 3 Characters";
  //   }
  //   return error;
  // }

  // function validateExperience(value) {
  //   let error;
  //   if (!value) {
  //     error = "Experience is Required";
  //   } else if (!/^\(?([0-9]{1,2})$/i.test(value)) {
  //     error = "Enter a Valid Experience ";
  //   }
  //   return error;
  // }

  // function validateDesignation(value) {
  //   let error;
  //   if (!value) {
  //     error = "Designation is Required";
  //   } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
  //     error = "Enter a Valid Designation It should contain only Aplhabets";
  //   } else if (value.length > 15) {
  //     error = "Designation Should not more than 15";
  //   } else if (value.length < 3) {
  //     error = "Designation Should not less than 3 Characters";
  //   }
  //   return error;
  // }

  function validatePartner(value) {
    let error;
    if (!value) {
      error = "Partner Type is Required";
    }
    return error;
  }

  // function validateDOB(value) {
  //   let error;
  //   if (!value) {
  //     error = "Date of Birth is Required";
  //   }
  //   return error;
  // }

  // function validateDOJ(value) {
  //   let error;
  //   if (!value) {
  //     error = "Date of Joining is Required";
  //   }
  //   return error;
  // }

  // function validateLocation(value) {
  //   let error;
  //   if (!value) {
  //     error = "Location is Required";
  //   } else if (!/^[0-9a-zA-Z\s]+$/i.test(value)) {
  //     error = "Enter a Valid Location";
  //   } else if (value.length > 15) {
  //     error = "Location Should not more than 15";
  //   } else if (value.length < 3) {
  //     error = "Location Should not less than 3 Characters";
  //   }
  //   return error;
  // }

  // RP Referred Rp csv
  const [fileNames, setFileNames] = useState([]);
  const handleDrop = (acceptedFiles) => setFileNames(acceptedFiles[0]);

  // const updatecsvfile = (e) => {
  //   e.preventDefault();
  //   const formdata = new FormData();
  //   formdata.append("file", fileNames);

  //   let verify = localStorage.getItem("_id");
  //   console.log(verify);

  //   const headers = {
  //     "content-type": "multipart/form-data",
  //   };

  //   axios
  //     .post(`${SERVER_URL}/uploadreferraldata?_id=${Id}`, formdata)
  //     .then((res) => {
  //       console.log("res", res.data.status);
  //       let Status = res.data.status;
  //       if (Status === "success") {
  //         alert("Details Added successfuly");
  //       } else {
  //         alert("Details are already Exists");
  //       }
  //     });
  // };

  // New Pagination
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "fullname",
      text: "Partner Name",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "contact_no",
      text: "Mobile No.",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "referral_ID",
      text: "Partner Id",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell) => (!cell ? "-" : cell),
      searchable: false,
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell) => (
        <Link
          to={`/csrpreferedrpdetails/${cell}/${Number}/${Page}`}
          // target="_blank" // remove afterwards
          className="viewmore"
        >
          <CgArrowRightR />
        </Link>
      ),
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log("row", row);
      //?old
      history.push(`/csrpreferedrpdetails/${row._id}/${Number}/${Page}`);
      //new
      // window.open(
      //   `/csrpreferedrpdetails/${row._id}/${Number}/${Page}`,
      //   "_blank"
      // );
    },
  };

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    page: parseInt(Page),
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    // remove these two
    sizePerPage: 50,
    hideSizePerPage: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ],
  };

  return (
    <div>
      {isloading === false ? (
        <div>
          <div>
            <CS_SideNav />
          </div>

          <div className="main">
            <div className="content-tabs">
              <ToolkitProvider
                keyField="_id"
                data={leaddata}
                columns={columns}
                search={{ afterSearch }}
              >
                {(props) => (
                  <div>
                    <div className="top_menu_bar">
                      <div>
                        <Link
                          to={`/cspartnerdetails/${Id}/${Number}`}
                          className="partner_back_btn"
                        >
                          <div>
                            <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                            <span>Back</span>
                          </div>
                        </Link>
                      </div>
                      <div>
                        <div className="d-flex justify-content-around">
                          <div className="search_div" style={{ width: "47%" }}>
                            <SearchIcon />
                            <SearchBar
                              {...props.searchProps}
                              className="top_menu_search"
                              placeholder="Search Here..."
                              // style={{ width: "400px", height: "40px" }}
                            />
                            {/* <input
                              type="text"
                              className="top_menu_search"
                              placeholder="Search Here.."
                              onChange={(event) => {
                                Setsearchterm(event.target.value);
                              }}
                            /> */}
                          </div>
                          <div>
                            {/* <AddCSReferalReferedRP ids={Id} /> */}

                            {/* Add RP Referred RP POP Up Start */}
                            <button
                              className="add_btn"
                              data-toggle="modal"
                              data-target=".Add_Ref_popup"
                            >
                              <AddIcon className="search_icons" />
                              Add Partner
                            </button>
                            {/* Add RP Referred RP POP Up End */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="table_div">
                        {leaddata != "" ? (
                          <div>
                            <BootstrapTable
                              {...props.baseProps}
                              noDataIndication={emptyDataMessage}
                              pagination={paginationFactory(paginationoptions)}
                              filter={filterFactory()}
                              rowEvents={rowEvents}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div>
                              <WarningRoundedIcon className="no_data_alert" />
                            </div>
                            <div>
                              <h3>No records found for Customers</h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>

          {/* Add Partner Pop up start */}
          <div>
            <div
              id="Add_Ref_popup"
              className="modal fade Add_Ref_popup"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-dialog-centered">
                <div
                  className="modal-content modal_content_whole"
                  style={{ background: "white" }}
                >
                  {/*  */}
                  <div className="modal-body">
                    <div className="pb-3">
                      <span className="add_hot_lead_label">
                        {/* <LocalFireDepartmentIcon
                                              style={{
                                                color: "red",
                                              }}
                                            /> */}
                        Add Referral Partner
                      </span>
                      <span
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="CloseAddHotLeadPopup"
                        style={{
                          background: "transparent",
                          float: "right",
                          color: "#00000",
                        }}
                      >
                        <span aria-hidden="true" style={{ fontSize: "18px" }}>
                          X
                        </span>
                      </span>
                    </div>
                    {/* <div className="row">
                                  <div className="col-md-5 col-5 d-flex align-items-center justify-content-center">
                                
                                  //  RP Referred RP CSV FILE Start 
                                    <div>
                                      <Formik
                                        initialValues={{
                                          file: "",
                                        }}
                                        onSubmit={() => {}}
                                      >
                                        <Form>
                                          <Dropzone onDrop={handleDrop}>
                                            {({
                                              getRootProps,
                                              getInputProps,
                                            }) => (
                                              <div
                                                {...getRootProps({
                                                  className: "dropzone",
                                                })}
                                              >
                                                <input
                                                  name="fileNames"
                                                  {...getInputProps()}
                                                />
                                                <p>
                                                  Drag'n'drop files, or click to
                                                  select files
                                                </p>
                                              </div>
                                            )}
                                          </Dropzone>
                                          <div>
                                            <strong>Files:</strong>
                                            <ul>
                                              <li>{fileNames.name}</li>
                                            </ul>
                                            <button
                                              className="btn_add_hot_lead"
                                              type="submit"
                                              onClick={updatecsvfile}
                                            >
                                              Upload
                                            </button>
                                          </div>
                                        </Form>
                                      </Formik>
                                    </div>
                                    // RP Referred RP CSV FILE END
                                  </div>
                                  <div className="col-md-1 col-1 d-flex align-items-center justify-content-center flex-column">
                                    <div
                                      style={{
                                        height: "200px",
                                        border: "1px solid #969696",
                                      }}
                                    ></div>
                                    <div>OR</div>
                                    <div
                                      style={{
                                        height: "200px",
                                        border: "1px solid #969696",
                                      }}
                                    ></div>
                                  </div> */}

                    <div>
                      {/* <div className="col-md-6 col-6"> */}
                      <div>
                        <Formik
                          initialValues={{
                            fullname: "",
                            email: "",
                            contact_no: "",
                            companyname: "",
                            experience: "",
                            designation: "",
                            location: "",
                            dob: "",
                            doj: "",
                            partnertype: "",
                            Profile: "",
                          }}
                          // validationSchema={validate}
                          onSubmit={(values, { resetForm }) => {
                            // console.log("values", values);
                            updaterpdetails(values, resetForm);
                          }}
                        >
                          {(formik) => (
                            <div>
                              <Form>
                                {/* <AddReferalPopup /> */}
                                <div className="d-flex justify-content-between">
                                  <div
                                    className="p-1"
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    <div>
                                      <div className="p-3 add_hot_lead_content">
                                        <div className="row ">
                                          <div className="col-md-12">
                                            <div className="input-group_add_hot_lead ">
                                              <TextField
                                                type="text"
                                                name="fullname"
                                                label="Full Name"
                                                validate={validateName}
                                                placeholder="Enter Name"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row pb-2">
                                          <div className="col-6 col-sm-6">
                                            <div className="input-group_add_hot_lead ">
                                              <TextField
                                                type="email"
                                                style={{
                                                  textTransform: "lowercase",
                                                }}
                                                name="email"
                                                label="Email"
                                                placeholder="Enter Email"
                                              />
                                            </div>
                                          </div>

                                          <div className="col-6 col-sm-6">
                                            <div className="input-group_add_hot_lead ">
                                              <TextField
                                                type="tel"
                                                name="contact_no"
                                                label="Phone Number"
                                                validate={validateMobileNumber}
                                                placeholder="Enter Mobile Number"
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row pb-2">
                                          <div className="col-6 col-sm-6">
                                            <div className="input-group_add_hot_lead ">
                                              <TextField
                                                type="text"
                                                name="companyname"
                                                label="Company Name"
                                                placeholder="Enter Company Name"
                                              />
                                            </div>
                                          </div>

                                          <div className="col-6 col-sm-6">
                                            <div className="input-group_add_hot_lead ">
                                              <TextField
                                                type="text"
                                                name="experience"
                                                label="Total Experience"
                                                placeholder="Enter Experience"
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row pb-2">
                                          <div className="col-6 col-sm-6">
                                            <div className="input-group_add_hot_lead ">
                                              <TextField
                                                type="text"
                                                name="designation"
                                                label="Designation"
                                                placeholder="Enter Designation"
                                              />
                                            </div>
                                          </div>

                                          <div className="col-6 col-sm-6">
                                            <div className="input-group_add_hot_lead">
                                              <SelectField
                                                // className="form-select"
                                                name="partnertype"
                                                label="Type Of Partner"
                                                validate={validatePartner}
                                              >
                                                <option
                                                  selected
                                                  disabled
                                                  value=""
                                                >
                                                  Select
                                                </option>
                                                <option value="RP">RP</option>
                                                <option value="CP">CP</option>
                                              </SelectField>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-6 col-sm-6">
                                            <div className="input-group_add_hot_lead ">
                                              <TextField
                                                type="date"
                                                name="dob"
                                                label="Date of Birth"
                                              />
                                            </div>
                                          </div>

                                          <div className="col-6 col-sm-6">
                                            <div className="input-group_add_hot_lead ">
                                              <TextField
                                                type="date"
                                                name="doj"
                                                label="Joining Date"
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-12 col-sm-12">
                                            <div className="input-group_add_hot_lead ">
                                              <TextField
                                                type="text"
                                                name="location"
                                                label="Work Location"
                                                placeholder="Enter Location"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <button
                                            className="btn_add_hot_lead"
                                            type="submit"
                                          >
                                            {btnloader === true ? (
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                              ></span>
                                            ) : (
                                              ""
                                            )}
                                            Add Partner
                                          </button>
                                        </div>

                                        {/* 2nd Model */}
                                        <div
                                          id="lead_added_successfully"
                                          className="modal fade bd-lead-added-successfully"
                                          role="dialog"
                                          aria-labelledby="myLargeModalLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-sm">
                                            <CheckCircleIcon />
                                            <span>Lead Added Successfully</span>
                                          </div>
                                        </div>

                                        <div>
                                          <div
                                            className="d-flex justify-content-between"
                                            style={{
                                              height: "0",
                                            }}
                                          >
                                            <div
                                              className="p-3"
                                              style={{
                                                width: "100%",
                                              }}
                                            >
                                              <div className="pb-3">
                                                <div
                                                  className="modal fade popup-add_hot-lead-added"
                                                  role="dialog"
                                                  aria-labelledby="myLargeModalLabel"
                                                  aria-hidden="true"
                                                  // id="myModal"
                                                  // style={{display:"none"}}
                                                >
                                                  <div className="modal-dialog modal-dialog-centered">
                                                    <div
                                                      className="modal-content modal_content_whole"
                                                      style={{
                                                        background: "white",
                                                      }}
                                                    >
                                                      <div className="modal-body">
                                                        <div>
                                                          <div className="popup_icon_center">
                                                            <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                                                          </div>
                                                          <div className="popup_text_center">
                                                            <span className="popup_addbankdetails_text">
                                                              Your Account
                                                              details are
                                                              <br />
                                                              Received,after
                                                              verification you
                                                              will be notified
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Add Partner popup end */}

          {/* Partner Added Successfully */}
          <div
            id="PartnerAddedSuccessfully"
            className="modal fade bd-lead-added-successfully"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm modal-dialog-centered">
              <div
                className="modal-content modal-sm modal_content_whole"
                style={{ background: "white" }}
              >
                <div className="modal-body">
                  <div>
                    <div className="popup_icon_center">
                      <CheckCircleIcon className="popup_addbankdetails_icon" />
                    </div>
                    <div className="popup_text_center">
                      <p className="pt-2 popup_addbankdetails_text">
                        Partner Added Successfully
                      </p>
                      <div className="pt-2 m-0 d-flex justify-content-center">
                        <div
                          style={{
                            width: "50%",
                            textAlign: "center",
                          }}
                        >
                          <button
                            // type="button"
                            className="popup_btn_linkaccount"
                            data-dismiss="modal"
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default CSRPReferedPartner;
