import React, { useEffect } from "react";
import "../Dashboard/dashboard.css";
import { useHistory } from "react-router-dom";
import AdminNavInner from "./AdminNavInner";

export default function AdminNavigation() {
  let history = useHistory();

  useEffect(() => {
    const valid = localStorage.getItem("email");
    // console.log("dsdsdsdsdsds", valid);
    if (valid === null) {
      history.push("/");
    }
  });

  return (
    <div>
      <div>
        <AdminNavInner />
      </div>
      {/* <div className="main">
        <div className="content-tabs">
          <div>
            <div className="top_menu_bar">
              <div>
                <h4>Dashboard</h4>
              </div>
            </div>
            <div className="mt-3">
              <div className="table_div"></div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
