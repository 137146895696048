import { Field } from "formik";
import React, { useState } from "react";
import whatsApp from "../../Assets/logos_whatsapp.svg";
import {
  validateMobileNumber,
  validateEmail,
} from "../../Components/Helpers/validation";

const PartnerContact = ({
  showedit,
  refemail,
  refmobile,
  errors,
  touched,
  values,
}) => {
  return (
    <>
      {!showedit && (
        <div className="contact_info_">
          <div>
            <p className="m-0 admin_type_heading">Email</p>
            <span className="admin_type_value">
              {refemail === "" ? "-" : refemail}
            </span>
          </div>
          <div className="ml-4 mt-3">
            <div className="d-flex justify-content-between">
              <div>
                <p className="m-0 admin_type_heading">Mobile No.</p>
                <span className="admin_type_value">
                  {refmobile === "" ? "-" : refmobile}
                </span>
              </div>
              {refmobile !== "" ? (
                <div className="whats_app_div">
                  <a
                    href={`https://wa.me/${refmobile}`}
                    className="whatsapp_float"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={whatsApp} />
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
      {showedit && (
        <div className="contact_info_admin">
          <div className="input-group-custom">
            <label htmlFor="loc" className="label">
              Email
            </label>
            <br />
            <Field
              type="email"
              className="input-area"
              //   required
              id="email"
              name="email"
              value={values.email}
              //   onChange={(e) => {
              //     Setrefemail(e.target.value);
              //   }}
              validate={validateEmail}
            />
            {errors.email && touched.email && (
              <p className="error">{errors.email}</p>
            )}
          </div>
          <div className="mt-3 input-group-custom">
            <label htmlFor="mob" className="label">
              Mobile No
            </label>
            <br />
            <Field
              type="text"
              className="input-area"
              //   required
              id="mob"
              name="mobile"
              value={values.mobile}
              //   onChange={(e) => {
              //     Setrefmobile(e.target.value);
              //   }}
              validate={validateMobileNumber}
            />
            {errors.mobile && touched.mobile && (
              <p className="error">{errors.mobile}</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PartnerContact;
