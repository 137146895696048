import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { Formik, Form, Field } from "formik";
import { TextField } from "../Fields/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const OtherDynamicInputImage = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const { Id } = useParams();
  const [comment_date, Setcomment_date] = useState("");
  const valid = localStorage.getItem("CSName");
  const [btnloader, setbtnloader] = useState(false);

  // Individual Validation Start
  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is Required";
    } else if (!/^[a-z][A-Z\s.]+$/i.test(value)) {
      error = "Enter a Valid Name It should contain only Aplhabets";
    } else if (value.length > 20) {
      error = "Name Should not more than 20";
    } else if (value.length < 3) {
      error = "Name Should not less than 3 Characters";
    }
    return error;
  }

  function validateMobileNumber(value) {
    let error;
    if (!value) {
      error = "Mobile Number is Required";
    } else if (value.length > 10) {
      error = "Mobile Number Should not more than 10";
    } else if (value.length < 10) {
      error = "Mobile Number Should not less than 10 ";
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
    ) {
      error = "Invalid Mobile Number...Enter Numeric";
    }
    return error;
  }

  function validateStatus(value) {
    let error;
    if (!value) {
      error = "Status is Required";
    }
    return error;
  }

  function validateDate(value) {
    let error;
    if (!value) {
      error = "Date is Required";
    }
    return error;
  }

  function validateComments(value) {
    let error;
    if (!value) {
      error = "Comments is Required";
    } else if (!/^[a-z][A-Z\s,]+$/i.test(value)) {
      error = "Enter a Valid Comments It should contain only Aplhabets";
    } else if (value.length > 100) {
      error = "Comments Should not more than 100";
    } else if (value.length < 3) {
      error = "Comments Should not less than 3 Characters";
    }
    return error;
  }

  // const disablePastDate = () => {
  //   const today = new Date();
  //   const dd = String(today.getDate() + 0).padStart(2, "0");
  //   const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  //   const yyyy = today.getFullYear();
  //   return yyyy + "-" + mm + "-" + dd;
  // };

  // const disableFuctureDate = () => {
  //   var dtToday = new Date();
  //   var month = dtToday.getMonth() + 1;
  //   var day = dtToday.getDate();
  //   var year = dtToday.getFullYear();

  //   if (month < 10) month = "0" + month.toString();
  //   if (day < 10) day = "0" + day.toString();
  //   var maxDate = year + "-" + month + "-" + day;
  //   console.log("max", maxDate);
  // };

  var now = new Date();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  var today = now.getFullYear() + "-" + month + "-" + day;

  // $(function () {

  //   $("#txtDate").attr("max", maxDate);
  // });
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          fullname: "",
          mobilenumber: "",
          call_status: "",
          comment_date: today,
          comment: "",
        }}
        onSubmit={(values, { resetForm }) => {
          setbtnloader(true);
          const headers = {
            "Content-Type": "application/json",
          };

          axios
            .post(`${SERVER_URL}/addbulkleadbycs?lgs_name=${valid}`, values, {
              headers,
            })
            .then((res) => {
              // console.log("Hello Don", res);
              let Status = res.data.status;
              if (Status === "success") {
                setbtnloader(false);
                // alert("Lead Added sucessfully");
                toast.success("Lead Added sucessfully", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                resetForm({ data: "" });
              } else if (Status === "failed") {
                setbtnloader(false);
                // alert("Lead Details are already exists");
                toast.error("Lead Details Already Exists", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            })
            .catch((err) => {
              alert("Some Internal Error", err);
            });
        }}
      >
        {({ errors, touched }) => (
          <div>
            <Form>
              <div className="pt-4 row">
                <div className="col-6 col-md-4">
                  <div className="form-group">
                    <label htmlFor="fullname" className="label_txt_mc">
                      Full Name
                    </label>
                    <Field
                      type="text"
                      className="p-3 input_view_txt_mc"
                      placeholder="Enter Name"
                      id="fullname"
                      name="fullname"
                      autoComplete="off"
                      validate={validateName}
                    />
                  </div>
                  {errors.fullname && touched.fullname && (
                    <p className="error">{errors.fullname}</p>
                  )}
                </div>

                <div className="col-6 col-md-4">
                  <div className="form-group">
                    <label htmlFor="mobilenumber" className="label_txt_mc">
                      Phone Number
                    </label>
                    <Field
                      type="tel"
                      className="p-3 input_view_txt_mc"
                      placeholder="Enter Number"
                      id="mobilenumber"
                      name="mobilenumber"
                      validate={validateMobileNumber}
                      autoComplete="off"
                    />
                  </div>
                  {errors.mobilenumber && touched.mobilenumber && (
                    <p className="error">{errors.mobilenumber}</p>
                  )}
                </div>

                <div className="col-6 col-md-4">
                  <div className="form-group">
                    <label htmlFor="vendorname" className="label_txt_mc">
                      Call Status
                    </label>
                    <Field
                      as="select"
                      className="p-3 input_view_txt_mc"
                      id="select"
                      defaultValue={"Select"}
                      validate={validateStatus}
                      name="call_status"
                    >
                      <option selected disabled value="">
                        Select Status
                      </option>
                      <option value="Not Connected">Not Connected</option>
                      <option value="Follow Up">Follow Up</option>
                      <option value="DNR">DNR</option>
                      <option value="Interested">Interested</option>
                      <option value="Meeting Fixed">Meeting Fixed</option>
                      <option value="Qualified">Qualified</option>
                      <option value="Not Intersted">Not Intersted</option>
                      <option value="Dummy">Dummy</option>
                      <option value="Out of scope">Out of scope</option>
                    </Field>
                  </div>
                  {errors.customer_status && touched.customer_status && (
                    <p className="error">{errors.customer_status}</p>
                  )}
                </div>
              </div>

              <div className="pt-4 row">
                <div className="col-6 col-md-5">
                  <div className="form-group">
                    <label htmlFor="date" className="label_txt_mc">
                      Date
                    </label>
                    <Field
                      type="text"
                      className="p-3 input_view_txt_mc"
                      id="date"
                      name="comment_date"
                      placeholder="Choose Date"
                      // validate={validateDate}
                      value={today}
                      // min={disablePastDate()}
                      // max={disableFuctureDate()}
                    />
                  </div>
                  {errors.comment_date && touched.comment_date && (
                    <p className="error">{errors.comment_date}</p>
                  )}
                </div>

                <div className="col-6 col-md-7">
                  <div className="form-group">
                    <label htmlFor="comments" className="label_txt_mc">
                      Comment
                    </label>
                    <Field
                      type="text"
                      className="p-3 input_view_txt_mc"
                      placeholder="Enter Comments"
                      id="comments"
                      name="comment"
                      validate={validateComments}
                      autoComplete="off"
                    />
                  </div>
                  {errors.comment && touched.comment && (
                    <p className="error">{errors.comment}</p>
                  )}
                </div>
              </div>

              <div className="pt-4">
                <button
                  className="btn_lead_comments"
                  style={{ background: "#f4f4f4" }}
                >
                  {btnloader === true ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                  {/* <AddIcon className="search_icons_comments_lead" /> */}
                  Save
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default OtherDynamicInputImage;
