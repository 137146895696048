import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import IS_SideNav from "../IS_SideNav";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Footer from "../../Dashboard/Footer";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const ISChannelPartnerDetails = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  // var DownloadButton = require('downloadbutton/es5')
  const [show, toggleShow] = useState(false);
  const [showbank, toggleShowbank] = useState(false);
  const [showedit, toggleShowedit] = useState(false);
  const [showeditkyc, toggleShoweditkyc] = useState(false);
  const [showprofilepic, toggleShowProfilepic] = useState(false);
  const [showcmts, toggleshowcmts] = useState(false);
  const { Id } = useParams();
  // console.log("id123", Id);

  // var DownloadButton = require('downloadbutton/es5');
  let history = useHistory();

  // state to set all personal info values of partners
  const [refnames, Setrefnames] = useState([]);
  const [refjoiningdate, Setrefjoiningdate] = useState([]);
  const [refcompname, Setrefcompname] = useState([]);
  const [reftotalexp, Setreftotalexp] = useState([]);
  const [refdob, Setrefdob] = useState([]);
  const [refdesg, Setrefdesg] = useState([]);
  const [reftype, Setreftype] = useState([]);
  const [refworkloc, Setrefworkloc] = useState([]);

  // state to set contact info
  const [refemail, Setrefemail] = useState([]);
  const [refmobile, Setrefmobile] = useState([]);

  const [verifaction, SetVerification] = useState("");

  // Bank details
  const [refbankdetails, Setrefbankdetails] = useState([]);
  const [refpatname, Setrefpatname] = useState([]);
  const [refbankname, Setrefbankname] = useState([]);
  const [refbranchname, Setrefbranchname] = useState([]);
  const [refaccno, Setrefaccno] = useState([]);
  const [refifsccode, Setrefifsccode] = useState([]);
  const [refbankacctype, Setrefbankacctype] = useState([]);

  // To set KYC Details
  const [refpanno, Setrefpanno] = useState([]);
  const [refaadharno, Setrefaadharno] = useState([]);

  const [profilepic, setprofilepic] = useState("");

  // Target
  const [remarks, Setremarks] = useState("");
  const [FSECode, SetFSECode] = useState("");

  useEffect(() => {
    const valid = localStorage.getItem("user_code");
    if (valid === null) {
      history.push("/");
    }

    getData();

    // to fetch bank details api
    axios.get(`${SERVER_URL}/getbankdetailsbyid?_id=${Id}`).then((res) => {
      // Bank Detais
      var bankdetailslength = res.data;
      var lengthofbank = res.data.documents.length;
      // console.log("dssd", res);
      localStorage.setItem("lengthofbank", lengthofbank);
      if (lengthofbank > 0 && lengthofbank != "") {
        Setrefbankdetails(res.data.documents[0]);
        Setrefpatname(res.data.documents[0].partnerName);
        Setrefbankname(res.data.documents[0].bankName);
        Setrefbranchname(res.data.documents[0].branch);
        Setrefaccno(res.data.documents[0].accountNumber);
        Setrefifsccode(res.data.documents[0].ifscCode);
        Setrefbankacctype(res.data.documents[0].accountType);
        Setrefpanno(res.data.documents[0].panNumber);
        Setrefaadharno(res.data.documents[0].adharNumber);
        // console.log("NEWDOBB", res.data);
      } else {
        Setrefbankdetails("");
        Setrefpatname("");
        Setrefbankname("");
        Setrefbranchname("");
        Setrefaccno("");
        Setrefifsccode("");
        Setrefbankacctype("");
        Setrefpanno("");
        Setrefaadharno("");
      }
    });
  }, []);

  const getData = () => {
    // console.log(refjoiningdate);
    axios.get(`${SERVER_URL}/getpartnersbyid?_id=${Id}`).then((reps) => {
      // Personal
      Setrefnames(reps.data.partners[0].fullname);
      Setrefjoiningdate(reps.data.partners[0].doj);
      Setrefcompname(reps.data.partners[0].companyname);
      Setreftotalexp(reps.data.partners[0].experience);
      Setrefdob(reps.data.partners[0].dob);
      Setrefdesg(reps.data.partners[0].designation);
      Setreftype(reps.data.partners[0].partnertype);
      Setrefworkloc(reps.data.partners[0].location);
      Setremarks(reps.data.partners[0].remarks);
      // Contact info
      Setrefemail(reps.data.partners[0].email);
      Setrefmobile(reps.data.partners[0].mobile);
      // console.log("fse", reps.data);
      SetFSECode(reps.data.partners[0].referral_code);
      // Verification
      SetVerification(reps.data.partners[0].verified);
      // SetPersfamt(reps.data.partners[0].persfamt);
      // SetPerprojectamt(reps.data.partners[0].perprojectamt);
      // console.log("Persfamt", reps.data.partners[0].persfamt);
    });
  };

  // To update the details
  const mydetails = (e) => {
    e.preventDefault();
    const upddata = {
      remarks: remarks,
    };
    // console.log("data", upddata);

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/updatepartner?_id=${Id}`, upddata, {
        headers,
      })
      .then((res) => {
        // console.log("Hello Don", res);
        let Status = res.data.status;
        if (Status === "success") {
          // alert("Profile Details are edited sucessfully");
          toast.success("Comments are edited successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getData();
        } else if (Status === "failed") {
          // alert("Profile Details are already exists");
          toast.error("Comments are Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        alert("Some Internal Error", err);
      });
  };

  return (
    <div>
      <div>
        <IS_SideNav />
      </div>
      <div className="main">
        <div className="content-tabs">
          <div>
            <div className="row top_menu_bar">
              <div className="col-md-10 d-flex align-items-center">
                {/* <Link to={`/islistchpartner`} className="partner_back_btn">
                    <span>
                      <ArrowBackIosNewIcon />
                      Back
                    </span>
                  </Link> */}

                <Link to={`/islistchpartner`} className="partner_back_btn">
                  <div>
                    <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                    <span>Back</span>
                  </div>
                </Link>
              </div>

              <div className="col-md-2">
                <div className="d-flex justify-content-end">
                  <div className="dropdown verify_btn">
                    {verifaction === false ? (
                      <div>
                        <button
                          className="add_btn"
                          type="button"
                          id="dropdownMenuButton"
                          style={{ padding: "10px 10px" }}
                        >
                          <CancelRoundedIcon className="not_verify_icons" />
                          Not Verified
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="add_btn"
                          type="button"
                          id="dropdownMenuButton"
                        >
                          <CheckCircleIcon className="verify_icons" />
                          Verified
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="partner_details_edit_sec">
              <form>
                <div className="row">
                  <div className="col-md-8">
                    <div className="p-3 admin_patrner_personal_info">
                      <div>
                        <p className="head_txt head_txt_margin">
                          Partner Personal Info
                        </p>
                        {/* <div
                            className="edit_icon"
                            onClick={() => toggleShow(!show)}
                          >
                            {!show && (
                              <div>
                                <EditIcon className="search_icons" />
                                <span className="edit_txt_btn">Edit</span>
                              </div>
                            )}
                            {show && (
                              <div>
                                <button
                                  type="submit"
                                  className="edit_icon"
                                  onClick={mydetails}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div> */}
                      </div>
                      {!show && (
                        <div className="viewing_details">
                          <div className="row">
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Full Name
                                </p>
                                <span className="admin_type_value">
                                  {refnames === "" ? "-" : refnames}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Joining Date
                                </p>
                                <span className="admin_type_value">
                                  {refjoiningdate === "" ? "-" : refjoiningdate}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Company Name
                                </p>
                                <span className="admin_type_value">
                                  {refcompname === "" ? "-" : refcompname}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Total Experience
                                </p>
                                <span className="admin_type_value">
                                  {reftotalexp === "" ? "-" : reftotalexp}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Date of Birth
                                </p>
                                <span className="admin_type_value">
                                  {refdob === "" ? "-" : refdob}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Designation
                                </p>
                                <span className="admin_type_value">
                                  {refdesg === "" ? "-" : refdesg}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Type of Partner
                                </p>
                                <span className="admin_type_value">
                                  {reftype === "" ? "-" : reftype}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Work Location
                                </p>
                                <span className="admin_type_value">
                                  {refworkloc === "" ? "-" : refworkloc}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* To Edit START */}
                      {/* {show && (
                          <div className="edit_details">
                            <div className="row">
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="inputName" className="label">
                                    Full Name
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="inputName"
                                    name="name"
                                    value={refnames}
                                    onChange={(e) => {
                                      Setrefnames(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="date" className="label">
                                    Joining Date
                                  </label>
                                  <input
                                    type="date"
                                    className="input-area admin_partner_det_input"
                                    name="joining_date"
                                    value={refjoiningdate}
                                    onChange={(e) => {
                                      Setrefjoiningdate(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="comp" className="label">
                                    Company Name
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="comp"
                                    name="company_name"
                                    value={refcompname}
                                    onChange={(e) => {
                                      Setrefcompname(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="exp" className="label">
                                    Total Experience
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="exp"
                                    name="total_expernice"
                                    value={reftotalexp}
                                    onChange={(e) => {
                                      Setreftotalexp(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="dob" className="label">
                                    Date of Birth
                                  </label>
                                  <input
                                    type="date"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="dob"
                                    name="dob"
                                    value={refdob}
                                    onChange={(e) => {
                                      Setrefdob(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="des" className="label">
                                    Designation
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="des"
                                    name="designation"
                                    value={refdesg}
                                    onChange={(e) => {
                                      Setrefdesg(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="type" className="label">
                                    Type of Partner
                                  </label>
                                  <br />
                                  <span>{reftype}</span>
                                </div>
                              </div>

                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="loc" className="label">
                                    Work Location
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="loc"
                                    name="work_location"
                                    value={refworkloc}
                                    onChange={(e) => {
                                      Setrefworkloc(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                      {/* To Edit START */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="p-3 admin_patrner_personal_info">
                      <div>
                        <p className="head_txt head_txt_margin">
                          Partner Contact Info
                        </p>
                        {/* <div
                            className="edit_icon"
                            onClick={() => toggleShowedit(!showedit)}
                          >
                            {!showedit && (
                              <div>
                                <EditIcon className="search_icons" />
                                <span className="edit_txt_btn">Edit</span>
                              </div>
                            )}
                            {showedit && (
                              <div>
                                <button
                                  type="submit"
                                  className="edit_icon"
                                  onClick={mydetails}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div> */}
                      </div>
                      {!showedit && (
                        <div className="contact_info_">
                          <div>
                            <p className="m-0 admin_type_heading">Email</p>
                            <span className="admin_type_value">
                              {refemail === "" ? "-" : refemail}
                            </span>
                          </div>
                          <div className="ml-4 mt-3">
                            <p className="m-0 admin_type_heading">Mobile No.</p>
                            <span className="admin_type_value">
                              {refmobile === "" ? "-" : refmobile}
                            </span>
                          </div>
                        </div>
                      )}
                      {/* {showedit && (
                          <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                            <div className="input-group-custom">
                              <label htmlFor="loc" className="label">
                                Email
                              </label>
                              <br />
                              <input
                                type="email"
                                className="input-area"
                                required
                                id="email"
                                name="email"
                                value={refemail}
                                onChange={(e) => {
                                  Setrefemail(e.target.value);
                                }}
                              />
                            </div>
                            <div className="input-group-custom mt-3">
                              <label htmlFor="mob" className="label">
                                Mobile No.
                              </label>
                              <br />
                              <input
                                type="text"
                                className="input-area"
                                required
                                id="mob"
                                name="mobile_no"
                                value={refmobile}
                                onChange={(e) => {
                                  Setrefmobile(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        )} */}
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  {/* <div className="col-md-3">
                      <div className="p-3 admin_patrner_personal_info">
                        <div className="d-flex justify-content-between">
                          <p className="head_txt">Profile Pic</p>
                        </div>
                        {!showprofilepic && (
                          <div className="viewing_details">
                            <div className="row">
                              <div className="col-6 col-md-6">
                                <button className="download_btn">
                                  <DownloadIcon />
                                  Photo
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div> */}

                  <div className="col-md-5">
                    <div
                      className="p-3 admin_patrner_personal_info"
                      style={{ height: "100%" }}
                    >
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Comment</p>
                        <div
                          className="edit_icon"
                          onClick={() => toggleshowcmts(!showcmts)}
                        >
                          {!showcmts && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span className="edit_txt_btn">Edit</span>
                            </div>
                          )}
                          {showcmts && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={mydetails}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!showcmts && (
                        <div className="viewing_details">
                          <div className="row">
                            <div className="col-6 col-md-6">
                              <div>
                                <span className="admin_type_value">
                                  {remarks === "" ? "-" : remarks}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {showcmts && (
                        <div className="edit_details">
                          <div className="row">
                            <div className="col-12 col-md-12">
                              <div className="input-group-custom">
                                {/* <label htmlFor="loc" className="label">
                                    Comments
                                  </label> */}
                                <textarea
                                  rows="4"
                                  cols="8"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputName"
                                  name="name"
                                  value={remarks}
                                  onChange={(e) => {
                                    Setremarks(e.target.value);
                                  }}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div
                  className="mt-3 d-flex justify-content-between"
                  style={{ width: "32%" }}
                >
                  <div>
                    <Link to={`/ischreferedleads/${Id}`}>
                      <button className="refered_lead_btn">
                        Referred Leads <ChevronRightIcon />
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default ISChannelPartnerDetails;
