import React from "react";
import profilepic from "../../Assets/profilepic.png";
import PieChartIcon from "@mui/icons-material/PieChart";
import GroupIcon from "@mui/icons-material/Group";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useHistory } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Link } from "react-router-dom";
import adminprofile from "../../Assets/adminprofile.png";
import LogoutIcon from "@mui/icons-material/Logout";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
export default function UserNav() {
  let history = useHistory();
  function logout() {
    localStorage.removeItem("username");
    history.push("/");
  }
  return (
    <div>
      <div className="sidenav">
        <div>
          <div className="d-flex">
            <div>
              <img src={adminprofile} className="profile_pic_sidenav" />
            </div>
            <div className="p-2 profile_details">
              <span>Robert Fox </span>
              <p className="mb-0">robert12@gmail.com</p>
            </div>
          </div>

          <div className="pt-5">
            <Link to="/dashuser" className="d-flex side_menu_dashboardbtn">
              <div className="row">
                <div className="col-3">
                  <PieChartIcon className="" />
                </div>
                <div className="col-9 align-self-center">
                  <span>Dashboard</span>
                </div>
              </div>
            </Link>
          </div>

          <div className="pt-3">
            <Link
              to="/listofpartners"
              className="d-flex side_menu_dashboardbtn"
            >
              <div className="row">
                <div className="col-3">
                  <PieChartIcon className="" />
                </div>
                <div className="ps-0 col-9 align-self-center">
                  <span>List of Partners</span>
                </div>
              </div>
            </Link>
          </div>

          <div className="pt-3">
            {/* <Link to="/login" className="side_menu_dashboardbtn1" onClick={logout}> */}
            <div
              //
              className="d-flex side_menu_dashboardbtn1"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <div className="row">
                <div className="col-3">
                  <LogoutIcon />
                </div>
                <div className="col-9 align-self-center">
                  <span>Logout</span>
                </div>
              </div>
            </div>
            {/* </Link> */}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                </div>
                <div className="popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Logout ?
                  </span>
                </div>
                <div className="pt-3 d-flex">
                  <div style={{ width: "50%", textAlign: "center" }}>
                    <button
                      type="button"
                      className="popup_btn_unlinkaccount"
                      onClick={logout}
                      data-dismiss="modal"
                    >
                      Yes
                    </button>
                  </div>
                  <div style={{ width: "50%", textAlign: "center" }}>
                    <button
                      // type="button"
                      className="popup_btn_close"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
