import React from "react";

const PartnerHandledBy = ({ onboarderby, managedby }) => {
  return (
    <div className="viewing_details">
      <div className="row">
        <div className="col-12 col-md-6">
          <div>
            <p className="m-0 admin_type_heading">Onboarded By</p>
            <span className="admin_type_value">
              {onboarderby === "" ? "-" : onboarderby}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div>
            <p className="m-0 admin_type_heading">Managed By</p>
            <span className="admin_type_value">
              {managedby === "" ? "-" : managedby}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerHandledBy;
