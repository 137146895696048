import React from "react";

const DynamicInputFieldsDisplay = ({ comments }) => {
  // console.log("sss", comments);
  return (
    <div>
      {comments != ""
        ? comments.map((cust) => {
            return (
              <div className="pt-2 row" key={cust._id}>
                <div className="col-12 col-md-4">
                  <div className="input-group-custom">
                    <label htmlFor="date" className="label">
                      Date
                    </label>
                    <input
                      // type="date"
                      className="input-area admin_partner_det_input"
                      required
                      id="date"
                      name="date"
                      value={cust.comment_date
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("-")}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="input-group-custom">
                    <label htmlFor="call_status" className="label">
                      Call Status
                    </label>
                    <input
                      className="input-area admin_partner_det_input"
                      id="call_status"
                      name="call_status"
                      value={cust.call_status}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="input-group-custom">
                    <label htmlFor="comments" className="label">
                      Comments
                    </label>
                    <input
                      type="text"
                      className="input-area admin_partner_det_input"
                      required
                      id="comments"
                      name="comments"
                      value={cust.comment}
                    />
                  </div>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default DynamicInputFieldsDisplay;
