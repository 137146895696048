import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";

export default function QuotePdfView() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const IMG_URL = process.env.REACT_APP_PDF_URL;
  const { Id } = useParams();
  const [quotes, Setquotes] = useState([]);

  useEffect(() => {
    // getpdffile();
  }, []);

  // const getpdffile = () => {
  //   axios.get(`${SERVER_URL}/getquotebyid?_id=${Id}`).then((ret) => {
  //     console.log("Pddf", ret.data.Quote);
  //     Setquotes(ret.data.Quote[0].quote);
  //   });
  // };
  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-10">
          <div>
            <iframe
              src={`${IMG_URL}/${Id}`}
              width="100%"
              style={{ height: "90vh" }}
            />
            {/* <object
              style={{ height: "100vh" }}
              width="100%"
              data={`${IMG_URL}/${quotes}`}
              type="application/pdf"
            ></object> */}
          </div>
        </div>
      </div>
    </div>
  );
}
