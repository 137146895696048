import react, { useState, useEffect } from "react";
import "./CendrolProjects.css";
import $ from "jquery";

import Switch from "../Switch/Switch";

import { GrClose } from "react-icons/gr";
import gripper from "../../../Assets/gripper.png";
import hoverDelBtn from "../../../Assets/Hover.png";
import { MdAddCircleOutline } from "react-icons/md";

function CendrolProjects() {
  const axios = require("axios").default;
  const [cendrolProjects, setCendrolProjects] = useState([]);
  const [files, setFiles] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [buildUpArea, setBuildUpArea] = useState("");
  const [size, setSize] = useState("");
  const [noOfbathrooms, setNoOfBathrooms] = useState("");
  const [projectValue, setProjectValue] = useState("");
  const [description, setDescription] = useState("");
  const [preUploadedImgs, setPreUploadedImgs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryInput, setCategoryInput] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [editView, setEditView] = useState(false);
  const [addCatComponent, setAddCatComponent] = useState(true);
  const [projectsInCategory, setProjectsInCategory] = useState([]);
  const [addIntoCategory, setAddIntoCategory] = useState("");
  const [inCategory, setInCategory] = useState(false);
  const [projectId, setProjectid] = useState("");
  const [projRankEdit, setProjRankEdit] = useState(false);

  useEffect(() => {
    getAllData();
    getCategories();
    setActiveTab(categories[0]);
  }, []);

  async function getAllData() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getprojects`)
      .then(function (response) {
        setCendrolProjects(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function addProject() {
    // console.log(files);
    // var imgsToSend = [];
    // [...files].forEach((image) => {
    // const reader = new FileReader();
    // reader.readAsBinaryString(image);
    // imgsToSend.push(image);
    // });

    var addProjData = new FormData();

    files.forEach((image) => {
      addProjData.append("projectimages", image);
    });

    addProjData.append("project_name", projectName);
    addProjData.append("builtup_area", buildUpArea);
    addProjData.append("size", size);
    addProjData.append("no_of_bathrooms", noOfbathrooms);
    addProjData.append("project_value", projectValue);
    addProjData.append("project_description", description);
    // addProjData.append("projectimages", imgsToSend);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/addprojects`, addProjData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setFiles();
        getAllData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleImageUpload(e) {
    var tempFilesArr = [...files];
    // tempFilesArr.push([URL.createObjectURL(e.target.files[0])]);
    tempFilesArr.push(e.target.files[0]);
    console.log(tempFilesArr);
    setFiles(tempFilesArr);
  }

  function removeImg(img) {
    var tempFilesArr = [...files];
    setFiles(tempFilesArr.filter((imgName) => imgName[0] != img[0]));
  }

  function showDets(project) {
    // console.log(project);
    setProjectName(project.project_name);
    setBuildUpArea(project.builtup_area);
    setSize(project.size);
    setNoOfBathrooms(project.no_of_bathrooms);
    setProjectValue(project.project_value);
    setDescription(project.project_description);
    setPreUploadedImgs(project.project_images);
    setProjectid(project._id);

    setEditView(true);

    window.$("#addProjectModal").modal("show");
  }

  function showEditView() {
    clearStates();

    window.$("#addProjectModal").modal("show");
  }

  function showAddCategory() {
    clearStates();

    window.$("#addCategoryModal").modal("show");
  }

  function clearStates() {
    setProjectName("");
    setBuildUpArea("");
    setSize("");
    setNoOfBathrooms("");
    setProjectValue("");
    setDescription("");
    setPreUploadedImgs([]);
  }

  function getCategories() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getcategories`)
      .then((res) => categoriesList(res));

    function categoriesList(res) {
      const resList = res.data.result;
      var catList = [];
      resList.map((cat) => catList.push(cat.Name));
      setCategories(catList);
      setActiveTab(catList[0]);
      getProjectsByCategory(catList[0]);
    }
  }

  // console.log(response.data.result)

  function getProjectsByCategory(category) {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/getprojectspercategory?category=${category}`
      )
      .then((response) => {
        if (response.data.result.length === 0) {
          console.log(response.data.result);
          setProjectsInCategory([]);
        } else {
          console.log(response.data.result);
          setProjectsInCategory(response.data.result);
        }
      });
  }

  function addCategory() {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/addcategory`, {
        category: categoryInput,
      })
      .then((res) => getCategories())
      .catch((err) => getCategories());
    setAddCatComponent(true);
  }

  function deleteCategory(catgry) {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/deletecategories`, {
        category: catgry,
      })
      .then((res) => getCategories())
      .catch((err) => console.log(err));
  }

  function addProjInCategory() {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/addprojectTocategory?category=${activeTab}`,
        {
          project_name: addIntoCategory,
        }
      )
      .then((res) => {
        getAllData();
        getProjectsByCategory(activeTab);
      })
      .catch((err) => console.log(err));
  }

  function removeProject() {
    if (inCategory === false) {
      console.log("not in category");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/deleteprojects?_id=${projectId}`
        )
        .then((res) => console.log(projectId));
    } else if (inCategory === true) {
      console.log("in category");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/deletecategoryprojects?_id=${projectId}`
        )
        .then((res) => console.log(projectId));
    }
    getAllData();
    getProjectsByCategory(activeTab);
  }

  function deleteFromCategory(id) {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/deletecategoryprojects?_id=${id}`
      )
      .then((res) => console.log(res));

    getAllData();
    getProjectsByCategory(activeTab);
  }

  return (
    <div className="contentDiv">
      <div
        className="modal fade"
        id="addCategoryModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ width: "450px" }}
        >
          <div className="modal-content popupBox">
            <div className="popupHeader">
              <h5
                className="modal-title popupHeadText"
                id="exampleModalLongTitle"
              >
                Edit Categories
              </h5>
              <button className="popupClose" data-dismiss="modal">
                <GrClose />
              </button>
            </div>
            <div className="modal-body popupBody">
              <div className="popupRow popupTableHead">
                <div className="popupDivL" style={{ marginTop: "0px" }}>
                  <p className="popupLabel">Category Name</p>
                </div>
                <div className="popupDivR" style={{ marginTop: "0px" }}>
                  <p className="popupLabel">Action</p>
                </div>
              </div>
              <div>
                {categories.map((category) => {
                  return (
                    <div className="popupRow">
                      <p className="popupInput popupDivL">{category}</p>
                      <button
                        className="catDelete popupDivR"
                        onClick={() => deleteCategory(category)}
                      >
                        Delete
                      </button>
                    </div>
                  );
                })}
              </div>
              {addCatComponent === true ? (
                <button
                  className="popupSubmit"
                  onClick={() => setAddCatComponent(false)}
                >
                  + Add Category
                </button>
              ) : (
                <div className="popupRow">
                  <input
                    className="popupInput popupDivL"
                    placeholder="New Category"
                    onChange={(e) => setCategoryInput(e.target.value)}
                  />
                  <button
                    className="catAdd popupDivR"
                    onClick={() => {
                      addCategory();
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="addProjectModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div
            className="modal-content popupBox"
            style={{ marginBottom: "15px" }}
          >
            <div className="popupHeader">
              <h5
                className="modal-title popupHeadText"
                id="exampleModalLongTitle"
              >
                Add Project
              </h5>
              <button className="popupClose" data-dismiss="modal">
                <GrClose />
              </button>
            </div>
            <div className="modal-body popupBody">
              <div className="popupBiSectionDiv">
                <div className="popupFormDiv">
                  <div className="popupFormRow">
                    <span className="marginR15">
                      <p className="popupLabel">Project Name</p>
                      <input
                        className="popupInput"
                        style={{ width: "150%" }}
                        value={projectName}
                        onChange={(e) => {
                          setProjectName(e.target.value);
                        }}
                      />
                    </span>
                  </div>
                  <div className="popupFormRow">
                    <span className="marginR15">
                      <p className="popupLabel">Build Up Area</p>
                      <input
                        className="popupInput"
                        value={buildUpArea}
                        onChange={(e) => {
                          setBuildUpArea(e.target.value);
                        }}
                      />
                    </span>
                    <span className="marginR15">
                      <p className="popupLabel">Size</p>
                      <input
                        className="popupInput"
                        value={size}
                        onChange={(e) => {
                          setSize(e.target.value);
                        }}
                      />
                    </span>
                  </div>
                  <div className="popupFormRow">
                    <span className="marginR15">
                      <p className="popupLabel">No. Of Bathrooms</p>
                      <input
                        className="popupInput"
                        value={noOfbathrooms}
                        onChange={(e) => {
                          setNoOfBathrooms(e.target.value);
                        }}
                      />
                    </span>
                    <span className="marginR15">
                      <p className="popupLabel">Project Value</p>
                      <input
                        className="popupInput"
                        value={projectValue}
                        onChange={(e) => {
                          setProjectValue(e.target.value);
                        }}
                      />
                    </span>
                  </div>
                  <p className="popupLabel">Description</p>
                  <textarea
                    placeholder="Enter Description Here"
                    className="popupInputLg"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>

                <div className="popupImgDiv">
                  <p className="popupLabel" style={{ width: "100%" }}>
                    Project Images
                  </p>
                  {preUploadedImgs?.map((img) => {
                    console.log(img);
                    return (
                      <div className="imgParent" onClick={() => removeImg(img)}>
                        <img className="hoverDel" src={hoverDelBtn} />
                        <img
                          className="imgPrev"
                          src={`${process.env.REACT_APP_IMAGES_URL}/${img}`}
                        />
                      </div>
                    );
                  })}
                  {files?.map((img) => {
                    var ImageSource = URL.createObjectURL(img);
                    return (
                      <div className="imgParent" onClick={() => removeImg(img)}>
                        <img className="hoverDel" src={hoverDelBtn} />
                        <img className="imgPrev" src={ImageSource} />
                      </div>
                    );
                  })}
                  {files?.length < 6 && !editView && (
                    <div class="upload-btn-wrapper">
                      <button class="addimgbtn">
                        <MdAddCircleOutline />
                        <p className="addProjText">Add Photo</p>
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        onChange={(e) => handleImageUpload(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
              {editView === false ? (
                <button
                  className="popupSubmitSm"
                  onClick={() => addProject()}
                  data-dismiss="modal"
                >
                  + Add Project
                </button>
              ) : (
                <div>
                  <button
                    className="catDelete popupDeleteSm"
                    onClick={() => {
                      removeProject();
                    }}
                    data-dismiss="modal"
                  >
                    Remove
                  </button>
                  {/* <button className="popupSubmitSm">Submit</button> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="contHead">
        <h6 id="setHeader">Cendrol Projects</h6>
        <div className="btns">
          <button
            className="addBtn"
            // data-toggle="modal"
            // data-target="#addProjectModal"
            onClick={() => {
              setEditView(false);
              showEditView();
            }}
          >
            + Add Project
          </button>
        </div>
      </div>
      <div className="viewContentDiv">
        {cendrolProjects.map((project, index) => {
          return (
            <div
              className="cellDiv"
              onClick={() => (showDets(project), setInCategory(false))}
            >
              <div
                className="imgDiv"
                style={{
                  backgroundImage: `url("${process.env.REACT_APP_IMAGES_URL}/${project.project_images[0]}")`,
                }}
              >
                <div className="details">
                  <div style={{ padding: "15px" }}>
                    <p className="projName">{project.project_name}</p>
                    <p className="viewDets">View Details</p>
                  </div>
                  <p className="rank">{index + 1}</p>
                </div>
              </div>
            </div>
          );
        })}

        {/* Tab Section */}
        <div className="tabsNbtns">
          <div className="catTabs">
            {categories.map((category) => {
              var bgColor = "#f5f5f5";
              activeTab !== category
                ? (bgColor = "#f5f5f5")
                : (bgColor = `linear-gradient(162.29deg, #FFE185 -6.85%, #F7BA00 100.08%)`);
              return (
                <p
                  className="catCells"
                  style={{ background: bgColor }}
                  onClick={() => (
                    setActiveTab(category), getProjectsByCategory(category)
                  )}
                >
                  {category}
                </p>
              );
            })}
          </div>
          <div className="btns">
            <button
              className="addBtn"
              // data-toggle="modal"
              // data-target="#addProjectModal"
              onClick={() => {
                setEditView(false);
                showAddCategory();
              }}
            >
              Edit Categories
            </button>
          </div>
        </div>

        {projectsInCategory.map((result, index) => {
          return (
            <div className="cellRowDiv">
              <div className="rowRanknProjName">
                <img src={gripper} className="gripper" />
                <p className="rank projRowRank">{index + 1}</p>
                <div className="rowInfoText">
                  <p className="rowLabels">Project Name</p>
                  <p className="rowInfoText">{result.project_name}</p>
                </div>
              </div>
              <div>
                <p className="rowLabels">Build Up Area</p>
                <p className="rowInfoText">{result.builtup_area}</p>
              </div>
              <div className="projectValue">
                <p className="rowInfoText">{result.project_value}/-</p>
              </div>
              <div
                className="viewDetsRowBtn"
                onClick={() => (showDets(result), setInCategory(true))}
              >
                <p className="viewDets" style={{ color: "black" }}>
                  View Details
                </p>
              </div>
            </div>
          );
        })}
        <div>
          <button
            className="addBtn"
            data-toggle="modal"
            data-target="#addProjInCatModal"
          >
            + Add Project
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id="addProjInCatModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ width: "450px" }}
        >
          <div className="modal-content popupBox">
            <div className="popupHeader">
              <h5
                className="modal-title popupHeadText"
                id="exampleModalLongTitle"
              >
                Add Project to "{activeTab}" Category
              </h5>
              <button className="popupClose" data-dismiss="modal">
                <GrClose />
              </button>
            </div>
            <div className="modal-body popupBody">
              <p className="popupLabel">Select Project</p>
              <select
                name="project"
                id="catProject"
                className="popupInput"
                onChange={(e) => setAddIntoCategory(e.target.value)}
              >
                {cendrolProjects.map((project) => (
                  <option value={project.project_name}>
                    {project.project_name}
                  </option>
                ))}
              </select>

              <button
                className="popupSubmit"
                onClick={() => addProjInCategory()}
                data-dismiss="modal"
              >
                + Add Project
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CendrolProjects;
