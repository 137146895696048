import React, { useState, useEffect } from "react";
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import {
  startOfDay,
  endOfDay,
  subMonths,
  endOfToday,
  subYears,
  startOfToday,
} from "date-fns";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import NoData from "../../Dashboard/NoData";
import axios from "axios";

const TotalLeadsCalled = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const valid = localStorage.getItem("ISName");
  //   Date
  var date = new Date();
  const [dateValue, setdateValue] = useState([
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // OnChange Function for Date Range Picker
  const onChange = (dates) => {
    // console.log("dates", dates);
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start < end) {
        setStartDate(start);
        setEndDate(end);
      } else {
        setStartDate(end);
        setEndDate(start);
      }

      // Converting it to format dd/mm/yyyy
      // setStartDate(start.toISOString().slice(0, 10));
      // setEndDate(end.toISOString().slice(0, 10));
      // console.log("StartDate", startDate);
      // console.log("EndDate", endDate);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const [linechartdata, setlinechartdata] = useState([]);

  useEffect(() => {
    getGraph();
  }, [startDate, endDate]);

  const getGraph = () => {
    axios
      .get(
        `${SERVER_URL}/leadcalls?from_date=${startDate}&to_date=${endDate}&is_name=${valid}`
      )
      .then((res) => {
        console.log("res", res);
        setlinechartdata(res.data.result);
      });
    // .catch((err) => alert(err));
  };

  var data = [];
  data = linechartdata;

  const Ranges = [
    {
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Month",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
    },
    {
      label: "3 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
    },
    {
      label: "6 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
    },
    {
      label: "Year",
      value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pb-4">
        <div>
          <span className="dashboard_head_txt">Total Leads Called</span>
        </div>
        <div>
          <DateRangePicker
            // style={{ left: "780px" }}
            name="start"
            placeholder="Select Date Range"
            format="dd/MM/yyyy"
            character="-"
            placement="bottomEnd"
            onChange={onChange}
            ranges={Ranges}
            // defaultValue={dateValue}
          />
        </div>
      </div>

      <div className="dashboard_line_graph">
        {linechartdata != "" ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={data}
              margin={{
                top: 15,
                left: -15,
                bottom: 15,
                right: 40,
              }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" vertical={false} /> */}
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="monthName"
                tickLine={false}
                axisLine={false}
                scale="point"
              />
              <YAxis tickLine={false} axisLine={false} />
              <Tooltip />
              <Legend
                align="right"
                verticalAlign="top"
                layout="horizontal"
                formatter={(value) => (
                  <span className="text-color-class">{value}</span>
                )}
                iconType="rect"
              />
              <Line
                type="monotone"
                dataKey="totalCalls"
                stroke="#F6BA00"
                // activeDot={{ r: 8 }}
              />
              {/* <Line type="monotone" dataKey="Calls Done" stroke="#F6BA00" /> */}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div>
            <NoData />
          </div>
        )}
      </div>
    </div>
  );
};

export default TotalLeadsCalled;
