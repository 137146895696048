import React, { useEffect, useState } from "react";

export default function InclusionDetails({ saveinclusion }) {
  const [ExtraInclusion, SetExtraInclusion] = useState("");
  const [ExtraInclusionAmt, SetExtraInclusionAmt] = useState("");
  return (
    <div>
      <div className="row d-flex justify-content-start align-items-center mb-3">
        <div className="col-2 col-md-7">
          <input
            type="text"
            className="input_fields_quoteg"
            placeholder="Extra Add-ons"
            onChange={(e) => {
              SetExtraInclusion(e.target.value);
            }}
          />
        </div>
        <div className="col-2 col-md-3">
          <input
            type="text"
            className="input_fields_quoteg"
            placeholder="Amount"
            onChange={(e) => {
              SetExtraInclusionAmt(e.target.value);
            }}
          />
        </div>
        <div className="col-2 col-md-1">
          <button
            className="submit_cont_btn w-100"
            onClick={() => saveinclusion(ExtraInclusion, ExtraInclusionAmt)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
