import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminNav from "../AdminNavBar/AdminNav";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import Footer from "../Dashboard/Footer";
import $ from "jquery";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  validateName,
  validateMobileNumber,
  ConstructionDate,
  LastActivityDate,
} from "../Helpers/validation";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function UserDetails() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [show, toggleShow] = useState(false);
  const [showedit, toggleShowedit] = useState(false);
  const [showeditcmt, toggleShoweditcmt] = useState(false);
  //   const [SelectedPhoto, setSelectedPhoto] = useState([]);

  // state to set all personal info values of partners
  const [usersname, Setusersname] = useState("");
  const [userempid, Setuserempid] = useState("");
  const [userjoiningdate, Setuserjoiningdate] = useState("");
  const [usermobile, Setusermobile] = useState("");
  const [usercode, Setusercode] = useState("");

  // console.log("usercode", usercode);

  const [usersid, Setusersid] = useState("");
  const [userpassword, Setuserpassword] = useState("");
  const [userrole, Setuserrole] = useState("");
  const [userstatus, Setuserstatus] = useState("");
  const [userOptions, SetuserOptions] = useState([]);

  const [btnloader, setbtnloader] = useState(false);
  const [uploadPercentage, SetuploadPercentage] = useState(0);

  console.log("uploadPercentage", uploadPercentage);

  const { Id } = useParams();
  const { Page } = useParams();
  // console.log("id", Id);
  // var DownloadButton = require('downloadbutton/es5');
  let history = useHistory();

  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }

    getUsers();
  }, []);

  // console.log("rolw", userrole);

  const getUsers = () => {
    axios
      .get(`${SERVER_URL}/getuserbyid?_id=${Id}`)
      .then((userdetailsvalue) => {
        let status = userdetailsvalue.data.status;
        if (status === "success") {
          Setusersname(userdetailsvalue.data.User[0].name);
          Setuserempid(userdetailsvalue.data.User[0].emp_ID);
          Setuserjoiningdate(userdetailsvalue.data.User[0].doj);
          Setuserrole(userdetailsvalue.data.User[0].role);
          Setusersid(userdetailsvalue.data.User[0].userId);
          Setuserpassword(userdetailsvalue.data.User[0].password);
          Setusermobile(userdetailsvalue.data.User[0].mobile);
          Setusercode(userdetailsvalue.data.User[0].user_code);
          Setuserstatus(userdetailsvalue.data.User[0].user_status);
          // console.log("User details of each user", userdetailsvalue.data.User);

          axios
            .get(
              `${SERVER_URL}/inactiveusers?role=${userdetailsvalue.data.User[0].role}`
            )
            .then((res) => {
              // console.log("inactive", res);
              SetuserOptions(res.data.users);
            });
        } else {
          toast.error("Server Error try after some Time", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // console.log("res", userdetailsvalue);
      });
  };

  function validateUser(value) {
    let error;
    if (!value) {
      error = "Please Select User";
    }
    // console.log("err", error);
    return error;
  }

  // To update the details
  const updateuserdetails = (e) => {
    e.preventDefault();
    const updatedres = {
      name: usersname,
      emp_ID: userempid,
      doj: userjoiningdate,
      mobile: usermobile,
      userId: usersid,
      password: userpassword,
      role: userrole,
      usercode: usercode,
    };

    // console.log("data", updatedres);
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/updateuser?_id=${Id}`, updatedres, {
        headers,
      })
      .then((resultfse) => {
        // console.log("Hello Don", resultfse);
        let Status = resultfse.data.status;
        if (Status === "success") {
          // alert("Profile Details are edited sucessfully");
          toast.success("Profile Details are edited successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (Status === "failed") {
          // alert("Profile Details are already exists");
          toast.error("Profile Details are Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  const deleteuserdetails = () => {
    axios
      .delete(`${SERVER_URL}/deleteuser?_id=${Id}`)
      .then((resultuserdeleted) => {
        let Status = resultuserdeleted.data.status;
        if (Status === "success") {
          window.$("#deletePartner").modal("hide");
          window.$("#PartnerAddedSuccessfully").modal("show");
          window.setTimeout(function () {
            // $(".modal-backdrop").remove();
            // history.push("/rplist");
            $(".modal-backdrop").remove();
            history.push(`/manageuser/${Page}`);
          }, 3000);
        } else {
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const deactivateuserPopUp = () => {
    window.$("#deactivateuser").modal("show");
  };

  const removeUser = () => {
    window.$("#deletePartner").modal("show");
  };

  const importData = () => {
    window.$("#importDataPopUp").modal("show");
  };

  const deactivateUsers = () => {
    axios.get(`${SERVER_URL}/deactivateuser?_id=${Id}`).then((res) => {
      console.log("resdeactivate", res);
      let Status = res.data.status;
      if (Status === "success") {
        window.$("#deactivateuser").modal("hide");
        getUsers();
        toast.success("User Deactivated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        window.$("#deactivateuser").modal("hide");
        toast.error("Internal Error try after some time", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <div>
      <div>
        <AdminNav />
      </div>

      <div className="main">
        <div className="content-tabs">
          <div>
            <div className="top_menu_bar">
              <div>
                {/* <Link to="/manageuser" className="partner_back_btn">
                    <span>
                      <ArrowBackIosNewIcon />
                      Back
                    </span>
                  </Link> */}

                <Link to={`/manageuser/${Page}`} className="partner_back_btn">
                  <div>
                    <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                    <span>Back</span>
                  </div>
                </Link>
              </div>

              <div>
                <div className="d-flex justify-content-end">
                  {/* <button
                    className="delete_btn"
                    data-toggle="modal"
                    data-target=".partner_details_delete"
                  >
                    <DeleteIcon className="del_icons" />
                    Delete
                  </button> */}
                  <div className="dropdown action">
                    <button
                      className="btn dropdown-toggle dropdown_action"
                      type="button"
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    {userstatus === "" ? (
                      ""
                    ) : userstatus === "active" ? (
                      <div
                        className="dropdown-menu w-100 dropdown-menu-right"
                        aria-labelledby="dropdownMenu2"
                      >
                        <button
                          className="dropdown-item"
                          // type="button"
                          // data-toggle="modal"
                          // data-target=".deactivateuser"
                          onClick={deactivateuserPopUp}
                        >
                          Deactivate User
                        </button>
                        <div className="dropdown-divider"></div>
                        <button
                          className="dropdown-item"
                          // type="button"
                          // data-target=".partner_details_delete"
                          onClick={removeUser}
                        >
                          Remove User
                        </button>
                        <div className="dropdown-divider"></div>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={importData}
                        >
                          Import Data
                        </button>
                      </div>
                    ) : (
                      <div
                        className="dropdown-menu w-100 dropdown-menu-right"
                        aria-labelledby="dropdownMenu2"
                      >
                        <button
                          className="dropdown-item"
                          // type="button"
                          // data-toggle="modal"
                          // data-target=".deactivateuser"
                          onClick={deactivateuserPopUp}
                        >
                          Activate User
                        </button>
                        <div className="dropdown-divider"></div>
                        <button
                          className="dropdown-item"
                          // type="button"
                          // data-target=".partner_details_delete"
                          onClick={removeUser}
                        >
                          Remove User
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="partner_details_edit_sec">
              <form>
                <div className="row">
                  <div className="col-md-8">
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <div className="d-flex" style={{ gap: "10px" }}>
                          <p>Personal Info</p>
                          {userstatus === "" ? (
                            ""
                          ) : userstatus === "active" ? (
                            <div>
                              <span className="call_status">Active</span>
                            </div>
                          ) : (
                            <div>
                              <span className="in_active">Inactive</span>
                            </div>
                          )}
                        </div>

                        <div
                          className="edit_icon"
                          onClick={() => toggleShow(!show)}
                        >
                          {!show && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span> Edit</span>
                            </div>
                          )}
                          {show && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={updateuserdetails}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      {!show && (
                        <div className="viewing_details">
                          <div className="row">
                            <div className="col-6 col-md-2">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Full Name
                                </p>
                                <span className="admin_type_value">
                                  {usersname}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-2">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Employee ID
                                </p>
                                <span className="admin_type_value">
                                  {userempid}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Joining Date
                                </p>
                                <span className="admin_type_value">
                                  {userjoiningdate === ""
                                    ? "-"
                                    : userjoiningdate
                                        .slice(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("-")}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-2">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  User Type
                                </p>
                                <span className="admin_type_value">
                                  {userrole}
                                </span>
                              </div>
                            </div>

                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Mobile Number
                                </p>
                                <span className="admin_type_value">
                                  {usermobile}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {show && (
                        <div className="edit_details">
                          <div className="row">
                            <div className="col-6 col-md-2">
                              <div className="input-group-custom">
                                <label htmlFor="inputName" className="label">
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputName"
                                  name="name"
                                  value={usersname}
                                  onChange={(e) => {
                                    Setusersname(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-2">
                              <div className="input-group-custom">
                                <label htmlFor="inputEMPID" className="label">
                                  Employee ID
                                </label>
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputEMPID"
                                  name="emp_ID"
                                  value={userempid}
                                  onChange={(e) => {
                                    Setuserempid(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div className="input-group-custom">
                                <label htmlFor="inputdoj" className="label">
                                  Joining Date
                                </label>
                                <input
                                  type="date"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputdoj"
                                  name="doj"
                                  value={userjoiningdate}
                                  onChange={(e) => {
                                    Setuserjoiningdate(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-2">
                              <div className="input-group-custom">
                                <label htmlFor="role" className="label">
                                  User Type
                                </label>
                                <select
                                  className="p-1 input-area admin_partner_det_input"
                                  name="role"
                                  // label="Select"
                                  value={userrole}
                                  defaultValue={"select"}
                                  // validate={validateType}
                                  // autoComplete="off"
                                  onChange={(e) => {
                                    Setuserrole(e.target.value);
                                  }}
                                >
                                  <option selected value="">
                                    Select
                                  </option>
                                  <option value="Admin">Admin</option>
                                  <option value="BDE">BDE</option>
                                  <option value="FSE">FSE</option>
                                  <option value="IS">IS</option>
                                  <option value="CS">CS</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-6 col-md-3">
                              <div className="input-group-custom">
                                <label htmlFor="inputMobile" className="label">
                                  Mobile Number
                                </label>
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="inputMobile"
                                  name="mobile"
                                  value={usermobile}
                                  onChange={(e) => {
                                    Setusermobile(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    {/* <Formik
                      enableReinitialize={true}
                      initialValues={{
                        userId: usersid,
                        password: userpassword,
                      }}
                      onSubmit={(values) => {
                        alert("hello");
                        console.log("val", values);
                      }}
                    >
                      {({ errors, touched, handleSubmit, values }) => (
                        <Form> */}
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Credentails</p>
                        <div
                          className="edit_icon"
                          onClick={() => toggleShowedit(!showedit)}
                        >
                          {!showedit && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span> Edit</span>
                            </div>
                          )}
                          {showedit && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={updateuserdetails}
                                // onClick={handleSubmit}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      {!showedit && (
                        <div className="contact_info_">
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  User ID
                                </p>
                                <span className="admin_type_value">
                                  {usersid}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Password
                                </p>
                                <span className="admin_type_value">
                                  {userpassword != "" ? "****" : "NA"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {showedit && (
                        <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="input-group-custom">
                                <label htmlFor="inputName" className="label">
                                  User ID
                                </label>
                                {/* <Field */}
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  // required
                                  id="inputName"
                                  name="userId"
                                  value={usersid}
                                  onChange={(e) => {
                                    Setusersid(e.target.value);
                                  }}
                                  // validate={validateName}
                                  // value={values.userId}
                                  autoComplete="off"
                                />
                                {/* {errors.userId && touched.userId && (
                                  <p className="error">{errors.userId}</p>
                                )} */}
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="input-group-custom">
                                <label htmlFor="comp" className="label">
                                  Password
                                </label>
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="comp"
                                  name="password"
                                  value={userpassword}
                                  onChange={(e) => {
                                    Setuserpassword(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* </Form>
                      )}
                    </Formik> */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 mt-3">
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>User Code</p>
                        <div
                          className="edit_icon"
                          onClick={() => toggleShoweditcmt(!showeditcmt)}
                        >
                          {!showeditcmt && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span> Edit</span>
                            </div>
                          )}
                          {showeditcmt && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={updateuserdetails}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!showeditcmt && (
                        <div className="contact_info_">
                          <div>
                            <span
                              className="admin_type_value"
                              style={{
                                fontSize: "13px",
                                lineHeight: "0.1rem",
                              }}
                            >
                              {usercode}
                            </span>
                          </div>
                        </div>
                      )}
                      {showeditcmt && (
                        <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                          <div className="input-group-custom">
                            <input
                              type="text"
                              className="input-area"
                              id="usercode"
                              name="usercode"
                              value={usercode}
                              onChange={(e) => {
                                Setusercode(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>

      {/* Delete Confirmation */}
      <div
        id="deletePartner"
        className="modal fade partner_details_delete"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                </div>
                <div className="pt-2 popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Delete this User ?
                  </span>
                </div>
                <div
                  className="pt-3 m-0 d-flex justify-content-center"
                  style={{ gap: "10px" }}
                >
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <button
                      type="button"
                      className="popup_btn_unlinkaccount"
                      style={{ width: "100%" }}
                      onClick={deleteuserdetails}
                      // data-toggle="modal"
                      // data-target=".partner-details-deleted-success"
                      // data-dismiss="modal"
                    >
                      Delete
                    </button>
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <button
                      // type="button"
                      className="popup_btn_close"
                      data-dismiss="modal"
                      style={{ width: "100%" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Deactivate User */}
      <div
        id="deactivateuser"
        className="modal fade partner_details_delete"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                </div>
                <div className="pt-2 popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Deactivate this User ?
                  </span>
                </div>
                <div
                  className="pt-3 m-0 d-flex justify-content-center"
                  style={{ gap: "10px" }}
                >
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <button
                      type="button"
                      className="popup_btn_unlinkaccount"
                      style={{ width: "100%" }}
                      // data-toggle="modal"
                      // data-target=".partner-details-deleted-success"
                      // data-dismiss="modal"
                      onClick={deactivateUsers}
                    >
                      Deactivate
                    </button>
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <button
                      style={{ width: "100%" }}
                      // type="button"
                      className="popup_btn_close"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Import Data */}
      <div
        id="importDataPopUp"
        className="modal fade Add_Ref_popup"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body" style={{ background: "#FFFFFF" }}>
              <div className="pb-3">
                <span className="add_hot_lead_label">Import Data</span>
                <span
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="CloseAddHotLeadPopup"
                  style={{
                    background: "transparent",
                    float: "right",
                    // color: "red",
                  }}
                >
                  <CloseRoundedIcon />
                </span>
              </div>

              <Formik
                initialValues={{
                  username: "",
                }}
                // validationSchema={validate}
                onSubmit={(values, { resetForm }) => {
                  // console.log("val", values);
                  setbtnloader(true);

                  const data = {
                    username: values.username,
                    user_code: usercode,
                  };

                  console.log("data", data);

                  // const config = {
                  //   onUploadProgress: (progressEvent) => {
                  //     console.log("progressEvent", progressEvent);
                  //     const { loaded, total } = progressEvent;
                  //     let percent = Math.floor((loaded * 100) / total);
                  //     console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                  //     console.log("pwer", percent);
                  //     if (percent < 100) {
                  //       SetuploadPercentage(percent);
                  //     }
                  //   },
                  // };

                  // setInterval(() => {
                  // let width = uploadPercentage;
                  // if (width <= 100) {
                  //   console.log(width);
                  //   width++;
                  //   console.log("sss", width);
                  //   SetuploadPercentage(width);
                  // }
                  let width = 100;
                  for (let i = 0; i <= width; i++) {
                    console.log("i", i);
                    SetuploadPercentage(i);
                  }
                  // }, 1000);

                  const headers = {
                    "Content-Type": "application/json",
                  };

                  axios
                    .post(`${SERVER_URL}/transferdata`, data, {
                      headers,
                    })
                    .then((res) => {
                      // console.log(res);
                      let Status = res.data.status;
                      if (Status === "success") {
                        SetuploadPercentage(100);
                        window.$("#importDataPopUp").modal("hide");
                        setbtnloader(false);
                        toast.success("All data transfered successfully", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                        setTimeout(() => {
                          SetuploadPercentage(0);
                        }, 1000);
                      } else if (Status === "failed") {
                        setbtnloader(false);
                        toast.error("Internal Error try after some time", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    });
                }}
              >
                {(errors, touched, handleSubmit) => (
                  <div>
                    <Form>
                      <div>
                        <label className="filter_input_head_txt pb-3">
                          Select User
                        </label>
                        <div className="input-group_add_hot_lead ">
                          <Field
                            as="select"
                            className="field_input_txt"
                            name="username"
                            validate={validateUser}
                          >
                            <option disabled selected value="">
                              Select
                            </option>

                            {userOptions != ""
                              ? userOptions.map((item, i) => {
                                  return (
                                    <option key={i} value={item.Name}>
                                      {item.Name}
                                    </option>
                                  );
                                })
                              : "No Data"}
                          </Field>
                          {errors.username && touched.username && (
                            <div className="pt-2 error">{errors.username}</div>
                          )}
                        </div>
                      </div>

                      <div className="pt-4">
                        {btnloader === true ? (
                          <div className="progress">
                            <div
                              className="progress-bar bg-warning progress-bar-striped"
                              role="progressbar"
                              aria-valuenow={uploadPercentage}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: uploadPercentage + "%" }}
                            >
                              {uploadPercentage}%
                            </div>
                          </div>
                        ) : (
                          <button
                            className=" btn_apply_filter"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Import Data
                          </button>
                        )}

                        {/* <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            // style={{width: "50%"}}
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div> */}
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      {/* Sucuesfully deleted */}
      <div
        id="PartnerAddedSuccessfully"
        className="modal fade bd-lead-added-successfully"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                </div>
                <div className="popup_text_center">
                  <p className="pt-2 popup_addbankdetails_text">
                    User Deleted Successfully
                  </p>
                  <div className="pt-2 m-0 d-flex justify-content-center">
                    <div
                      style={{
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <button
                        // type="button"
                        className="popup_btn_linkaccount"
                        data-dismiss="modal"
                        onClick={() => {
                          history.push(`/manageuser/${Page}`);
                        }}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
