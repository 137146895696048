import React, { useState } from "react";
import AdminNav from "../AdminNavBar/AdminNav";
import Footer from "../Dashboard/Footer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const finalSpaceCharacters = [
  {
    id: "gary",
    name: "Gary Goodspeed",
    thumb: "/images/gary.png",
  },
  {
    id: "cato",
    name: "Little Cato",
    thumb: "/images/cato.png",
  },
  {
    id: "kvn",
    name: "KVN",
    thumb: "/images/kvn.png",
  },
  {
    id: "mooncake",
    name: "Mooncake",
    thumb: "/images/mooncake.png",
  },
  {
    id: "quinn",
    name: "Quinn Ergon",
    thumb: "/images/quinn.png",
  },
];

const AdSalesPipe = () => {
  const [characters, updateCharacters] = useState(finalSpaceCharacters);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
  }

  return (
    <div>
      <div>
        <AdminNav />
      </div>
      {/* <div style={{ overflow: "hidden" }}> */}
      <div style={{ overflow: "hidden", height: "100vh" }}>
        <div className="main">
          <div className="content-tabs">
            <div>
              <div className="top_menu_bar">
                <div>
                  <span className="dashboard_head_txt">Sales Pipline</span>
                </div>
              </div>

              {/* <div className="panel">
                <div className="fun_wrap">
                  <div className="fun"></div>
                </div>
              </div> */}
              <div>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <ul
                        className="characters"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {characters.map(({ id, name, thumb }, index) => {
                          return (
                            <Draggable key={id} draggableId={id} index={index}>
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="characters-thumb">
                                    <img src={thumb} alt={`${name} Thumb`} />
                                  </div>
                                  <p>{name}</p>
                                </li>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom" style={{ marginTop: "10px" }}>
          <Footer />
        </div>
      </div>

      {/* </div> */}
    </div>
  );
};

export default AdSalesPipe;
