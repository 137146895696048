import AdminNav from "../AdminNavBar/AdminNav";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import { Link } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AddFsePopup from "../PopUp/AddFsePopup";
import Fserow from "./Fserow";

export default function Fselist() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [fsedata, Setfsedata] = useState([]);
  const [searchterm, Setsearchterm] = useState("");
  let history = useHistory();
  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }
    getCustomer();
  }, []);

  const getCustomer = () => {
    axios
      .get(`${SERVER_URL}/getfse`)
      .then((repe) => {
        console.log("dsdssdsdsdds", repe);
        let customer = repe.data.FSE;
        console.log("ITS EMP ID", customer);
        Setfsedata(
          customer.map((d) => {
            return {
              select: false,
              id: d._id,
              name: d.name,
              mobile: d.mobile,
              empid: d.employee_id,
              doj: d.doj,
            };
          })
        );
      })
      .catch((err) => alert(err));
  };

  const deletemultiplerecords = async () => {
    let arrayids = [];
    await fsedata.forEach((d) => {
      if (d.select) {
        arrayids.push(d.id);
      }
    });
    console.log("for del", arrayids);
    axios
      // .post(`https://pure-wave-48602.herokuapp.com/deletemore`, {
      .post(`${SERVER_URL}/deletemore`, {
        arrayids,
      })
      .then((resultdeleted) => {
        console.log("FSE deleted", resultdeleted);
        getCustomer();
      })
      .catch((err) => alert(err));
  };
  return (
    <div>
      <div>
        <AdminNav />
      </div>
      <div className="main">
        <div className="content-tabs">
          <div>
            <div className="top_menu_bar">
              <div>
                <h4>List Of Field Sales Executive</h4>
              </div>
              <div>
                <div className="d-flex justify-content-around">
                  <div className="search_div">
                    <SearchIcon />
                    <input
                      type="text"
                      className="top_menu_search"
                      placeholder="Search Here.."
                      onChange={(event) => {
                        Setsearchterm(event.target.value);
                      }}
                    />
                  </div>
                  <button
                    className="add_btn"
                    data-toggle="modal"
                    data-target=".fse_details_delete"

                    // data-target=".bd-example-modal-fse"
                  >
                    <DeleteIcon className="del_icons" />
                    Delete
                  </button>
                  <div
                    className="modal fade fse_details_delete"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog  modal-dialog-centered ">
                      <div
                        className="modal-content modal_content_whole"
                        style={{ background: "white" }}
                      >
                        <div className="modal-body">
                          <div>
                            <div className="popup_icon_center">
                              <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                            </div>
                            <div className="popup_text_center">
                              <span className="popup_addbankdetails_text">
                                Are you sure want to Delete this FSE ?
                              </span>
                            </div>
                            <div className="pt-3 d-flex">
                              <div
                                style={{ width: "50%", textAlign: "center" }}
                              >
                                <button
                                  type="button"
                                  className="popup_btn_unlinkaccount"
                                  // onClick={deletefsedetails}
                                  data-toggle="modal"
                                  data-target=".partner-details-deleted-success"
                                  data-dismiss="modal"
                                  onClick={() => {
                                    deletemultiplerecords();
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                              <div
                                style={{ width: "50%", textAlign: "center" }}
                              >
                                <button
                                  // type="button"
                                  className="popup_btn_close"
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-dialog  modal-dialog-centered ">
                      <div
                        className="modal-content modal_content_whole"
                        style={{ background: "white" }}
                      >
                        <div className="modal-body">
                          <div>
                            <div className="popup_icon_center">
                              <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                            </div>
                            <div className="popup_text_center">
                              <span className="popup_addbankdetails_text">
                                Please contact Field Sales Executive to valid
                                FSE Code
                              </span>
                            </div>
                            <div className="pt-3 d-flex justify-content-center">
                              {/* <div
                                  style={{ width: "50%", textAlign: "center" }}
                                >
                                  <button
                                    type="button"
                                    className="popup_btn_unlinkaccount"
                                    onClick={deletefsedetails}
                                    data-toggle="modal"
                                    data-target=".partner-details-deleted-success"
                                    data-dismiss="modal"
                                  >
                                    Delete
                                  </button>
                                </div> */}
                              <div
                                style={{ width: "50%", textAlign: "center" }}
                              >
                                <button
                                  // type="button"
                                  className="popup_btn_close"
                                  data-dismiss="modal"
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade partner-details-deleted-success"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    id="myModal"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div
                        className="modal-content modal_content_whole"
                        style={{ background: "white" }}
                      >
                        <div className="modal-body">
                          <div>
                            <div className="popup_icon_center">
                              <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                            </div>
                            <div className="popup_text_center">
                              <span className="popup_addbankdetails_text">
                                FSE Deleted Successfully
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <AddFsePopup />
                  </div>
                  <div
                    className="modal fade exampleModal"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog  modal-dialog-centered ">
                      <div
                        className="modal-content modal_content_whole"
                        style={{ background: "white" }}
                      >
                        <div className="modal-body">
                          <div>
                            <div className="popup_icon_center">
                              <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                            </div>
                            <div className="popup_text_center">
                              <span className="popup_addbankdetails_text">
                                Are you sure want to Delete this Partners ?
                              </span>
                            </div>
                            <div className="pt-3 d-flex">
                              <div
                                style={{ width: "50%", textAlign: "center" }}
                              >
                                <button
                                  type="button"
                                  className="popup_btn_unlinkaccount"
                                  // onClick={deletebankdetails}
                                  data-toggle="modal"
                                  data-target=".popup-bank-details-deleted"
                                  data-dismiss="modal"
                                >
                                  Delete
                                </button>
                              </div>
                              <div
                                style={{ width: "50%", textAlign: "center" }}
                              >
                                <button
                                  // type="button"
                                  className="popup_btn_close"
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Sucuesfully deleted */}
                  <div
                    className="modal fade popup-bank-details-deleted"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    id="myModal"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div
                        className="modal-content modal_content_whole"
                        style={{ background: "white" }}
                      >
                        <div className="modal-body">
                          <div>
                            <div className="popup_icon_center">
                              <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                            </div>
                            <div className="popup_text_center">
                              <span className="popup_addbankdetails_text">
                                Lead Deleted Successfully
                              </span>
                              <div className="pt-3 d-flex justify-content-center">
                                <div
                                  style={{
                                    width: "50%",
                                    textAlign: "center",
                                  }}
                                >
                                  <button
                                    // type="button"
                                    className="popup_btn_linkaccount"
                                    data-dismiss="modal"
                                  >
                                    OK
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="table_div">
                <table className="table leaderboard_table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            let value = e.target.checked;
                            Setfsedata(
                              fsedata.map((d) => {
                                d.select = value;
                                return d;
                              })
                            );
                          }}
                          className="mr-2"
                        />
                      </th>
                      <th>Partner Name</th>
                      <th>Mobile No</th>
                      <th>Employee ID</th>
                      <th>Date of Joining</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  {fsedata != "" ? (
                    // fsedata.map((custo, index) => {
                    //   return (
                    //     <tr key={custo._id}>
                    //       <td>
                    //         <input
                    //           type="checkbox"
                    //           checked={d.select}
                    //           onChange={(e) => {
                    //             let value = e.target.checked;
                    //             Setfsedata(
                    //               fsedata.map((sd) => {
                    //                 if (sd.id === d.id) {
                    //                   sd.select = value;
                    //                 }
                    //                 return sd;
                    //               })
                    //             );
                    //           }}
                    //         />
                    //       </td>
                    //       <td>{custo.name}</td>
                    //       <td>{custo.mobile}</td>
                    //       <td>{custo.employee_id}</td>
                    //       <td>{custo.doj}</td>
                    //       <td>
                    //         <Link
                    //           to={`/fsedetails/${custo._id}`}
                    //           className="admin_panel_viewmore_list"
                    //         >
                    //           View More
                    //         </Link>
                    //       </td>
                    //     </tr>
                    //   );
                    // })ff
                    // dff
                    <tbody>
                      <Fserow
                        fsedata={fsedata}
                        Setfsedata={Setfsedata}
                        searchterm={searchterm}
                      />
                    </tbody>
                  ) : (
                    <div className="d-flex">
                      <div>
                        <WarningRoundedIcon className="no_data_alert" />
                      </div>
                      <div>
                        <h3>No records found for Field Sales Executive </h3>
                      </div>
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
