import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import axios from "axios";
import NoData from "../../Dashboard/NoData";

import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import { startOfDay, endOfDay, subMonths, subYears } from "date-fns";

const PartnerCallStatus = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [totalcalls, settotalcalls] = useState("");
  const [notconnectedcalls, setnotconnectedcalls] = useState("");
  const [followup, setfollowup] = useState("");
  const [Interested, setInterested] = useState("");
  const [meetingfixed, setmeetingfixed] = useState("");
  const [dummy, setdummy] = useState("");
  const [potential, setpotential] = useState("");
  const [outofscope, setoutofscope] = useState("");
  const [notInterested, setnotInterested] = useState("");

  const [totalcallspercent, settotalcallspercent] = useState("");
  const [notconnectedcallspercent, setnotconnectedcallspercent] = useState("");
  const [followuppercent, setfollowuppercent] = useState("");
  const [Interestedpercent, setInterestedpercent] = useState("");
  const [meetingfixedpercent, setmeetingfixedpercent] = useState("");
  const [dummypercent, setdummypercent] = useState("");
  const [potentialpercent, setpotentialpercent] = useState("");
  const [outofscopepercent, setoutofscopepercent] = useState("");
  const [notInterestedpercent, setnotInterestedpercent] = useState("");

  const LGSName = localStorage.getItem("CSName");

  //   Date
  var date = new Date();
  let dateValue = [
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ];

  // const [startDate, setStartDate] = useState(dateValue[0].toLocaleDateString());
  // const [endDate, setEndDate] = useState(dateValue[1].toLocaleDateString());

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    getPackageData();
  }, [startDate, endDate]);

  const getPackageData = () => {
    axios
      .get(
        `${SERVER_URL}/partnercallstatus?lgs_name=${LGSName}&from_date=${startDate}&to_date=${endDate}`
      )
      .then((res) => {
        // console.log("respartner", res);
        settotalcalls(res.data.numberdata.totalcalls);
        setnotconnectedcalls(res.data.numberdata.Not_connected);
        setfollowup(res.data.numberdata.Followup);
        setInterested(res.data.numberdata.interested);
        setmeetingfixed(res.data.numberdata.Meeting_fixed);
        setdummy(res.data.numberdata.dummy);
        setnotInterested(res.data.numberdata.not_interested);
        setpotential(res.data.numberdata.potential);
        setoutofscope(res.data.numberdata.outofscope);

        settotalcallspercent(res.data.percentdata.totalcalls);
        setnotconnectedcallspercent(res.data.percentdata.Not_connected);
        setfollowuppercent(res.data.percentdata.Followup);
        setInterestedpercent(res.data.percentdata.interested);
        setmeetingfixedpercent(res.data.percentdata.Meeting_fixed);
        setdummypercent(res.data.percentdata.dummy);
        setpotentialpercent(res.data.percentdata.potential);
        setoutofscopepercent(res.data.percentdata.outofscope);
        setnotInterestedpercent(res.data.percentdata.not_interested);
      });
    // .catch((err) => alert(err));
  };

  // OnChange Function for Date Range Picker
  const onChange = (dates) => {
    // console.log("dates", dates);
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start < end) {
        setStartDate(start);
        setEndDate(end);
      } else {
        setStartDate(end);
        setEndDate(start);
      }
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const Ranges = [
    {
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Month",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
    },
    {
      label: "3 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
    },
    {
      label: "6 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
    },
    {
      label: "Year",
      value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
    },
  ];

  const data = [
    {
      name: "Follow Up",
      value: followup,
      percentage: followuppercent,
    },
    { name: "Interested", value: Interested, percentage: Interestedpercent },
    {
      name: "Meeting Fixed",
      value: meetingfixed,
      percentage: meetingfixedpercent,
    },
    {
      name: "Potential",
      value: potential,
      percentage: potentialpercent,
    },
    {
      name: "Not Connected",
      value: notconnectedcalls,
      percentage: notconnectedcallspercent,
    },
    {
      name: "Dummy",
      value: dummy,
      percentage: dummypercent,
    },
    {
      name: "Not Interested",
      value: notInterested,
      percentage: notInterestedpercent,
    },
    {
      name: "Out Of Scope",
      value: outofscope,
      percentage: outofscopepercent,
    },
  ];

  const COLORS = [
    "rgba(252, 194, 22, 1)",
    "rgba(22, 169, 252, 1)",
    "rgba(22, 252, 197, 1)",
    "rgba(252, 119, 22, 1)",
    "rgba(254, 62, 62, 1)",
    "rgba(147, 141, 128, 1)",
    "rgba(14, 14, 14, 1)",
    "rgba(126, 170, 170, 1)",
  ];
  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div
          className="subscribers-by-channel-tooltip"
          style={{
            background: "#fff",
            color: "#000",
            padding: "5px",
            border: "1px solid #000",
          }}
        >
          <p className="subscribers-by-channel-tooltip-title mb-0 mt-0">
            {payload[0].name}
          </p>
          <p className="subscribers-by-channel-tooltip-label mb-0 mt-0">{`Value : ${payload[0].value}`}</p>
          <p className="subscribers-by-channel-tooltip-intro mb-0 mt-0">
            {" "}
            {`Percentage : ${payload[0].payload.payload.percentage}`}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div className="p-4 d-flex justify-content-between align-items-center">
        <span className="pie_graph_head_txt">Partner Call Status</span>
        <div className="date_range-picker">
          <DateRangePicker
            // style={{ left: "780px" }}
            name="start"
            placeholder="Select Date Range"
            format="dd/MM/yyyy"
            character="-"
            placement="bottomEnd"
            onChange={onChange}
            ranges={Ranges}
            // defaultValue={dateValue}
            // cleanable={false}
          />
        </div>
      </div>

      {followup != "" ||
      Interested != "" ||
      notconnectedcalls != "" ||
      meetingfixed != "" ||
      dummy != "" ||
      potential != "" ||
      outofscope != "" ||
      notInterested != "" ? (
        <ResponsiveContainer width={"90%"} height={280}>
          <PieChart height={280}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              // label={renderCustomizedLabel}
              // outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              startAngle={90}
              endAngle={-270}
              height={280}
              width={280}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>

            {/* <Tooltip />
         
          <Legend /> */}
            {/* <Pie
            isAnimationActive={true}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label
          /> */}
            {/* <Tooltip /> */}
            <Tooltip content={<CustomTooltip />} />
            <Legend
              align="right"
              verticalAlign="middle"
              layout="vertical"
              formatter={(value) => (
                <span className="text-color-class">{value}</span>
              )}
            />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <div>
          <NoData />
        </div>
      )}
    </div>
  );
};

export default PartnerCallStatus;
