import React from "react";
import UserNav2 from "../UserNav2";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import UpdatedQuote from "./UpdatedQuote";
export default function QGen() {
  const { Id } = useParams();
  const { Page } = useParams();
  return (
    <div>
      <div>
        <UserNav2 />
      </div>
      <div>
        <div className="main">
          <div className="content-tabs">
            <div>
              <div className="top_menu_bar">
                <div>
                  <Link
                    to={`/leadetailsview/${Id}/${Page}`}
                    className="partner_back_btn"
                  >
                    <span>
                      <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                      Generate Quotation
                    </span>
                  </Link>
                </div>
              </div>
              <div className="partner_details_edit_sec">
                <div className="">
                  <div>
                    <UpdatedQuote bdeid={Id} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
