import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Specs from "./Specs";
var sizearray = [];
var calval;
let usedArea = 0;
export default function Floors({ indx, builtuparea, coun, getvalues }) {
  let NextFloorDetails = [];
  const [counter1, setCounter1] = useState(1);
  const [remainingarea, Setremainingarea] = useState(0);
  var special = [
    "Ground",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];

  const handleAreaChange = (usedSize, floor_no) => {
    Setremainingarea(remainingarea + +usedSize);
    localStorage.setItem("FLOOR" + floor_no, remainingarea + +usedSize);
  };

  const generatenewrow1 = (e) => {
    e.preventDefault();
    setCounter1(counter1 + 1);
    // console.log(counter);
  };
  function stringifyNumber(n) {
    if (n < 20) return special[n];
  }

  return (
    <div>
      <div className="site_details_main">
        <div className="site_details_heading_div">
          <span className="text-white mb-0">
            {stringifyNumber(indx)} Floor Details | Built Up Area -{builtuparea}
          </span>
        </div>
        {Array.from(Array(counter1)).map((c, index) => {
          return (
            <Specs
              floorNo={indx}
              handleAreaChange={handleAreaChange}
              indx={index}
              getvalues={getvalues}
            />
          );
        })}
        <div className="p-2">
          {/* {builtuparea === remainingarea ? (
          ""
        ) : ( */}
          <button className="add_new_btn" onClick={generatenewrow1}>
            <AddIcon className="add_icon" /> Add New
          </button>
          {/* )} */}
          <div
            className="ml-2"
            style={{
              color: "#000",
              fontFamily: "Gm",
              padding: "12px",
              paddingLeft: "5px",
            }}
          >
            Total Used : {remainingarea} SQ.FT.
          </div>
        </div>
      </div>
    </div>
  );
}
