import react, { useState, useEffect } from "react";
import "./TrendingPartners.css";
import { GrClose } from "react-icons/gr";


function TrendingPartners() {
  const axios = require("axios").default;
  const [trendingPartnenrs, setTrendingPartnenrs] = useState([]);
  const [detailView, setDetailView] = useState(false);
  const [partnerName, setPartnerName] = useState("");
  const [videourl, setVideourl] = useState("");
  const [location, setLocation] = useState("");
  const [editView, setEditView] = useState(false);
  const [files, setFiles] = useState([]);
  const [id, setId] = useState("");

  useEffect(() => {
    getAllData();
  }, []);

  async function getAllData() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/gettrendingpartners`)
      .then(function (response) {
        console.log(response.data.result);
        setTrendingPartnenrs(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleShowDets(name, vidurl, loc, id) {
    setPartnerName(name);
    setVideourl(vidurl);
    setLocation(loc);
    setId(id);
    setEditView(true);
    window.$("#addPartnerModal").modal("show");
  }

  function clearDets() {
    setPartnerName();
    setVideourl();
    setLocation();
    setEditView(false);
  }

  function handleImageUpload(e) {
    var tempFilesArr = [...files];
    // tempFilesArr.push([URL.createObjectURL(e.target.files[0])]);
    tempFilesArr.push(e.target.files[0]);
    console.log(tempFilesArr);
    setFiles(tempFilesArr);
  }

  function addTrendingPartner() {
    var partnerData = new FormData();

    files.forEach((image) => {
      partnerData.append("image", image);
    });

    partnerData.append("partner_name", partnerName);
    partnerData.append("partner_location", location);
    partnerData.append("video_link", videourl);
    // partnerData.append("image", location);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/addtrendingpartner`, partnerData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        getAllData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function deletePartner() {
    console.log(`${process.env.REACT_APP_BASE_URL}/deleteTrendPartner?_id=${id}`);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/deleteTrendPartner?_id=${id}`)
      .then((res) => getAllData());

    
  }

  return (
    <div className="contentDiv">
      <div
        className="modal fade"
        id="addPartnerModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ width: "450px" }}
        >
          <div className="modal-content popupBox">
            <div className="popupHeader">
              <h5
                className="modal-title popupHeadText"
                id="exampleModalLongTitle"
              >
                Add Partner
              </h5>
              <button className="popupClose" data-dismiss="modal">
                <GrClose />
              </button>
            </div>
            <div className="modal-body popupBody">
              <div>
                <div class="popupFormRow">
                  <div>
                    <p className="popupLabel">Partner Name</p>
                    <input
                      className="popupInput"
                      style={{ width: "190px" }}
                      defaultValue={partnerName}
                      onChange={(e) => setPartnerName(e.target.value)}
                    />
                  </div>
                  <div>
                    <p className="popupLabel">URL of Video</p>
                    <input
                      className="popupInput"
                      style={{ width: "190px" }}
                      defaultValue={videourl}
                      onChange={(e) => setVideourl(e.target.value)}
                    />
                  </div>
                </div>
                <div class="popupFormRow">
                  <div>
                    <p className="popupLabel">Location</p>
                    <input
                      className="popupInput"
                      style={{ width: "190px" }}
                      defaultValue={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                  {!editView && (
                    <div>
                      <p className="popupLabel">Upload Profile Pic</p>
                      <input
                        type={"file"}
                        className="popupInput"
                        style={{ width: "190px" }}
                        onChange={(e) => handleImageUpload(e)}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* <button
                className="popupSubmit"
                // onClick={() => addVideo()}
                data-dismiss="modal"
              >
                + Add Partner
              </button> */}

              {!editView ? (
                <button
                  className="popupSubmit"
                  onClick={() => addTrendingPartner()}
                  data-dismiss="modal"
                >
                  + Add Partner
                </button>
              ) : (
                <div style={{ marginTop: "25px" }}>
                  <button
                    className="catDelete popupDeleteSm"
                    onClick={() => {
                      deletePartner();
                    }}
                    data-dismiss="modal"
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="contHead">
        <h6 id="setHeader">Trending Partners</h6>
        <div className="btns">
          <button
            className="addBtn"
            data-toggle="modal"
            data-target="#addPartnerModal"
            onClick={() => clearDets()}
          >
            + Add Partner
          </button>
        </div>
      </div>
      <div className="viewContentDiv">
        {trendingPartnenrs.map((partner, index) => (
          <div
            className="partnerDiv"
            onClick={() =>
              handleShowDets(
                partner.partner_name,
                partner.partner_location,
                partner.partner_location,
                partner._id
              )
            }
          >
            <div className="imgNdets">
              <div
                className="partnerImg"
                style={{
                  backgroundImage: `url("${process.env.REACT_APP_IMAGES_URL}/${partner.partner_image}")`,
                }}
              />
              <div className="nameNloc">
                <p>{partner.partner_name}</p>
                <p>{partner.partner_location}</p>
              </div>
            </div>
            <p className="rank">{index + 1}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TrendingPartners;
