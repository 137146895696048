import React, { useState, useEffect } from "react";
import CS_SideNav from "../CS_SideNav";
import { Link } from "react-router-dom";
import axios from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useParams } from "react-router";
import DynamicInputFiledsImage from "../DynamicInputFieldImage";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const AddCSCPLeadImage = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const { ImageUrl } = useParams();
  const [show, toggleShow] = useState(false);

  const { Id } = useParams();
  const { Number } = useParams();
  const { Page } = useParams();
  const { Status } = useParams();
  const { ImageId } = useParams();

  let history = useHistory();

  const [counter, setCounter] = useState(1);

  const handleClick = (e) => {
    e.preventDefault();
    setCounter(counter + 1);
    // console.log(counter);
  };

  const setstat = (e) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };
    // SetVerifystat(e.target.value);
    let selectt = e.target.value;
    const statverified = {
      image_status: selectt,
      imageId: ImageId,
    };

    // console.log("123", statverified);
    axios
      .post(`${SERVER_URL}/assignbulktoIS?_id=${Id}`, statverified, {
        headers,
      })
      .then((resultsverified) => {
        let Status = resultsverified.data.status;
        if (Status === "success") {
          toast.success("Status Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  return (
    <div>
      <div>
        <div>
          <CS_SideNav />
        </div>
        <div>
          <div className="main">
            <div className="content-tabs">
              <div>
                <div className="top_menu_bar">
                  <div>
                    <Link
                      to={`/cschreferedleads/${Id}/${Number}/${Page}`}
                      className="partner_back_btn"
                    >
                      <div>
                        <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                        <span>Back</span>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <div
                      className="d-flex justify-content-around"
                      style={{ gap: "20px" }}
                    >
                      <div>
                        {Status === "Pending" ? (
                          <select
                            style={{
                              border: "none",
                              width: "150px",
                              padding: "10px",
                            }}
                            onChange={setstat}
                            className="form-select select-form"
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            <option value="Completed">Completed</option>
                            <option value="Pending">Pending</option>
                          </select>
                        ) : (
                          <div
                            style={{
                              gap: "10px",
                              height: "fit-content",
                              alignSelf: "center",
                              padding: "10px",
                            }}
                            className="dropdown verify_btn"
                          >
                            <span>
                              <CheckCircleIcon className="verify_icons" />
                            </span>
                            Completed
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-3">
                  <div className="row leads_comment_content">
                    <div className="col-12 col-md-6">
                      <img
                        src={`${process.env.REACT_APP_IMAGES_URL}/${ImageUrl}`}
                        className="doc_view"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="p-3 admin_patrner_personal_info">
                        <div>
                          <p className="head_txt head_txt_margin">Comments</p>
                        </div>

                        {!show && (
                          <div>
                            {Array.from(Array(counter)).map((c, index) => {
                              return <DynamicInputFiledsImage key={c} />;
                            })}

                            <div className="pt-4">
                              <button
                                className="btn_lead_comments"
                                onClick={handleClick}
                              >
                                <AddIcon className="search_icons_comments_lead" />
                                Add Lead
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCSCPLeadImage;
