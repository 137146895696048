import React, { useEffect, useState } from "react";
import AdminNav from "../AdminNavBar/AdminNav";
import axios from "axios";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import File from "../../Assets/filemanager.svg";
import { Link } from "react-router-dom";
import Filter from "../../Assets/Filter.svg";
import {
  validateName,
  validateMobileNumber,
  ConstructionDate,
  LastActivityDate,
} from "../Helpers/validation";
import { useHistory } from "react-router-dom";
import { CgArrowRightR } from "react-icons/cg";
import { useParams } from "react-router";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
// Pagination
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Helpers/Loader";
import Footer from "../Dashboard/Footer";
// lead pop up start
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Formik, Form, Field } from "formik";
import { TextField } from "../Fields/TextField";
import { SelectField } from "../Fields/SelectField";
// csv start
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import "./file.css";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function ReferedLeads() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [leaddata, Setleaddata] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [btnloader, setbtnloader] = useState(false);
  const [btnbulkloader, setbtnbulkloader] = useState(false);

  const { Id } = useParams();
  const { Number } = useParams();
  const { PageNo } = useParams();
  // console.log("REDD", Id);

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [bulkleaddata, Setbulkleaddata] = useState([]);
  const [bulkid, Setbulkid] = useState([]);

  const [assignOption, setassignOption] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [ConstructionDateStart, setConstructionDateStart] = useState("");
  const [ConstructionDateEnd, setConstructionDateEnd] = useState("");

  let history = useHistory();
  const { SearchBar } = Search;
  const [Page, setPage] = useState(PageNo ? PageNo : 1);
  const [PerPage, setPerPage] = useState(30);
  const [searchcount, setsearchcount] = useState([]);
  const [enabledelete, setenabledelete] = useState(false);
  // console.log("enabledelete", enabledelete);
  const [SelectedId, setSelectedId] = useState([]);
  const [ImageSelectedId, setImageSelectedId] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }

    getrps();

    getbluck();

    // To Fetch Assign To Options DropDown
    axios.get(`${SERVER_URL}/assignees`).then((response) => {
      // console.log("Cust", response);
      setassignOption(response.data.Assignees);
      // console.log("Customer", Customer);
    });
  }, []);

  const getrps = () => {
    axios
      .get(`${SERVER_URL}/listofcustomersbyid?partner_id=${Id}`)
      .then((respon) => {
        Setleaddata(respon.data.listofcustomers);
        setisloading(false);
      });
  };

  const getbluck = () => {
    axios.get(`${SERVER_URL}/getbulkdata?_id=${Id}`).then((respon) => {
      Setbulkleaddata(respon.data.Partners);
      Setbulkid(respon.data.partner_id);
      setisloading(false);
      // console.log("data", respon);
    });
  };

  const selectedRows = leaddata.filter((row) => row.isSelect);
  // console.log("selected", selectedRows);
  const LeadSelectedId = selectedRows.map((row) => row._id);
  // console.log("select", LeadSelectedId);

  const deletemultiplerp = async () => {
    let arrayids = [];
    // await leaddata.forEach((d) => {
    //   if (d.select) {
    //     arrayids.push(d._id);
    //   }
    // });
    // console.log("for del", arrayids);
    arrayids = LeadSelectedId.length === 0 ? ImageSelectedId : LeadSelectedId;

    axios
      .post(`${SERVER_URL}/deletecustomers`, {
        arrayids,
      })
      .then((resultdeleted) => {
        // console.log("FSE deleted", resultdeleted);
        let Status = resultdeleted.data.status;
        if (Status === "success") {
          window.$("#delete_confimartion").modal("hide");
          window.$("#deleted_successfully").modal("show");
          getrps();
        } else {
          // alert("Internal Error");
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getrps();
        }
      })
      .catch((err) => alert(err));
  };

  const selectedImageRows = bulkleaddata.filter((row) => row.isSelect);
  // console.log("selected", selectedImageRows);
  const LeadImageSelectedId = selectedImageRows.map((row) => row._id);
  // console.log("select", LeadImageSelectedId);

  //   deleteothersbulk;
  const deletemultiplebulk = async () => {
    let arrayids = [];
    arrayids =
      LeadImageSelectedId.length === 0 ? SelectedId : LeadImageSelectedId;
    // await bulkleaddata.forEach((d) => {
    //   if (d.select) {
    //     arrayids.push(d._id);
    //   }
    // });
    // console.log("for del", arrayids);
    axios
      .post(`${SERVER_URL}/deleteRPbulk?_id=${Id}`, {
        arrayids,
      })
      .then((resultdeleted) => {
        // console.log("FSE deleted", resultdeleted);
        let Status = resultdeleted.data.status;
        if (Status === "success") {
          window.$("#delete_confimartion_Image").modal("hide");
          window.$("#deleted_successfully").modal("show");
          getbluck();
        } else {
          // alert("Internal Error");
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getbluck();
        }
      })
      .catch((err) => alert(err));
  };

  // Lead popup start
  const [leadprofile, Setleadprofile] = useState(null);
  const [bookamt, Setbookamt] = useState("");
  const [leadpackagename, Setleadpackagename] = useState("");

  const changeprofilepic = (e) => {
    Setleadprofile(e.target.files[0]);
    console.log("custprofileee", e.target.files[0]);
  };
  function validatePhoto(value) {
    let error;
    if (leadprofile === null) {
      error = "Profile Image is Required";
    }
    return error;
  }

  const updateleaddetails = (data, resetForm) => {
    setbtnloader(true);
    // Setbookamt(data.booking_amt);
    // console.log("bok", data.booking_amt);
    const formdata = new FormData();
    formdata.append("packagename", data.packagename);
    formdata.append("name", data.name);
    formdata.append("email", data.email);
    formdata.append("mobile", data.mobile);
    formdata.append("area", data.area);
    formdata.append("booking_amt", data.booking_amt);
    formdata.append("stages", data.stages);
    // console.log("STAGE", data.stages);
    formdata.append("location", data.location);
    formdata.append("start_date", data.start_date);
    formdata.append("plot_number", data.plot_number);
    formdata.append("profile_pic", leadprofile);

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/addcustomers?_id=${Id}`, formdata, {
        headers,
      })
      .then((res) => {
        // console.log("adeerr", res);
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#bd-example-modal-lg_ref3").modal("hide");
          window.$("#PartnerAddedSuccessfully").modal("show");
          resetForm({ data: "" });
          setbtnloader(false);
          getrps();
        } else if (Status === "failed") {
          setbtnloader(false);
          // alert(" Details are already Exists");
          toast.error("Lead Details are Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  // CSV START
  const [fileNames, setFileNames] = useState([]);
  const [imageUpload, setimageUpload] = useState([]);
  const handleDrop = (acceptedFiles) => setFileNames(acceptedFiles[0]);
  const handleImage = (acceptedFiles) => setimageUpload(acceptedFiles[0]);

  const updatecsvfile = () => {
    if (fileNames.length === 0) {
      toast.error("CSV File is Requried", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      // fileNames.type != "application/vnd.ms-excel"
      fileNames.type !== "text/csv"
    ) {
      toast.error("Please Select a CSV Format File", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFileNames([""]);
    } else {
      setbtnbulkloader(true);
      const formdata = new FormData();
      formdata.append("file", fileNames);

      axios
        .post(`${SERVER_URL}/uploadcustomerdata?_id=${Id}`, formdata)
        .then((res) => {
          let Status = res.data.status;
          if (Status === "failed") {
            let Message = res.data.msg;
            if (
              Message === "Invalid name!!"
              //  ||
              // Message === "Invalid mobile number!!"
            ) {
              setbtnbulkloader(false);
              toast.error(
                "Please Upload a File with all Required Fields(Name)....",
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
            setFileNames([""]);
            window.$("#bd-example-modal-lg_ref3").modal("hide");
          } else if (Status === "success") {
            window.$("#bd-example-modal-lg_ref3").modal("hide");
            toast.success("Leads are Added sucessfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setFileNames([""]);
            getrps();
            setbtnbulkloader(false);
          }
        });
    }
  };

  const uploadImage = () => {
    if (imageUpload.length === 0) {
      toast.error("Files are Requried", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setbtnloader(true);
      const formdata = new FormData();
      formdata.append("bulkdata", imageUpload);

      axios.post(`${SERVER_URL}/bulkdataa?_id=${Id}`, formdata).then((res) => {
        let Status = res.data.status;
        if (Status === "failed") {
          toast.error("Some Internal Error try Again Later....", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setimageUpload([""]);
          window.$("#Add_BluckImage").modal("hide");
          setbtnloader(false);
        } else if (Status === "success") {
          window.$("#Add_BluckImage").modal("hide");
          toast.success("Leads are Added sucessfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setimageUpload([""]);
          getbluck();
          setbtnloader(false);
        }
      });
    }
  };

  // OnChange Function for Date Range Picker of Construction Date
  const onChange = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);

      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start > end) {
        setStartDate(end);
        setEndDate(start);
      } else {
        setStartDate(start);
        setEndDate(end);
      }
    } else {
      // console.log("empty");
      setStartDate("");
      setEndDate("");
    }
  };

  const handleJoining = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];

      // Converting it to format dd/mm/yyyy
      // setConstructionDateStart(start.toISOString().slice(0, 10));
      // setConstructionDateEnd(end.toISOString().slice(0, 10));
      if (start < end) {
        setConstructionDateStart(start);
        setConstructionDateEnd(end);
      } else {
        setConstructionDateStart(end);
        setConstructionDateEnd(start);
      }
    } else {
      // console.log("empty");
      setConstructionDateStart("");
      setConstructionDateEnd("");
    }
  };

  // For Assigning To Bluck
  const setassignes = (e, ids) => {
    e.preventDefault();

    const headers = {
      "Content-Type": "application/json",
    };
    // SetVerifystat(e.target.value);
    let selectt = e.target.value;
    const statverified = {
      ISname: selectt,
      imageId: ids,
    };

    // console.log("id", ids);

    axios
      .post(`${SERVER_URL}/assignbulktoIS?_id=${Id}`, statverified, {
        headers,
      })
      .then((resultsverified) => {
        // console.log("verified orr", resultsverified);
        let Status = resultsverified.data.status;
        if (Status === "success") {
          // alert("Partner Assigned Successfully");
          toast.success("Bulk Images Assigned Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getbluck();
        } else {
          // alert("Error");
          toast.error("Internal Error try after...", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  const AllLeadFilters = (data) => {
    setbtnloader(true);
    const sendData = {
      package: data.package,
      area_from: data.area_from,
      area_to: data.area_to,
      construct_from_date: ConstructionDateStart,
      construct_to_date: ConstructionDateEnd,
      persf_from: data.persf_from,
      persf_to: data.persf_to,
      is_name: data.is_name,
      project_from: data.project_from,
      project_to: data.project_to,
      source: data.source,
      booking_from: data.booking_from,
      booking_to: data.booking_to,
      call_status: data.call_status,
      activity_from: startDate,
      activity_to: endDate,
      location: data.location,
      development_name: data.development_name,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(
        `${SERVER_URL}/filteronleads?lead_source=RP&partner_id=${Id}`,
        sendData,
        {
          headers,
        }
      )
      .then((res) => {
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#Add_Filter_popup").modal("hide");
          setPage(1);
          Setleaddata(res.data.Leads);
          setbtnloader(false);
          // remove
          if (res.data.Leads.length !== 0) {
            var number = document.getElementsByClassName(
              "react-bootstrap-table-page-btns-ul"
            )[0];
            var firstnum = number.querySelectorAll("li, a")[1];
            firstnum.click();
          }
        } else {
          toast.error("Something Went Wrong...try Again!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          Setleaddata("");
          setbtnloader(false);
        }
      });
  };

  // New
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const leadCallStatus = (cell) => {
    switch (cell) {
      case "Not Connected":
        return (
          <div>
            <button className="call_not_connected">Not Connected</button>
          </div>
        );
      case "Follow Up":
        return (
          <div>
            <button className="call_follow_up">Follow Up</button>
          </div>
        );
      case "DNR":
        return (
          <div>
            <button className="call_dnr">DNR</button>
          </div>
        );
      case "Interested":
        return (
          <div>
            <button className="call_intersted">Interested</button>
          </div>
        );
      case "Meeting Fixed":
        return (
          <div>
            <button className="call_meeting">Meeting Fixed</button>
          </div>
        );
      case "Qualified":
        return (
          <div>
            <button className="call_qualified">Qualified</button>
          </div>
        );
      case "Not Intersted":
        return (
          <div>
            <button className="call_not_intersted">Not Intersted</button>
          </div>
        );
      case "Meeting Done":
        return (
          <div>
            <button className="call_meet_done">Meeting Done</button>
          </div>
        );
      case "Deal Lost":
        return (
          <div>
            <button className="call_deal_lost">Deal Lost</button>
          </div>
        );
      case "Booking Amt. Received":
        return (
          <div>
            <button className="call_book_amount">Booking Amt. Received</button>
          </div>
        );
      case "Quotation Sent":
        return (
          <div>
            <button className="call_quotation">Quotation Sent</button>
          </div>
        );
      case "Dummy":
        return (
          <div>
            <button className="call_dummy">Dummy</button>
          </div>
        );
      case "Out of scope":
        return (
          <div>
            <button className="call_out_of_scope">Out of scope</button>
          </div>
        );
      default:
        return "-";
    }
  };

  const selectRow = {
    mode: "checkbox",
    // onSelectAll: onSelectAll,

    // clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      Setleaddata((rows) => {
        return rows.map((r) => {
          if (r._id !== row._id) {
            return r;
          }
          return { ...r, isSelect };
        });
      });
    },

    onSelectAll: (isSelect, rows, e) => {
      let array = [];
      for (let i = 0; i < rows.length; i++) {
        array.push(rows[i]._id);
        // console.log("arra", array);
        if (array.length > 0) {
          setenabledelete(true);
        } else {
          setenabledelete(false);
        }
        setSelectedId(array);
      }
      // selecting all data
      // Setleaddata((row) => {
      //   return row.map((rows) => {
      //     return { ...rows, isSelect };
      //   });
      // });
    },
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "Lead Name",
      formatter: (cell) => (!cell ? "-" : cell),
      // sort: true,
    },
    {
      dataField: "lead_id",
      text: "Lead ID",
      // sort: true,
      formatter: (cell) => (!cell ? "-" : cell),
      // searchable: false,
    },
    {
      dataField: "handled_by",
      text: "Inside Sales",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "contact_no",
      text: "Mobile Number",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "call_status",
      text: "Status",
      // formatter: (cell) => (!cell ? "-" : cell),
      formatter: leadCallStatus,
    },
    {
      dataField: "lead_source",
      text: "Source",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "partner_name",
      text: "Referred by",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "activityDateTime",
      text: "Last Activity Date",
      formatter: (cell) => (!cell ? "-" : cell),
      sort: true,
    },
    {
      dataField: "start_date",
      text: " Construction Start",
      formatter: (cell) =>
        !cell ? "-" : cell.slice(0, 10).split("-").reverse().join("-"),
      searchable: false,
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell) => (
        <Link
          to={`/leadetails/${cell}/${Number}/${Page}`}
          // target="_blank" // remove afterwards
          className="viewmore"
        >
          <CgArrowRightR />
        </Link>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "activityDateTime",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log("row", row);
      // old
      history.push(`/leadetails/${row._id}/${Number}/${Page}`);
      //new
      // window.open(`/leadetails/${row._id}/${Number}/${Page}`, "_blank");
    },
  };

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    page: parseInt(Page),
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    // remove these two
    sizePerPage: 50,
    hideSizePerPage: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const leadAssignImage = (cell, row) => {
    return (
      <select
        className="p-2 select_dropdown_assign_to"
        style={{ width: "100%" }}
        onChange={(e) => {
          setassignes(e, row._id);
        }}
      >
        <option value="">
          {row.image_assigned ? row.image_assigned : "Select"}
        </option>

        {assignOption != ""
          ? assignOption.map((item, i) => {
              if (item.Name != row.assigned_to) {
                return (
                  <option key={i} value={item.Name}>
                    {item.Name}
                  </option>
                );
              }
            })
          : "No Data"}
      </select>
    );
  };

  const selectImageRow = {
    mode: "checkbox",
    // onSelectAll: onSelectAll,

    // clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      Setbulkleaddata((rows) => {
        return rows.map((r) => {
          if (r._id !== row._id) {
            return r;
          }
          return { ...r, isSelect };
        });
      });
    },

    onSelectAll: (isSelect, rows, e) => {
      let array = [];
      for (let i = 0; i < rows.length; i++) {
        array.push(rows[i]._id);
        // console.log("arra", array);
        if (array.length > 0) {
          setenabledelete(true);
        } else {
          setenabledelete(false);
        }
        setImageSelectedId(array);
      }
      // selecting all data
      // Setleaddata((row) => {
      //   return row.map((rows) => {
      //     return { ...rows, isSelect };
      //   });
      // });
    },
  };

  const splitImage = (cell, row) => {
    let ImageSpilt = row.bulk_image.split(".").pop();
    return <span>{ImageSpilt}</span>;
  };

  const formatOpsButtons = (cell, row, rowIndex, formatExtraData) => {
    // console.log("ID", Id);
    // console.log("row", row);
    // console.log("cell", cell);
    return (
      <Link
        to={`/addrpimagelead/${bulkid}/${row.bulk_image}/${row.image_status}/${row._id}/${Number}/${Page}`}
        className="viewmore"
        // target="_blank" // remove afterwards
      >
        <CgArrowRightR />
      </Link>
    );
  };

  const rowEventsBulkImage = {
    onClick: (e, row, rowIndex) => {
      // console.log("row", row);
      let getCurrentCellIndex = e.target.cellIndex;
      // console.log("row", getCurrentCellIndex);
      let getLastCellIndex = document.querySelector(
        "table tr:last-child td:nth-child(5)"
      ).cellIndex;
      // console.log("rows", getLastCellIndex);
      // As this event is for the hole ROW, we need to exclude last one, as we have different events there. You can exclude no matter which one, even pass it as parameter.

      if (
        getCurrentCellIndex !== getLastCellIndex &&
        getCurrentCellIndex !== undefined
      ) {
        // old
        history.push(
          `/addrpimagelead/${bulkid}/${row.bulk_image}/${row.image_status}/${row._id}/${Number}/${Page}`
        );
        //new
        // window.open(
        //   `/addrpimagelead/${bulkid}/${row.bulk_image}/${row.image_status}/${row._id}/${Number}/${Page}`,
        //   "_blank"
        // );
        // console.log(`----> ${JSON.stringify(row)} |||| ${rowIndex}`);
      }
    },
  };

  // Bulk Image
  const bulkImageColumns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "bulk_image",
      text: "Document Name",
      formatter: (cell) => (!cell ? "-" : cell),
      // sort: true,
    },
    {
      dataField: "",
      text: "Document Type",
      // sort: true,
      formatter: splitImage,
      searchable: false,
    },
    {
      dataField: "image_status",
      text: "Status",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "image_assigned",
      text: "Assigned To",
      formatter: leadAssignImage,
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: formatOpsButtons,
    },
  ];

  return (
    <div>
      {isloading === false ? (
        <div>
          <div>
            <AdminNav />
          </div>
          <div className="main">
            <div className="content-tabs">
              {toggleState === 1 ? (
                <ToolkitProvider
                  keyField="_id"
                  data={leaddata}
                  columns={columns}
                  search={{ afterSearch }}
                  defaultSortDirection={{ defaultSorted }}
                >
                  {(props) => (
                    <div>
                      <div className="top_menu_bar">
                        <div>
                          <Link
                            to={`/rpdetails/${Id}/${Number}`}
                            className="partner_back_btn"
                          >
                            <span>
                              <ArrowBackIosNewIcon
                                style={{ fontSize: "18px" }}
                              />
                              <span>Back</span>
                            </span>
                          </Link>
                        </div>

                        <div>
                          <div
                            className="d-flex justify-content-around"
                            style={{ gap: "15px" }}
                          >
                            <div
                              className="search_div"
                              style={{ width: "40%" }}
                            >
                              <SearchIcon />
                              <SearchBar
                                {...props.searchProps}
                                className="top_menu_search"
                                placeholder="Search Here..."
                              />
                              {/* <input
                                type="text"
                                className="top_menu_search"
                                placeholder="Search Here.."
                                onChange={(event) => {
                                  Setsearchterm(event.target.value);
                                }}
                              /> */}
                            </div>

                            <div>
                              <button
                                className="add_btn"
                                data-toggle="modal"
                                data-target=".Add_Filter_popup"
                              >
                                <img src={Filter} />
                                Filter
                              </button>
                            </div>

                            <div>
                              {/* Lead pop up data start */}
                              <button
                                className="add_btn"
                                data-toggle="modal"
                                data-target="#bd-example-modal-lg_ref3"
                              >
                                <AddIcon className="search_icons" />
                                Add Lead
                              </button>
                              {/* Lead pop up data end */}
                            </div>

                            <button
                              className="delete_btn"
                              data-toggle="modal"
                              data-target=".exampleModal"
                              style={{ padding: "7px" }}
                            >
                              <DeleteIcon className="del_icons" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <div className="pt-5">
                          <div className="d-flex tabs_start">
                            <div className="tab_lead_data_start">
                              <button
                                className={
                                  toggleState === 1
                                    ? "tab_lead_data active_tab_lead_data"
                                    : "tab_lead_data"
                                }
                                onClick={() => toggleTab(1)}
                              >
                                Lead Data{" "}
                                <span className="total_count_txt">
                                  (
                                  {searchcount === 0
                                    ? searchcount
                                    : searchcount > 0
                                    ? searchcount
                                    : leaddata.length}{" "}
                                  leads)
                                </span>
                              </button>
                            </div>

                            <div className="tab_lead_data_start">
                              <button
                                className={
                                  toggleState === 2
                                    ? "tab_lead_data active_tab_lead_data"
                                    : "tab_lead_data"
                                }
                                onClick={() => toggleTab(2)}
                              >
                                Lead Bulk Data{" "}
                                {/* <span className="total_count_txt">
                                  ({bulkleaddata.length} images)
                                </span> */}
                                <span className="total_count_txt">
                                  ({bulkleaddata.length} images)
                                </span>
                              </button>
                            </div>
                          </div>

                          <div className="pt-3">
                            <div className="table_div">
                              {leaddata != "" ? (
                                <BootstrapTable
                                  {...props.baseProps}
                                  selectRow={selectRow}
                                  noDataIndication={emptyDataMessage}
                                  pagination={paginationFactory(
                                    paginationoptions
                                  )}
                                  filter={filterFactory()}
                                  rowEvents={rowEvents}
                                  // classes={"scroll_table"}
                                  wrapperClasses="table-responsive"
                                />
                              ) : (
                                <div className="d-flex">
                                  <div>
                                    <WarningRoundedIcon className="no_data_alert" />
                                  </div>
                                  <div>
                                    <h3>No records found for Customers</h3>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              ) : (
                <ToolkitProvider
                  keyField="_id"
                  data={bulkleaddata}
                  columns={bulkImageColumns}
                  search={{ afterSearch }}
                >
                  {(props) => (
                    <div>
                      <div className="top_menu_bar">
                        <div>
                          <Link
                            to={`/rpdetails/${Id}/${Number}`}
                            className="partner_back_btn"
                          >
                            <span>
                              <ArrowBackIosNewIcon
                                style={{ fontSize: "18px" }}
                              />
                              <span>Back</span>
                            </span>
                          </Link>
                        </div>

                        <div>
                          <div
                            className="d-flex justify-content-around"
                            style={{ gap: "15px" }}
                          >
                            <div
                              className="search_div"
                              style={{ width: "52%" }}
                            >
                              <SearchIcon />
                              <SearchBar
                                {...props.searchProps}
                                className="top_menu_search"
                                placeholder="Search Here..."
                              />
                              {/* <input
                                type="text"
                                className="top_menu_search"
                                placeholder="Search Here.."
                                onChange={(event) => {
                                  Setsearchterm(event.target.value);
                                }}
                              /> */}
                            </div>

                            <div>
                              {/* Lead pop up data start */}
                              <button
                                className="add_btn"
                                data-toggle="modal"
                                data-target="#Add_BluckImage"
                              >
                                <AddIcon className="search_icons" />
                                Add Bulk
                              </button>
                              {/* Lead pop up data end */}
                            </div>

                            <button
                              className="delete_btn"
                              data-toggle="modal"
                              data-target=".bulkdelete"
                              style={{ padding: "7px" }}
                            >
                              <DeleteIcon className="del_icons" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <div className="pt-5">
                          <div className="d-flex tabs_start">
                            <div className="tab_lead_data_start">
                              <button
                                className={
                                  toggleState === 1
                                    ? "tab_lead_data active_tab_lead_data"
                                    : "tab_lead_data"
                                }
                                onClick={() => toggleTab(1)}
                              >
                                Lead Data{" "}
                                <span className="total_count_txt">
                                  ({leaddata.length} leads)
                                </span>
                              </button>
                            </div>

                            <div className="tab_lead_data_start">
                              <button
                                className={
                                  toggleState === 2
                                    ? "tab_lead_data active_tab_lead_data"
                                    : "tab_lead_data"
                                }
                                onClick={() => toggleTab(2)}
                              >
                                Lead Bulk Data{" "}
                                {/* <span className="total_count_txt">
                                  ({bulkleaddata.length} images)
                                </span> */}
                                <span className="total_count_txt">
                                  (
                                  {searchcount === 0
                                    ? searchcount
                                    : searchcount > 0
                                    ? searchcount
                                    : bulkleaddata.length}{" "}
                                  images)
                                </span>
                              </button>
                            </div>
                          </div>

                          <div className="pt-3">
                            <div className="table_div">
                              {bulkleaddata != "" ? (
                                <BootstrapTable
                                  {...props.baseProps}
                                  selectRow={selectImageRow}
                                  noDataIndication={emptyDataMessage}
                                  // pagination={paginationFactory(
                                  //   paginationoptions
                                  // )}
                                  filter={filterFactory()}
                                  rowEvents={rowEventsBulkImage}
                                  // classes={"scroll_table"}
                                  wrapperClasses="table-responsive"
                                />
                              ) : (
                                <div className="d-flex">
                                  <div>
                                    <WarningRoundedIcon className="no_data_alert" />
                                  </div>
                                  <div>
                                    <h3>No records found for Customers</h3>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>

          {/* Add Lead  Start */}
          <div
            id="bd-example-modal-lg_ref3"
            className="modal fade "
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg  modal-dialog-centered ">
              <div
                className="modal-content modal_content_whole"
                style={{ background: "white" }}
              >
                <div className="modal-body">
                  <div className="pb-2">
                    <span className="add_hot_lead_label">
                      {/* <LocalFireDepartmentIcon
                                      style={{
                                        color: "red",
                                      }}
                                    /> */}
                      Add Lead
                    </span>
                    <span
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="CloseAddHotLeadPopup"
                      style={{
                        background: "transparent",
                        float: "right",
                        color: "#00000",
                      }}
                    >
                      <span aria-hidden="true" style={{ fontSize: "18px" }}>
                        X
                      </span>
                    </span>
                  </div>

                  <div className="row">
                    <div className="col-md-5 col-5 d-flex align-items-center justify-content-center">
                      {/* CSV start */}
                      <Formik
                        initialValues={{
                          fileNames: "",
                        }}
                        onSubmit={() => {
                          // updatecsvfile();
                        }}
                      >
                        <div className="App">
                          <Form>
                            <Dropzone onDrop={handleDrop}>
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  {...getRootProps({
                                    className: "dropzone",
                                  })}
                                >
                                  <input
                                    name="fileNames"
                                    {...getInputProps()}
                                  />
                                  <p className="drag_txt_csv">
                                    Drag & Drop files, or click to select files
                                  </p>
                                </div>
                              )}
                            </Dropzone>
                            <div>
                              <strong>Files:</strong>
                              <ul>
                                {/* {fileNames.map((fileName) => ( */}
                                <li>{fileNames.name}</li>
                                {/* ))} */}
                              </ul>
                              <button
                                className="btn_add_hot_lead"
                                type="submit"
                                onClick={updatecsvfile}
                              >
                                {btnbulkloader === true ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  ""
                                )}
                                Upload
                              </button>
                            </div>
                          </Form>
                        </div>
                      </Formik>
                      {/* CSV end */}
                    </div>
                    <div className="col-md-1 col-1 d-flex align-items-center justify-content-center flex-column">
                      <div
                        style={{
                          height: "45%",
                          border: "1px solid #dfdfdf",
                        }}
                      ></div>
                      <div>OR</div>
                      <div
                        style={{
                          height: "45%",
                          border: "1px solid #dfdfdf",
                        }}
                      ></div>
                    </div>
                    <div className="col-6 col-md-6">
                      <Formik
                        initialValues={{
                          name: "",
                          email: "",
                          mobile: "",
                          packagename: "",
                          area: "",
                          booking_amt: "",
                          // stages: "",
                          location: "",
                          start_date: "",
                          profile_pic: "",
                          plot_number: "",
                        }}
                        onSubmit={(values, { resetForm }) => {
                          // Setbookamt(values.booking_amt);
                          updateleaddetails(values, resetForm);
                        }}
                      >
                        {(formik) => (
                          <div>
                            {/* {console.log(formik)} */}
                            <Form>
                              <div className="d-flex justify-content-between">
                                <div
                                  className="p-3"
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div>
                                    <div className="p-3 add_hot_lead_content">
                                      <div className="row ">
                                        <div className="col-md-12">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="text"
                                              name="name"
                                              label="Full Name"
                                              validate={validateName}
                                              placeholder="Enter Name"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row pb-3">
                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="email"
                                              name="email"
                                              label="Email"
                                              placeholder="Enter Email"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="tel"
                                              name="mobile"
                                              label="Phone Number"
                                              validate={validateMobileNumber}
                                              placeholder="Enter Mobile Number"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="number"
                                              name="area"
                                              label="Plot Size(Sq.ft.)"
                                              placeholder="Enter Size"
                                              min="0"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="number"
                                              name="plot_number"
                                              label="Plot Number"
                                              placeholder="Enter Number"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="input-group_add_hot_lead ">
                                            {/* <TextField
                                                            type="text"
                                                            // className="input-area_add_hot_lead"
                                                            // required
                                                            // id="inputEmail"
                                                            name="packagename"
                                                            label="Package"
                                                            validate={
                                                              validatePackage
                                                            }
                                                            // autoComplete="off"
                                                          /> */}
                                            {/* <label>Select Package</label> */}
                                            <SelectField
                                              // className="form-select input-area"
                                              name="packagename"
                                              label="Select Package"
                                              // onChange={(e) => {
                                              //   Setleadpackagename(
                                              //     e.target.value
                                              //   );
                                              // }}
                                              // validate={validateType}
                                              // autoComplete="off"
                                            >
                                              <option
                                                selected
                                                disabled
                                                value=""
                                              >
                                                Select
                                              </option>
                                              <option value="Standard">
                                                Standard
                                              </option>
                                              <option value="Cendrol Plus">
                                                Cendrol Plus
                                              </option>
                                              {/* <option value="Premium">
                                                              Premium
                                                            </option> */}
                                            </SelectField>
                                          </div>
                                        </div>
                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="number"
                                              name="booking_amt"
                                              label="Booking Amount"
                                              placeholder="Enter Amount"
                                              min="0"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row mt-3">
                                        <div className="col-12 col-sm-12">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="date"
                                              name="start_date"
                                              label="Construction start"
                                              placeholder="Construction start"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        {/* <div className="col-6 col-sm-6">
                                                        <div className="input-group_add_hot_lead ">
                                                          <TextField
                                                            type="file"
                                                            name="profile_pic"
                                                            onChange={(e) =>
                                                              changeprofilepic(
                                                                e
                                                              )
                                                            }
                                                            label="Profile pic"
                                                          />
                                                        </div>
                                                      </div> */}

                                        <div className="col-12 col-sm-12">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="text"
                                              name="location"
                                              label="Plot Location"
                                              placeholder="Enter Plot Location"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <button
                                          className="btn_add_hot_lead"
                                          type="submit"
                                        >
                                          {btnloader === true ? (
                                            <span
                                              className="spinner-border spinner-border-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                          ) : (
                                            ""
                                          )}
                                          Add Lead
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </div>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Add Lead  End */}

          {/* Add Bulk Image Start */}
          <div
            id="Add_BluckImage"
            className="modal fade "
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered ">
              <div
                className="modal-content modal_content_whole"
                style={{ background: "white" }}
              >
                <div className="modal-body">
                  <div className="pb-2">
                    <span className="upload_document_txt">Upload Document</span>
                    <span
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="CloseAddHotLeadPopup"
                      style={{
                        background: "transparent",
                        float: "right",
                        color: "#221e1f",
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </div>

                  <div>
                    <Formik
                      initialValues={{
                        bulkdata: "",
                      }}
                      onSubmit={() => {
                        // updatecsvfile();
                      }}
                    >
                      <div>
                        <Form>
                          <Dropzone onDrop={handleImage}>
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps({
                                  className: "dropzone_img",
                                })}
                              >
                                <div>
                                  <div className="mb-3">
                                    <img src={File} width="30%" />
                                  </div>
                                  <input name="bulkdata" {...getInputProps()} />
                                  <p className="drag_txt_csv">
                                    Drag & Drop Your File here <br /> or
                                  </p>
                                </div>
                                <div>
                                  <button className="browse_btn">
                                    Browse Files
                                  </button>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div>
                            <strong>Files:</strong>
                            <ul>
                              {/* {fileNames.map((fileName) => ( */}
                              <li>{imageUpload.name}</li>
                              {/* ))} */}
                            </ul>
                            <button
                              className="btn_upload_document"
                              type="submit"
                              onClick={uploadImage}
                            >
                              {btnloader === true ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                              Upload Document
                            </button>
                          </div>
                        </Form>
                      </div>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Add Bulk Image End */}

          {/* Filter Pop Up start */}
          <div>
            <div
              id="Add_Filter_popup"
              className="modal fade Add_Filter_popup"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div
                  className="modal-content modal_content_whole"
                  style={{ background: "white" }}
                >
                  <div className="modal-body" style={{ background: "#FFFFFF" }}>
                    <div className="pb-3">
                      <span className="add_hot_lead_label">Apply Filters</span>
                      <span
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="CloseAddHotLeadPopup"
                        style={{
                          background: "transparent",
                          float: "right",
                          color: "#221e1f",
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                    <Formik
                      initialValues={{
                        package: "",
                        area_from: "",
                        area_to: "",
                        persf_from: "",
                        persf_to: "",
                        is_name: "",
                        project_from: "",
                        project_to: "",
                        source: "RP",
                        booking_from: "",
                        booking_to: "",
                        call_status: "",
                        location: "",
                        development_name: "",
                      }}
                      // validationSchema={validate}
                      onSubmit={(values, { resetForm }) => {
                        AllLeadFilters(values);
                      }}
                    >
                      {(formik) => (
                        <div>
                          <Form>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Package
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="package"
                                  >
                                    <option disabled selected value="">
                                      Select Package
                                    </option>
                                    <option value="Standard">Standard</option>
                                    <option value="Cendrol Plus">
                                      Cendrol Plus
                                    </option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Area(SQ.FT)
                                  </label>
                                  <div className="d-flex">
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="0sq.ft"
                                        id="from"
                                        name="area_from"
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="align-self-center to_txt">
                                      to
                                    </div>
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="100sq.ft"
                                        id="from"
                                        name="area_to"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Construction Start Date
                                  </label>
                                  <DateRangePicker
                                    placeholder="Select Date Range"
                                    format="dd/MM/yyyy"
                                    character="-"
                                    onChange={handleJoining}
                                    ranges={ConstructionDate}
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Per SQ.FT. Amount
                                  </label>
                                  <div className="d-flex">
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 0"
                                        id="from"
                                        name="persf_from"
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="align-self-center to_txt">
                                      to
                                    </div>
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 100"
                                        id="from"
                                        name="persf_to"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Inside Sales
                                  </label>
                                  <div className="input-group_add_hot_lead ">
                                    <Field
                                      as="select"
                                      className="field_input_txt"
                                      name="is_name"
                                    >
                                      <option disabled selected value="">
                                        Select User
                                      </option>
                                      {assignOption != ""
                                        ? assignOption.map((item, i) => {
                                            return (
                                              <option key={i} value={item.Name}>
                                                {item.Name}
                                              </option>
                                            );
                                          })
                                        : ""}
                                      <option value="-">
                                        Unassigned Leads
                                      </option>
                                    </Field>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Project Value
                                  </label>
                                  <div className="d-flex">
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 0"
                                        id="from"
                                        name="project_from"
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="align-self-center to_txt">
                                      to
                                    </div>
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 100"
                                        id="from"
                                        name="project_to"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Lead Source
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    // as="select"
                                    className="field_input_txt"
                                    name="source"
                                    value={"RP"}
                                  >
                                    {/* <option disabled selected value="">
                                  Select User
                                </option>
                                <option value="RP">RP</option>
                                <option value="CP">CP</option>
                                <option value="DM">DM</option>
                                <option value="Others">Others</option> */}
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Booking Amount
                                </label>
                                <div className="d-flex">
                                  <div>
                                    <Field
                                      type="text"
                                      className="field_input_txt"
                                      placeholder="Rs 0"
                                      id="from"
                                      name="booking_from"
                                      autoComplete="off"
                                    />
                                  </div>
                                  <div className="align-self-center to_txt">
                                    to
                                  </div>
                                  <div>
                                    <Field
                                      type="text"
                                      className="field_input_txt"
                                      placeholder="Rs 100"
                                      id="from"
                                      name="booking_to"
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Call Status
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="call_status"
                                  >
                                    <option disabled selected value="">
                                      Select Call Status
                                    </option>
                                    <option value="Not Connected">
                                      Not Connected
                                    </option>
                                    <option value="Follow Up">Follow Up</option>
                                    <option value="DNR">DNR</option>
                                    <option value="Interested">
                                      Interested
                                    </option>
                                    <option value="Meeting Fixed">
                                      Meeting Fixed
                                    </option>
                                    <option value="Qualified">Qualified</option>
                                    <option value="Not Intersted">
                                      Not Intersted
                                    </option>
                                    <option value="Dummy">Dummy</option>
                                    <option value="Out of scope">
                                      Out of scope
                                    </option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Last Activity Date
                                  </label>
                                  <DateRangePicker
                                    placeholder="Select Date Range"
                                    format="dd/MM/yyyy"
                                    character="-"
                                    onChange={onChange}
                                    ranges={LastActivityDate}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Plot Location
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    type="text"
                                    className="field_input_txt"
                                    placeholder="Enter Plot Location"
                                    id="from"
                                    name="location"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Development Name
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    type="text"
                                    className="field_input_txt"
                                    placeholder="Enter Development Name"
                                    id="from"
                                    name="development_name"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row pt-4">
                              <div className="col-12 col-md-6">
                                <button
                                  type="reset"
                                  className="btn btn-lg btn_resetall"
                                >
                                  Reset All
                                </button>
                              </div>
                              <div className="col-12 col-md-6">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn_apply_filter"
                                >
                                  {btnloader === true ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    ""
                                  )}
                                  Apply Filter
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Filter Pop Up End */}

          {/* Delete For Single */}
          <div
            id="delete_confimartion"
            className="modal fade exampleModal"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm modal-dialog-centered ">
              <div
                className="modal-content modal-sm modal_content_whole"
                style={{ background: "white" }}
              >
                <div className="modal-body">
                  <div>
                    <div className="popup_icon_center">
                      <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                    </div>
                    <div className="pt-2 popup_text_center">
                      <span className="popup_addbankdetails_text">
                        Are you sure want to Delete{" "}
                        {LeadSelectedId.length !== 0
                          ? LeadSelectedId.length
                          : SelectedId.length !== 0
                          ? SelectedId.length
                          : ""}{" "}
                        Leads ?
                      </span>
                    </div>
                    <div className="pt-4 m-0 d-flex justify-content-center">
                      <div style={{ width: "50%", textAlign: "center" }}>
                        <button
                          type="button"
                          className="popup_btn_unlinkaccount"
                          // onClick={deletebankdetails}
                          // data-toggle="modal"
                          // data-target=".popup-lead-deleted"
                          // data-dismiss="modal"
                          onClick={() => {
                            deletemultiplerp();
                          }}
                        >
                          Delete
                        </button>
                      </div>
                      <div style={{ width: "50%", textAlign: "center" }}>
                        <button
                          // type="button"
                          className="popup_btn_close"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Delete Confrmation popup For BulkImages*/}
          <div
            id="delete_confimartion_Image"
            className="modal fade bulkdelete"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm modal-dialog-centered ">
              <div
                className="modal-content modal-sm modal_content_whole"
                style={{ background: "white" }}
              >
                <div className="modal-body">
                  <div>
                    <div className="popup_icon_center">
                      <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                    </div>
                    <div className="pt-2 popup_text_center">
                      <span className="popup_addbankdetails_text">
                        Are you sure want to Delete{" "}
                        {LeadImageSelectedId.length !== 0
                          ? LeadImageSelectedId.length
                          : ImageSelectedId.length !== 0
                          ? ImageSelectedId.length
                          : ""}{" "}
                        Bulk Lead Images ?
                      </span>
                    </div>
                    <div className="pt-4 m-0 d-flex justify-content-center">
                      <div style={{ width: "50%", textAlign: "center" }}>
                        <button
                          type="button"
                          className="popup_btn_unlinkaccount"
                          onClick={() => {
                            deletemultiplebulk();
                          }}
                        >
                          Delete
                        </button>
                      </div>
                      <div style={{ width: "50%", textAlign: "center" }}>
                        <button
                          // type="button"
                          className="popup_btn_close"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Sucuesfully deleted */}
          <div
            className="modal fade popup-lead-deleted"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="deleted_successfully"
          >
            <div className="modal-dialog modal-sm modal-dialog-centered">
              <div
                className="modal-content modal-sm modal_content_whole"
                style={{ background: "white" }}
              >
                <div className="modal-body">
                  <div>
                    <div className="popup_icon_center">
                      <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                    </div>
                    <div className="pt-2 popup_text_center">
                      <span className="popup_addbankdetails_text">
                        Leads Deleted Successfully
                      </span>
                      <div className="pt-4 d-flex justify-content-center">
                        <div
                          style={{
                            width: "50%",
                            textAlign: "center",
                          }}
                        >
                          <button
                            // type="button"
                            className="popup_btn_linkaccount"
                            data-dismiss="modal"
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Lead Added */}
          <div
            id="PartnerAddedSuccessfully"
            className="modal fade bd-lead-added-successfully"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm modal-dialog-centered">
              <div
                className="modal-content modal-sm modal_content_whole"
                style={{ background: "white" }}
              >
                <div className="modal-body">
                  <div>
                    <div className="popup_icon_center">
                      <CheckCircleIcon className="popup_addbankdetails_icon" />
                    </div>
                    <div className="popup_text_center">
                      <p className="pt-2 popup_addbankdetails_text">
                        Lead Added Successfully
                      </p>
                      <div className="pt-2 m-0 d-flex justify-content-center">
                        <div
                          style={{
                            width: "50%",
                            textAlign: "center",
                          }}
                        >
                          <button
                            // type="button"
                            className="popup_btn_linkaccount"
                            data-dismiss="modal"
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
}
