import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import AdminLogin from "./Components/AdminLogin/AdminLogin";
import Login from "./Components/Login/Login";
import FSEList from "./Components/Login/FSEList";
import RPList from "./Components/Login/RPList";
import FseDetails from "./Components/Fse/FseDetails";
import Fselist from "./Components/Fse/Fselist";
import ManageUser from "./Components/MangeUser/ManageUser";
import RefPartnerList from "./Components/RPList/RefPartnerList";
import RefPartnerDetails from "./Components/RPList/RefPartnerDetails";
import ReferedLeads from "./Components/RPList/ReferedLeads";
import AddRPLeadImage from "./Components/RPList/AddRPLeadImage";
import LeadDetails from "./Components/RPList/LeadDetails";
import RpReferedrp from "./Components/RPList/RpReferedrp";
import ChannelPartners from "./Components/ChannelPartners/ChannelPartners";
import ChannelPartnerDetails from "./Components/ChannelPartners/ChannelPartnerDetails";
import ChannelPartnerReferedLead from "./Components/ChannelPartners/ChannelPartnerReferedLeads";
import ChannelPartnerReferedLeadDetails from "./Components/ChannelPartners/ChannelPartnerReferedLeadDetails";
import AddCPLeadImage from "./Components/ChannelPartners/AddCPLeadImage";
import AllCustomer from "./Components/AllCustomerAdmin/AllCustomer";
import UserDashboard from "./Components/BD/UserDashboard";
import ListofCustomer from "./Components/BD/ListofCustomer";
import LeadDetailsView from "./Components/BD/LeadDetailsView";
import BdDashboard from "./Components/BD/BDDashboard/BdDashboard";
import RefPartnerDetailsView from "./Components/Fseuser/RefPartnerDetailsView";
import ListofPartners from "./Components/Fseuser/ListofPartners";
import RpReferedrpdetails from "./Components/RPList/RpReferedrpdetails";
import Dashboard from "./Components/Dashboard/Dashboard";
import UserDetails from "./Components/MangeUser/UserDetails";
import LeadDetailsAllLeads from "./Components/AllCustomerAdmin/LeadDetailsAllLeads";
import AdminOtherLeads from "./Components/AdminOther/AdminOtherLeads";
import AdminOtherLeadsDetails from "./Components/AdminOther/AdminOtherLeadDetails";
import OtherLeadImage from "./Components/AdminOther/AddOtherImageLead";

import ProfileDetailsAdmin from "./Components/Profile/ProfileDetailsAdmin";
import ProfileDetailsCS from "./Components/Profile/ProfileDetailsCS";
import ProfileDetailsIS from "./Components/Profile/ProfileDetailsIS";
import ProfileDetailsBD from "./Components/Profile/ProfileDetailsBD";

import Dashbrd from "./Components/Fseuser/Dashbrd";

import ChannelSalesListPartner from "./Components/ChannelSales/CSlistofPartners";
import ChannelSalesPartnerDetails from "./Components/ChannelSales/CSPartnerDetails";
import ChannelSalesRPReferedPartner from "./Components/ChannelSales/CSRPReferedPartner";
import ChannelSalesRPReferedPartnerDetails from "./Components/ChannelSales/CSRPReferedPartnerdetails";
import ChannelSalesRPReferedLeads from "./Components/ChannelSales/CSReferedLeads";
import ChannelSalesRPReferedLeadsDetails from "./Components/ChannelSales/CSRPReferedLeadsDetails";
import AddCSRPLeadImage from "./Components/ChannelSales/AddCSRPLeadImage";
import ChannelSalesListChannelPartner from "./Components/ChannelSales/CSChannelPartner/CSlistofChannelPartner";
import ChannelSalesChannelPartnerDetails from "./Components/ChannelSales/CSChannelPartner/CSChannelPartnerDetails";
import ChannelSalesChannelPartnerReferedLeads from "./Components/ChannelSales/CSChannelPartner/CSCPReferedLeads";
import ChannelSalesChannelPartnerReferedLeadsDetails from "./Components/ChannelSales/CSChannelPartner/CSCPReferedLeadDetails";
import AddCSCPLeadImage from "./Components/ChannelSales/CSChannelPartner/AddCSCPLeadImage";
import CSOtherLeads from "./Components/ChannelSales/CSothers/CSOtherLeads";
import CSOtherLeadDetails from "./Components/ChannelSales/CSothers/CSOtherLeadDetails";
import CSOtherImage from "./Components/ChannelSales/CSothers/CSOtherImageLeads";

import InsideSalesListPartner from "./Components/InsideSales/ISlistofPartners";
import ISDashboard from "./Components/InsideSales/ISDashboard/ISDashboard";
import InsideSalesPartnerDetails from "./Components/InsideSales/ISPartnerDetails";
import InsideSalesRPReferedPartner from "./Components/InsideSales/ISRPReferedPartner";
import InsideSalesRPReferedPartnerDetails from "./Components/InsideSales/ISRPReferedPartnerdetails";
import InsideSalesRPReferedLeads from "./Components/InsideSales/ISReferedLeads";
import InsideSalesRPReferedLeadsDetails from "./Components/InsideSales/ISRPReferedLeadsDetails";
import AddISRPLeadImage from "./Components/InsideSales/AddISRPLeadImage";
import InsideSalesListChannelPartner from "./Components/InsideSales/ISChannelPartners/ISlistofChannelPartner";
import InsideSalesChannelPartnerDetails from "./Components/InsideSales/ISChannelPartners/ISChannelPartnerDetails";
import InsideSalesChannelPartnerReferedLeads from "./Components/InsideSales/ISChannelPartners/ISCPReferedLeads";
import InsideSalesChannelPartnerReferedLeadsDetails from "./Components/InsideSales/ISChannelPartners/ISCPReferedLeadDetails";
import AddISCPLeadImage from "./Components/InsideSales/ISChannelPartners/AddISCPLeadImage";
import ISDMLeads from "./Components/InsideSales/IS_DMLeads";
import IS_DMLeadDetails from "./Components/InsideSales/IS_DMLeadDetails";
import ISAllLeads from "./Components/InsideSales/IS_AllLeads";
import IS_AllLeadsDetails from "./Components/InsideSales/IS_AllLeadsDetails";
import ISOtherLeads from "./Components/InsideSales/ISOthers/ISOtherLeads";
import ISOtherLeadDetails from "./Components/InsideSales/ISOthers/ISOtherLeadDetails";
import ISOtherImage from "./Components/InsideSales/ISOthers/ISOtherImageLeads";

import UserReports from "./Components/Reports/UserReports";
import PartnerReports from "./Components/Reports/PartnerReports";
import PaymentReports from "./Components/Reports/PaymentReports";
import AdminReports from "./Components/Reports/AdminReports";
import LeadReports from "./Components/Reports/LeadReports";

import DigitalMarketingPartner from "./Components/DigitalMarketing/DMPartnerList";
import DigitalMarketingLeads from "./Components/DigitalMarketing/DMLeadsList";
import DigitalMarketingLeadsDetails from "./Components/DigitalMarketing/DMLeadDetails";
import DigitalMarketingCS from "./Components/DigitalMarketing/DMCSList";

import ADSalesPipe from "./Components/SalesPipeLine/AdSalesPipe";
import CSDashboard from "./Components/ChannelSales/CSDashboard/CSDashboard";
import AutoLogout from "./BasicUI/AutoLogout";
import Settings from "./Components/Settings/index";
// quote
import FinalQuotePdf from "./Components/BD/QuoteGenerator/FinalQuotePdf";
import QuotePdfView from "./Components/BD/QuoteGenerator/QuotePdfView";
import QuoteGenerator from "./Components/BD/QuoteGenerator/QuoteGenerator";
import UpdatedQuote from "./Components/BD/QG/UpdatedQuote";
import QGen from "./Components/BD/QG/QGen";

// import CalendarTest from "./Components/ChannelSales/CSDashboard/CalendarTest";
// const UserReports = lazy(() => import("./Components/Reports/UserReports"));

function App() {
  return (
    <Router basename={"/refappadmindev"}>
      <div className="App">
        <AutoLogout>
          <Switch>
            {/* Super Admin Login */}
            <Route path="/" component={Login} exact />
            <Route path="/dash" component={Dashboard} />
            <Route path="/dashuser" component={UserDashboard} />
            <Route path="/rplistforadmin" component={RPList} />
            <Route path="/fselistforadmin" component={FSEList} />
            <Route path="/dashforadmin" component={Dashbrd} />
            <Route path="/rplist/:PageNo" component={RefPartnerList} />
            <Route
              path="/rpreferedrp/:Id/:Number/:PageNo"
              component={RpReferedrp}
            />
            <Route path="/rpdetails/:Id/:Page" component={RefPartnerDetails} />
            <Route
              path="/rpreferedrpdetails/:Id/:Number/:Page"
              component={RpReferedrpdetails}
            />
            <Route
              path="/addrpimagelead/:Id/:ImageUrl/:Status/:ImageId/:Number/:Page"
              component={AddRPLeadImage}
            />
            <Route path="/chlist/:PageNo" component={ChannelPartners} />
            <Route path="/allcustomers/:PageNo" component={AllCustomer} />
            <Route
              path="/cpdetails/:Id/:Page"
              component={ChannelPartnerDetails}
            />
            <Route
              path="/cprefleads/:Id/:Number/:PageNo"
              component={ChannelPartnerReferedLead}
            />
            <Route
              path="/cprefleaddetails/:Id/:Number/:Page"
              component={ChannelPartnerReferedLeadDetails}
            />
            <Route
              path="/addcpimagelead/:Id/:ImageUrl/:Status/:ImageId/:Number/:Page"
              component={AddCPLeadImage}
            />
            <Route path="/adotleads/:PageNo" component={AdminOtherLeads} />
            <Route
              path="/adotleaddetails/:Id/:Page"
              component={AdminOtherLeadsDetails}
            />
            <Route
              path="/otleadimage/:ImageUrl/:Status/:Id/:Page"
              component={OtherLeadImage}
            />
            <Route path="/fselist" component={Fselist} />
            <Route path="/fsedetails/:Id" component={FseDetails} />
            <Route
              path="/referedlead/:Id/:Number/:PageNo"
              component={ReferedLeads}
            />
            <Route
              path="/leadetails/:Id/:Number/:Page"
              component={LeadDetails}
            />
            <Route
              path="/leaddetailsalleads/:Id/:Page"
              component={LeadDetailsAllLeads}
            />
            <Route path="/manageuser/:PageNo" component={ManageUser} />
            <Route path="/userdetails/:Id/:Page" component={UserDetails} />
            <Route path="/listofcustomer/:PageNo" component={ListofCustomer} />
            <Route path="/listofpartners" component={ListofPartners} />
            <Route
              path="/leadetailsview/:Id/:Page"
              component={LeadDetailsView}
            />
            <Route path="/bdashboard" component={BdDashboard} />
            <Route
              path="/rpdetailsview/:Id"
              component={RefPartnerDetailsView}
            />
            {/* <Route path="/adminlogin" component={AdminLogin} /> */}
            <Route
              path="/cslistpartner/:PageNo"
              component={ChannelSalesListPartner}
            />
            <Route
              path="/cspartnerdetails/:Id/:Page"
              component={ChannelSalesPartnerDetails}
            />
            <Route path="/csdash" component={CSDashboard} />
            <Route
              path="/csrpreferedpartner/:Id/:Number/:PageNo"
              component={ChannelSalesRPReferedPartner}
            />
            <Route
              path="/csrpreferedrpdetails/:Id/:Number/:Page"
              component={ChannelSalesRPReferedPartnerDetails}
            />
            <Route
              path="/csrpreferedleads/:Id/:Number/:PageNo"
              component={ChannelSalesRPReferedLeads}
            />
            <Route
              path="/csrpreferedleadsdetails/:Id/:Number/:Page"
              component={ChannelSalesRPReferedLeadsDetails}
            />
            <Route
              path="/addcsrpimagelead/:Id/:ImageUrl/:Status/:ImageId/:Number/:Page"
              component={AddCSRPLeadImage}
            />
            <Route path="/csotherleads/:PageNo" component={CSOtherLeads} />
            <Route
              path="/csotleaddetails/:Id/:Page"
              component={CSOtherLeadDetails}
            />
            <Route
              path="/csleadimage/:ImageUrl/:Status/:Id/:Page"
              component={CSOtherImage}
            />
            <Route
              path="/cslistchpartner/:PageNo"
              component={ChannelSalesListChannelPartner}
            />
            <Route
              path="/cschpartnerdetails/:Id/:Page"
              component={ChannelSalesChannelPartnerDetails}
            />
            <Route
              path="/cschreferedleads/:Id/:Number/:PageNo"
              component={ChannelSalesChannelPartnerReferedLeads}
            />
            <Route
              path="/cschleaddetails/:Id/:Number/:Page"
              component={ChannelSalesChannelPartnerReferedLeadsDetails}
            />
            <Route
              path="/addcscpimagelead/:Id/:ImageUrl/:Status/:ImageId/:Number/:Page"
              component={AddCSCPLeadImage}
            />
            <Route
              path="/islistpartner/:PageNo"
              component={InsideSalesListPartner}
            />
            <Route path="/isdash" component={ISDashboard} />
            <Route path="/isdmleads/:PageNo" component={ISDMLeads} />
            <Route
              path="/isdmleaddetails/:Id/:Page"
              component={IS_DMLeadDetails}
            />
            <Route path="/isalleads/:PageNo" component={ISAllLeads} />
            <Route
              path="/isalleadsdetails/:Id/:Page"
              component={IS_AllLeadsDetails}
            />
            <Route
              path="/ispartnerdetails/:Id"
              component={InsideSalesPartnerDetails}
            />
            <Route
              path="/isrpreferedpartner/:Id"
              component={InsideSalesRPReferedPartner}
            />
            <Route
              path="/isrpreferedrpdetails/:Id"
              component={InsideSalesRPReferedPartnerDetails}
            />
            <Route
              path="/isrpreferedleads/:Id/:Ping/:PageNo"
              component={InsideSalesRPReferedLeads}
            />
            <Route
              path="/isrpreferedleadsdetails/:Id/:Ping/:Page"
              component={InsideSalesRPReferedLeadsDetails}
            />
            <Route
              path="/addisrpimagelead/:Id/:ImageUrl/:Status/:ImageId/:Ping/:Page"
              component={AddISRPLeadImage}
            />
            <Route
              path="/islistchpartner/:PageNo"
              component={InsideSalesListChannelPartner}
            />
            <Route
              path="/ischpartnerdetails/:Id"
              component={InsideSalesChannelPartnerDetails}
            />
            <Route
              path="/ischreferedleads/:Id/:Ping/:PageNo"
              component={InsideSalesChannelPartnerReferedLeads}
            />
            <Route
              path="/ischleaddetails/:Id/:Ping/:Page"
              component={InsideSalesChannelPartnerReferedLeadsDetails}
            />
            <Route
              path="/addiscpimagelead/:Id/:ImageUrl/:Status/:ImageId/:Ping/:Page"
              component={AddISCPLeadImage}
            />
            <Route path="/isotherleads/:PageNo" component={ISOtherLeads} />
            <Route
              path="/isotleaddetails/:Id/:Page"
              component={ISOtherLeadDetails}
            />
            <Route
              path="/isleadimage/:ImageUrl/:Status/:Id/:Page"
              component={ISOtherImage}
            />
            <Route path="/profileadmin" component={ProfileDetailsAdmin} />
            <Route path="/profilecs" component={ProfileDetailsCS} />
            <Route path="/profileis" component={ProfileDetailsIS} />
            <Route path="/profilebd" component={ProfileDetailsBD} />
            <Route path="/userreport" component={UserReports} />
            <Route path="/partnerreport" component={PartnerReports} />
            <Route path="/adminreport" component={AdminReports} />
            <Route path="/paymentreport" component={PaymentReports} />
            <Route path="/leadreport" component={LeadReports} />
            <Route path="/dmpartnerlist" component={DigitalMarketingPartner} />
            <Route
              path="/dmleadslist/:PageNo"
              component={DigitalMarketingLeads}
            />
            <Route
              path="/dmleaddetails/:Id/:Page"
              component={DigitalMarketingLeadsDetails}
            />
            <Route path="/dmlistCS" component={DigitalMarketingCS} />
            <Route path="/adsalespipe" component={ADSalesPipe} />
            <Route path="/settings" component={Settings} />
            {/* quote */}
            <Route path="/quotenew/:Id/:Page" component={QGen} />
            <Route path="/quotepdfview/:Id" component={QuotePdfView} />
            <Route path="/quotepdf/:Id" component={FinalQuotePdf} />
            {/* <Route path="/quotenew" component={QGen} /> */}
            {/* <Route path="/test" component={Test} /> */}
          </Switch>
        </AutoLogout>
      </div>
    </Router>
  );
}

export default App;
