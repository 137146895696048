import React, { useEffect, useState } from "react";
import CS_SideNav from "../CS_SideNav";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { CgArrowRightR } from "react-icons/cg";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Footer from "../../Dashboard/Footer";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
// Pagination
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
//PopUp
import Loader from "../../Helpers/Loader";
import Filter from "../../../Assets/Filter.svg";
import CloseIcon from "@mui/icons-material/Close";
import {
  validateName,
  validateMobileNumber,
  JoiningDate,
  LastActivityDate,
  validatePartnerName,
  validateAssigne,
  LeadsFromRange,
  LeadsToRange,
} from "../../Helpers/validation";
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Formik, Form, Field, useField } from "formik";
import { TextField } from "../../Fields/TextField";
//PopUP csv
import Dropzone from "react-dropzone";
import "../../RPList/file.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const CSlistofChannelPartner = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [partnerdata, Setpartnerdata] = useState([]);
  const [assignOption, setassignOption] = useState([]);
  const [partnerOption, setpartnerOption] = useState([]);
  const [partnerSource, setpartnerSource] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [JoiningDateStart, setJoiningDateStart] = useState("");
  const [JoiningDateEnd, setJoiningDateEnd] = useState("");
  const [isloading, setisloading] = useState(true);
  const [btnloader, setbtnloader] = useState(false);
  const [btnbulkloader, setbtnbulkloader] = useState(false);
  // Paganiation
  const { PageNo } = useParams();
  const { SearchBar } = Search;
  const [Page, setPage] = useState(PageNo ? PageNo : 1);
  const [PerPage, setPerPage] = useState(50);
  const [searchcount, setsearchcount] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  let verify = localStorage.getItem("user_code");
  const username = localStorage.getItem("CSName");
  const { Id } = useParams();
  let history = useHistory();

  useEffect(() => {
    const valid = localStorage.getItem("user_code");
    if (valid === null) {
      history.push("/");
    }

    GetPartners();

    GetPartnerOption();

    // To Fetch Assign To Options DropDown
    axios.get(`${SERVER_URL}/assignees`).then((response) => {
      // console.log("Cust", response);
      setassignOption(response.data.Assignees);
      // console.log("Customer", Customer);
    });

    // To Fetch Partner Options DropDown

    // To Fetch Partner Source By Options DropDown
    axios.get(`${SERVER_URL}/partnerSource`).then((response) => {
      // console.log("Cust", response);
      setpartnerSource(response.data.Source);
    });
  }, []);

  // TO get Partner Options
  const GetPartnerOption = () => {
    axios
      .get(`${SERVER_URL}/cppartnernames?cs_name=${username}`)
      .then((response) => {
        // console.log("partners", response);
        setpartnerOption(response.data.Partners);
        // console.log("Customer", Customer);
      });
  };

  // To fetch List of details
  const GetPartners = () => {
    axios
      .get(
        `${SERVER_URL}/getcppartners?cs_name=${username}&user_code=${verify}`
      )
      .then((responselistpat) => {
        Setpartnerdata(responselistpat.data.Partners);
        setisloading(false);
        // console.log("Customer data", responselistpat);
      });
  };

  // For Assigning prority To
  const setPrority = (e, ids) => {
    // console.log("id", ids);
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };

    let selectPrority = e.target.value;
    const Prority = {
      priority: selectPrority,
    };

    axios
      .post(`${SERVER_URL}/partnerpriority?_id=${ids}`, Prority, {
        headers,
      })
      .then((resultsverified) => {
        // console.log("verified orr", resultsverified);
        let Status = resultsverified.data.status;
        if (Status === "success") {
          // alert("Partner Assigned Successfully");
          toast.success("Prority Assigned Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // window.setTimeout(function () {
          //   window.location.reload();
          // }, 1000);
          GetPartners();
        } else {
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  // For Assigning To
  const setassignes = (e, ids) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };

    let selectt = e.target.value;
    const statverified = {
      assignee: selectt,
    };

    axios
      .post(`${SERVER_URL}/updateassignee?_id=${ids}`, statverified, {
        headers,
      })
      .then((resultsverified) => {
        // console.log("verified orr", resultsverified);
        let Status = resultsverified.data.status;
        if (Status === "success") {
          // alert("Partner Assigned Successfully");
          toast.success("Partner Assigned  Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // alert("Error");
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  // Pop Up Content
  const [Rpprofile, setRpprofile] = useState("");
  const [RPpartnertype, setRPpartnertype] = useState("CP");

  // To Add Partner Details
  const updaterpdetails = (data, resetForm) => {
    setbtnloader(true);
    const formdata = new FormData();
    formdata.append("fullname", data.fullname);
    formdata.append("partnersource", data.partnersource);
    formdata.append("email", data.email);
    formdata.append("mobile", data.mobile);
    formdata.append("companyname", data.companyname);
    formdata.append("experience", data.experience);
    formdata.append("designation", data.designation);
    formdata.append("location", data.location);
    formdata.append("dob", data.dob);
    formdata.append("doj", data.doj);
    formdata.append("partnertype", data.partnertype);
    formdata.append("profile_pic", Rpprofile);

    let verify = localStorage.getItem("user_code");
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/addpartnerbycs?user_code=${verify}`, formdata, {
        headers,
      })
      .then((res) => {
        // console.log("res", res.data.status);
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#Add_Ref_popup").modal("hide");
          window.$("#PartnerAddedSuccessfully").modal("show");
          resetForm({ data: "" });
          GetPartners();
          GetPartnerOption();
          setbtnloader(false);
          // history.push("/rplist");
        } else {
          // window.$("#Add_Ref_popup").modal("hide");
          // alert("Details are already Exists");
          setbtnloader(false);
          toast.error("Details are Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // resetForm({ data: "" });
        }
      });
  };

  const changeprofilepic = (e) => {
    setRpprofile(e.target.files[0]);
    // console.log("rpprofileee", e.target.files[0]);
  };

  // CSV File Content start
  const [fileNames, setFileNames] = useState([]);
  const handleDrop = (acceptedFiles) => setFileNames(acceptedFiles[0]);

  // To Upload CSV File
  const updatecsvfile = () => {
    if (fileNames.length === 0) {
      toast.error("CSV File is Requried", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      // fileNames.type != "application/vnd.ms-excel"
      fileNames.type !== "text/csv"
    ) {
      toast.error("Please Select a CSV Format File", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFileNames([""]);
    } else {
      setbtnbulkloader(true);
      const formdata = new FormData();
      formdata.append("file", fileNames);

      axios
        .post(`${SERVER_URL}/uploadpartnerdata?user_code=${verify}`, formdata)
        .then((res) => {
          let Message = res.data.status;

          let Status = res.data.status;
          if (Status === "failed") {
            let Message = res.data.msg;
            if (
              Message === "Invalid Name!!" ||
              // Message === "Invalid mobile number!!" ||
              Message === "Enter PartnerType!"
            ) {
              setbtnbulkloader(false);
              toast.error(
                "Please Upload a File with all Required Fields(Name, Partner Type)....",
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
            setFileNames([""]);
            window.$("#Add_Ref_popup").modal("hide");
          } else if (Status === "success") {
            window.$("#Add_Ref_popup").modal("hide");
            toast.success("Partners are Added sucessfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setFileNames([""]);
            GetPartners();
            setbtnbulkloader(false);
          }
        });
    }
  };

  // OnChange Function for Date Range Picker of Construction Date
  const onChange = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);

      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start > end) {
        setStartDate(end);
        setEndDate(start);
      } else {
        setStartDate(start);
        setEndDate(end);
      }
    } else {
      // console.log("empty");
      setStartDate("");
      setEndDate("");
    }
  };

  const handleJoining = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];

      // Converting it to format dd/mm/yyyy
      // setJoiningDateStart(start.toISOString().slice(0, 10));
      // setJoiningDateEnd(end.toISOString().slice(0, 10));
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start < end) {
        setJoiningDateStart(start);
        setJoiningDateEnd(end);
      } else {
        setJoiningDateStart(end);
        setJoiningDateEnd(start);
      }
    } else {
      // console.log("empty");
      setJoiningDateStart("");
      setJoiningDateEnd("");
    }
  };

  const CPFilters = (data) => {
    setbtnloader(true);
    const sendData = {
      joining_from_date: JoiningDateStart,
      joining_to_date: JoiningDateEnd,
      experience: data.experience,
      location: data.location,
      source: data.source,
      verified: data.verified,
      onboarded_by: data.onboarded_by,
      managed_by: data.managed_by,
      priority: data.priority,
      activity_from: startDate,
      activity_to: endDate,
    };

    // console.log("sendData", sendData);

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(
        `${SERVER_URL}/filteronpartners?partnertype=CP&lgs_name=${username}`,
        sendData,
        {
          headers,
        }
      )
      .then((res) => {
        // console.log("res", res);
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#Add_Filter_popup").modal("hide");
          setPage(1);
          Setpartnerdata(res.data.Partners);
          setbtnloader(false);
          // remove
          if (res.data.Partners.length !== 0) {
            var number = document.getElementsByClassName(
              "react-bootstrap-table-page-btns-ul"
            )[0];
            var firstnum = number.querySelectorAll("li, a")[1];
            firstnum.click();
          }
        } else {
          toast.error("Something Went Wrong...try Again!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          Setpartnerdata("");
          setbtnloader(false);
        }
      });
  };

  // New Pagination
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const partPriority = (cell, row) => {
    return (
      <select
        className="p-2 select_dropdown_assign_to"
        onChange={(e) => {
          setPrority(e, row._id);
        }}
      >
        <option disabled selected value="">
          {row.priority ? row.priority : "Select"}
        </option>
        <option value="High">High</option>
        <option value="Medium">Medium</option>
        <option value="Low">Low</option>
      </select>
    );

    // switch (cell) {
    //   case "High":
    //     return (
    //       <div>
    //         <button className="high_priority">High</button>
    //       </div>
    //     );
    //   case "Medium":
    //     return (
    //       <div>
    //         <button className="medium_priority">Medium</button>
    //       </div>
    //     );
    //   case "low_priority":
    //     return (
    //       <div>
    //         <button className="low_priority">Low</button>
    //       </div>
    //     );
    //   default:
    //     return "-";
    // }
  };

  const partKYC = (cell) => {
    switch (cell) {
      case true:
        return (
          <div>
            <button className="verified_btn">Verified</button>
          </div>
        );
      case false:
        return (
          <div>
            <button className="pending_btn">Pending</button>
          </div>
        );
      default:
        return "-";
    }
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "fullname",
      text: "Partner Name",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "partnersource",
      text: "Source",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "partner_ID",
      text: "Partner Id",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "priority",
      text: "Priority",
      formatter: partPriority,
    },
    {
      dataField: "verified",
      text: "KYC",
      // formatter: (cell) => (!cell ? "-" : cell),
      formatter: partKYC,
    },
    {
      dataField: "partner_rank",
      text: "Rank",
      formatter: (cell) => (!cell ? "-" : cell),
      searchable: false,
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell) => (
        <Link
          to={`/cschpartnerdetails/${cell}/${Page}`}
          // target="_blank" // remove afterwards
          className="viewmore"
        >
          <CgArrowRightR />
        </Link>
      ),
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log("row", row);
      // history.push(`/cspartnerdetails/${row._id}`);
      let getCurrentCellIndex = e.target.cellIndex;
      // console.log("row", getCurrentCellIndex);
      let getLastCellIndex = document.querySelector(
        "table tr:last-child td:nth-child(6)"
      ).cellIndex;
      // console.log("rows", getLastCellIndex);
      // As this event is for the hole ROW, we need to exclude last one, as we have different events there. You can exclude no matter which one, even pass it as parameter.

      if (
        getCurrentCellIndex !== getLastCellIndex &&
        getCurrentCellIndex !== undefined
      ) {
        // old
        history.push(`/cschpartnerdetails/${row._id}/${Page}`);
        //new
        // window.open(`/cschpartnerdetails/${row._id}/${Page}`, "_blank");
        // console.log(`----> ${JSON.stringify(row)} |||| ${rowIndex}`);
      }
    },
  };

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    page: parseInt(Page),
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    // remove these two
    sizePerPage: 50,
    hideSizePerPage: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ],
  };

  return (
    <div>
      {isloading === false ? (
        <div>
          <CS_SideNav />
          <div className="main">
            <div className="content-tabs">
              <ToolkitProvider
                keyField="_id"
                data={partnerdata}
                columns={columns}
                search={{ afterSearch }}
              >
                {(props) => (
                  <div>
                    <div className="top_menu_bar">
                      <div>
                        <div>
                          <span className="CS_head_txt">
                            List Of Channel Partners
                          </span>
                        </div>
                        <span className="total_count_txt">
                          (
                          {searchcount === 0
                            ? searchcount
                            : searchcount > 0
                            ? searchcount
                            : partnerdata.length}{" "}
                          partners)
                        </span>
                      </div>

                      <div>
                        <div className="d-flex justify-content-around">
                          <div className="search_div" style={{ width: "25%" }}>
                            <SearchIcon className="search_icon" />
                            <SearchBar
                              {...props.searchProps}
                              className="top_menu_search"
                              placeholder="Search Here..."
                              // style={{ width: "400px", height: "40px" }}
                            />
                            {/* <input
                              type="text"
                              className="top_menu_search"
                              placeholder="Search Here.."
                              onChange={(event) => {
                                Setsearchterms(event.target.value);
                              }}
                            /> */}
                          </div>

                          <div>
                            <div>
                              <button
                                className="add_btn"
                                data-toggle="modal"
                                data-target=".Assign_leads"
                              >
                                <PersonAddAltRoundedIcon className="search_icons" />
                                Assign Leads
                              </button>

                              <div>
                                <div
                                  id="Assign_leads"
                                  className="modal fade Assign_leads"
                                  role="dialog"
                                  aria-labelledby="myLargeModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-dialog-centered">
                                    <div
                                      className="modal-content modal_content_whole"
                                      style={{ background: "white" }}
                                    >
                                      <div
                                        className="modal-body"
                                        style={{ background: "#FFFFFF" }}
                                      >
                                        <div className="pb-3">
                                          <span className="add_hot_lead_label">
                                            Assign Leads
                                          </span>
                                          <span
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            id="CloseAddHotLeadPopup"
                                            style={{
                                              background: "transparent",
                                              float: "right",
                                              // color: "red",
                                            }}
                                          >
                                            <CloseRoundedIcon />
                                          </span>
                                        </div>
                                        <Formik
                                          initialValues={{
                                            assignee: "",
                                            name: "",
                                            from: "",
                                            to: "",
                                          }}
                                          onSubmit={(values, { resetForm }) => {
                                            const headers = {
                                              "Content-Type":
                                                "application/json",
                                            };

                                            axios
                                              .post(
                                                `${SERVER_URL}/updateassignee`,
                                                values,
                                                {
                                                  headers,
                                                }
                                              )
                                              .then((res) => {
                                                let Status = res.data.status;
                                                if (Status === "success") {
                                                  window
                                                    .$("#Assign_leads")
                                                    .modal("hide");
                                                  toast.success(
                                                    "Leads Assigned Successfully",
                                                    {
                                                      position: "top-right",
                                                      autoClose: 5000,
                                                      hideProgressBar: true,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                    }
                                                  );
                                                  resetForm({ values: "" });
                                                } else if (
                                                  Status === "failed"
                                                ) {
                                                  toast.error(
                                                    "Details are Already Exists",
                                                    {
                                                      position: "top-right",
                                                      autoClose: 5000,
                                                      hideProgressBar: true,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                    }
                                                  );
                                                  resetForm({ values: "" });
                                                }
                                              });
                                          }}
                                        >
                                          {({ errors, touched }) => (
                                            <div>
                                              <Form>
                                                <div className="row">
                                                  <div className="col-12 col-md-6">
                                                    <label
                                                      className="filter_input_head_txt pb-3"
                                                      style={{
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      Partner Name
                                                    </label>
                                                    <div className="input-group_add_hot_lead">
                                                      <Field
                                                        as="select"
                                                        className="field_input_txt"
                                                        name="name"
                                                        validate={
                                                          validatePartnerName
                                                        }
                                                        defaultValue={"select"}
                                                      >
                                                        <option
                                                          selected
                                                          value=""
                                                        >
                                                          Choose Partner
                                                        </option>
                                                        {/* partnerOption */}
                                                        {partnerOption != ""
                                                          ? partnerOption.map(
                                                              (item, i) => {
                                                                return (
                                                                  <option
                                                                    key={i}
                                                                    value={
                                                                      item.Name
                                                                    }
                                                                  >
                                                                    {item.Name}
                                                                  </option>
                                                                );
                                                              }
                                                            )
                                                          : "No Data"}
                                                      </Field>
                                                      {errors.name &&
                                                        touched.name && (
                                                          <div className="pt-2 error">
                                                            {errors.name}
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>

                                                  <div className="col-12 col-md-6">
                                                    <label
                                                      className="filter_input_head_txt pb-3"
                                                      style={{
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      Assign to
                                                    </label>
                                                    <div className="input-group_add_hot_lead">
                                                      <Field
                                                        as="select"
                                                        className="field_input_txt"
                                                        name="assignee"
                                                        validate={
                                                          validateAssigne
                                                        }
                                                        defaultValue={"select"}
                                                      >
                                                        <option
                                                          selected
                                                          value=""
                                                        >
                                                          Select
                                                        </option>
                                                        {assignOption != ""
                                                          ? assignOption.map(
                                                              (item, i) => {
                                                                return (
                                                                  <option
                                                                    key={i}
                                                                    value={
                                                                      item.Name
                                                                    }
                                                                  >
                                                                    {item.Name}
                                                                  </option>
                                                                );
                                                              }
                                                            )
                                                          : "No Data"}
                                                      </Field>
                                                      {errors.assignee &&
                                                        touched.assignee && (
                                                          <div className="pt-2 error">
                                                            {errors.assignee}
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="row pt-4">
                                                  <label
                                                    className="filter_input_head_txt pb-3"
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    Leads Range
                                                  </label>
                                                  <div className="col-12 col-md-6">
                                                    <Field
                                                      type="text"
                                                      className="field_input_txt"
                                                      placeholder="From"
                                                      id="from"
                                                      name="from"
                                                      validate={LeadsFromRange}
                                                      autoComplete="off"
                                                    />
                                                    {errors.from &&
                                                      touched.from && (
                                                        <div className="pt-2 error">
                                                          {errors.from}
                                                        </div>
                                                      )}
                                                  </div>
                                                  <div className="col-12 col-md-6">
                                                    <Field
                                                      type="text"
                                                      className="field_input_txt"
                                                      placeholder="To"
                                                      id="to"
                                                      name="to"
                                                      validate={LeadsToRange}
                                                      autoComplete="off"
                                                    />
                                                    {errors.to &&
                                                      touched.to && (
                                                        <div className="pt-2 error">
                                                          {errors.to}
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>

                                                <div className="row pt-4">
                                                  <div className="col-12 col-md-6">
                                                    <button
                                                      type="button"
                                                      data-dismiss="modal"
                                                      className="btn btn-lg btn_resetall"
                                                    >
                                                      Cancel
                                                    </button>
                                                  </div>
                                                  <div className="col-12 col-md-6">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-lg btn_apply_filter"
                                                    >
                                                      Assign Leads
                                                    </button>
                                                  </div>
                                                </div>
                                              </Form>
                                            </div>
                                          )}
                                        </Formik>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <button
                              className="add_btn"
                              data-toggle="modal"
                              data-target=".Add_Filter_popup"
                            >
                              <img src={Filter} />
                              Filter
                            </button>
                          </div>

                          <div>
                            {/* Pop Up For Adding Partner Start  */}
                            <div>
                              <div>
                                <button
                                  className="add_btn"
                                  data-toggle="modal"
                                  data-target=".Add_Ref_popup"
                                >
                                  <AddIcon className="search_icons" />
                                  Add Partner
                                </button>
                              </div>

                              <div>
                                <div
                                  id="Add_Ref_popup"
                                  className="modal fade Add_Ref_popup"
                                  role="dialog"
                                  aria-labelledby="myLargeModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-lg modal-dialog-centered  ">
                                    <div
                                      className="modal-content modal_content_whole"
                                      style={{ background: "white" }}
                                    >
                                      {/*  */}
                                      <div className="modal-body">
                                        <div className="pb-3">
                                          <span className="add_hot_lead_label">
                                            Add Channel Partner
                                          </span>
                                          <span
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            id="CloseAddHotLeadPopup"
                                            style={{
                                              background: "transparent",
                                              float: "right",
                                              color: "#00000",
                                            }}
                                          >
                                            <span
                                              aria-hidden="true"
                                              style={{ fontSize: "18px" }}
                                            >
                                              X
                                            </span>
                                          </span>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-5 col-5 d-flex align-items-center justify-content-center">
                                            {/* ReferalPartner CSV Start */}
                                            {/* <AddReferalPartnerCsv /> */}
                                            <div>
                                              <Formik
                                                initialValues={{
                                                  file: "",
                                                }}
                                                onSubmit={() => {
                                                  // updatecsvfile();
                                                }}
                                              >
                                                <Form>
                                                  <Dropzone onDrop={handleDrop}>
                                                    {({
                                                      getRootProps,
                                                      getInputProps,
                                                    }) => (
                                                      <div
                                                        {...getRootProps({
                                                          className: "dropzone",
                                                        })}
                                                      >
                                                        <input
                                                          name="fileNames"
                                                          {...getInputProps()}
                                                        />
                                                        <p className="drag_txt_csv">
                                                          Drag & Drop files, or
                                                          click to select files
                                                        </p>
                                                      </div>
                                                    )}
                                                  </Dropzone>
                                                  <div>
                                                    <strong>Files:</strong>
                                                    <ul>
                                                      {/* {fileNames.map((fileName) => ( */}
                                                      <li>{fileNames.name}</li>
                                                      {/* ))} */}
                                                    </ul>
                                                    <button
                                                      className="btn_add_hot_lead"
                                                      type="submit"
                                                      onClick={updatecsvfile}
                                                    >
                                                      {btnbulkloader ===
                                                      true ? (
                                                        <span
                                                          className="spinner-border spinner-border-sm"
                                                          role="status"
                                                          aria-hidden="true"
                                                        ></span>
                                                      ) : (
                                                        ""
                                                      )}
                                                      Upload
                                                    </button>
                                                  </div>
                                                </Form>
                                              </Formik>
                                            </div>
                                            {/* ReferalPartner CSV End */}
                                          </div>
                                          <div className="col-md-1 col-1 d-flex align-items-center justify-content-center flex-column">
                                            <div
                                              style={{
                                                height: "45%",
                                                border: "1px solid #dfdfdf",
                                              }}
                                            ></div>
                                            <div>OR</div>
                                            <div
                                              style={{
                                                height: "45%",
                                                border: "1px solid #dfdfdf",
                                              }}
                                            ></div>
                                          </div>
                                          <div className="col-md-6 col-6">
                                            <Formik
                                              initialValues={{
                                                fullname: "",
                                                partnersource: "",
                                                email: "",
                                                mobile: "",
                                                companyname: "",
                                                experience: "",
                                                designation: "",
                                                location: "",
                                                dob: "",
                                                doj: "",
                                                partnertype: RPpartnertype,
                                                Profile: "",
                                              }}
                                              // validationSchema={validate}
                                              onSubmit={(
                                                values,
                                                { resetForm }
                                              ) => {
                                                updaterpdetails(
                                                  values,
                                                  resetForm
                                                );
                                              }}
                                            >
                                              {(formik) => (
                                                <div>
                                                  {/* {console.log(formik)} */}
                                                  <Form>
                                                    {/* <AddReferalPopup /> */}
                                                    <div className="d-flex justify-content-between">
                                                      <div
                                                        className="p-1"
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <div>
                                                          <div className="p-3 add_hot_lead_content">
                                                            <div className="row ">
                                                              <div className="col-md-6">
                                                                <div className="input-group_add_hot_lead ">
                                                                  <TextField
                                                                    type="text"
                                                                    name="fullname"
                                                                    label="Full Name"
                                                                    validate={
                                                                      validateName
                                                                    }
                                                                    placeholder="Enter Name"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div className="col-md-6">
                                                                <div className="input-group_add_hot_lead">
                                                                  <TextField
                                                                    type="text"
                                                                    name="partnersource"
                                                                    label="Source"
                                                                    placeholder="Enter SOurce"
                                                                  />
                                                                </div>
                                                              </div>
                                                              {/* <div className="col-md-6">
                                                        <div className="input-group_add_hot_lead ">
                                                          <TextField
                                                            type="file"
                                                            name="Profile"
                                                            label="Profile Pic"
                                                            onChange={(e) =>
                                                              changeprofilepic(
                                                                e
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div> */}
                                                            </div>
                                                            <div className="row pb-2">
                                                              <div className="col-6 col-sm-6">
                                                                <div className="input-group_add_hot_lead ">
                                                                  <TextField
                                                                    type="email"
                                                                    style={{
                                                                      textTransform:
                                                                        "lowercase",
                                                                    }}
                                                                    name="email"
                                                                    label="Email"
                                                                    placeholder="Enter Email"
                                                                  />
                                                                </div>
                                                              </div>

                                                              <div className="col-6 col-sm-6">
                                                                <div className="input-group_add_hot_lead ">
                                                                  <TextField
                                                                    type="tel"
                                                                    name="mobile"
                                                                    label="Phone Number"
                                                                    validate={
                                                                      validateMobileNumber
                                                                    }
                                                                    placeholder="Enter Mobile Number"
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>

                                                            <div className="row pb-2">
                                                              <div className="col-6 col-sm-6">
                                                                <div className="input-group_add_hot_lead ">
                                                                  <TextField
                                                                    type="text"
                                                                    name="companyname"
                                                                    label="Company Name"
                                                                    placeholder="Enter Company Name"
                                                                  />
                                                                </div>
                                                              </div>

                                                              <div className="col-6 col-sm-6">
                                                                <div className="input-group_add_hot_lead ">
                                                                  <TextField
                                                                    type="text"
                                                                    name="experience"
                                                                    label="Experience (In Years)"
                                                                    placeholder="Enter Experience"
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>

                                                            <div className="row pb-2">
                                                              <div className="col-6 col-sm-6">
                                                                <div className="input-group_add_hot_lead ">
                                                                  <TextField
                                                                    type="text"
                                                                    name="designation"
                                                                    label="Designation"
                                                                    placeholder="Enter Designation"
                                                                  />
                                                                </div>
                                                              </div>

                                                              <div className="col-6 col-sm-6">
                                                                <div className="input-group_add_hot_lead">
                                                                  <TextField
                                                                    type="text"
                                                                    name="partnertype"
                                                                    label="Type Of Partner"
                                                                    value={"CP"}
                                                                  />
                                                                  {/* <SelectField
                                                            className="form-select"
                                                            name="partnertype"
                                                            label="Type Of Partner"
                                                            validate={
                                                              validatePartner
                                                            }
                                                          >
                                                            <option
                                                              selected
                                                              value=""
                                                            >
                                                              Select
                                                            </option>
                                                            <option value="RP">
                                                              RP
                                                            </option>
                                                            <option value="CP">
                                                              CP
                                                            </option>
                                                          </SelectField> */}
                                                                </div>
                                                              </div>
                                                            </div>

                                                            <div className="row">
                                                              <div className="col-6 col-sm-6">
                                                                <div className="input-group_add_hot_lead ">
                                                                  <TextField
                                                                    type="date"
                                                                    name="dob"
                                                                    label="Date of Birth"
                                                                  />
                                                                </div>
                                                              </div>

                                                              <div className="col-6 col-sm-6">
                                                                <div className="input-group_add_hot_lead ">
                                                                  <TextField
                                                                    type="date"
                                                                    name="doj"
                                                                    label="Joining Date"
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>

                                                            <div className="row">
                                                              <div className="col-12 col-sm-12">
                                                                <div className="input-group_add_hot_lead ">
                                                                  <TextField
                                                                    type="text"
                                                                    name="location"
                                                                    label="Work Location"
                                                                    placeholder="Enter Location"
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div>
                                                              <button
                                                                className="btn_add_hot_lead"
                                                                type="submit"
                                                              >
                                                                {btnloader ===
                                                                true ? (
                                                                  <span
                                                                    className="spinner-border spinner-border-sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                  ></span>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                Add Partner
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Form>
                                                </div>
                                              )}
                                            </Formik>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Pop Up For Adding Partner End  */}
                          </div>

                          <div
                            id="PartnerAddedSuccessfully"
                            className="modal fade bd-lead-added-successfully"
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                              <div
                                className="modal-content modal-sm modal_content_whole"
                                style={{ background: "white" }}
                              >
                                <div className="modal-body">
                                  <div>
                                    <div className="popup_icon_center">
                                      <CheckCircleIcon className="popup_addbankdetails_icon" />
                                    </div>
                                    <div className="popup_text_center">
                                      <p className="pt-2 popup_addbankdetails_text">
                                        Partner Added Successfully
                                      </p>
                                      <div className="pt-2 m-0 d-flex justify-content-center">
                                        <div
                                          style={{
                                            width: "50%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <button
                                            // type="button"
                                            className="popup_btn_linkaccount"
                                            data-dismiss="modal"
                                          >
                                            OK
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="table_div">
                        {partnerdata != "" ? (
                          <div>
                            <BootstrapTable
                              {...props.baseProps}
                              noDataIndication={emptyDataMessage}
                              pagination={paginationFactory(paginationoptions)}
                              filter={filterFactory()}
                              rowEvents={rowEvents}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div>
                              <WarningRoundedIcon className="no_data_alert" />
                            </div>
                            <div>
                              <h3>No records found for Customers</h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>

          {/* Filter Pop Up start */}
          <div>
            <div
              id="Add_Filter_popup"
              className="modal fade Add_Filter_popup"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div
                  className="modal-content modal_content_whole"
                  style={{ background: "white" }}
                >
                  <div className="modal-body" style={{ background: "#FFFFFF" }}>
                    <div className="pb-3">
                      <span className="add_hot_lead_label">Apply Filters</span>
                      <span
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="CloseAddHotLeadPopup"
                        style={{
                          background: "transparent",
                          float: "right",
                          color: "#221e1f",
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                    <Formik
                      initialValues={{
                        experience: "",
                        verified: "",
                        onboarded_by: "",
                        managed_by: "",
                        priority: "",
                        location: "",
                        source: "",
                      }}
                      // validationSchema={validate}
                      onSubmit={(values, { resetForm }) => {
                        // otherLeadsFilter(values);
                        CPFilters(values);
                      }}
                    >
                      {(formik) => (
                        <div>
                          <Form>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Joining Date
                                  </label>
                                  <DateRangePicker
                                    placeholder="Select Date Range"
                                    format="dd/MM/yyyy"
                                    character="-"
                                    onChange={handleJoining}
                                    ranges={JoiningDate}
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Experience
                                  </label>
                                  <Field
                                    type="text"
                                    className="field_input_txt"
                                    placeholder="Enter Experience"
                                    id="from"
                                    name="experience"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Partner Source
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="source"
                                  >
                                    <option disabled selected value="">
                                      Select Source
                                    </option>
                                    {partnerSource != ""
                                      ? partnerSource.map((item, i) => {
                                          return (
                                            <option key={i} value={item}>
                                              {item}
                                            </option>
                                          );
                                        })
                                      : ""}
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Verification
                                  </label>
                                  <div className="input-group_add_hot_lead ">
                                    <Field
                                      as="select"
                                      className="field_input_txt"
                                      name="verified"
                                    >
                                      <option disabled selected value="">
                                        Select Status
                                      </option>
                                      <option value="true">Verified</option>
                                      <option value="false">Pending</option>
                                    </Field>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Priority
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="priority"
                                  >
                                    <option disabled selected value="">
                                      Select Priority
                                    </option>
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Last Activity Date
                                  </label>
                                  <DateRangePicker
                                    placeholder="Select Date Range"
                                    format="dd/MM/yyyy"
                                    character="-"
                                    onChange={onChange}
                                    ranges={LastActivityDate}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-12">
                                <label className="filter_input_head_txt pb-3">
                                  Location
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    type="text"
                                    className="field_input_txt"
                                    placeholder="Enter Location"
                                    id="from"
                                    name="location"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row pt-4">
                              <div className="col-12 col-md-6">
                                <button
                                  type="reset"
                                  className="btn btn-lg btn_resetall"
                                >
                                  Reset All
                                </button>
                              </div>
                              <div className="col-12 col-md-6">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn_apply_filter"
                                >
                                  {btnloader === true ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    ""
                                  )}
                                  Apply Filter
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Filter Pop Up End */}
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default CSlistofChannelPartner;
