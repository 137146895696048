import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminNav from "../AdminNavBar/AdminNav";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const ProfileDetailsAdmin = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [show, toggleShow] = useState(false);
  const [showedit, toggleShowedit] = useState(false);
  const [showeditcmt, toggleShoweditcmt] = useState(false);
  //   const [SelectedPhoto, setSelectedPhoto] = useState([]);

  // state to set all personal info values of partners
  const [usersname, Setusersname] = useState("");
  const [userempid, Setuserempid] = useState("");
  const [userjoiningdate, Setuserjoiningdate] = useState("");
  const [usermobile, Setusermobile] = useState("");
  const [usercode, Setusercode] = useState("");

  const [usersid, Setusersid] = useState("");
  const [userpassword, Setuserpassword] = useState("");
  const [userrole, Setuserrole] = useState("");

  //   console.log("id", Id);
  // var DownloadButton = require('downloadbutton/es5');
  let history = useHistory();

  const prof_ID = localStorage.getItem("prof_ID");

  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }

    axios
      .get(`${SERVER_URL}/getuserbyid?_id=${prof_ID}`)
      .then((userdetailsvalue) => {
        // console.log("res", userdetailsvalue);

        Setusersname(userdetailsvalue.data.User[0].name);
        Setuserempid(userdetailsvalue.data.User[0].emp_ID);
        Setuserjoiningdate(userdetailsvalue.data.User[0].doj);
        Setuserrole(userdetailsvalue.data.User[0].role);
        Setusersid(userdetailsvalue.data.User[0].userId);
        Setuserpassword(userdetailsvalue.data.User[0].password);
        Setusermobile(userdetailsvalue.data.User[0].mobile);
        Setusercode(userdetailsvalue.data.User[0].user_code);
        // console.log("User details of each user", userdetailsvalue.data.User);
      });
  }, []);

  // date conversion
  // var doj = new Date(userjoiningdate);
  // var dd = String(doj.getDate()).padStart(2, "0");
  // var mm = String(doj.getMonth() + 1).padStart(2, "0"); //January is 0!
  // var yyyy = doj.getFullYear();
  // doj = dd + "-" + mm + "-" + yyyy;

  // To update the details
  const updateuserdetails = (e) => {
    e.preventDefault();
    const updatedres = {
      name: usersname,
      emp_ID: userempid,
      doj: userjoiningdate,
      mobile: usermobile,
      userId: usersid,
      password: userpassword,
      role: userrole,
      usercode: usercode,
    };

    // console.log("data", updatedres);
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/updateuser?_id=${prof_ID}`, updatedres, {
        headers,
      })
      .then((resultfse) => {
        // console.log("Hello Don", resultfse);
        let Status = resultfse.data.status;
        if (Status === "success") {
          // alert("Profile Details are edited sucessfully");
          toast.success("Profile Details are edited sucessfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (Status === "failed") {
          // alert("Profile Details are already exists");
          toast.error("Profile Details Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Some Internal Error", err);
      });
  };

  return (
    <div>
      <div>
        <AdminNav />
      </div>
      <div>
        <div className="main">
          <div className="content-tabs">
            <div>
              <div className="row top_menu_bar">
                <div className="col-md-8 d-flex align-items-center">
                  <Link to="/dash" className="partner_back_btn">
                    <span>
                      <ArrowBackIosNewIcon />
                      Back
                    </span>
                  </Link>
                </div>
              </div>

              <div className="partner_details_edit_sec">
                <form>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="p-3 admin_patrner_personal_info">
                        <div className="d-flex justify-content-between head_txt head_txt_margin">
                          <p>Personal Info</p>
                          <div
                            className="edit_icon"
                            onClick={() => toggleShow(!show)}
                          >
                            {!show && (
                              <div>
                                <EditIcon className="search_icons" />
                                <span> Edit</span>
                              </div>
                            )}
                            {show && (
                              <div>
                                <button
                                  type="submit"
                                  className="edit_icon"
                                  onClick={updateuserdetails}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        {!show && (
                          <div className="viewing_details">
                            <div className="row">
                              <div className="col-6 col-md-2">
                                <div>
                                  <p className="m-0 admin_type_heading">
                                    Full Name
                                  </p>
                                  <span className="admin_type_value">
                                    {usersname === "" ? "-" : usersname}
                                  </span>
                                </div>
                              </div>
                              <div className="col-6 col-md-2">
                                <div>
                                  <p className="m-0 admin_type_heading">
                                    Employee ID
                                  </p>
                                  <span className="admin_type_value">
                                    {userempid === "" ? "-" : userempid}
                                  </span>
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div>
                                  <p className="m-0 admin_type_heading">
                                    Joining Date
                                  </p>
                                  <span className="admin_type_value">
                                    {/* {doj === "" ? "-" : doj} */}
                                    {userjoiningdate === ""
                                      ? "-"
                                      : userjoiningdate
                                          .slice(0, 10)
                                          .split("-")
                                          .reverse()
                                          .join("-")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-6 col-md-2">
                                <div>
                                  <p className="m-0 admin_type_heading">
                                    User Type
                                  </p>
                                  <span className="admin_type_value">
                                    {userrole === "" ? "-" : userrole}
                                  </span>
                                </div>
                              </div>

                              <div className="col-6 col-md-3">
                                <div>
                                  <p className="m-0 admin_type_heading">
                                    Mobile Number
                                  </p>
                                  <span className="admin_type_value">
                                    {usermobile === "" ? "-" : usermobile}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {show && (
                          <div className="edit_details">
                            <div className="row">
                              <div className="col-6 col-md-2">
                                <div className="input-group-custom">
                                  <label htmlFor="inputName" className="label">
                                    Full Name
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="inputName"
                                    name="name"
                                    value={usersname}
                                    onChange={(e) => {
                                      Setusersname(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-2">
                                <div className="input-group-custom">
                                  <label htmlFor="inputEMPID" className="label">
                                    Employee ID
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="inputEMPID"
                                    name="emp_ID"
                                    value={userempid}
                                    onChange={(e) => {
                                      Setuserempid(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="inputdoj" className="label">
                                    Joining Date
                                  </label>
                                  <br />
                                  <span>{userjoiningdate}</span>
                                  {/* <input
                                    type="date"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="inputdoj"
                                    name="doj"
                                    value={userjoiningdate}
                                    onChange={(e) => {
                                      Setuserjoiningdate(e.target.value);
                                    }}
                                  /> */}
                                </div>
                              </div>
                              <div className="col-6 col-md-2">
                                <div className="input-group-custom">
                                  <label htmlFor="role" className="label">
                                    User Type
                                  </label>
                                  <br />
                                  <span>
                                    {userrole === "" ? "-" : userrole}
                                  </span>
                                  {/* <select
                                    className="p-1 input-area admin_partner_det_input"
                                    name="role"
                                    // label="Select"
                                    value={userrole}
                                    defaultValue={"select"}
                                    // validate={validateType}
                                    // autoComplete="off"
                                    onChange={(e) => {
                                      Setuserrole(e.target.value);
                                    }}
                                  >
                                    <option selected value="">
                                      Select
                                    </option>
                                    <option value="Admin">Admin</option>
                                    <option value="BD">BD</option>
                                    <option value="FSE">FSE</option>
                                    <option value="IS">IS</option>
                                    <option value="LGS">LGS</option>
                                  </select> */}
                                </div>
                              </div>

                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label
                                    htmlFor="inputMobile"
                                    className="label"
                                  >
                                    Mobile Number
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="inputMobile"
                                    name="mobile"
                                    value={usermobile}
                                    onChange={(e) => {
                                      Setusermobile(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="p-3 admin_patrner_personal_info">
                        <div className="d-flex justify-content-between head_txt head_txt_margin">
                          <p>Credentials</p>
                          <div
                            className="edit_icon"
                            onClick={() => toggleShowedit(!showedit)}
                          >
                            {!showedit && (
                              <div>
                                <EditIcon className="search_icons" />
                                <span> Edit</span>
                              </div>
                            )}
                            {showedit && (
                              <div>
                                <button
                                  type="submit"
                                  className="edit_icon"
                                  onClick={updateuserdetails}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div>
                        </div>

                        {!showedit && (
                          <div className="contact_info_">
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div>
                                  <p className="m-0 admin_type_heading">
                                    Employee ID
                                  </p>
                                  <span className="admin_type_value">
                                    {usersid === "" ? "-" : usersid}
                                  </span>
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div>
                                  <p className="m-0 admin_type_heading">
                                    Password
                                  </p>
                                  <span className="admin_type_value">
                                    {userpassword != "" ? "****" : "NA"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {showedit && (
                          <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="input-group-custom">
                                  <label htmlFor="inputName" className="label">
                                    Employee ID
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="inputName"
                                    name="userid"
                                    value={usersid}
                                    onChange={(e) => {
                                      Setusersid(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="input-group-custom">
                                  <label htmlFor="comp" className="label">
                                    Password
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="comp"
                                    name="password"
                                    value={userpassword}
                                    onChange={(e) => {
                                      Setuserpassword(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mt-3">
                      <div className="p-3 admin_patrner_personal_info">
                        <div className="d-flex justify-content-between head_txt head_txt_margin">
                          <p>User Code</p>
                          {/* <div
                            className="edit_icon"
                            onClick={() => toggleShoweditcmt(!showeditcmt)}
                          >
                            {!showeditcmt && (
                              <div>
                                <EditIcon className="search_icons" />
                                <span> Edit</span>
                              </div>
                            )}
                            {showeditcmt && (
                              <div>
                                <button
                                  type="submit"
                                  className="edit_icon"
                                  onClick={updateuserdetails}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div> */}
                        </div>
                        {!showeditcmt && (
                          <div className="contact_info_">
                            <div>
                              <span className="admin_type_value">
                                {usercode === "" ? "-" : usercode}
                              </span>
                            </div>
                          </div>
                        )}
                        {showeditcmt && (
                          <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                            <div className="input-group-custom">
                              <input
                                type="text"
                                className="input-area"
                                id="usercode"
                                name="usercode"
                                value={usercode}
                                onChange={(e) => {
                                  Setusercode(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailsAdmin;
