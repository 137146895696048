import React, { useState } from "react";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Formik, Form, Field, useField } from "formik";
import { TextField } from "../Fields/TextField";
import { useHistory } from "react-router-dom";
import axios from "axios";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import $ from "jquery";
import { SelectField } from "../Fields/SelectField";
import FileTesting from "../PopUp/FileTesting";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const AddReferalPopup = () => {
  let history = useHistory();

  const [Rpprofile, setRpprofile] = useState("");
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [RPpartnertype, setRPpartnertype] = useState("RP");

  const updaterpdetails = (data) => {
    const formdata = new FormData();
    formdata.append("fullname", data.fullname);
    formdata.append("email", data.email);
    formdata.append("mobile", data.mobile);
    formdata.append("companyname", data.companyname);
    formdata.append("experience", data.experience);
    formdata.append("designation", data.designation);
    formdata.append("location", data.location);
    formdata.append("dob", data.dob);
    formdata.append("doj", data.doj);
    formdata.append("partnertype", data.partnertype);
    formdata.append("profile_pic", Rpprofile);

    let verify = localStorage.getItem("_id");
    // console.log(verify);

    // console.log("Data", formdata);

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/addpartner`, formdata, {
        headers,
      })
      .then((res) => {
        // console.log("res", res.data.status);
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#Add_Ref_popup").modal("hide");
          window.$("#PartnerAddedSuccessfully").modal("show");
          // history.push("/rplist");
        } else {
          // alert("Details are already Exists");
          toast.error("Details Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const changeprofilepic = (e) => {
    setRpprofile(e.target.files[0]);
    // console.log("rpprofileee", e.target.files[0]);
  };

  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is Required";
    } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
      error = "Enter a Valid Name It should contain only Aplhabets";
    } else if (value.length > 20) {
      error = "Name Should not more than 20";
    } else if (value.length < 3) {
      error = "Name Should not less than 3 Characters";
    }
    return error;
  }

  // function validateEmail(value) {
  //   let error;
  //   if (!value) {
  //     error = "Email is Required";
  //   } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value)) {
  //     error = "Invalid email address";
  //   }
  //   return error;
  // }

  function validateMobileNumber(value) {
    let error;
    if (!value) {
      error = "Mobile Number is Required";
    } else if (value.length > 10) {
      error = "Mobile Number Should not more than 10";
    } else if (value.length < 10) {
      error = "Mobile Number Should not less than 10 ";
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
    ) {
      error = "Invalid Mobile Number...Enter Numeric";
    }
    return error;
  }

  // function validateCompanyName(value) {
  //   let error;
  //   if (!value) {
  //     error = "Company Name is Required";
  //   } else if (!/^[0-9a-zA-Z\s]+$/i.test(value)) {
  //     error = "Enter a Valid Company Name";
  //   } else if (value.length > 15) {
  //     error = "Company Name Should not more than 15";
  //   } else if (value.length < 3) {
  //     error = "Company Name Should not less than 3 Characters";
  //   }
  //   return error;
  // }

  // function validateExperience(value) {
  //   let error;
  //   if (!value) {
  //     error = "Experience is Required";
  //   } else if (!/^\(?([0-9]{1,2})$/i.test(value)) {
  //     error = "Enter a Valid Experience ";
  //   }
  //   return error;
  // }

  // function validateDesignation(value) {
  //   let error;
  //   if (!value) {
  //     error = "Designation is Required";
  //   } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
  //     error = "Enter a Valid Designation It should contain only Aplhabets";
  //   } else if (value.length > 15) {
  //     error = "Designation Should not more than 15";
  //   } else if (value.length < 3) {
  //     error = "Designation Should not less than 3 Characters";
  //   }
  //   return error;
  // }

  // function validatePartner(value) {
  //   let error;
  //   if (!value) {
  //     error = "Partner Type is Required";
  //   }
  //   return error;
  // }

  // function validateDOB(value) {
  //   let error;
  //   if (!value) {
  //     error = "Date of Birth is Required";
  //   }
  //   return error;
  // }

  // function validateDOJ(value) {
  //   let error;
  //   if (!value) {
  //     error = "Date of Joining is Required";
  //   }
  //   return error;
  // }

  // function validateLocation(value) {
  //   let error;
  //   if (!value) {
  //     error = "Location is Required";
  //   } else if (!/^[0-9a-zA-Z\s]+$/i.test(value)) {
  //     error = "Enter a Valid Location";
  //   } else if (value.length > 15) {
  //     error = "Location Should not more than 15";
  //   } else if (value.length < 3) {
  //     error = "Location Should not less than 3 Characters";
  //   }
  //   return error;
  // }

  return (
    <div>
      <div>
        <button
          className="add_btn"
          data-toggle="modal"
          data-target=".Add_Ref_popup"
        >
          <AddIcon className="search_icons" />
          Add Partner
        </button>
      </div>
      <Formik
        initialValues={{
          fullname: "",
          email: "",
          mobile: "",
          companyname: "",
          experience: "",
          designation: "",
          location: "",
          dob: "",
          doj: "",
          partnertype: RPpartnertype,
          Profile: "",
        }}
        // validationSchema={validate}
        onSubmit={(values) => {
          // console.log(values);
          updaterpdetails(values);
        }}
      >
        {(formik) => (
          <div>
            {/* {console.log(formik)} */}
            <Form>
              <div>
                <div
                  id="Add_Ref_popup"
                  className="modal fade Add_Ref_popup"
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div
                      className="modal-content modal_content_whole"
                      style={{ background: "white" }}
                    >
                      {/*  */}
                      <div className="modal-body">
                        <div className="pb-3">
                          <span className="add_hot_lead_label">
                            Add Referral Partner
                          </span>
                          <span
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            id="CloseAddHotLeadPopup"
                            style={{
                              background: "transparent",
                              float: "right",
                              color: "red",
                            }}
                          >
                            <span aria-hidden="true">X Close</span>
                          </span>
                        </div>
                        <div className="row">
                          <div className="col-md-5 col-5 d-flex align-items-center justify-content-center">
                            <FileTesting />
                          </div>
                          <div className="col-md-1 col-1 d-flex align-items-center justify-content-center flex-column">
                            <div
                              style={{
                                height: "45%",
                                border: "1px solid #dfdfdf",
                              }}
                            ></div>
                            <div>OR</div>
                            <div
                              style={{
                                height: "45%",
                                border: "1px solid #dfdfdf",
                              }}
                            ></div>
                          </div>
                          <div className="col-md-6 col-6">
                            {/* <AddReferalPopup /> */}
                            <div className="d-flex justify-content-between">
                              <div className="p-1" style={{ width: "100%" }}>
                                <div>
                                  <div className="p-3 add_hot_lead_content">
                                    <div className="row ">
                                      <div className="col-md-6">
                                        <div className="input-group_add_hot_lead ">
                                          <TextField
                                            type="text"
                                            // className="input-area_add_hot_lead"
                                            // required
                                            // id="inputName"
                                            name="fullname"
                                            label="Full Name"
                                            validate={validateName}
                                            // autoComplete="off"
                                            // value={formik.values.name}
                                            // onChange={formik.handleChange}
                                          />
                                          {/* <label
                              htmlFor="inputName"
                              className="label_add_hot_lead"
                            >
                              Name
                            </label> */}
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="input-group_add_hot_lead ">
                                          <TextField
                                            type="file"
                                            // className="input-area_add_hot_lead"
                                            // required
                                            // id="inputName"
                                            name="Profile"
                                            label="Photo"
                                            onChange={(e) =>
                                              changeprofilepic(e)
                                            }
                                            // autoComplete="off"
                                            // value={formik.values.name}
                                            // onChange={formik.handleChange}
                                          />
                                          {/* <label
                            htmlFor="inputName"
                            className="label_add_hot_lead"
                          >
                            Name
                          </label> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row pb-2">
                                      <div className="col-6 col-sm-6">
                                        <div className="input-group_add_hot_lead ">
                                          <TextField
                                            type="email"
                                            style={{
                                              textTransform: "lowercase",
                                            }}
                                            // className="input-area_add_hot_lead"
                                            // required
                                            // id="inputEmail"
                                            name="email"
                                            label="Email"
                                            // validate={validateEmail}
                                            // autoComplete="off"
                                          />
                                          {/* <label
                              htmlFor="inputEmail"
                              className="label_add_hot_lead"
                            >
                              Email
                            </label> */}
                                        </div>
                                      </div>

                                      <div className="col-6 col-sm-6">
                                        <div className="input-group_add_hot_lead ">
                                          <TextField
                                            type="tel"
                                            // className="input-area_add_hot_lead"
                                            // required
                                            // id="inputNuber"
                                            name="mobile"
                                            label="Phone Number"
                                            validate={validateMobileNumber}
                                            // autoComplete="off"
                                          />
                                          {/* <label
                              htmlFor="inputNumber"
                              className="label_add_hot_lead"
                            >
                              Phone Number
                            </label> */}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row pb-2">
                                      <div className="col-6 col-sm-6">
                                        <div className="input-group_add_hot_lead ">
                                          <TextField
                                            type="text"
                                            // className="input-area_add_hot_lead"
                                            // required
                                            // id="inputPlotSize"
                                            name="companyname"
                                            label="Company Name"
                                            // validate={validateCompanyName}
                                            // autoComplete="off"
                                          />
                                          {/* <label
                              htmlFor="inputPlotSize"
                              className="label_add_hot_lead"
                            >
                              Plot Size
                            </label> */}
                                        </div>
                                      </div>

                                      <div className="col-6 col-sm-6">
                                        <div className="input-group_add_hot_lead ">
                                          <TextField
                                            type="text"
                                            // className="input-area_add_hot_lead"
                                            // required
                                            // id="inputDevelopmentName"
                                            name="experience"
                                            label="Total Experience"
                                            // validate={validateExperience}
                                            // autoComplete="off"
                                          />
                                          {/* <label
                              htmlFor="inputDevelopmentName"
                              className="label_add_hot_lead"
                            >
                              Development Name
                            </label> */}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row pb-2">
                                      <div className="col-6 col-sm-6">
                                        <div className="input-group_add_hot_lead ">
                                          <TextField
                                            type="text"
                                            // className="input-area_add_hot_lead"
                                            // required
                                            // id="inputPlotSize"
                                            name="designation"
                                            label="Designation"
                                            // validate={validateDesignation}
                                            // autoComplete="off"
                                          />
                                          {/* <label
                              htmlFor="inputPlotSize"
                              className="label_add_hot_lead"
                            >
                              Plot Size
                            </label> */}
                                        </div>
                                      </div>

                                      <div className="col-6 col-sm-6">
                                        <div className="input-group_add_hot_lead">
                                          <TextField
                                            type="text"
                                            // className="input-area_add_hot_lead"
                                            // required
                                            // id="inputPlotSize"
                                            name="partnertype"
                                            label="Type of Partner"
                                            value={"RP"}
                                            // validate={validateDesignation}
                                            // autoComplete="off"
                                          />
                                          {/* <SelectField
                                            className="form-select"
                                            name="partnertype"
                                            label="Type Of Partner"
                                            // validate={validatePartner}
                                          >
                                            <option value="">Select</option>
                                            <option value="RP">RP</option>
                                            <option value="CP">CP</option>
                                          </SelectField> */}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6 col-sm-6">
                                        <div className="input-group_add_hot_lead ">
                                          <TextField
                                            type="date"
                                            // className="input-area_add_hot_lead"
                                            // required
                                            // id="inputLocation"
                                            name="dob"
                                            label="Date of Birth"
                                            // validate={validateDOB}
                                            // autoComplete="off"
                                          />
                                          {/* <label
                              htmlFor="inputLocation"
                              className="label_add_hot_lead"
                            >
                              Location
                            </label> */}
                                        </div>
                                      </div>

                                      <div className="col-6 col-sm-6">
                                        <div className="input-group_add_hot_lead ">
                                          <TextField
                                            type="date"
                                            // className="input-area_add_hot_lead"
                                            // required
                                            // id="inputConstructionDate"
                                            name="doj"
                                            // validate={validateDOJ}
                                            label="Joining Date"
                                            // autoComplete="off"
                                          />
                                          {/* <label
                              htmlFor="inputConstructionDate"
                              className="label_add_hot_lead"
                            >
                              Construction Start
                            </label> */}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-12 col-sm-12">
                                        <div className="input-group_add_hot_lead ">
                                          <TextField
                                            type="text"
                                            // className="input-area_add_hot_lead"
                                            // required
                                            // id="inputPlotSize"
                                            name="location"
                                            // validate={validateLocation}
                                            label="Work Location"
                                            // autoComplete="off"
                                          />
                                          {/* <label
                              htmlFor="inputPlotSize"
                              className="label_add_hot_lead"
                            >
                              Plot Size
                            </label> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <button
                                        className="btn_add_hot_lead"
                                        type="submit"
                                        // data-1toggle="modal"
                                        // data-target=".bd-lead-added-successfully"
                                        // data-toggle="modal"
                                        // data-target=".popup-add_hot-lead-added"
                                        // data-toggle="modal"
                                        // data-target="popup-add_hot-lead-added"
                                        // data-dismiss="modal"
                                        // onClick={updaterpdetails}
                                      >
                                        Add Partner
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            {/* 2nd Model */}

            <div
              id="PartnerAddedSuccessfully"
              className="modal fade bd-lead-added-successfully"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div
                  className="modal-content modal_content_whole"
                  style={{ background: "white" }}
                >
                  <div className="modal-body">
                    <div>
                      <div className="popup_icon_center">
                        <CheckCircleIcon className="popup_addbankdetails_icon" />
                      </div>
                      <div className="popup_text_center">
                        <span className="popup_addbankdetails_text">
                          Partner Added Successfully
                        </span>
                        <div className="pt-3 d-flex justify-content-center">
                          <div
                            style={{
                              width: "50%",
                              textAlign: "center",
                            }}
                          >
                            <button
                              // type="button"
                              className="popup_btn_linkaccount"
                              data-dismiss="modal"
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddReferalPopup;
