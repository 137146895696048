import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import IS_SideNav from "./IS_SideNav";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Footer from "../Dashboard/Footer";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const ISRPReferedPartnerdetails = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [show, toggleShow] = useState(false);
  const [showedit, toggleShowedit] = useState(false);
  const [showeditcmt, toggleShoweditcmt] = useState(false);

  const { Id } = useParams();

  let history = useHistory();

  // state to set all personal info values of partners
  const [refnames, Setrefnames] = useState([]);
  const [refjoiningdate, Setrefjoiningdate] = useState([]);
  const [refcompname, Setrefcompname] = useState([]);
  const [reftotalexp, Setreftotalexp] = useState([]);
  const [refdob, Setrefdob] = useState([]);
  const [refdesg, Setrefdesg] = useState([]);
  const [reftype, Setreftype] = useState([]);
  const [refworkloc, Setrefworkloc] = useState([]);

  // state to set contact info
  const [refemail, Setrefemail] = useState([]);
  const [refmobile, Setrefmobile] = useState([]);

  // Comments
  const [remark, Setremark] = useState("");
  const [partnerids, Setpartnerids] = useState("");

  useEffect(() => {
    const valid = localStorage.getItem("ISName");
    if (valid === null) {
      history.push("/");
    }

    getPartData();
  }, []);

  const getPartData = () => {
    axios.get(`${SERVER_URL}/getrefpartnerbyid?_id=${Id}`).then((reps) => {
      // Personal
      Setpartnerids(reps.data.Referral_partners[0].partner_id);
      Setrefnames(reps.data.Referral_partners[0].fullname);
      Setrefjoiningdate(reps.data.Referral_partners[0].doj);
      Setrefcompname(reps.data.Referral_partners[0].companyname);
      Setreftotalexp(reps.data.Referral_partners[0].experience);
      Setrefdob(reps.data.Referral_partners[0].dob);
      Setrefdesg(reps.data.Referral_partners[0].designation);
      Setreftype(reps.data.Referral_partners[0].partnertype);
      Setrefworkloc(reps.data.Referral_partners[0].location);

      // Contact info
      Setrefemail(reps.data.Referral_partners[0].email);
      Setrefmobile(reps.data.Referral_partners[0].contact_no);

      // Comments
      Setremark(reps.data.Referral_partners[0].comments);
    });
  };

  // To update the details
  const mydetails = (e) => {
    e.preventDefault();
    const upddata = {
      fullname: refnames,
      doj: refjoiningdate,
      companyname: refcompname,
      experience: reftotalexp,
      dob: refdob,
      designation: refdesg,
      partnertype: reftype,
      location: refworkloc,
      email: refemail,
      contact_no: refmobile,
      //   remarks:
      comments: remark,
    };
    // console.log("data", upddata);
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/updaterefpartner?_id=${Id}`, upddata, {
        //pure-wave-48602.herokuapp.com/updaterefpartner?_id=61bb2b3d9ac710448ad1126f
        headers,
      })
      .then((res) => {
        // console.log("Hello Don", res);
        let Status = res.data.status;
        if (Status === "success") {
          // alert("Profile Details are edited sucessfully");
          toast.success("Profile Details are edited successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getPartData();
          // window.setTimeout(function () {
          //   window.location.reload();
          // }, 100);
        } else if (Status === "failed") {
          // alert("Profile Details are already exists");
          toast.error("Profile Details are Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Some Internal Error", err);
      });
  };

  return (
    <div>
      <div>
        <IS_SideNav />
      </div>

      <div className="main">
        <div className="content-tabs">
          <div>
            <div className="row top_menu_bar">
              <div className="col-md-8 d-flex align-items-center">
                {/* <Link
                    to={`/isrpreferedpartner/${partnerids}`}
                    className="partner_back_btn"
                  >
                    <span>
                      <ArrowBackIosNewIcon />
                      Back
                    </span>
                  </Link> */}

                <Link
                  to={`/isrpreferedpartner/${partnerids}`}
                  className="partner_back_btn"
                >
                  <div>
                    <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                    <span>Back</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="partner_details_edit_sec">
              <form>
                <div className="row">
                  <div className="col-md-8">
                    <div className="p-3 admin_patrner_personal_info">
                      <div>
                        <p className="head_txt head_txt_margin">
                          Partner Personal Info
                        </p>
                        {/* Toggle State to edit and save start */}
                        {/* <div
                            className="edit_icon"
                            onClick={() => toggleShow(!show)}
                          >
                            {!show && (
                              <div>
                                <EditIcon className="search_icons" />
                                <span> Edit</span>
                              </div>
                            )}
                            {show && (
                              <div>
                                <button
                                  type="submit"
                                  className="edit_icon"
                                  onClick={mydetails}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div> */}
                        {/* Toggle State to edit and save end */}
                      </div>
                      {!show && (
                        <div className="viewing_details">
                          <div className="row">
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Full Name
                                </p>
                                <span className="admin_type_value">
                                  {refnames === "" ? "-" : refnames}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Joining Date
                                </p>
                                <span className="admin_type_value">
                                  {refjoiningdate === "" ? "-" : refjoiningdate}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Company Name
                                </p>
                                <span className="admin_type_value">
                                  {refcompname === "" ? "-" : refcompname}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Total Experience
                                </p>
                                <span className="admin_type_value">
                                  {reftotalexp === "" ? "-" : reftotalexp}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Date of Birth
                                </p>
                                <span className="admin_type_value">
                                  {refdob === "" ? "-" : refdob}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Designation
                                </p>
                                <span className="admin_type_value">
                                  {refdesg === "" ? "-" : refdesg}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Type of Partner
                                </p>
                                <span className="admin_type_value">
                                  {reftype === "" ? "-" : reftype}
                                </span>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Work Location
                                </p>
                                <span className="admin_type_value">
                                  {refworkloc === "" ? "-" : refworkloc}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* TO EDIT START */}
                      {/* {show && (
                          <div className="edit_details">
                            <div className="row">
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="inputName" className="label">
                                    Full Name
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="inputName"
                                    name="name"
                                    value={refnames}
                                    onChange={(e) => {
                                      Setrefnames(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="date" className="label">
                                    Joining Date
                                  </label>
                                  <input
                                    type="date"
                                    className="input-area admin_partner_det_input"
                                    name="joining_date"
                                    value={refjoiningdate}
                                    onChange={(e) => {
                                      Setrefjoiningdate(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="comp" className="label">
                                    Company Name
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="comp"
                                    name="company_name"
                                    value={refcompname}
                                    onChange={(e) => {
                                      Setrefcompname(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="exp" className="label">
                                    Total Experience
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="exp"
                                    name="total_expernice"
                                    value={reftotalexp}
                                    onChange={(e) => {
                                      Setreftotalexp(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="dob" className="label">
                                    Date of Birth
                                  </label>
                                  <input
                                    type="date"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="dob"
                                    name="dob"
                                    defaultValue={refdob}
                                    onChange={(e) => {
                                      Setrefdob(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="des" className="label">
                                    Designation
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="des"
                                    name="designation"
                                    value={refdesg}
                                    onChange={(e) => {
                                      Setrefdesg(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="type" className="label">
                                    Type of Partner
                                  </label>
                                  <select
                                    className="input-area admin_partner_det_input"
                                    defaultValue={"Select"}
                                    value={reftype}
                                    onChange={(e) => {
                                      Setreftype(e.target.value);
                                    }}
                                  >
                                    <option selected value="">
                                      Select
                                    </option>
                                    <option value="RP">RP</option>
                                    <option value="CP">CP</option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-6 col-md-3">
                                <div className="input-group-custom">
                                  <label htmlFor="loc" className="label">
                                    Work Location
                                  </label>
                                  <input
                                    type="text"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="loc"
                                    name="work_location"
                                    value={refworkloc}
                                    onChange={(e) => {
                                      Setrefworkloc(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                      {/* TO EDIT END */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="p-3 admin_patrner_personal_info">
                      <div>
                        <p className="head_txt head_txt_margin">
                          Partner Contact Info
                        </p>
                        {/* Toggle State to edit and save start */}
                        {/* <div
                            className="edit_icon"
                            onClick={() => toggleShowedit(!showedit)}
                          >
                            {!showedit && (
                              <div>
                                <EditIcon className="search_icons" />
                                <span> Edit</span>
                              </div>
                            )}
                            {showedit && (
                              <div>
                                <button
                                  type="submit"
                                  className="edit_icon"
                                  onClick={mydetails}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div> */}
                        {/* Toggle State to edit and save end */}
                      </div>
                      {!showedit && (
                        <div className="contact_info_">
                          <div>
                            <p className="m-0 admin_type_heading">Email</p>
                            <span className="admin_type_value">
                              {refemail === "" ? "-" : refemail}
                            </span>
                          </div>
                          <div className="ml-4 mt-3">
                            <p className="m-0 admin_type_heading">Mobile No</p>
                            <span className="admin_type_value">
                              {refmobile === "" ? "-" : refmobile}
                            </span>
                          </div>
                        </div>
                      )}
                      {/* TO EDIT START */}
                      {/* {showedit && (
                          <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                            <div className="input-group-custom">
                              <label htmlFor="loc" className="label">
                                Email
                              </label>
                              <br />
                              <input
                                type="email"
                                className="input-area"
                                required
                                id="email"
                                name="email"
                                value={refemail}
                                onChange={(e) => {
                                  Setrefemail(e.target.value);
                                }}
                              />
                            </div>
                            <div className="input-group-custom mt-3">
                              <label htmlFor="mob" className="label">
                                Mobile No
                              </label>
                              <br />
                              <input
                                type="text"
                                className="input-area"
                                required
                                id="mob"
                                name="contact_no"
                                value={refmobile}
                                onChange={(e) => {
                                  Setrefmobile(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        )} */}
                      {/* TO EDIT END */}
                    </div>
                  </div>
                  <div className="col-md-4 mt-3">
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Comments</p>
                        <div
                          className="edit_icon"
                          onClick={() => toggleShoweditcmt(!showeditcmt)}
                        >
                          {!showeditcmt && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span> Edit</span>
                            </div>
                          )}
                          {showeditcmt && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={mydetails}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!showeditcmt && (
                        <div className="contact_info_">
                          <div>
                            <span
                              className="admin_type_value"
                              style={{
                                fontSize: "13px",
                                lineHeight: "0.1rem",
                              }}
                            >
                              {remark === "" ? "-" : remark}
                            </span>
                          </div>
                        </div>
                      )}
                      {showeditcmt && (
                        <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                          <div className="input-group-custom">
                            {/* <label htmlFor="loc" className="label">
                                Comments
                              </label>
                              <br /> */}

                            <textarea
                              className="input-area"
                              width="100%"
                              rows="5"
                              cols="20"
                              name="email"
                              value={remark}
                              onChange={(e) => {
                                Setremark(e.target.value);
                              }}
                            ></textarea>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default ISRPReferedPartnerdetails;
