import React, { useEffect, useState } from "react";
import AdminNav from "../AdminNavBar/AdminNav";
import axios from "axios";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { CgArrowRightR } from "react-icons/cg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { SelectField } from "../Fields/SelectField";
import Footer from "../Dashboard/Footer";
// rpreferedrp popup
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Formik, Form, Field, useField } from "formik";
import { TextField } from "../Fields/TextField";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Helpers/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function ReferedLeads() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [leaddata, Setleaddata] = useState([]);
  const [isloading, setisloading] = useState(true);
  const { Id } = useParams();
  const { Number } = useParams();
  const { PageNo } = useParams();

  // Paganiation
  const { SearchBar } = Search;
  const [Page, setPage] = useState(PageNo ? PageNo : 1);
  const [PerPage, setPerPage] = useState(50);
  const [searchcount, setsearchcount] = useState([]);
  const [enabledelete, setenabledelete] = useState(false);
  const [SelectedId, setSelectedId] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  let history = useHistory();
  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }

    getrps();
  }, []);

  const getrps = () => {
    axios.get(`${SERVER_URL}/getrefpartner?_id=${Id}`).then((respon) => {
      Setleaddata(respon.data.Referral_partners);
      setisloading(false);
      // console.log("lead data", respon);
    });
  };

  const selectedRows = leaddata.filter((row) => row.isSelect);
  // console.log("selected", selectedRows);
  const PartnerSelectedId = selectedRows.map((row) => row._id);
  // console.log("select", PartnerSelectedId);

  const deletemultiplerp = async () => {
    // alert("for del");
    let arrayids = [];
    arrayids = PartnerSelectedId.length === 0 ? SelectedId : PartnerSelectedId;
    // await leaddata.forEach((d) => {
    //   if (d.select) {
    //     arrayids.push(d._id);
    //   }
    // });
    // console.log("for del", arrayids);
    axios
      .post(`${SERVER_URL}/deletemultiplereferrals`, {
        arrayids,
      })
      .then((resultdeleted) => {
        // console.log("FSE deleted", resultdeleted);
        let Status = resultdeleted.data.status;
        if (Status === "success") {
          window.$("#deletePartner").modal("hide");
          window.$("#PartnerDeletedSuccessfully").modal("show");
          // window.setTimeout(function () {
          //   $(".modal-backdrop").remove();
          //   history.push("/rplist");
          // }, 3000);
        } else {
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        getrps();
      })
      .catch((err) => alert(err));
  };

  // rpreferedrp start
  const [leadprofile, Setleadprofile] = useState(null);
  const [bookamt, Setbookamt] = useState("");

  const changeprofilepic = (e) => {
    Setleadprofile(e.target.files[0]);
    console.log("custprofileee", e.target.files[0]);
  };

  // function validatePhoto(value) {
  //   let error;
  //   if (leadprofile === null) {
  //     error = "Profile Image is Required";
  //   }
  //   return error;
  // }

  // Validations Start
  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is Required";
    } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
      error = "Enter a Valid Name It should contain only Aplhabets";
    } else if (value.length > 20) {
      error = "Name Should not more than 20";
    } else if (value.length < 3) {
      error = "Name Should not less than 3 Characters";
    }
    return error;
  }

  // function validateEmail(value) {
  //   let error;
  //   if (!value) {
  //     error = "Email is Required";
  //   } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value)) {
  //     error = "Invalid email address";
  //   }
  //   return error;
  // }

  function validateMobileNumber(value) {
    let error;
    if (!value) {
      error = "Mobile Number is Required";
    } else if (value.length > 10) {
      error = "Mobile Number Should not more than 10";
    } else if (value.length < 10) {
      error = "Mobile Number Should not less than 10 ";
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
    ) {
      error = "Invalid Mobile Number...Enter Numeric";
    }
    return error;
  }

  function validatePartner(value) {
    let error;
    if (!value) {
      error = "Partner Type is Required";
    }
    return error;
  }

  // function validateCompanyName(value) {
  //   let error;
  //   if (!value) {
  //     error = "Company Name is Required";
  //   } else if (!/^[0-9a-zA-Z\s]+$/i.test(value)) {
  //     error = "Enter a Valid Company Name";
  //   } else if (value.length > 15) {
  //     error = "Company Name Should not more than 15";
  //   } else if (value.length < 3) {
  //     error = "Company Name Should not less than 3 Characters";
  //   }
  //   return error;
  // }

  // function validateExperience(value) {
  //   let error;
  //   if (!value) {
  //     error = "Experience is Required";
  //   } else if (!/^\(?([0-9]{1,2})$/i.test(value)) {
  //     error = "Enter a Valid Experience ";
  //   }
  //   return error;
  // }

  // function validateDesignation(value) {
  //   let error;
  //   if (!value) {
  //     error = "Designation is Required";
  //   } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
  //     error = "Enter a Valid Designation It should contain only Aplhabets";
  //   } else if (value.length > 25) {
  //     error = "Designation Should not more than 25";
  //   } else if (value.length < 3) {
  //     error = "Designation Should not less than 3 Characters";
  //   }
  //   return error;
  // }

  // function validatePartner(value) {
  //   let error;
  //   if (!value) {
  //     error = "Partner Type is Required";
  //   }
  //   return error;
  // }

  // function validateDOB(value) {
  //   let error;
  //   if (!value) {
  //     error = "Date of Birth is Required";
  //   }
  //   return error;
  // }

  // function validateLocation(value) {
  //   let error;
  //   if (!value) {
  //     error = "Location is Required";
  //   } else if (!/^[0-9a-zA-Z\s]+$/i.test(value)) {
  //     error = "Enter a Valid Location";
  //   } else if (value.length > 15) {
  //     error = "Location Should not more than 15";
  //   } else if (value.length < 3) {
  //     error = "Location Should not less than 3 Characters";
  //   }
  //   return error;
  // }

  // Validation End
  const addleaddata = (data, resetForm) => {
    const formdata = new FormData();
    // formdata.append("packagename", data.packagename);
    formdata.append("fullname", data.fullname);
    formdata.append("email", data.email);
    formdata.append("contact_no", data.contact_no);
    formdata.append("companyname", data.companyname);
    formdata.append("experience", data.experience);
    formdata.append("designation", data.designation);
    formdata.append("location", data.location);
    formdata.append("partnertype", data.partnertype);
    formdata.append("dob", data.dob);
    formdata.append("doj", data.doj);
    formdata.append("profile_pic", leadprofile);
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/addreferralpartner?_id=${Id}`, formdata, {
        headers,
      })
      .then((res) => {
        // console.log(res);
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#bd-example-modal-lg_ref3").modal("hide");
          window.$("#PartnerAddedSuccessfully").modal("show");
          resetForm({ data: "" });
          getrps();
        } else if (Status === "failed") {
          // alert(" Details are already Exists");
          toast.error("Partner Details are Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  // rpreferedrp csv

  // New Pagination
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const selectRow = {
    mode: "checkbox",
    // onSelectAll: onSelectAll,

    // clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      Setleaddata((rows) => {
        return rows.map((r) => {
          if (r._id !== row._id) {
            return r;
          }
          return { ...r, isSelect };
        });
      });
    },

    onSelectAll: (isSelect, rows, e) => {
      let array = [];
      for (let i = 0; i < rows.length; i++) {
        array.push(rows[i]._id);
        console.log("arra", array);
        if (array.length > 0) {
          setenabledelete(true);
        } else {
          setenabledelete(false);
        }
        setSelectedId(array);
      }
      // selecting all data
      // Setleaddata((row) => {
      //   return row.map((rows) => {
      //     return { ...rows, isSelect };
      //   });
      // });
    },
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "fullname",
      text: "Partner Name",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "contact_no",
      text: "Mobile No.",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "referral_ID",
      text: "Partner ID",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell) => (
        <Link
          to={`/rpreferedrpdetails/${cell}/${Number}/${Page}`}
          // target="_blank" // remove afterwards
          className="viewmore"
        >
          <CgArrowRightR />
        </Link>
      ),
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log("row", row);
      // old
      history.push(`/rpreferedrpdetails/${row._id}/${Number}/${Page}`);
      //new
      // window.open(`/rpreferedrpdetails/${row._id}/${Number}/${Page}`, "_blank");
    },
  };

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    page: parseInt(Page),
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    // remove these two
    sizePerPage: 50,
    hideSizePerPage: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ],
  };

  return (
    <div>
      {isloading === false ? (
        <div>
          <div>
            <AdminNav />
          </div>
          <div className="main">
            <div className="content-tabs">
              <ToolkitProvider
                keyField="_id"
                data={leaddata}
                columns={columns}
                search={{ afterSearch }}
              >
                {(props) => (
                  <div>
                    <div className="top_menu_bar">
                      <div>
                        <Link
                          to={`/rpdetails/${Id}/${Number}`}
                          className="partner_back_btn"
                        >
                          <div>
                            <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                            <span>Back</span>
                          </div>
                        </Link>
                      </div>

                      <div>
                        <div className="d-flex justify-content-around">
                          <div className="search_div" style={{ width: "37%" }}>
                            <SearchIcon />
                            <SearchBar
                              {...props.searchProps}
                              className="top_menu_search"
                              placeholder="Search Here..."
                            />
                            {/* <input
                              type="text"
                              className="top_menu_search"
                              placeholder="Search Here.."
                              onChange={(event) => {
                                Setsearchterm(event.target.value);
                              }}
                            /> */}
                          </div>

                          <div>
                            {/* <AddRpLeadPopup ids={Id} /> */}
                            {/* Rpreferedrp pop up start */}

                            <button
                              className="add_btn"
                              data-toggle="modal"
                              data-target=".bd-example-modal-lg_ref3"
                            >
                              <AddIcon className="search_icons" />
                              Add Partner
                            </button>

                            {/* Rpreferedrp pop up end */}
                          </div>

                          <button
                            className="delete_btn"
                            data-toggle="modal"
                            data-target=".exampleModal"
                          >
                            <DeleteIcon className="del_icons" />
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="table_div">
                        {leaddata != "" ? (
                          <div>
                            <BootstrapTable
                              // ref="table"
                              {...props.baseProps}
                              selectRow={selectRow}
                              noDataIndication={emptyDataMessage}
                              pagination={paginationFactory(paginationoptions)}
                              filter={filterFactory()}
                              rowEvents={rowEvents}
                              wrapperClasses="table-responsive"
                            />
                            {/* <table className="table report_table leaderboard_table">
                    </table> */}
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div>
                              <WarningRoundedIcon className="no_data_alert" />
                            </div>
                            <div>
                              <h3>No records found for Customers</h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>

          {/* Add Pop Up popup */}
          <div
            id="bd-example-modal-lg_ref3"
            className="modal fade bd-example-modal-lg_ref3"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-dialog-centered ">
              <div
                className="modal-content modal_content_whole"
                style={{ background: "white" }}
              >
                <div className="modal-body">
                  <div className="pb-2">
                    <span className="add_hot_lead_label">
                      Add Referral Partner
                    </span>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="CloseAddHotLeadPopup"
                      style={{
                        background: "transparent",
                        float: "right",
                        color: "red",
                        border: "none",
                      }}
                    >
                      <span aria-hidden="true">X Close</span>
                    </button>
                  </div>

                  {/* <div className="row">
                                          <div className="col-md-5 col-5 d-flex align-items-center justify-content-center">
                                            <AddCustomerCsv Id={Id} />
                                          </div>
                                          <div className="col-md-1 col-1 d-flex align-items-center justify-content-center flex-column">
                                            <div
                                              style={{
                                                height: "200px",
                                                border: "1px solid #969696",
                                              }}
                                            ></div>
                                            <div>OR</div>
                                            <div
                                              style={{
                                                height: "200px",
                                                border: "1px solid #969696",
                                              }}
                                            ></div>
                                          </div>
                                          <div className="col-6 col-md-6"> */}
                  <Formik
                    initialValues={{
                      fullname: "",
                      email: "",
                      contact_no: "",
                      companyname: "",
                      experience: "",
                      designation: "",
                      location: "",
                      dob: "",
                      doj: "",
                      partnertype: "",
                      Profile: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      // Setbookamt(values.booking_amt);
                      addleaddata(values, resetForm);
                    }}
                  >
                    {(formik) => (
                      <div>
                        <Form>
                          <div>
                            {/* <AddRpLeadPopup ids={Id} /> */}
                            <div>
                              <div className="d-flex justify-content-between">
                                <div
                                  className="p-3"
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div>
                                    <div className="p-3 add_hot_lead_content">
                                      <div className="row ">
                                        <div className="col-md-12">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="text"
                                              name="fullname"
                                              label="Full Name"
                                              placeholder="Enter Name"
                                              validate={validateName}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row pb-3">
                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="email"
                                              name="email"
                                              label="Email"
                                              placeholder="Enter Email"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="tel"
                                              name="contact_no"
                                              label="Phone Number"
                                              validate={validateMobileNumber}
                                              placeholder="Enter Mobile Number"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="text"
                                              name="companyname"
                                              label="Company Name"
                                              placeholder="Enter Company Name"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="number"
                                              name="experience"
                                              label="Total Experience"
                                              placeholder="Enter Experience"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row pb-2">
                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="text"
                                              name="designation"
                                              label="Designation"
                                              placeholder="Enter Designation"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead">
                                            <SelectField
                                              // className="form-select"
                                              name="partnertype"
                                              label="Type Of Partner"
                                              validate={validatePartner}
                                            >
                                              <option
                                                selected
                                                disabled
                                                value=""
                                              >
                                                Select
                                              </option>
                                              <option value="RP">RP</option>
                                              <option value="CP">CP</option>
                                            </SelectField>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-12 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="date"
                                              name="dob"
                                              label="Date of Birth"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-6 col-sm-6">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="date"
                                              name="doj"
                                              label="Joining Date"
                                            />
                                          </div>
                                        </div>
                                        {/* <div className="col-6 col-sm-6">
                                                        <div className="input-group_add_hot_lead ">
                                                          <TextField
                                                            type="file"
                                                            name="profile_pic"
                                                            label="Select Profile Pic"
                                                            onChange={(e) =>
                                                              changeprofilepic(
                                                                e
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div> */}
                                      </div>

                                      <div className="row mt-2">
                                        <div className="col-12 col-sm-12">
                                          <div className="input-group_add_hot_lead ">
                                            <TextField
                                              type="text"
                                              name="location"
                                              label="Location"
                                              placeholder="Enter Location"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div>
                                        <button
                                          className="btn_add_hot_lead"
                                          type="submit"
                                        >
                                          Add Partner
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>

          {/* Delete Confirmation popup */}
          <div
            id="deletePartner"
            className="modal fade exampleModal"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm modal-dialog-centered ">
              <div
                className="modal-content modal-sm modal_content_whole"
                style={{ background: "white" }}
              >
                <div className="modal-body">
                  <div>
                    <div className="popup_icon_center">
                      <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                    </div>
                    <div className="pt-2 popup_text_center">
                      <span className="popup_addbankdetails_text">
                        Are you sure want to Delete this Partners ?
                      </span>
                    </div>
                    <div className="pt-4 m-0 d-flex justify-content-center">
                      <div
                        style={{
                          width: "50%",
                          textAlign: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="popup_btn_unlinkaccount"
                          // onClick={deletebankdetails}
                          // data-toggle="modal"
                          // data-target=".popup-bank-details-deleted"
                          // data-dismiss="modal"
                          onClick={() => {
                            deletemultiplerp();
                          }}
                        >
                          Delete
                        </button>
                      </div>

                      <div
                        style={{
                          width: "50%",
                          textAlign: "center",
                        }}
                      >
                        <button
                          // type="button"
                          className="popup_btn_close"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Successfully Deleted */}
          <div
            id="PartnerDeletedSuccessfully"
            className="modal fade bd-lead-added-successfully"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm modal-dialog-centered">
              <div
                className="modal-content modal-sm modal_content_whole"
                style={{ background: "white" }}
              >
                <div className="modal-body">
                  <div>
                    <div className="popup_icon_center">
                      <CheckCircleIcon className="popup_addbankdetails_icon" />
                    </div>
                    <div className="pt-2 popup_text_center">
                      <span className="popup_addbankdetails_text">
                        Partner Deleted Successfully
                      </span>
                      <div className="pt-4 d-flex justify-content-center">
                        <div
                          style={{
                            width: "50%",
                            textAlign: "center",
                          }}
                        >
                          <button
                            // type="button"
                            className="popup_btn_linkaccount"
                            data-dismiss="modal"
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Successfully Added */}
          <div
            id="PartnerAddedSuccessfully"
            className="modal fade bd-lead-added-successfully"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm modal-dialog-centered">
              <div
                className="modal-content modal-sm modal_content_whole"
                style={{ background: "white" }}
              >
                <div className="modal-body">
                  <div>
                    <div className="popup_icon_center">
                      <CheckCircleIcon className="popup_addbankdetails_icon" />
                    </div>
                    <div className="popup_text_center">
                      <p className="pt-2 popup_addbankdetails_text">
                        Partner Added Successfully
                      </p>
                      <div className="pt-2 m-0 d-flex justify-content-center">
                        <div
                          style={{
                            width: "50%",
                            textAlign: "center",
                          }}
                        >
                          <button
                            // type="button"
                            className="popup_btn_linkaccount"
                            data-dismiss="modal"
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
}
