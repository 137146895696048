import React, { useState, useEffect } from "react";
import AdminNav from "../AdminNavBar/AdminNav";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Footer from "../Dashboard/Footer";
// Pagination
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Helpers/Loader";
import { toast } from "react-toastify";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const DMPartnerList = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [Period, setPeriod] = useState("day");
  const [userdata, Setuserdata] = useState([]);
  let history = useHistory();
  const [assignOption, setassignOption] = useState([]);
  const [isloading, setisloading] = useState(true);
  // Paganiation
  const { SearchBar } = Search;
  const [Page, setPage] = useState(1);
  const [PerPage, setPerPage] = useState(50);
  const [searchcount, setsearchcount] = useState([]);
  const [enabledelete, setenabledelete] = useState(false);
  const [SelectedId, setSelectedId] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }

    getUsers();

    // To Fetch Assign To Options DropDown
    axios.get(`${SERVER_URL}/csnames`).then((response) => {
      //   console.log("Cust", response);
      setassignOption(response.data.Assignees);
    });
  }, [Period]);

  const getUsers = () => {
    axios
      .get(`${SERVER_URL}/dmfilters?filterValue=${Period}`)
      .then((response) => {
        // console.log("res", response);
        setisloading(false);
        Setuserdata(response.data.partners);
      });
  };

  const selectedRows = userdata.filter((row) => row.isSelect);
  // console.log("selected", selectedRows);
  const PartnerSelectedId = selectedRows.map((row) => row._id);
  // console.log("select", PartnerSelectedId);

  const deletemultipleuserrecords = () => {
    let ids = [];
    ids = PartnerSelectedId.length === 0 ? SelectedId : PartnerSelectedId;
    // userdata.forEach((de) => {
    //   if (de.select) {
    //     ids.push(de._id);
    //   }
    // });

    axios
      .post(`${SERVER_URL}/deletedmpartner`, {
        ids,
      })
      .then((resultdeleteds) => {
        // console.log("FSE deleted", resultdeleteds);
        let Status = resultdeleteds.data.status;
        if (Status === "success") {
          window.$("#MultipleDelete").modal("hide");
          window.$("#SuccessfullyDeleted").modal("show");
          getUsers();
        } else {
          // alert("Internal Server Error");
          toast.error("Internal Server Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => alert(err));
  };

  // For Assigning To
  const setassignes = (e, ids) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };

    // SetVerifystat(e.target.value);
    let selectt = e.target.value;
    const statverified = {
      assignee: selectt,
    };

    // assigncs?_id=622c7b69fd029bd2d20687fd
    axios
      .post(`${SERVER_URL}/assigncs?_id=${ids}`, statverified, {
        headers,
      })
      .then((resultsverified) => {
        // console.log("verified orr", resultsverified);
        let Status = resultsverified.data.status;
        if (Status === "success") {
          // alert("Partner Assigned Successfully");
          toast.success("Partner Assigned Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // alert("Error");
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  // New Pagination
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const assignCS = (cell, row) => {
    return (
      <select
        style={{ width: "100%" }}
        className="p-2 select_dropdown_assign_to"
        onChange={(e) => {
          setassignes(e, row._id);
        }}
      >
        <option value="">{row.assigned_to ? row.assigned_to : "Select"}</option>

        {assignOption != ""
          ? assignOption.map((item, i) => {
              if (item.Name != row.assigned_to) {
                return (
                  <option key={i} value={item.Name}>
                    {item.Name}
                  </option>
                );
              }
            })
          : "No Data"}
      </select>
    );
  };

  const selectRow = {
    mode: "checkbox",
    // onSelectAll: onSelectAll,

    // clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      Setuserdata((rows) => {
        return rows.map((r) => {
          if (r._id !== row._id) {
            return r;
          }
          return { ...r, isSelect };
        });
      });
    },

    onSelectAll: (isSelect, rows, e) => {
      let array = [];
      for (let i = 0; i < rows.length; i++) {
        array.push(rows[i]._id);
        console.log("arra", array);
        if (array.length > 0) {
          setenabledelete(true);
        } else {
          setenabledelete(false);
        }
        setSelectedId(array);
      }
      // selecting all data
      // Setleaddata((row) => {
      //   return row.map((rows) => {
      //     return { ...rows, isSelect };
      //   });
      // });
    },
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "fullname",
      text: "Partner Name",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "creationdate",
      text: "Creation Date",
      formatter: (cell) =>
        !cell ? "-" : cell.slice(0, 10).split("-").reverse().join("-"),
    },
    {
      dataField: "assigned_to",
      text: "Assign To LGS",
      formatter: assignCS,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    // remove these two
    sizePerPage: 50,
    hideSizePerPage: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ],
  };

  return (
    <div>
      {isloading === false ? (
        <div>
          <div>
            <AdminNav />
          </div>
          <div className="main">
            <div className="content-tabs">
              <ToolkitProvider
                keyField="_id"
                data={userdata}
                columns={columns}
                search={{ afterSearch }}
              >
                {(props) => (
                  <div>
                    <div className="top_menu_bar">
                      <div>
                        <div>
                          <span className="CS_head_txt">
                            Partners From Digital Marketing
                          </span>
                        </div>
                        <span className="total_count_txt">
                          (
                          {searchcount === 0
                            ? searchcount
                            : searchcount > 0
                            ? searchcount
                            : userdata.length}{" "}
                          partners)
                        </span>
                      </div>
                      <div>
                        <div
                          className="d-flex justify-content-end"
                          style={{ gap: "15px" }}
                        >
                          <div className="search_div" style={{ width: "50%" }}>
                            <SearchIcon />
                            <SearchBar
                              {...props.searchProps}
                              className="top_menu_search"
                              placeholder="Search Here..."
                            />
                            {/* <input
                              type="text"
                              className="top_menu_search"
                              placeholder="Search Here.."
                              onChange={(event) => {
                                Setsearchterm(event.target.value);
                              }}
                            /> */}
                          </div>

                          <div>
                            <div>
                              <select
                                onChange={(e) => setPeriod(e.target.value)}
                                className="add_btn form-select"
                                defaultValue={"day"}
                                style={{ width: "120px" }}
                              >
                                <option
                                  value="day"
                                  // onClick={newperiod()}
                                  onChange={(e) => setPeriod("day")}
                                >
                                  Today
                                </option>
                                <option
                                  value="week"
                                  onChange={(e) => setPeriod("week")}
                                >
                                  This Week
                                </option>
                                <option
                                  value="month"
                                  onChange={(e) => setPeriod("month")}
                                >
                                  This Month
                                </option>
                                <option
                                  value="quarter"
                                  onChange={(e) => setPeriod("quarter")}
                                >
                                  3 Months
                                </option>
                                <option
                                  value="year"
                                  onChange={(e) => setPeriod("year")}
                                >
                                  This Year
                                </option>
                              </select>
                            </div>
                          </div>

                          <button
                            className="add_btn"
                            data-toggle="modal"
                            data-target="#MultipleDelete"
                            style={{ padding: "7px" }}
                          >
                            <DeleteIcon className="del_icons" />
                          </button>

                          <div
                            id="MultipleDelete"
                            className="modal fade exampleModal"
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-sm modal-dialog-centered ">
                              <div
                                className="modal-content modal-sm modal_content_whole"
                                style={{ background: "white" }}
                              >
                                <div className="modal-body">
                                  <div>
                                    <div className="popup_icon_center">
                                      <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                                    </div>
                                    <div className="popup_text_center">
                                      <span className="popup_addbankdetails_text">
                                        Are you sure want to Delete{" "}
                                        {PartnerSelectedId.length !== 0
                                          ? PartnerSelectedId.length
                                          : SelectedId.length !== 0
                                          ? SelectedId.length
                                          : ""}
                                        Partners ?
                                      </span>
                                    </div>
                                    <div className="pt-4 m-0 d-flex justify-content-center">
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="popup_btn_unlinkaccount"
                                          onClick={() => {
                                            deletemultipleuserrecords();
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <button
                                          // type="button"
                                          className="popup_btn_close"
                                          data-dismiss="modal"
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Sucuesfully deleted */}
                          <div
                            className="modal fade popup-bank-details-deleted"
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                            id="SuccessfullyDeleted"
                          >
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                              <div
                                className="modal-content modal-sm modal_content_whole"
                                style={{ background: "white" }}
                              >
                                <div className="modal-body">
                                  <div>
                                    <div className="popup_icon_center">
                                      <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                                    </div>
                                    <div className="pt-2 popup_text_center">
                                      <span className="popup_addbankdetails_text">
                                        Partners Deleted Successfully
                                      </span>
                                      <div className="pt-2 m-0 d-flex justify-content-center">
                                        <div
                                          style={{
                                            width: "50%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <button
                                            // type="button"
                                            className="popup_btn_linkaccount"
                                            data-dismiss="modal"
                                          >
                                            OK
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="table_div">
                        {userdata != "" ? (
                          <div>
                            <BootstrapTable
                              {...props.baseProps}
                              selectRow={selectRow}
                              noDataIndication={emptyDataMessage}
                              pagination={paginationFactory(paginationoptions)}
                              filter={filterFactory()}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div>
                              <WarningRoundedIcon className="no_data_alert" />
                            </div>
                            <div>
                              <h3>No records found for Customers</h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default DMPartnerList;
