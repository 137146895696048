import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import "./settings.css";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import Popup from "reactjs-popup";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "../../Fields/TextField";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
import EditPackage from "./EditPackage";
import EditDocument from "./EditDocument";
export default function PackSettings() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [packagedoc, Setpackagedoc] = useState(null);
  const [doc, Setdoc] = useState(null);
  const [doc1, Setdoc1] = useState(null);
  const [packagelist, Setpackagelist] = useState([]);
  const [documentlist, Setdocumentlist] = useState([]);
  const [fileval, Setfileval] = useState(false);
  const [fileval1, Setfileval1] = useState(false);
  const closepop = () => {
    document.getElementById("closematerialpopup2").click();
  };

  console.log("docs", doc);
  useEffect(() => {
    getpackagesfunc();
    getalldocsfunc();
  }, []);

  // to get all packages
  const getpackagesfunc = () => {
    axios.get(`${SERVER_URL}/getpackages`).then((resultant) => {
      console.log("Packages aray", resultant.data.result);
      Setpackagelist(resultant.data.result);
    });
  };

  //to get all documents
  const getalldocsfunc = () => {
    axios.get(`${SERVER_URL}/getdocuments`).then((res) => {
      console.log("All docs array", res.data.result);
      Setdocumentlist(res.data.result);
    });
  };

  //   validations
  function validatePhoto(value) {
    let error;
    if (doc === null) {
      error = "Document is Required";
    }
    return error;
  }
  function validatepack(e) {
    let error;
    if (packagedoc === null) {
      error = "Document is Required";
    }
    return error;
  }
  const changeprofilepic = (e) => {
    Setpackagedoc(e.target.files[0]);
  };

  const changedocument = (e) => {
    Setdoc1(e.target.files[0]);
  };

  function validateDocumentname(value) {
    let error;
    if (!value) {
      error = "Document name is Required";
    }
    return error;
  }
  function validatePackagename(value) {
    let error;
    if (!value) {
      error = "Package name is Required";
    }
    return error;
  }

  function validatePrice(value) {
    let error;
    if (!value) {
      error = "Price is Required";
    }
    return error;
  }

  function validateForm() {
    let x = document.forms["myForm"]["package"].value;
    if (x == "") {
      Setfileval(true);
    }
  }

  function validateForm1() {
    let x = document.forms["myForm1"]["document"].value;
    if (x == "") {
      Setfileval1(true);
    }
  }

  const printChecked = (e, ie) => {
    const checkedvalue = e == true ? "active" : "inactive";
    console.log("eval", checkedvalue);
    axios
      .get(
        `${SERVER_URL}/updatePackageStatus?package_status=${checkedvalue}&_id=${ie}`
      )
      .then((resp) => {
        console.log("res", resp);
      });
  };

  //   Adding Package
  const addpackage = (data, resetForm) => {
    const formdata = new FormData();
    formdata.append("package_name", data.package_name);
    formdata.append("package_price", data.package_price);
    formdata.append("package", packagedoc);

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${SERVER_URL}/addpackage`, formdata, {
        headers,
      })
      .then((reps) => {
        let Status = reps.data.status;
        if (Status === "success") {
          toast.success("Package Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.$("#popup_addPack").modal("hide");

          getpackagesfunc();
          // getLeadData();
          // getTaskData();
          // resetForm();
          resetForm({ data: "" });
        } else {
          window.$("#popup_addPack").modal("hide");
          resetForm({ data: "" });
          toast.error("Something Went wrong try after some Time!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  //   adding documents
  const adddocuments = (data, resetForm) => {
    const formdata = new FormData();
    formdata.append("document_name", data.document_name);
    formdata.append("document", doc1);

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${SERVER_URL}/addQGdocument`, formdata, {
        headers,
      })
      .then((reps) => {
        let Status = reps.data.status;
        if (Status === "success") {
          toast.success("Document Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.$("#popup_addDoc").modal("hide");
          getalldocsfunc();
          // getLeadData();
          // getTaskData();
          // resetForm();
          resetForm({ data: "" });
        } else {
          window.$("#popup_addDoc").modal("hide");
          resetForm({ data: "" });
          toast.error("Something Went wrong try after some Time!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <div style={{ backgroundColor: "#F4F4F4" }}>
      <div className="row mt-3">
        <div className="col-12">
          <div className="document_main_div">
            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="m-0 package_headings">Packages</h4>
                </div>
                <div>
                  <button
                    className="add_task_btn"
                    data-toggle="modal"
                    data-target="#popup_addPack"
                  >
                    <AddIcon className="add_task_icon" />
                    Add Package
                  </button>
                </div>
              </div>
              <hr className="mt-2 mb-3" />
              <div className="row">
                {packagelist.map((ide, index) => {
                  return (
                    <>
                      <div className="col-4">
                        <div className="p-2">
                          <div className="package_box">
                            <div className="d-flex justify-content-between align-items-center pack_txt">
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "10px" }}
                              >
                                <div className="package_indicator">
                                  {ide.packages.package_name.slice(0, 1)}
                                </div>
                                <div className="">
                                  {ide.packages.package_name}
                                </div>
                              </div>
                              <div>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    defaultChecked={
                                      ide.packages.package_status === "active"
                                        ? true
                                        : false
                                    }
                                    // onClick={() => {
                                    //   status = !status;
                                    // }}
                                    onChange={(e) => {
                                      printChecked(e.target.checked, ide._id);
                                    }}
                                    // checked={true}
                                    // onChange={() => {
                                    //   ide.packages.package_status =
                                    //     !ide.packages.package_status;
                                    // }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="mt-3">
                              <span className="pack_price">
                                Package Price - {ide.packages.package_price}
                                /Sq.ft.
                              </span>
                            </div>
                            <hr className="mb-2" />
                            <Popup
                              modal
                              className="poup_scroll_imp fade popup-overlay1 "
                              trigger={
                                <div className="text-center edit_pack">
                                  <EditIcon />
                                  Edit this Package
                                </div>
                              }
                              style={{
                                background: "rgb(0,0,0,0.5)",
                              }}
                            >
                              {(close) => (
                                <div>
                                  <button
                                    id="closematerialpopup1"
                                    className="close_btn_popup_edit"
                                    onClick={close}
                                    style={{
                                      background: "#f4f4f4",
                                      border: "none",
                                      borderRadius: "50%",
                                      marginRight: "10px",
                                      marginTop: "0px",
                                      fontSize: "20px",
                                      width: "35px",
                                      position: "absolute",
                                      right: "0",
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <EditPackage
                                    ident={ide._id}
                                    pname={ide.packages.package_name}
                                    prices={ide.packages.package_price}
                                    filename={ide.packages.package_document}
                                  />
                                </div>
                              )}
                            </Popup>
                            {/* <div
                              className="text-center edit_pack"
                              data-toggle="modal"
                              data-target="#popup_editPack"
                            >
                              <EditIcon />
                              Edit this Package
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mt-3 document_main_div">
            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="package_headings m-0">Extra Documents</h4>
                </div>
                <div>
                  <button
                    className="add_task_btn"
                    data-toggle="modal"
                    data-target="#popup_addDoc"
                  >
                    <AddIcon className="add_task_icon" />
                    Add Document
                  </button>
                </div>
              </div>
              <hr className="mb-3 mt-2" />
              <div className="row p-2">
                {documentlist != ""
                  ? documentlist.slice(0, 4).map((ky, index) => {
                      return (
                        <div className="col-3">
                          <div className="docs_box">
                            <div className="d-flex row">
                              <div className="col-8">
                                <p className="pack_txt1">
                                  {ky.documents.document_name}
                                </p>
                              </div>
                              <div className="col-4 text-center">
                                <Popup
                                  modal
                                  className="poup_scroll_imp fade popup-overlay1 "
                                  trigger={
                                    <div className="text-center edit_pack">
                                      <EditIcon className="docs_edit_btn" />
                                      Edit
                                    </div>
                                  }
                                  style={{
                                    background: "rgb(0,0,0,0.5)",
                                  }}
                                >
                                  {(close) => (
                                    <div>
                                      <button
                                        id="closematerialpopup2"
                                        className="close_btn_popup_edit"
                                        onClick={close}
                                        style={{
                                          background: "#f4f4f4",
                                          border: "none",
                                          borderRadius: "50%",
                                          marginRight: "10px",
                                          marginTop: "0px",
                                          fontSize: "20px",
                                          width: "35px",
                                          position: "absolute",
                                          display: "none",
                                          right: "0",
                                        }}
                                      >
                                        &times;
                                      </button>
                                      <EditDocument
                                        idnt={ky._id}
                                        docname={ky.documents.document_name}
                                        closepop={closepop}
                                        filenamedoc={ky.documents.document}
                                        getalldocsfunc={getalldocsfunc}
                                      />
                                    </div>
                                  )}
                                </Popup>
                              </div>
                            </div>
                            <div className="mt-2">
                              <button className="dwnd_btn">
                                <FileDownloadIcon className="download_ico" />
                                Download
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
                {documentlist != ""
                  ? documentlist.slice(4).map((ky, index) => {
                      return (
                        <div className="col-3 mt-3">
                          <div className="docs_box">
                            <div className="d-flex row">
                              <div className="col-8">
                                <p className="pack_txt1">
                                  {ky.documents.document_name}
                                </p>
                              </div>
                              <div className="col-4 text-center">
                                <Popup
                                  modal
                                  className="poup_scroll_imp fade popup-overlay1 "
                                  trigger={
                                    <div className="text-center edit_pack">
                                      <EditIcon className="docs_edit_btn" />
                                      Edit
                                    </div>
                                  }
                                  style={{
                                    background: "rgb(0,0,0,0.5)",
                                  }}
                                >
                                  {(close) => (
                                    <div>
                                      <button
                                        id="closematerialpopup2"
                                        className="close_btn_popup_edit"
                                        onClick={close}
                                        style={{
                                          background: "#f4f4f4",
                                          border: "none",
                                          borderRadius: "50%",
                                          marginRight: "10px",
                                          marginTop: "0px",
                                          fontSize: "20px",
                                          width: "35px",
                                          position: "absolute",
                                          display: "none",
                                          right: "0",
                                        }}
                                      >
                                        &times;
                                      </button>
                                      <EditDocument
                                        idnt={ky._id}
                                        docname={ky.documents.document_name}
                                        closepop={closepop}
                                        filenamedoc={ky.documents.document}
                                      />
                                    </div>
                                  )}
                                </Popup>
                              </div>
                            </div>
                            <div className="mt-2">
                              <button className="dwnd_btn">
                                <FileDownloadIcon className="download_ico" />
                                Download
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up for Add Package start */}
      <div
        id="popup_addPack"
        className="modal fade popup_meet"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="pb-3">
                  <div className="d-flex justify-content-between form_head_txt">
                    <div className="">
                      <span>Add Package</span>
                    </div>
                    <div type="button" data-dismiss="modal" aria-label="Close">
                      <CloseIcon />
                    </div>
                  </div>

                  <Formik
                    initialValues={{
                      package_name: "",
                      package_price: "",
                      package: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      addpackage(values, resetForm);
                    }}
                  >
                    {({ errors, touched }) => (
                      <div>
                        <Form name="myForm">
                          <div className="row pt-3">
                            <div className="col-6 col-md-6">
                              <label className="filter_input_head_txt pb-3">
                                Package Name
                              </label>
                              <div className="">
                                <Field
                                  type="text"
                                  className="field_input_txt"
                                  name="package_name"
                                  validate={validatePackagename}
                                  placeholder="Enter Title"
                                />
                                {errors.package_name &&
                                  touched.package_name && (
                                    <div className="pt-2 error">
                                      {errors.package_name}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="col-6 col-md-6">
                              <label className="filter_input_head_txt pb-3">
                                Price Per SQ.FT.
                              </label>
                              <div className="">
                                <Field
                                  type="text"
                                  className="field_input_txt"
                                  name="package_price"
                                  validate={validatePrice}
                                  placeholder="Enter Title"
                                />
                                {errors.package_price &&
                                  touched.package_price && (
                                    <div className="pt-2 error">
                                      {errors.package_price}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-12 col-md-12">
                              <label className="filter_input_head_txt pb-3">
                                Upload Package Document
                              </label>
                              <div className="">
                                <input
                                  type="file"
                                  className="field_input_txt"
                                  name="package"
                                  validate={validatepack}
                                  onChange={(e) => {
                                    changeprofilepic(e);
                                    Setfileval(false);
                                    // Setpackagedoc(e.target.files[0]);
                                    // validatepack(e.target.files[0]);
                                  }}
                                  placeholder="Select Date"
                                />
                                {/* {errors.package && touched.package && (
                                  <div className="pt-2 error">
                                    {errors.package}
                                  </div>
                                )} */}
                                {fileval === true ? (
                                  <p style={{ color: "red" }}>
                                    Please Select Package File
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="pt-3">
                            <button
                              type="submit"
                              onClick={validateForm}
                              className="btn btn-lg btn_apply_filter"
                            >
                              <AddIcon className="add_task_icon" />
                              Add Package
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up for Add Package End*/}

      {/* Pop Up for Add Documents start */}
      <div
        id="popup_addDoc"
        className="modal fade popup_meet"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="pb-3">
                  <div className="d-flex justify-content-between form_head_txt">
                    <div className="">
                      <span>Add Document</span>
                    </div>
                    <div type="button" data-dismiss="modal" aria-label="Close">
                      <CloseIcon />
                    </div>
                  </div>

                  <Formik
                    initialValues={{
                      document_name: "",
                      document: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      adddocuments(values, resetForm);
                    }}
                  >
                    {({ errors, touched }) => (
                      <div>
                        <Form name="myForm1">
                          <div className="row pt-3">
                            <div className="col-12 col-md-12">
                              <label className="filter_input_head_txt pb-3">
                                Document Name
                              </label>
                              <div className="">
                                <Field
                                  type="text"
                                  className="field_input_txt"
                                  name="document_name"
                                  validate={validateDocumentname}
                                  placeholder="Enter Document Name"
                                />
                                {errors.document_name &&
                                  touched.document_name && (
                                    <div className="pt-2 error">
                                      {errors.document_name}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-12 col-md-12">
                              <label className="filter_input_head_txt pb-3">
                                Upload Document
                              </label>
                              <div className="">
                                {/* <Field
                                  type="file"
                                  className="field_input_txt"
                                  name="document"
                                  validate={validatePhoto}
                                  onChange={(e) => changedocument(e)}
                                />
                                {errors.document && touched.document && (
                                  <div className="pt-2 error">
                                    {errors.document}
                                  </div>
                                )} */}
                                <input
                                  type="file"
                                  className="field_input_txt"
                                  name="document"
                                  validate={validatepack}
                                  onChange={(e) => {
                                    changedocument(e);
                                    Setfileval1(false);
                                    // Setpackagedoc(e.target.files[0]);
                                    // validatepack(e.target.files[0]);
                                  }}
                                  placeholder="Select Date"
                                />
                                {fileval1 === true ? (
                                  <p style={{ color: "red" }}>
                                    Please Select Document File
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="pt-3">
                            <button
                              type="submit"
                              onClick={validateForm1}
                              className="btn btn-lg btn_apply_filter"
                            >
                              <AddIcon className="add_task_icon" />
                              Add Document
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up for Add Document End*/}
    </div>
  );
}
