import React from "react";
import { Field } from "formik";

const PartnerReferralId = ({
  values,
  errors,
  touched,
  referalcode,
  showref,
}) => {
  return (
    <>
      {!showref && (
        <div className="viewing_details">
          <div className="row">
            <div className="col-6 col-md-6">
              <div>{referalcode.length === 0 ? "-" : referalcode}</div>
            </div>
          </div>
        </div>
      )}
      {showref && (
        <div className="edit_details">
          <div className="row">
            <div className="col-6 col-md-6">
              <div className="input-group-custom">
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="inputName"
                  name="referralcode"
                  value={values.referralcode}
                  //   onChange={(e) => {
                  //     Setreferalcode(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PartnerReferralId;
