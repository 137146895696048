import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import DynamicInputFieldDisplay from "./DynamicInputFieldsDisplay";
import { Formik, Form, Field, useField } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const DyamicInputFields = ({ data, counter }) => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [call_status, Setcall_status] = useState("");
  const [comment, Setcomment] = useState("");

  const { Id } = useParams();
  const [remark, Setremark] = useState("");

  const handleComment = (e) => {
    Setcomment_date(e.target.value);
  };

  var now = new Date();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  var today = now.getFullYear() + "-" + month + "-" + day;
  const [comment_date, Setcomment_date] = useState(today);

  function validateComments(value) {
    let error;
    if (!value) {
      error = "Comments is Required";
    } else if (!/^[a-z][A-Z\s,]+$/i.test(value)) {
      error = "Enter a Valid Comments It should contain only Aplhabets";
    } else if (value.length > 100) {
      error = "Comments Should not more than 100";
    } else if (value.length < 3) {
      error = "Comments Should not less than 3 Characters";
    }
    return error;
  }

  function validateStatus(value) {
    let error;
    if (!value) {
      error = "Call Status is Required";
    }
    return error;
  }

  return (
    <div>
      {/* <DynamicInputFieldDisplay comments={comments} /> */}
      <div className="row pt-2">
        <div className="col-12 col-md-4">
          <div className="input-group-custom">
            <label htmlFor="date" className="label">
              Date
            </label>
            <input
              type="text"
              className="input-area admin_partner_det_input"
              required
              id="date"
              name="date"
              value={comment_date.slice(0, 10).split("-").reverse().join("-")}
              // onChange={(e) => {
              //   data(e.target.value, callstatus, comments, counter);
              // }}
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="input-group-custom">
            <label htmlFor="select" className="label">
              Call Status
            </label>
            <select
              // as="select"
              className="input-area admin_partner_det_input"
              id="select"
              defaultValue={"Select"}
              name="call_status"
              onChange={(e) => {
                Setcall_status(e.target.value);
                data(comment_date, e.target.value, comment, counter);
              }}
              // validate={validateStatus}
            >
              <option selected value="">
                Select
              </option>
              <option value="Not Connected">Not Connected</option>
              <option value="Follow Up">Follow Up</option>
              <option value="DNR">DNR</option>
              <option value="Interested">Interested</option>
              <option value="Meeting Fixed">Meeting Fixed</option>
              <option value="Qualified">Qualified</option>
              <option value="Not Intersted">Not Intersted</option>
              <option value="Dummy">Dummy</option>
              <option value="Out of scope">Out of scope</option>
            </select>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="input-group-custom">
            <label htmlFor="comments" className="label">
              Comments
            </label>
            <textarea
              type="text"
              className="input-area admin_partner_det_input"
              id="comments"
              name="comment"
              rows="4"
              cols="50"
              // validate={validateComments}
              onChange={(e) => {
                Setcomment(e.target.value);
                data(comment_date, call_status, e.target.value, counter);
              }}
              style={{ height: "70px" }}
            />
          </div>
        </div>
      </div>

      {/* <Formik
        initialValues={{
          comment_date: comment_date,
          comment: "",
          call_status: "",
        }}
        onSubmit={(values, { resetForm }) => {
          const headers = {
            "Content-Type": "application/json",
          };
          axios
            .post(`${SERVER_URL}/updatecomments?_id=${Id}`, values, {
              headers,
            })
            .then((res) => {
              let Status = res.data.status;
              if (Status === "success") {
                toast.success("Comments are Added successfully", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else if (Status === "failed") {
                toast.error("Comments are Already Exists", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              alert("Some Internal Error", err);
            });
        }}
      >
        {({ errors, touched, handleSubmit }) => (
          <div>
            <Form>
              <div>
                <div className="row pt-2">
                  <div className="col-12 col-md-3">
                    <div className="input-group-custom">
                      <label htmlFor="date" className="label">
                        Date
                      </label>
                      <Field
                        type="text"
                        className="input-area admin_partner_det_input"
                        required
                        id="date"
                        name="date"
                        value={comment_date}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="input-group-custom">
                      <label htmlFor="select" className="label">
                        Call Status
                      </label>
                      <Field
                        as="select"
                        className="input-area admin_partner_det_input"
                        id="select"
                        defaultValue={"Select"}
                        name="call_status"
                        validate={validateStatus}
                      >
                        <option selected value="">
                          Select
                        </option>

                        <option value="Not Connected">Not Connected</option>
                        <option value="Follow Up">Follow Up</option>
                        <option value="DNR">DNR</option>
                        <option value="Interested">Interested</option>
                        <option value="Meeting Fixed">Meeting Fixed</option>
                        <option value="Qualified">Qualified</option>
                      </Field>
                    </div>
                    {errors.call_status && touched.call_status && (
                      <p className="error">{errors.call_status}</p>
                    )}
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="input-group-custom">
                      <label htmlFor="comments" className="label">
                        Comments
                      </label>
                      <Field
                        type="text"
                        className="input-area admin_partner_det_input"
                        id="comments"
                        name="comment"
                        validate={validateComments}
                      />
                    </div>
                    {errors.comment && touched.comment && (
                      <p className="error">{errors.comment}</p>
                    )}
                  </div>

                  <div
                    className="col-2 col-md-2"
                    style={{ alignSelf: "center" }}
                  >
                    <button
                      type="submit"
                      className="edit_icon"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik> */}
    </div>
  );
};

export default DyamicInputFields;
