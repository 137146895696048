import react, { useState, useEffect } from "react";
import "./switch.css";

function Switch(props) {
  const [mode, setMode] = useState("innerDiv");

  useEffect(() => {
    props.status === "active" && setMode("SwitchActive");
  }, []);

  function toggle() {
    var eleMode = "inactive";
    mode === "innerDiv" ? setMode("SwitchActive") : setMode("innerDiv");
    mode === "innerDiv" ? (eleMode = "active") : (eleMode = "inactive");
    props.toggle(eleMode, props._id, props.index);
  }

  return (
    <div id="SwitchDiv" onClick={() => toggle()}>
      <div id={mode}>
        <div id="toggleBubble" />
      </div>
    </div>
  );
}

export default Switch;
