import React, { useState, useEffect } from "react";
import axios from "axios";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Left from "../../Assets/left_leader.svg";
import Right from "../../Assets/right_leader.svg";
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import { BiRupee } from "react-icons/bi";
import { Link } from "react-router-dom";
import { startOfDay, endOfDay, subMonths, subYears } from "date-fns";

const LeaderBoard = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [LeaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${SERVER_URL}/leaderboardfilters?from_date=${startDate}&to_date=${endDate}`
      )
      .then((response) => {
        // console.log("Period off", response);
        setLeaderboardData(response.data.result);
      });
  }, [startDate, endDate]);

  // OnChange Function for Date Range Picker
  const onChange = (dates) => {
    // console.log("dates", dates);
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start < end) {
        setStartDate(start);
        setEndDate(end);
      } else {
        setStartDate(end);
        setEndDate(start);
      }
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const Ranges = [
    {
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Month",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
    },
    {
      label: "3 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
    },
    {
      label: "6 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
    },
    {
      label: "Year",
      value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <span className="dashboard_head_txt">Partners Leaderboard</span>
        <div>
          <DateRangePicker
            // style={{ left: "780px" }}
            name="start"
            placeholder="Select Date Range"
            format="dd/MM/yyyy"
            character="-"
            placement="bottomEnd"
            onChange={onChange}
            ranges={Ranges}
            // defaultValue={dateValue}
            // cleanable={false}
          />
        </div>
      </div>

      <div className="pt-3">
        <div className="p-3 pie_graph_dashboard" style={{ width: "100%" }}>
          <div className="table-responsive">
            <table
              className="table report_table leaderboard_table"
              style={{ height: "100%" }}
            >
              <tbody>
                {LeaderboardData != "" ? (
                  LeaderboardData.map((leader, index) => {
                    if (index < 5) {
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              {/* {index < 3 ? (
                                <div>
                                  <img src={Left} />
                                  {index + 1}
                                  <img src={Right} />
                                </div>
                              ) : (
                          
                              )} */}
                              <div>
                                <img src={Left} />
                                {index + 1}
                                <img src={Right} />
                              </div>
                            </td>

                            <td style={{ width: "100px" }}>
                              <div style={{ float: "left" }}>
                                <img
                                  src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                                  className="rounded-circle"
                                  width={40}
                                  height={40}
                                />
                                {""}
                                {""}
                                {leader.name}
                              </div>
                            </td>
                            <td>
                              <span className="dashboard_leaderboard_projects">
                                {leader.Noofprojects + " + Project Referred"}
                              </span>
                            </td>
                            <td>
                              <span className="dashboard_total_earnings_txt">
                                Total Earnings :
                              </span>
                              <span className="dashboard_leaderboard_net_earning">
                                <BiRupee />
                                {leader.Net_Earning}
                              </span>
                            </td>
                            <td>
                              {leader.PartnerType === "RP" ? (
                                <Link
                                  to={`/rpdetails/${leader.PartnerID}/1`}
                                  className="admin_panel_viewmore_list"
                                >
                                  View More
                                </Link>
                              ) : (
                                <Link
                                  to={`/cpdetails/${leader.PartnerID}/1`}
                                  className="admin_panel_viewmore_list"
                                >
                                  View More
                                </Link>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    } else {
                      <div className="d-flex">
                        <div>
                          <WarningRoundedIcon className="no_data_alert_leaderboard" />
                        </div>
                        <div>
                          <h4>No records found for LeaderBoard</h4>
                        </div>
                      </div>;
                    }
                  })
                ) : (
                  <div className="d-flex">
                    <div>
                      <WarningRoundedIcon className="no_data_alert_leaderboard" />
                    </div>
                    <div>
                      <h4>No records found for LeaderBoard</h4>
                    </div>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
