import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminNav from "../AdminNavBar/AdminNav";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import whatsApp from "../../Assets/logos_whatsapp.svg";
import DynamicInputFields from "../ChannelSales/DyamicInputFields";
import DynamicInputFieldDisplay from "../ChannelSales/DynamicInputFieldsDisplay";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field, useField } from "formik";
import LeadPersonal from "../../BasicUI/LeadDetails/LeadPersonal";
import LeadContact from "../../BasicUI/LeadDetails/LeadContact";
import LeadStage1Details from "../../BasicUI/LeadDetails/LeadStage1Details";
import LeadStage2Details from "../../BasicUI/LeadDetails/LeadStage2Details";
import LeadStage3Details from "../../BasicUI/LeadDetails/LeadStage3Details";
import LeadDealDetails from "../../BasicUI/LeadDetails/LeadDealDetails";
import LeadMeeting from "../../BasicUI/LeadDetails/LeadMeeting";
import {
  validateMeetingDate,
  validateBDEAssigne,
  validateFromTime,
  validateToTime,
  validateMeetingLink,
  validateMeetingLocation,
} from "../Helpers/validation";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Footer from "../Dashboard/Footer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactTooltip from "react-tooltip";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const ChannelPartnerReferedLeadDetails = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [show, toggleShow] = useState(false);
  const [showedit, toggleShowedit] = useState(false);
  const [showeditcmt, toggleShoweditcmt] = useState(false);
  const [showleadproedit, toggleshowleadproedit] = useState(false);
  const [showmeetedit, toggleshowmeetedit] = useState(false);
  const [btnloader, setbtnloader] = useState(false);

  const { Id } = useParams();
  const { Number } = useParams();
  const { Page } = useParams();

  let history = useHistory();

  // state to set all personal info values of partners
  const [refnames, Setrefnames] = useState("");
  const [reflocation, Setreflocation] = useState("");
  const [refsqft, Setrefsqft] = useState("");
  const [refconstructiondate, Setrefconstructiondate] = useState("");
  const [refplotnumber, Setrefplotnumber] = useState("");

  // state to set contact info
  const [refemail, Setrefemail] = useState("");
  const [refmobile, Setrefmobile] = useState("");

  // Comments
  const [comment_date, Setcomment_date] = useState("");
  const [remark, Setremark] = useState("");
  const [partnerids, Setpartnerids] = useState("");
  // SQFT
  const [showeditsqft, toggleshoweditsqft] = useState(false);
  const [Persfamt, SetPersfamt] = useState([30]);

  const [callstatus, Setcallstatus] = useState("");
  const [CommentsData, SetCommentsData] = useState([]);

  const [comments, Setcomments] = useState([]);
  // console.log("comments", comments);

  const [profilepic, setprofilepic] = useState("");

  // ----------------------------For payment stages ----------------------------
  const [paymentstage1, Setpaymentstage1] = useState("");
  const [paymentstage2, Setpaymentstage2] = useState("");
  const [paymentstage3, Setpaymentstage3] = useState("");

  const [releaserangefrom1, Setreleaserangefrom1] = useState("");
  const [releaserangefrom2, Setreleaserangefrom2] = useState("");
  const [releaserangefrom3, Setreleaserangefrom3] = useState("");

  const [releaserangeto1, Setreleaserangeto1] = useState("");
  const [releaserangeto2, Setreleaserangeto2] = useState("");
  const [releaserangeto3, Setreleaserangeto3] = useState("");

  const [paymentdate1, Setpaymentdate1] = useState("");
  const [paymentdate2, Setpaymentdate2] = useState("");
  const [paymentdate3, Setpaymentdate3] = useState("");

  const [paymentmode1, Setpaymentmode1] = useState("");
  const [paymentmode2, Setpaymentmode2] = useState("");
  const [paymentmode3, Setpaymentmode3] = useState("");

  const [stage1, Setstage1] = useState("");
  const [stage2, Setstage2] = useState("");
  const [stage3, Setstage3] = useState("");

  const [creditedstat1, Setcreditedstat1] = useState("");
  const [creditedstat2, Setcreditedstat2] = useState("");
  const [creditedstat3, Setcreditedstat3] = useState("");

  const [constStage1, SetconstStage1] = useState("");
  const [constStage2, SetconstStage2] = useState("");
  const [constStage3, SetconstStage3] = useState("");

  const [CustomerStatus, SetCustomerStatus] = useState("");

  // Call Status
  const [callStatus, setcallStatus] = useState("");
  // Meeting
  const [meetdate, setmeetdate] = useState("");
  const [meetstarttime, setmeetstarttime] = useState("");
  const [meetendttime, setmeetendttime] = useState("");
  const [meetassignbde, setmeetassignbde] = useState("");
  const [meetlink, setmeetlink] = useState("");
  const [meetdesc, setmeetdesc] = useState("");
  const [meetloc, setmeetloc] = useState("");
  const [updmeetloc, setupdmeetloc] = useState("");
  const [updmeetlink, setupdmeetlink] = useState("");
  const [developmentName, SetdevelopmentName] = useState("");
  const [nooffloor, Setnooffloor] = useState("");
  const [source, setsource] = useState("");
  const [referredBy, setreferredBy] = useState("");
  const [plotlocation, setplotlocation] = useState("");
  const [projectValue, SetprojectValue] = useState("");
  const [leadbudget, setleadbudget] = useState("");
  // bde Assign
  const [bdeassignOption, setbdeassignOption] = useState([]);

  const [commentvalue, Setcommentvalue] = useState([]);
  const [partnerid, Setpartnerid] = useState("");

  // Dont Know
  const [approvedvalue, Setapprovedvalue] = useState([]);

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [showtarget, toggleShowtarget] = useState(false);
  const [showtarget2, toggleShowtarget2] = useState(false);
  const [showtarget3, toggleShowtarget3] = useState(false);
  const [showref, toggleShowref] = useState(false);
  const [leadbookingamt, Setleadbookingamt] = useState([]);

  const [leaddpaymentstage, Setleaddpaymentstage] = useState([]);
  const [leadpackagename, Setleadpackagename] = useState([]);

  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }

    getLeadData();

    getStageDetils();

    // Deductions
    axios
      .get(`${SERVER_URL}/getearningdetails?_id=${Id}`)
      .then((resultvalue) => {
        // console.log("Apprr val", resultvalue.data.Approved_value);
        Setapprovedvalue(resultvalue.data.Approved_value);
      });

    // To Fetch Assign To Options DropDown for BDE
    axios.get(`${SERVER_URL}/bdlist`).then((response) => {
      // console.log("Cust", response);
      setbdeassignOption(response.data.Assignees);
      // console.log("Customer", Customer);
    });
  }, []);

  const getLeadData = () => {
    axios.get(`${SERVER_URL}/leadbyid?_id=${Id}`).then((reps) => {
      // console.log("res", reps);

      // Personal
      Setpartnerids(reps.data.listofcustomers.partner_id);
      Setrefnames(reps.data.listofcustomers.name);
      Setreflocation(reps.data.listofcustomers.location);
      Setrefsqft(reps.data.listofcustomers.area);
      Setrefconstructiondate(reps.data.listofcustomers.start_date);
      Setrefplotnumber(reps.data.listofcustomers.plot_number);
      Setleadpackagename(reps.data.listofcustomers.packagename);
      const len = reps.data.listofcustomers.stages.length - 1;
      // console.log("looo", len);
      if (len >= 0) {
        Setleaddpaymentstage(reps.data.listofcustomers.stages[len].stage);
      }
      Setleadbookingamt(reps.data.listofcustomers.booking_amt);
      SetCustomerStatus(reps.data.listofcustomers.customerstatus);

      // Contact info
      Setrefemail(reps.data.listofcustomers.email);
      Setrefmobile(reps.data.listofcustomers.contact_no);

      // Call Status
      setcallStatus(reps.data.listofcustomers.call_status);
      SetdevelopmentName(reps.data.listofcustomers.development_name);
      SetprojectValue(reps.data.listofcustomers.project_value);
      Setnooffloor(reps.data.listofcustomers.no_of_floors);
      setsource(reps.data.listofcustomers.lead_source);
      setreferredBy(reps.data.listofcustomers.partner_name);
      setplotlocation(reps.data.listofcustomers.plot_location);
      setleadbudget(reps.data.listofcustomers.lead_budget);
      setmeetdate(reps.data.listofcustomers.meeting_date);
      setmeetstarttime(reps.data.listofcustomers.meeting_from_time);
      setmeetendttime(reps.data.listofcustomers.meeting_to_time);
      setmeetassignbde(reps.data.listofcustomers.assigned_bde);
      setmeetlink(reps.data.listofcustomers.meeting_link);
      setmeetdesc(reps.data.listofcustomers.meeting_description);
      setmeetloc(reps.data.listofcustomers.meeting_location);
      setupdmeetlink(reps.data.listofcustomers.meeting_link);
      setupdmeetloc(reps.data.listofcustomers.meeting_location);
      // sqft
      SetPersfamt(reps.data.listofcustomers.persfamt);

      // Comments
      Setcomment_date(reps.data.listofcustomers.comment_date);
      Setremark(reps.data.listofcustomers.comments);

      Setcomments(reps.data.listofcustomers.comments);
      setCounter1(reps.data.listofcustomers.comments.length);
      // console.log("commentsNew", reps.data.listofcustomers.comments);
    });
  };

  const getStageDetils = () => {
    axios
      .get(`${SERVER_URL}/getstagedetails?_id=${Id}`)
      .then((responsedatas) => {
        // console.log("res", responsedatas);
        responsedatas.data.Stages.map((stagerecord) => {
          // if first array is there run this
          if (stagerecord.stage === "Stage 1") {
            Setpaymentmode1(stagerecord.payment_mode);
            Setpaymentstage1(stagerecord.net_earning);
            Setreleaserangefrom1(stagerecord.payment_date_from);
            Setreleaserangeto1(stagerecord.payment_date_to);
            Setpaymentdate1(stagerecord.payment_date);
            Setstage1(stagerecord.stage);
            Setcreditedstat1(stagerecord.credited);
            SetconstStage1(stagerecord.construction_stage);
            // console.log("Reeu", responsedatas.data.Stages);
          }

          // If second array has values and populated
          if (stagerecord.stage === "Stage 2") {
            Setpaymentmode2(stagerecord.payment_mode);
            Setpaymentstage2(stagerecord.net_earning);
            Setreleaserangefrom2(stagerecord.payment_date_from);
            Setreleaserangeto2(stagerecord.payment_date_to);
            Setpaymentdate2(stagerecord.payment_date);
            Setstage2(stagerecord.stage);
            Setcreditedstat2(stagerecord.credited);
            SetconstStage2(stagerecord.construction_stage);
          }

          if (stagerecord.stage === "Stage 3") {
            Setpaymentmode3(stagerecord.payment_mode);
            Setpaymentstage3(stagerecord.net_earning);
            Setreleaserangefrom3(stagerecord.payment_date_from);
            Setreleaserangeto3(stagerecord.payment_date_to);
            Setpaymentdate3(stagerecord.payment_date);
            Setstage3(stagerecord.stage);
            Setcreditedstat3(stagerecord.credited);
            SetconstStage3(stagerecord.construction_stage);
          }
        });
      });
  };

  const [counter, setCounter] = useState(1);
  const [counter1, setCounter1] = useState(1);

  const handleClick = (e) => {
    e.preventDefault();
    if (counter1 < 14) {
      setCounter((prevcounter) => prevcounter + 1);
      setCounter1((prevcounter1) => prevcounter1 + 1);
    }
  };

  const changeprofile = (e) => {
    setprofilepic(e.target.files[0]);
    // console.log("setting state", e.target.files[0]);
  };

  const saveprofilepic = () => {
    const formdata = new FormData();
    formdata.append("profile_pic", profilepic);
    axios
      .post(`${SERVER_URL}/uploadcustprofile?_id=${Id}`, formdata)
      .then((repos) => {
        console.log("newyear", repos);
      });
  };

  // TO delete the lead by id
  const deleteleaddetails = () => {
    axios.delete(`${SERVER_URL}/deletecustomer?_id=${Id}`).then((res) => {
      // console.log("Deleted record", res);
      let Status = res.data.status;
      if (Status === "success") {
        window.$("#deletePartner").modal("hide");
        window.$("#PartnerDeletedSuccessfully").modal("show");
        window.setTimeout(function () {
          window.$(".modal-backdrop").remove();
          history.push(`/cprefleads/${partnerids}/${Number}/${Page}`);
        }, 3000);
      } else {
        toast.error("Internal Error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const commentsData = (comment_date, call_status, comment, index) => {
    const labarray = CommentsData;
    labarray.splice(index, 1, {
      comment_date: comment_date,
      call_status: call_status,
      comment: comment,
    });

    SetCommentsData([]);
    SetCommentsData(labarray);
  };

  const saveComments = () => {
    if (CommentsData.length > 0) {
      const comment = {
        data: CommentsData,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(`${SERVER_URL}/updatecomments?_id=${Id}`, comment, {
          headers,
        })
        .then((res) => {
          let Status = res.data.status;
          if (Status === "success") {
            toast.success("Comments are Added successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getLeadData();
            // setCounter(1);
            // setCounter1(1);
            window.setTimeout(function () {
              window.location.reload();
            }, 100);
          } else if (Status === "failed") {
            toast.error("Comments are Already Exists", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Some Internal Error", err);
        });
    } else {
      alert("please fill all the details");
    }
  };

  const ScheduleMeeting = (e) => {
    e.preventDefault();

    let meeting = e.target.value;
    if (meeting === "Online") {
      window.$("#Schedule_Online_meeting").modal("show");
    } else if (meeting === "Offline") {
      window.$("#Schedule_Offline_meeting").modal("show");
    }
  };

  return (
    <div>
      <div>
        <AdminNav />
      </div>

      <div className="main">
        <div className="content-tabs">
          <div>
            <div className="top_menu_bar">
              <div>
                {/* <Link
                    to={`/cprefleads/${partnerids}`}
                    className="partner_back_btn"
                  >
                    <span>
                      <ArrowBackIosNewIcon />
                      Back
                    </span>
                  </Link> */}

                <Link
                  to={`/cprefleads/${partnerids}/${Number}/${Page}`}
                  className="partner_back_btn"
                >
                  <span>
                    <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                    <span>Back</span>
                  </span>
                </Link>
              </div>

              <div>
                <div
                  className="d-flex justify-content-around"
                  style={{ gap: "20px" }}
                >
                  <div>
                    {meetlink !== "" || meetloc !== "" ? (
                      ""
                    ) : callStatus === "Meeting Fixed" ? (
                      <div>
                        {/* <div className="d-flex justify-content-around"> */}
                        <select
                          onChange={(e) => {
                            ScheduleMeeting(e);
                          }}
                          style={{ border: "none", height: "47px" }}
                          className="form-select select-form"
                        >
                          <option disabled selected value="">
                            Schedule Meeting
                          </option>
                          <option value="Online">Online Meeting</option>
                          <option value="Offline">Offline Meeting</option>
                        </select>

                        {/* </div> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      className="delete_btn"
                      data-toggle="modal"
                      data-target=".deletelead"
                    >
                      <DeleteIcon className="del_icons" />
                      Delete
                    </button>

                    <div
                      id="deletePartner"
                      className="modal fade deletelead"
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div
                          className="modal-content modal-sm modal_content_whole"
                          style={{ background: "white" }}
                        >
                          <div className="modal-body">
                            <div>
                              <div className="popup_icon_center">
                                <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                              </div>
                              <div className="popup_text_center">
                                <p className="pt-2 popup_addbankdetails_text">
                                  Are you sure want to Delete this Lead ?
                                </p>
                                <div className="pt-4 m-0 d-flex justify-content-center">
                                  <div
                                    style={{
                                      width: "50%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="popup_btn_unlinkaccount"
                                      onClick={deleteleaddetails}
                                      data-dismiss="modal"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                  <div
                                    style={{
                                      width: "50%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <button
                                      className="popup_btn_close"
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Sucuesfully deleted */}
                    <div
                      className="modal leaddeletedsucess"
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                      id="PartnerDeletedSuccessfully"
                    >
                      <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div
                          className="modal-content modal-sm modal_content_whole"
                          style={{ background: "white" }}
                        >
                          <div className="modal-body">
                            <div>
                              <div className="popup_icon_center">
                                <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                              </div>
                              <div className="pt-2 popup_text_center">
                                <span className="popup_addbankdetails_text">
                                  Lead Deleted Successfully
                                </span>
                                <div className="pt-2 m-0 d-flex justify-content-center">
                                  <div
                                    style={{
                                      width: "50%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <button
                                      // type="button"
                                      className="popup_btn_linkaccount"
                                      data-dismiss="modal"
                                      onClick={() => {
                                        history.push(
                                          `/referedlead/${partnerids}/${Number}/${Page}`
                                        );
                                      }}
                                    >
                                      OK
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="partner_details_edit_sec">
              <div className="row">
                <div className="col-md-8">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      name: refnames,
                      packagename: leadpackagename,
                      area: refsqft,
                      plot_number: refplotnumber,
                      development_name: developmentName,
                      no_of_floors: nooffloor,
                      start_date: refconstructiondate,
                      //  status: CustomerStatus,
                      plot_location: plotlocation,
                      lead_budget: leadbudget,
                    }}
                    onSubmit={(values) => {
                      // console.log("val", values);
                      const headers = {
                        "Content-Type": "application/json",
                      };

                      axios
                        .post(
                          `${SERVER_URL}/updatecustomer?_id=${Id}`,
                          values,
                          {
                            headers,
                          }
                        )
                        .then((resul) => {
                          let Status = resul.data.status;
                          if (Status === "success") {
                            toast.success(
                              "Profile Details are edited sucessfully",
                              {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                            getLeadData();
                            toggleShow(false);
                            // window.setTimeout(function () {
                            //   window.location.reload();
                            // }, 100);
                          } else if (Status === "failed") {
                            toggleShow(false);
                            // alert("Profile Details are already exists");
                            toast.error("Profile Details Already Exists", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }
                        })
                        .catch((err) => {
                          alert("Some Internal Error", err);
                        });
                    }}
                  >
                    {({ errors, touched, handleSubmit, values }) => (
                      <Form>
                        <div className="p-3 admin_patrner_personal_info">
                          <div className="d-flex justify-content-between head_txt head_txt_margin">
                            <p>Lead Personal Info</p>
                            <div
                              className="edit_icon"
                              onClick={() => toggleShow(!show)}
                            >
                              {!show && (
                                <div>
                                  <EditIcon className="search_icons" />
                                  <span> Edit</span>
                                </div>
                              )}
                              {show && (
                                <div>
                                  <button
                                    type="submit"
                                    className="edit_icon"
                                    onClick={handleSubmit}
                                  >
                                    Save
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <LeadPersonal
                            values={values}
                            errors={errors}
                            touched={touched}
                            show={show}
                            refnames={refnames}
                            leadpackagename={leadpackagename}
                            refsqft={refsqft}
                            refplotnumber={refplotnumber}
                            developmentName={developmentName}
                            nooffloor={nooffloor}
                            refconstructiondate={refconstructiondate}
                            callStatus={callStatus}
                            source={source}
                            referredBy={referredBy}
                            plotlocation={plotlocation}
                            leadbudget={leadbudget}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className="col-md-4">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      email: refemail,
                      contact_no: refmobile,
                      location: reflocation,
                    }}
                    onSubmit={(values) => {
                      const headers = {
                        "Content-Type": "application/json",
                      };

                      axios
                        .post(
                          `${SERVER_URL}/updatecustomer?_id=${Id}`,
                          values,
                          {
                            headers,
                          }
                        )
                        .then((resul) => {
                          let Status = resul.data.status;
                          if (Status === "success") {
                            toast.success(
                              "Lead Contact Details are edited sucessfully",
                              {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                            getLeadData();
                            toggleShowedit(false);
                            // window.setTimeout(function () {
                            //   window.location.reload();
                            // }, 100);
                          } else if (Status === "failed") {
                            toggleShowedit(false);
                            // alert("Profile Details are already exists");
                            toast.error("Lead Contact Details Already Exists", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }
                        })
                        .catch((err) => {
                          alert("Some Internal Error", err);
                        });
                    }}
                  >
                    {({ errors, touched, handleSubmit, values }) => (
                      <Form>
                        <div className="p-3 admin_patrner_personal_info">
                          <div className="d-flex justify-content-between head_txt head_txt_margin">
                            <p>Lead Contact Info</p>
                            <div
                              className="edit_icon"
                              onClick={() => toggleShowedit(!showedit)}
                            >
                              {!showedit && (
                                <div>
                                  <EditIcon className="search_icons" />
                                  <span> Edit</span>
                                </div>
                              )}
                              {showedit && (
                                <div>
                                  <button
                                    type="submit"
                                    className="edit_icon"
                                    onClick={handleSubmit}
                                  >
                                    Save
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <LeadContact
                            values={values}
                            errors={errors}
                            touched={touched}
                            showedit={showedit}
                            refemail={refemail}
                            refmobile={refmobile}
                            reflocation={reflocation}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="row mt-4 pb-2">
                <div className="col-md-8">
                  <div className="content-tabs_dashboard">
                    <div
                      className={
                        toggleState === 1
                          ? "content1  active-content1"
                          : "content1"
                      }
                    >
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          credited: creditedstat1,
                          amount: paymentstage1,
                          stage: stage1,
                          payment_date_from: releaserangefrom1,
                          payment_date_to: releaserangeto1,
                          payment_date: paymentdate1,
                          payment_mode: paymentmode1,
                          construction_stage: constStage1,
                        }}
                        onSubmit={(values) => {
                          // alert("hello");
                          // console.log("val", values);
                          const headers = {
                            "Content-Type": "application/json",
                          };

                          axios
                            .post(
                              `${SERVER_URL}/addpaymentstages?_id=${Id}`,
                              values,
                              {
                                headers,
                              }
                            )
                            .then((resul) => {
                              // console.log("Hello Don", resul);
                              let Status = resul.data.status;
                              if (Status === "success") {
                                // alert("Profile Details are edited sucessfully");
                                toast.success(
                                  "Stage-1 payment are edited sucessfully",
                                  {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                                getStageDetils();
                                toggleShowtarget(false);
                                // window.setTimeout(function () {
                                //   window.location.reload();
                                // }, 100);
                              } else if (Status === "failed") {
                                toggleShowtarget(false);
                                // alert("Profile Details are already exists");
                                toast.error("Stage-1 payment Already Exists", {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              }
                            })
                            .catch((err) => {
                              // console.log(err);
                              alert("Some Internal Errors", err);
                            });
                        }}
                      >
                        {({ errors, touched, handleSubmit, values }) => (
                          <Form>
                            <div className="p-3 admin_patrner_personal_info">
                              <div className="d-flex justify-content-between head_txt head_txt_margin">
                                <p>Stage-1 Payment Details</p>
                                <div
                                  className="edit_icon"
                                  onClick={() => toggleShowtarget(!showtarget)}
                                >
                                  {!showtarget && (
                                    <div>
                                      <EditIcon className="search_icons" />
                                      <span> Edit</span>
                                    </div>
                                  )}
                                  {showtarget && (
                                    <div>
                                      <button
                                        type="submit"
                                        className="edit_icon"
                                        onClick={handleSubmit}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <LeadStage1Details
                                values={values}
                                errors={errors}
                                touched={touched}
                                showtarget={showtarget}
                                creditedstat1={creditedstat1}
                                paymentstage1={paymentstage1}
                                stage1={stage1}
                                releaserangefrom1={releaserangefrom1}
                                releaserangeto1={releaserangeto1}
                                paymentdate1={paymentdate1}
                                paymentmode1={paymentmode1}
                                constStage1={constStage1}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                    <div
                      className={
                        toggleState === 2
                          ? "content1  active-content1"
                          : "content1"
                      }
                    >
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          credited: creditedstat2,
                          amount: paymentstage2,
                          stage: stage2,
                          payment_date_from: releaserangefrom2,
                          payment_date_to: releaserangeto2,
                          payment_date: paymentdate2,
                          payment_mode: paymentmode2,
                          construction_stage: constStage2,
                        }}
                        onSubmit={(values) => {
                          // alert("hello");
                          // console.log("val", values);
                          const headers = {
                            "Content-Type": "application/json",
                          };

                          axios
                            .post(
                              `${SERVER_URL}/addpaymentstages?_id=${Id}`,
                              values,
                              {
                                headers,
                              }
                            )
                            .then((resul) => {
                              // console.log("Hello Don", resul);
                              let Status = resul.data.status;
                              if (Status === "success") {
                                // alert("Profile Details are edited sucessfully");
                                toast.success(
                                  "Stage-2 payment are edited sucessfully",
                                  {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                                getStageDetils();
                                toggleShowtarget2(false);
                                // window.setTimeout(function () {
                                //   window.location.reload();
                                // }, 100);
                              } else if (Status === "failed") {
                                toggleShowtarget2(false);
                                // alert("Profile Details are already exists");
                                toast.error("Stage-2 payment Already Exists", {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              }
                            })
                            .catch((err) => {
                              // console.log(err);
                              alert("Some Internal Errors", err);
                            });
                        }}
                      >
                        {({ errors, touched, handleSubmit, values }) => (
                          <Form>
                            <div className="p-3 admin_patrner_personal_info">
                              <div className="d-flex justify-content-between head_txt head_txt_margin">
                                <p>Stage-2 Payment Details</p>
                                <div
                                  className="edit_icon"
                                  onClick={() =>
                                    toggleShowtarget2(!showtarget2)
                                  }
                                >
                                  {!showtarget2 && (
                                    <div>
                                      <EditIcon className="search_icons" />
                                      <span> Edit</span>
                                    </div>
                                  )}
                                  {showtarget2 && (
                                    <div>
                                      <button
                                        type="submit"
                                        className="edit_icon"
                                        onClick={handleSubmit}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <LeadStage2Details
                                values={values}
                                errors={errors}
                                touched={touched}
                                showtarget2={showtarget2}
                                creditedstat2={creditedstat2}
                                paymentstage2={paymentstage2}
                                stage2={stage2}
                                releaserangefrom2={releaserangefrom2}
                                releaserangeto2={releaserangeto2}
                                paymentdate2={paymentdate2}
                                paymentmode2={paymentmode2}
                                constStage2={constStage2}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                    <div
                      className={
                        toggleState === 3
                          ? "content1  active-content1"
                          : "content1"
                      }
                    >
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          credited: creditedstat3,
                          amount: paymentstage3,
                          stage: stage3,
                          payment_date_from: releaserangefrom3,
                          payment_date_to: releaserangeto3,
                          payment_date: paymentdate3,
                          payment_mode: paymentmode3,
                          construction_stage: constStage3,
                        }}
                        onSubmit={(values) => {
                          // alert("hello");
                          // console.log("val", values);
                          const headers = {
                            "Content-Type": "application/json",
                          };

                          axios
                            .post(
                              `${SERVER_URL}/addpaymentstages?_id=${Id}`,
                              values,
                              {
                                headers,
                              }
                            )
                            .then((resul) => {
                              // console.log("Hello Don", resul);
                              let Status = resul.data.status;
                              if (Status === "success") {
                                // alert("Profile Details are edited sucessfully");
                                toast.success(
                                  "Stage-3 payment are edited sucessfully",
                                  {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                                getStageDetils();
                                toggleShowtarget3(false);
                                // window.setTimeout(function () {
                                //   window.location.reload();
                                // }, 100);
                              } else if (Status === "failed") {
                                toggleShowtarget3(false);
                                // alert("Profile Details are already exists");
                                toast.error("Stage-3 payment Already Exists", {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              }
                            })
                            .catch((err) => {
                              // console.log(err);
                              alert("Some Internal Errors", err);
                            });
                        }}
                      >
                        {({ errors, touched, handleSubmit, values }) => (
                          <Form>
                            <div className="p-3 admin_patrner_personal_info">
                              <div className="d-flex justify-content-between head_txt head_txt_margin">
                                <p>Stage-3 Payment Details</p>
                                <div
                                  className="edit_icon"
                                  onClick={() =>
                                    toggleShowtarget3(!showtarget3)
                                  }
                                >
                                  {!showtarget3 && (
                                    <div>
                                      <EditIcon className="search_icons" />
                                      <span> Edit</span>
                                    </div>
                                  )}
                                  {showtarget3 && (
                                    <div>
                                      <button
                                        type="submit"
                                        className="edit_icon"
                                        onClick={handleSubmit}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <LeadStage3Details
                                values={values}
                                errors={errors}
                                touched={touched}
                                showtarget3={showtarget3}
                                creditedstat3={creditedstat3}
                                paymentstage3={paymentstage3}
                                stage3={stage3}
                                releaserangefrom3={releaserangefrom3}
                                releaserangeto3={releaserangeto3}
                                paymentdate3={paymentdate3}
                                paymentmode3={paymentmode3}
                                constStage3={constStage3}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <div
                    className="stepper-wrapper"
                    style={{ paddingTop: "0px" }}
                  >
                    <div className="stepper-item completed">
                      <div
                        className={toggleState === 1 ? " step-counter" : ""}
                        onClick={() => toggleTab(1)}
                        style={{ cursor: "pointer" }}
                      >
                        1
                      </div>
                      {/* <div class="step-name">First</div> */}
                    </div>
                    <div className="stepper-item completed">
                      <div
                        className={toggleState === 2 ? " step-counter" : " "}
                        onClick={() => toggleTab(2)}
                        style={{ cursor: "pointer" }}
                      >
                        2
                      </div>
                      {/* <div class="step-name">Second</div> */}
                    </div>
                    <div className="stepper-item completed">
                      <div
                        className={toggleState === 3 ? " step-counter" : ""}
                        onClick={() => toggleTab(3)}
                        style={{ cursor: "pointer" }}
                      >
                        3
                      </div>
                      {/* <div class="step-name">Third</div> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          project_value: projectValue,
                          booking_amt: leadbookingamt,
                          persfamt: Persfamt,
                        }}
                        onSubmit={(values) => {
                          const headers = {
                            "Content-Type": "application/json",
                          };

                          axios
                            .post(
                              `${SERVER_URL}/updatecustomer?_id=${Id}`,
                              values,
                              {
                                headers,
                              }
                            )
                            .then((res) => {
                              // console.log("Hello Don", res);
                              let Status = res.data.status;
                              if (Status === "success") {
                                // alert("Profile Details are edited sucessfully");
                                toast.success(
                                  "Deal Details are edited sucessfully",
                                  {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                                // window.setTimeout(function () {
                                //   window.location.reload();
                                // }, 100);
                                getLeadData();
                                toggleShowref(false);
                              } else if (Status === "failed") {
                                toggleShowref(false);
                                // alert("Profile Details are already exists");
                                toast.error("Deal Details Already Exists", {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              }
                            })
                            .catch((err) => {
                              alert("Some Internal Error", err);
                            });
                        }}
                      >
                        {({ errors, touched, handleSubmit, values }) => (
                          <Form>
                            <div className="p-3 admin_patrner_personal_info">
                              <div className="d-flex justify-content-between head_txt head_txt_margin">
                                <p>Deal Details</p>
                                <div
                                  className="edit_icon"
                                  onClick={() => toggleShowref(!showref)}
                                >
                                  {!showref && (
                                    <div>
                                      <EditIcon className="search_icons" />
                                      <span>Edit</span>
                                    </div>
                                  )}
                                  {showref && (
                                    <div>
                                      <button
                                        type="submit"
                                        className="edit_icon"
                                        onClick={handleSubmit}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <LeadDealDetails
                                values={values}
                                errors={errors}
                                touched={touched}
                                showref={showref}
                                Persfamt={Persfamt}
                                projectValue={projectValue}
                                leadbookingamt={leadbookingamt}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>

                    {/* <div className="col-md-12 mt-4">
                      <div className="p-3 admin_patrner_personal_info">
                        <div className="d-flex justify-content-between">
                          <p className="head_txt">Profile Pic</p>
                          <div
                            className="edit_icon"
                            onClick={() =>
                              toggleshowleadproedit(!showleadproedit)
                            }
                          >
                            {!showleadproedit && (
                              <div>
                                <EditIcon className="search_icons" />
                                <span> Edit</span>
                              </div>
                            )}
                            {showleadproedit && (
                              <div>
                                <button
                                  type="submit"
                                  className="edit_icon"
                                  onClick={saveprofilepic}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        {!showleadproedit && (
                          <div className="viewing_details">
                            <div className="row">
                              <div className="col-6 col-md-6">
                                <button className="download_btn">
                                  <DownloadIcon />
                                  Photo
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {showleadproedit && (
                          <div className="edit_details">
                            <div className="row">
                              <div className="">
                                <div className="input-group-custom">
                                  <input
                                    type="file"
                                    className="input-area admin_partner_det_input"
                                    required
                                    id="inputName"
                                    name="profile_pic"
                                    //   value={name}
                                    onChange={(e) => {
                                      changeprofile(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-8">
                  <div className="p-3 admin_patrner_personal_info">
                    <div className="d-flex justify-content-between head_txt head_txt_margin">
                      <p>Comments</p>
                      <div
                        className="edit_icon"
                        onClick={() => toggleShoweditcmt(!showeditcmt)}
                      >
                        {!showeditcmt && (
                          <div>
                            <EditIcon className="search_icons" />
                            <span> Edit</span>
                          </div>
                        )}
                        {showeditcmt && (
                          <div>
                            <button
                              type="submit"
                              className="edit_icon"
                              onClick={saveComments}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    {!showeditcmt && (
                      <div className="contact_info_">
                        {/* {Array.from(Array(counter)).map((c, index) => {
                                 return ( key={c} */}
                        {comments != "" ? (
                          comments.map((cust) => {
                            return (
                              <div className="pt-2 row">
                                <div className="col-12 col-md-4">
                                  <div>
                                    <p className="m-0 admin_type_heading">
                                      Date
                                    </p>
                                    <span className="admin_type_value">
                                      {cust.comment_date
                                        .slice(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("-")}
                                      {/* {comment_date === "" ? "NA" : comment_date} */}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-12 col-md-4">
                                  <div>
                                    <p className="m-0 admin_type_heading">
                                      Call Status
                                    </p>
                                    <span className="admin_type_value">
                                      {cust.call_status}
                                      {/* {comment_date === "" ? "NA" : comment_date} */}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-12 col-md-4">
                                  <div>
                                    <p className="m-0 admin_type_heading">
                                      Comments
                                    </p>
                                    <span className="admin_type_value">
                                      {cust.comment}
                                      {/* {remark === "" ? "NA" : remark} */}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div>
                            <div>NA</div>
                          </div>
                        )}

                        {/* );
                            })} */}
                      </div>
                    )}

                    {showeditcmt && (
                      <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                        {comments.length != 0 ? (
                          <div>
                            <DynamicInputFieldDisplay comments={comments} />
                          </div>
                        ) : (
                          ""
                        )}

                        {Array.from(Array(counter)).map((c, counter) => {
                          return (
                            <DynamicInputFields
                              data={commentsData}
                              counter={counter}
                            />
                          );
                        })}

                        <div className="pt-4">
                          {/* <button>Add Comments</button> */}
                          <button
                            className="add_btn btn_add_comments_inc"
                            onClick={handleClick}
                          >
                            <AddIcon className="search_icons" />
                            Add Comment
                          </button>
                        </div>

                        {/* <div className="input-group-custom">
                              <label htmlFor="loc" className="label">
                                Comments
                              </label>
                              <br />

                              <textarea
                                className="input-area"
                                width="100%"
                                rows="5"
                                cols="20"
                                name="email"
                                value={remark}
                                onChange={(e) => {
                                  Setremark(e.target.value);
                                }}
                              ></textarea>
                            </div> */}
                      </div>
                    )}
                  </div>
                </div>

                {updmeetlink !== "" || updmeetloc !== "" ? (
                  <div className="col-md-4">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        meeting_date: meetdate,
                        assign_bde: meetassignbde,
                        // meeting_time: meettime,
                        meeting_from_time: meetstarttime,
                        meeting_to_time: meetendttime,
                        meeting_location: meetloc,
                        meeting_link: meetlink,
                        meeting_description: meetdesc,
                      }}
                      onSubmit={(values) => {
                        // console.log("val", values);
                        const headers = {
                          "Content-Type": "application/json",
                        };

                        axios
                          .post(
                            `${SERVER_URL}/updatemeeting?_id=${Id}`,
                            values,
                            {
                              headers,
                            }
                          )
                          .then((res) => {
                            // console.log("Hello Don", res);
                            let Status = res.data.status;
                            if (Status === "success") {
                              // alert("Profile Details are edited sucessfully");
                              toast.success(
                                "Meeting Details are Updated successfully",
                                {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                }
                              );
                              getLeadData();
                              toggleshowmeetedit(false);
                              // window.setTimeout(function () {
                              //   window.location.reload();
                              // }, 100);
                            } else if (Status === "failed") {
                              toggleshowmeetedit(false);
                              // alert("Profile Details are already exists");
                              toast.error(
                                "Some Internal Error Try after Some Time...",
                                {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                }
                              );
                            }
                          });
                      }}
                    >
                      {({ errors, touched, handleSubmit, values }) => (
                        <Form>
                          <div className="p-3 admin_patrner_personal_info">
                            <div className="d-flex justify-content-between head_txt head_txt_margin">
                              <p>Meeting Details</p>
                              {/* Toggle State to edit and save start */}
                              <div
                                className="edit_icon"
                                onClick={() =>
                                  toggleshowmeetedit(!showmeetedit)
                                }
                              >
                                {!showmeetedit && (
                                  <div>
                                    <EditIcon className="search_icons" />
                                    <span> Edit</span>
                                  </div>
                                )}
                                {showmeetedit && (
                                  <div>
                                    <button
                                      type="submit"
                                      className="edit_icon"
                                      onClick={handleSubmit}
                                    >
                                      Save
                                    </button>
                                  </div>
                                )}
                              </div>
                              {/* Toggle State to edit and save end */}
                            </div>

                            <LeadMeeting
                              values={values}
                              errors={errors}
                              touched={touched}
                              showmeetedit={showmeetedit}
                              meetdate={meetdate}
                              meetassignbde={meetassignbde}
                              meetstarttime={meetstarttime}
                              meetendttime={meetendttime}
                              meetloc={meetloc}
                              meetlink={meetlink}
                              meetdesc={meetdesc}
                              updmeetlink={updmeetlink}
                              updmeetloc={updmeetloc}
                              bdeassignOption={bdeassignOption}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>

      {/* Pop Up Schedule Online Meeting Start*/}
      <div
        id="Schedule_Online_meeting"
        className="modal fade Schedule_Online_meeting"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body" style={{ background: "#FFFFFF" }}>
              <div className="pb-3">
                <span className="add_hot_lead_label">
                  Schedule Online Meeting
                </span>
                <span
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="CloseAddHotLeadPopup"
                  style={{
                    background: "transparent",
                    float: "right",
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
              <Formik
                initialValues={{
                  meeting_date: "",
                  assign_bde: "",
                  meeting_from_time: "",
                  meeting_to_time: "",
                  meeting_link: "",
                  meeting_description: "",
                }}
                // validationSchema={validate}
                onSubmit={(values, { resetForm }) => {
                  setbtnloader(true);
                  // console.log("val", values);
                  const headers = {
                    "Content-Type": "application/json",
                  };
                  axios
                    .post(`${SERVER_URL}/onlinemeeting?_id=${Id}`, values, {
                      headers,
                    })
                    .then((res) => {
                      let Status = res.data.status;
                      if (Status === "success") {
                        window.$("#Schedule_Online_meeting").modal("hide");
                        setbtnloader(false);
                        toast.success(
                          "Online Meeting Scheduled Successfully...!!",
                          {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          }
                        );
                        getLeadData();
                        // getLeadData();
                      } else {
                        setbtnloader(false);
                        toast.error("Something Went Wrong...try Again!!!", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    });
                }}
              >
                {({ errors, touched }) => (
                  <div>
                    <Form>
                      <div className="row pt-3">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting Date
                          </label>
                          <div>
                            <Field
                              // as="date"
                              type="date"
                              className="field_input_txt"
                              name="meeting_date"
                              validate={validateMeetingDate}
                              placeholder="Meeting Date"
                            />
                          </div>
                          {errors.meeting_date && touched.meeting_date && (
                            <div className="pt-2 error">
                              {errors.meeting_date}
                            </div>
                          )}
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Assign To
                          </label>
                          <div className="">
                            <Field
                              as="select"
                              className="field_input_txt"
                              name="assign_bde"
                              validate={validateBDEAssigne}
                            >
                              <option disabled selected value="">
                                Select BDE
                              </option>
                              {bdeassignOption != ""
                                ? bdeassignOption.map((item, i) => {
                                    return (
                                      <option key={i} value={item.Name}>
                                        {item.Name}
                                      </option>
                                    );
                                  })
                                : "No Data"}
                            </Field>
                            {errors.assign_bde && touched.assign_bde && (
                              <div className="pt-2 error">
                                {errors.assign_bde}
                              </div>
                            )}
                            {/* Assignees */}
                          </div>
                        </div>
                      </div>

                      <div className="row pt-4">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting From
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_from_time"
                              validate={validateFromTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_from_time &&
                              touched.meeting_from_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_from_time}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting To
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_to_time"
                              validate={validateToTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_to_time &&
                              touched.meeting_to_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_to_time}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Link
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            className="field_input_txt"
                            name="meeting_link"
                            validate={validateMeetingLink}
                            placeholder="Post The Meeting Link Here"
                          />
                          {errors.meeting_link && touched.meeting_link && (
                            <div className="pt-2 error">
                              {errors.meeting_link}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Description(If Any)
                        </label>
                        <div className="">
                          <Field
                            style={{ height: "100%" }}
                            as="textarea"
                            rows="5"
                            // type="text"
                            className="field_input_txt"
                            name="meeting_description"
                            placeholder="Write Description Here...."
                          />
                        </div>
                      </div>

                      <div className="pt-4">
                        <button
                          type="submit"
                          className="btn btn-lg btn_apply_filter"
                        >
                          {btnloader === true ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                          Schedule Meeting
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up Schedule Online Meeting End*/}

      {/* Pop Up Schedule Offline Meeting Start*/}
      <div
        id="Schedule_Offline_meeting"
        className="modal fade Schedule_Offline_meeting"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body" style={{ background: "#FFFFFF" }}>
              <div className="pb-3">
                <span className="add_hot_lead_label">
                  Schedule Offline Meeting
                </span>
                <span
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="CloseAddHotLeadPopup"
                  style={{
                    background: "transparent",
                    float: "right",
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
              <Formik
                initialValues={{
                  meeting_date: "",
                  assign_bde: "",
                  meeting_from_time: "",
                  meeting_to_time: "",
                  meeting_location: "",
                  meeting_description: "",
                }}
                // validationSchema={validate}
                onSubmit={(values, { resetForm }) => {
                  // console.log("val", values);
                  setbtnloader(true);
                  const headers = {
                    "Content-Type": "application/json",
                  };
                  axios
                    .post(`${SERVER_URL}/offlinemeeting?_id=${Id}`, values, {
                      headers,
                    })
                    .then((res) => {
                      // console.log("online meeting", res);
                      let Status = res.data.status;
                      if (Status === "success") {
                        window.$("#Schedule_Offline_meeting").modal("hide");
                        setbtnloader(false);
                        toast.success(
                          "Offline Meeting Scheduled Successfully...!!",
                          {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          }
                        );
                        getLeadData();
                      } else {
                        setbtnloader(false);
                        toast.error("Something Went Wrong...try Again!!!", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    });
                }}
              >
                {({ errors, touched }) => (
                  <div>
                    <Form>
                      <div className="row pt-3">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting Date
                          </label>
                          <div className="">
                            <Field
                              // as="date"
                              type="date"
                              className="field_input_txt"
                              name="meeting_date"
                              validate={validateMeetingDate}
                              placeholder="Meeting Date"
                            />
                            {errors.meeting_date && touched.meeting_date && (
                              <div className="pt-2 error">
                                {errors.meeting_date}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Assign To
                          </label>
                          <div className="">
                            <Field
                              as="select"
                              className="field_input_txt"
                              name="assign_bde"
                              validate={validateBDEAssigne}
                              defaultValue={"select"}
                            >
                              <option selected value="">
                                Select BDE
                              </option>
                              {bdeassignOption != ""
                                ? bdeassignOption.map((item, i) => {
                                    return (
                                      <option key={i} value={item.Name}>
                                        {item.Name}
                                      </option>
                                    );
                                  })
                                : "No Data"}
                            </Field>
                            {errors.assign_bde && touched.assign_bde && (
                              <div className="pt-2 error">
                                {errors.assign_bde}
                              </div>
                            )}
                            {/* Assignees */}
                          </div>
                        </div>
                      </div>

                      <div className="row pt-4">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting From
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_from_time"
                              validate={validateFromTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_from_time &&
                              touched.meeting_from_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_from_time}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting To
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_to_time"
                              validate={validateToTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_to_time &&
                              touched.meeting_to_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_to_time}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Location
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            className="field_input_txt"
                            name="meeting_location"
                            validate={validateMeetingLocation}
                            placeholder="Enter Full Location"
                          />
                          {errors.meeting_location &&
                            touched.meeting_location && (
                              <div className="pt-2 error">
                                {errors.meeting_location}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Description(If Any)
                        </label>
                        <div className="">
                          <Field
                            style={{ height: "100%" }}
                            as="textarea"
                            rows={5}
                            // type="text"
                            className="field_input_txt"
                            name="meeting_description"
                            placeholder="Write Description Here...."
                          />
                        </div>
                      </div>

                      <div className="pt-4">
                        <button
                          type="submit"
                          className="btn btn-lg btn_apply_filter"
                        >
                          {btnloader === true ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                          Schedule Meeting
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up Schedule Offline Meeting End*/}
    </div>
  );
};

export default ChannelPartnerReferedLeadDetails;
