import React, { useEffect, useState } from "react";
import axios from "axios";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import SearchIcon from "@mui/icons-material/Search";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Link } from "react-router-dom";
import { CgArrowRightR } from "react-icons/cg";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import UserNav2 from "./UserNav2";
// Pagination
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Helpers/Loader";
import Footer from "../Dashboard/Footer";

export default function ListofCustomer() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [leaddata, Setleaddata] = useState([]);
  const [qualified, Setqualified] = useState("");
  const [isloading, setisloading] = useState(true);
  const [Period, setPeriod] = useState("day");
  const { Id } = useParams();
  const name = localStorage.getItem("BDName");
  let history = useHistory();
  // console.log("REDD", Id);
  // Paganiation
  const { PageNo } = useParams();
  const { SearchBar } = Search;
  const [Page, setPage] = useState(PageNo ? PageNo : 1);
  const [PerPage, setPerPage] = useState(30);
  const [searchcount, setsearchcount] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  useEffect(() => {
    const valid = localStorage.getItem("BDName");
    if (valid === null) {
      history.push("/");
    }

    getLeads(valid);
  }, [Period]);

  const getLeads = (valid) => {
    // axios.get(`${SERVER_URL}/bdeleads?bde_name=${valid}`).then((respon) => {
    axios
      .get(`${SERVER_URL}/bdefilters?date_filter=${Period}&bde_name=${valid}`)
      .then((respon) => {
        Setleaddata(respon.data.Leads);
        Setqualified(respon.data.Leads);
        setisloading(false);
        // Customer Status
        // console.log("lead data", respon);
      });
  };

  const handleFilter = (e) => {
    e.preventDefault();
    // console.log("vall", e.target.value);

    const filterval = qualified.filter(
      (reqdata) => reqdata.call_status === e.target.value
    );
    // console.log("Filter valu", filterval);
    Setleaddata(filterval);

    if (e.target.value === "All") {
      axios.get(`${SERVER_URL}/bdeleads?bde_name=${name}`).then((respon) => {
        Setleaddata(respon.data.leads);
        Setqualified(respon.data.leads);
      });
    }
  };

  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const leadCallStatus = (cell) => {
    switch (cell) {
      case "Not Connected":
        return (
          <div>
            <button className="call_not_connected">Not Connected</button>
          </div>
        );
      case "Follow Up":
        return (
          <div>
            <button className="call_follow_up">Follow Up</button>
          </div>
        );
      case "DNR":
        return (
          <div>
            <button className="call_dnr">DNR</button>
          </div>
        );
      case "Interested":
        return (
          <div>
            <button className="call_intersted">Interested</button>
          </div>
        );
      case "Meeting Fixed":
        return (
          <div>
            <button className="call_meeting">Meeting Fixed</button>
          </div>
        );
      case "Qualified":
        return (
          <div>
            <button className="call_qualified">Qualified</button>
          </div>
        );
      case "Not Intersted":
        return (
          <div>
            <button className="call_not_intersted">Not Intersted</button>
          </div>
        );
      case "Meeting Done":
        return (
          <div>
            <button className="call_meet_done">Meeting Done</button>
          </div>
        );
      case "Deal Lost":
        return (
          <div>
            <button className="call_deal_lost">Deal Lost</button>
          </div>
        );
      case "Booking Amt. Received":
        return (
          <div>
            <button className="call_book_amount">Booking Amt. Received</button>
          </div>
        );
      case "Quotation Sent":
        return (
          <div>
            <button className="call_quotation">Quotation Sent</button>
          </div>
        );
      case "Dummy":
        return (
          <div>
            <button className="call_dummy">Dummy</button>
          </div>
        );
      case "Out of scope":
        return (
          <div>
            <button className="call_out_of_scope">Out of scope</button>
          </div>
        );
      default:
        return "-";
    }
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "Lead Name",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "handled_by",
      text: "Assigned By",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "contact_no",
      text: "Mobile No.",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "call_status",
      text: "Status",
      formatter: leadCallStatus,
    },
    {
      dataField: "lead_id",
      text: "Lead ID",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell) => (
        <Link to={`/leadetailsview/${cell}/${Page}`} className="viewmore">
          <CgArrowRightR />
        </Link>
      ),
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log("row", row);
      history.push(`/leadetailsview/${row._id}/${Page}`);
    },
  };

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    page: parseInt(Page),
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ],
  };

  return (
    <div>
      {isloading === false ? (
        <div>
          <div>
            <UserNav2 />
          </div>
          <div className="main">
            <div className="content-tabs">
              <ToolkitProvider
                keyField="_id"
                data={leaddata}
                columns={columns}
                search={{ afterSearch }}
              >
                {(props) => (
                  <div>
                    <div className="top_menu_bar">
                      <div>
                        <div>
                          <span className="CS_head_txt">List Of Leads</span>
                        </div>
                        <div>
                          <span className="total_count_txt">
                            (
                            {searchcount === 0
                              ? searchcount
                              : searchcount > 0
                              ? searchcount
                              : leaddata.length}{" "}
                            Leads)
                          </span>
                        </div>
                      </div>

                      <div>
                        <div className="d-flex justify-content-around">
                          <div className="search_div" style={{ width: "35%" }}>
                            <SearchIcon />
                            <SearchBar
                              {...props.searchProps}
                              className="top_menu_search"
                              placeholder="Search Here..."
                              // style={{ width: "400px", height: "40px" }}
                            />
                            {/* <input
                              type="text"
                              className="top_menu_search"
                              placeholder="Search Here.."
                              onChange={(event) => {
                                Setsearchterm(event.target.value);
                              }}
                            /> */}
                          </div>

                          <div>
                            <select
                              onChange={handleFilter}
                              style={{ border: "none", height: "100%" }}
                              className="form-select select-form"
                            >
                              <option disabled selected value="">
                                Select Status
                              </option>
                              <option value="All">All</option>
                              <option value="Not Connected">
                                Not Connected
                              </option>
                              <option value="Follow Up">Follow Up</option>
                              <option value="DNR">DNR</option>
                              <option value="Interested">Interested</option>
                              <option value="Meeting Fixed">
                                Meeting Fixed
                              </option>
                              <option value="Qualified">Qualified</option>
                            </select>
                          </div>

                          <div>
                            <select
                              onChange={(e) => setPeriod(e.target.value)}
                              style={{ height: "42px", width: "130px" }}
                              className="add_btn form-select"
                              defaultValue={"day"}
                              // style={{ width: "20%" }}
                            >
                              {/* {Period === "month" && <option value="month">Monthly</option>} */}
                              <option
                                value="day"
                                // onChange={(e) => setPeriod("day")}
                              >
                                Daily
                              </option>
                              <option
                                value="week"
                                //  onChange={(e) => setPeriod("week")}
                              >
                                Weekly
                              </option>
                              <option
                                value="month"
                                // onChange={(e) => setPeriod("month")}
                              >
                                Monthly
                              </option>
                              <option
                                value="quarter"
                                // onChange={(e) => setPeriod("quarter")}
                              >
                                Quarterly
                              </option>
                              <option
                                value="year"
                                //  onChange={(e) => setPeriod("year")}
                              >
                                Yearly
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="table_div">
                        {leaddata != "" ? (
                          <div>
                            <BootstrapTable
                              {...props.baseProps}
                              noDataIndication={emptyDataMessage}
                              pagination={paginationFactory(paginationoptions)}
                              filter={filterFactory()}
                              rowEvents={rowEvents}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div>
                              <WarningRoundedIcon className="no_data_alert" />
                            </div>
                            <div>
                              <h3>No records found for Customers</h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
}
