import React from "react";
import whatsApp from "../../Assets/logos_whatsapp.svg";
import { Field } from "formik";

const LeadContact = ({
  values,
  errors,
  touched,
  showedit,
  refemail,
  refmobile,
  reflocation,
}) => {
  return (
    <>
      {!showedit && (
        <div className="contact_info_">
          <div>
            <p className="m-0 admin_type_heading">Email</p>
            <span className="admin_type_value">
              {refemail === "" ? "-" : refemail}
            </span>
          </div>
          <div className="ml-4 mt-3">
            <div className="d-flex justify-content-between">
              <div>
                <p className="m-0 admin_type_heading">Mobile No.</p>
                <span className="admin_type_value">
                  {refmobile === "" ? "-" : refmobile}
                </span>
              </div>
              {refmobile !== "" ? (
                <div className="whats_app_div">
                  <a
                    href={`https://wa.me/${refmobile}`}
                    className="whatsapp_float"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={whatsApp} />
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="ml-4 mt-3">
            <p className="m-0 admin_type_heading">Current Location</p>
            <span className="admin_type_value">
              {reflocation === "" ? "-" : reflocation}
            </span>
          </div>
        </div>
      )}
      {showedit && (
        <div className="contact_info_admin">
          <div className="input-group-custom">
            <label htmlFor="loc" className="label">
              Email
            </label>
            <br />
            <Field
              type="email"
              className="input-area"
              //   required
              id="email"
              name="email"
              value={values.email}
              //   onChange={(e) => {
              //     Setleademail(e.target.value);
              //   }}
              // value={MyPersonalDetails.email}
              // onChange={handleChange("email")}
            />
          </div>
          <div className="mt-3 input-group-custom">
            <label htmlFor="mob" className="label">
              Mobile No
            </label>
            <br />
            <Field
              type="text"
              className="input-area"
              //   required
              id="mob"
              name="contact_no"
              value={values.contact_no}
              //   onChange={(e) => {
              //     Setleadmobileno(e.target.value);
              //   }}
              // value={MyPersonalDetails.mobile_no}
              // onChange={handleChange("mobile_no")}
            />
          </div>

          <div className="mt-3 input-group-custom">
            <label htmlFor="text" className="label">
              Location
            </label>
            <Field
              type="text"
              className="input-area admin_partner_det_input"
              name="location"
              value={values.location}
              //   onChange={(e) => {
              //     Setleadworkloc(e.target.value);
              //   }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LeadContact;
