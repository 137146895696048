import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import "./settings.css";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import Popup from "reactjs-popup";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "../../Fields/TextField";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
export default function EditPackage({ ident, pname, prices, filename }) {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [packagedoc, Setpackagedoc] = useState("");
  const updatepackage = (data, resetForm) => {
    const formdata = new FormData();
    formdata.append("package_name", data.package_name);
    formdata.append("package_price", data.package_price);
    formdata.append("package", packagedoc);
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${SERVER_URL}/updatePackages?_id=${ident}`, formdata, {
        headers,
      })
      .then((reps) => {
        let Status = reps.data.status;
        if (Status === "success") {
          toast.success("Package Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          //   window.$("#popup_addPack").modal("hide");
          document.getElementById("closematerialpopup1").click();
          //   resetForm({ data: "" });
        } else {
          //   window.$("#popup_addPack").modal("hide");
          //   resetForm({ data: "" });
          toast.error("Something Went wrong try after some Time!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  function validateDocumentname(value) {
    let error;
    if (!value) {
      error = "Document name is Required";
    }
    return error;
  }
  function validatePackagename(value) {
    let error;
    if (!value) {
      error = "Package name is Required";
    }
    return error;
  }

  function validatePrice(value) {
    let error;
    if (!value) {
      error = "Booking Amount is Required";
    }
    return error;
  }
  function validatepack(value) {
    let error;
    if (packagedoc === null) {
      error = "Document is Required";
    }
    return error;
  }
  const changeprofilepic = (e) => {
    Setpackagedoc(e.target.files[0]);
  };

  //   to delete package
  const deletepackage = () => {
    axios.post(`${SERVER_URL}/deletePackagesDocs?_id=${ident}`).then((reps) => {
      let Status = reps.data.status;
      if (Status === "success") {
        toast.success("Package Deleted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.$("#popup_addPack").modal("hide");
        document.getElementById("closematerialpopup1").click();
      } else {
        window.$("#popup_addPack").modal("hide");

        toast.error("Something Went wrong try after some Time!!!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <div>
      {/*Pop Up for edit package start */}
      <div className="">
        <div>
          <div className="pb-3">
            <div className="d-flex justify-content-between form_head_txt">
              <div className="">
                <span>Package Details</span>
              </div>
            </div>
            <Formik
              initialValues={{
                package_name: pname,
                package_price: prices,
                package: "",
              }}
              onSubmit={(values, { resetForm }) => {
                updatepackage(values, resetForm);
              }}
            >
              {({ errors, touched }) => (
                <div>
                  <Form>
                    <div className="row pt-3">
                      <div className="col-6 col-md-6">
                        <label className="filter_input_head_txt pb-3">
                          Document Name
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            className="field_input_txt"
                            name="package_name"
                            // value={values.package_name}
                            validate={validateDocumentname}
                            placeholder="Enter Title"
                          />
                          {errors.package_name && touched.package_name && (
                            <div className="pt-2 error">
                              {errors.package_name}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-6 col-md-6">
                        <label className="filter_input_head_txt pb-3">
                          Price Per SQ.FT.
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            className="field_input_txt"
                            name="package_price"
                            // value={values.package_price}
                            validate={validatePrice}
                            placeholder="Enter Title"
                          />
                          {errors.package_price &&
                            touched.tpackage_priceask_title && (
                              <div className="pt-2 error">
                                {errors.package_price}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12 col-md-12">
                        <label className="filter_input_head_txt pb-3">
                          Package Document
                        </label>
                        <div className="">
                          <input
                            type="file"
                            className="field_input_txt"
                            name="package"
                            // validate={validatepack}
                            onChange={(e) => {
                              changeprofilepic(e);
                            }}
                          />
                          <p>{filename}</p>
                          {errors.package && touched.package && (
                            <div className="pt-2 error">{errors.package}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="pt-3" style={{ position: "relative" }}>
                      <button
                        type="submit"
                        className="btn btn-lg save_changes_btn"
                      >
                        <EditIcon className="add_task_icon" />
                        Save Changes
                      </button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
            <button
              className="btn btn-lg remove_btn mt-3"
              style={{
                width: "46%",
                position: "absolute",
                top: "15.1em",
                left: "18em",
              }}
              onClick={() => {
                deletepackage();
              }}
            >
              <DeleteIcon className="delpackage_btn" />
              Remove
            </button>
          </div>
        </div>
      </div>
      {/* Pop Up for edit package End*/}
    </div>
  );
}
