import React, { useState, useEffect } from "react";
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import { startOfDay, endOfDay, subMonths, subYears } from "date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { ResponsiveContainer } from "recharts";
import NoData from "./NoData";

export function LGSPerformance() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [Lgsname, setLgsname] = useState([]);
  const [totalcall, settotalcall] = useState([]);
  const [followup, setfollowup] = useState([]);
  const [meetingfixed, setmeetingfixed] = useState([]);
  const [onboarded, setonboarded] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    getGraph();
  }, [startDate, endDate]);

  const getGraph = () => {
    axios
      .get(
        `${SERVER_URL}/LGSperformance?from_date=${startDate}&to_date=${endDate}`
      )
      .then((res) => {
        // console.log("res", res);
        setLgsname(res.data.LGSNames);
        settotalcall(res.data.totalCalls);
        setfollowup(res.data.followUps);
        setmeetingfixed(res.data.meetingFixed);
        setonboarded(res.data.onboarded);
      });
    // .catch((err) => alert(err));
  };

  const options = {
    indexAxis: "y",
    plugins: {
      legend: {
        title: {
          position: "start",
        },
        align: "start",
      },
    },

    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        grid: {
          display: false,
        },
        stacked: true,
      },
    },
  };

  const data = {
    labels: Lgsname,
    datasets: [
      {
        label: "Total Call",
        data: totalcall,
        backgroundColor: "#3AD3DD",
      },
      {
        label: "Follow Up",
        data: followup,
        backgroundColor: "#FCC216",
      },
      {
        label: "Meeting Fixed",
        data: meetingfixed,
        backgroundColor: "#B216FC",
      },
      {
        label: "Onboarded",
        data: onboarded,
        backgroundColor: "#3ADD5E",
      },
    ],
  };
  // OnChange Function for Date Range Picker
  const onChange = (dates) => {
    // console.log("dates", dates);
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start < end) {
        setStartDate(start);
        setEndDate(end);
      } else {
        setStartDate(end);
        setEndDate(start);
      }
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const Ranges = [
    {
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Month",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
    },
    {
      label: "3 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
    },
    {
      label: "6 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
    },
    {
      label: "Year",
      value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
    },
  ];

  return (
    <>
      <div className="py-4 d-flex justify-content-between align-items-center">
        <span className="pie_graph_head_txt">
          Lead Generation Specialist Performance
        </span>
        <div>
          <DateRangePicker
            // style={{ left: "780px" }}
            name="start"
            placeholder="Select Date Range"
            format="dd/MM/yyyy"
            character="-"
            placement="bottomEnd"
            onChange={onChange}
            ranges={Ranges}
            // defaultValue={dateValue}
            // cleanable={false}
          />
        </div>
      </div>
      <div className="isperformance_graph_dashboard p-3">
        <ResponsiveContainer width={"90%"} height={350}>
          <Bar options={options} data={data} />
        </ResponsiveContainer>
      </div>
    </>
  );
}
export default LGSPerformance;
