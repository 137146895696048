import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import axios from "axios";
import NoData from "../../Dashboard/NoData";

import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import { startOfDay, endOfDay, subMonths, subYears } from "date-fns";

const CallStatus = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const valid = localStorage.getItem("ISName");
  const [notconnected, setnotconnected] = useState(0);
  const [followup, setfollowup] = useState("");
  const [dnr, setdnr] = useState("");
  const [interested, setinterested] = useState(0);
  const [meetfixed, setmeetfixed] = useState("");
  const [qualified, setqualified] = useState("");
  const [dummy, setdummy] = useState("");
  const [notinterested, setnotinterested] = useState("");
  const [totalscall, settotalscall] = useState("");

  const [notconnectedpercent, setnotconnectedpercent] = useState(0);
  const [followuppercent, setfollowuppercent] = useState("");
  const [dnrpercent, setdnrpercent] = useState("");
  const [interestedpercent, setinterestedpercent] = useState(0);
  const [meetfixedpercent, setmeetfixedpercent] = useState("");
  const [qualifiedpercent, setqualifiedpercent] = useState("");
  const [dummypercent, setdummypercent] = useState("");
  const [notinterestedpercent, setnotinterestedpercent] = useState("");
  //   Date
  var date = new Date();
  let dateValue = [
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ];

  // const [startDate, setStartDate] = useState(dateValue[0].toLocaleDateString());
  // const [endDate, setEndDate] = useState(dateValue[1].toLocaleDateString());

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    getCallData();
  }, [startDate, endDate]);

  const getCallData = () => {
    axios
      .get(
        `${SERVER_URL}/callstatusgraph?from_date=${startDate}&to_date=${endDate}&is_name=${valid}`
      )
      .then((res) => {
        // console.log("res111", res);
        setnotconnected(res.data.numberdata.Not_connected);
        setfollowup(res.data.numberdata.Followup);
        setdnr(res.data.numberdata.dnr);
        setinterested(res.data.numberdata.interested);
        setmeetfixed(res.data.numberdata.Meeting_fixed);
        setqualified(res.data.numberdata.qualified);
        setdummy(res.data.numberdata.dummy);
        setnotinterested(res.data.numberdata.not_interested);
        settotalscall(res.data.numberdata.totalcalls);

        setnotconnectedpercent(res.data.percentdata.Not_connected);
        // console.log("eqe", res.data.percentdata.Not_connected);
        setfollowuppercent(res.data.percentdata.Followup);
        setdnrpercent(res.data.percentdata.dnr);
        setinterestedpercent(res.data.percentdata.interested);
        setmeetfixedpercent(res.data.percentdata.Meeting_fixed);
        setqualifiedpercent(res.data.percentdata.qualified);
        setdummypercent(res.data.percentdata.dummy);
        setnotinterestedpercent(res.data.percentdata.not_interested);

        // setdead(res.data.data.dead);
      });
    // .catch((err) => alert(err));
  };

  // OnChange Function for Date Range Picker
  const onChange = (dates) => {
    // console.log("dates", dates);
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start < end) {
        setStartDate(start);
        setEndDate(end);
      } else {
        setStartDate(end);
        setEndDate(start);
      }
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const Ranges = [
    {
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Month",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
    },
    {
      label: "3 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
    },
    {
      label: "6 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
    },
    {
      label: "Year",
      value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
    },
  ];

  const data = [
    { name: "Followup", value: followup, percentage: followuppercent },
    { name: "Interested", value: interested, percentage: interestedpercent },
    { name: "Meeting Fixed", value: meetfixed, percentage: meetfixedpercent },
    { name: "Qualified", value: qualified, percentage: qualifiedpercent },
    {
      name: "Not Connected",
      value: notconnected,
      percentage: notconnectedpercent,
    },
    { name: "DNR", value: dnr, percentage: dnrpercent },
    { name: "Dummy", value: dummy, percentage: dummypercent },
    {
      name: "Not Interested",
      value: notinterested,
      percentage: notinterestedpercent,
    },
    // { name: "Dead", value: dead },
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div
          className="subscribers-by-channel-tooltip"
          style={{
            background: "#fff",
            color: "#000",
            padding: "5px",
            border: "1px solid #000",
          }}
        >
          <p className="subscribers-by-channel-tooltip-title mb-0 mt-0">
            {payload[0].name}
          </p>
          <p className="subscribers-by-channel-tooltip-label mb-0 mt-0">{`Value : ${payload[0].value}`}</p>
          <p className="subscribers-by-channel-tooltip-intro mb-0 mt-0">
            {" "}
            {`Percentage : ${payload[0].payload.payload.percentage}`}
          </p>
        </div>
      );
    }

    return null;
  };
  // console.log("data", data);

  const COLORS = [
    "#FCC216",
    "#16A9FC",
    "#16FCC5",
    "#FC7716",
    "#FE3E3E",
    "#B216FC",
    "#938D80",
    "#0E0E0E",
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <div className="p-2">
      <div className="p-3 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center" style={{ gap: "7px" }}>
          <span className="pie_graph_head_txt">Call Status</span>
          <div className="total_calls">Total Call : {totalscall}</div>
        </div>
        <div className="date_range-picker">
          <DateRangePicker
            // style={{ left: "780px" }}
            name="start"
            placeholder="Select Date Range"
            format="dd/MM/yyyy"
            character="-"
            placement="bottomEnd"
            onChange={onChange}
            ranges={Ranges}
            // defaultValue={dateValue}
            // cleanable={false}
          />
        </div>
      </div>
      <hr className="mt-0 mb-0" />

      {notconnected != 0 || followup != 0 || dnr != 0 ? (
        <ResponsiveContainer width={"90%"} height={280}>
          {/* <PieChart height={280}>
            <Pie
              data={data}
              label={renderCustomizedLabel}
              cx="50%"
              cy="50%"
              labelLine={false}
              // label={renderCustomizedLabel}
              // outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              startAngle={90}
              endAngle={-270}
              height={280}
              width={280}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>

            <Tooltip />
            <Legend
              align="right"
              verticalAlign="middle"
              layout="vertical"
              formatter={(value) => (
                <span className="text-color-class">{value}</span>
              )}
            />
          </PieChart> */}
          <PieChart height={280}>
            <Pie
              data={data}
              //   label={renderCustomizedLabel}
              cx="50%"
              cy="50%"
              labelLine={false}
              // label={renderCustomizedLabel}
              // outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              startAngle={90}
              endAngle={-270}
              height={280}
              width={280}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>

            <Tooltip content={<CustomTooltip />} />
            <Legend
              align="right"
              verticalAlign="middle"
              layout="vertical"
              formatter={(value) => (
                <span className="text-color-class">{value}</span>
              )}
            />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <div>
          <NoData />
        </div>
      )}
    </div>
  );
};

export default CallStatus;
