import React, { useState, useEffect } from "react";
import AdminNav from "../AdminNavBar/AdminNav";
import "./Settings.css";
import { useHistory } from "react-router-dom";
import YtVideos from "./YtVideos/YtVideos";
import CendrolProjects from "./CendrolProjects/CendrolProjects";
import CendrolCollections from "./CendrolCollections/CendrolCollections";
import TrendingPartners from "./TrendingPartners/TrendingPartners";
import PackSettings from "./PackagesNDocuments/PackSettings";

function Settings() {
  const [activeTab, setActiveTab] = useState("Partner Home Page");
  let history = useHistory();

  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }
  }, []);

  function checkActive(tabName) {
    if (activeTab === tabName) {
      return "activeTab";
    } else {
      return "tabs";
    }
  }

  return (
    <div>
      <div>
        <AdminNav />
      </div>
      <div id="setDiv">
        <h5 id="setHeader" style={{ marginBottom: "25px" }}>
          Settings
        </h5>
        <div class="TabSec">
          <p
            onClick={() => setActiveTab("Partner Home Page")}
            class={checkActive("Partner Home Page")}
            style={{ width: "200px" }}
          >
            {"Partner Home Page"}
          </p>
          <p
            onClick={() => setActiveTab("Packages & Documents")}
            class={checkActive("Packages & Documents")}
            style={{ width: "250px" }}
          >
            {"Packages & Documents"}
          </p>
          <p class="tabs" style={{ width: "100%" }}></p>
        </div>
        {activeTab === "Partner Home Page" && (
          <div>
            <YtVideos />
            <CendrolProjects />
            <CendrolCollections />
            <TrendingPartners />
          </div>
        )}
        {activeTab === "Packages & Documents" && (
          <div>
            <PackSettings />
          </div>
        )}
      </div>
    </div>
  );
}

export default Settings;
