import React, { useState, useEffect } from "react";
import AdminNav from "../AdminNavBar/AdminNav";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { CgArrowRightR } from "react-icons/cg";
import { Link } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import axios from "axios";
import Filter from "../../Assets/Filter.svg";
import { useHistory } from "react-router-dom";
import Footer from "../Dashboard/Footer";
// Pagination
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Helpers/Loader";
import { Formik, Form, Field } from "formik";
import { useParams } from "react-router";
import {
  validateName,
  validateMobileNumber,
  ConstructionDate,
  LastActivityDate,
} from "../Helpers/validation";
import CloseIcon from "@mui/icons-material/Close";
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import { toast } from "react-toastify";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const DMLeadsList = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [userdata, Setuserdata] = useState([]);
  let history = useHistory();
  const [searchterm, Setsearchterm] = useState("");
  const [assignOption, setassignOption] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [btnloader, setbtnloader] = useState(false);

  // Paganiation
  const { PageNo } = useParams();
  const { SearchBar } = Search;
  const [Page, setPage] = useState(PageNo ? PageNo : 1);
  const [PerPage, setPerPage] = useState(50);
  const [searchcount, setsearchcount] = useState([]);
  const [enabledelete, setenabledelete] = useState(false);
  // console.log("enabledelete", enabledelete);
  const [SelectedId, setSelectedId] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [ConstructionDateStart, setConstructionDateStart] = useState("");
  const [ConstructionDateEnd, setConstructionDateEnd] = useState("");

  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }

    getUsers();

    // To Fetch Assign To Options DropDown
    axios.get(`${SERVER_URL}/assignees`).then((response) => {
      //   console.log("Cust", response);
      setassignOption(response.data.Assignees);
    });
  }, []);

  const getUsers = () => {
    axios.post(`${SERVER_URL}/filteronDMleads`).then((response) => {
      Setuserdata(response.data.Leads);
      setisloading(false);
    });
  };

  const selectedRows = userdata.filter((row) => row.isSelect);
  // console.log("selected", selectedRows);
  const LeadSelectedId = selectedRows.map((row) => row._id);
  // console.log("select", LeadSelectedId);

  const deletemultipleuserrecords = () => {
    let arrayids = [];
    arrayids = LeadSelectedId.length === 0 ? SelectedId : LeadSelectedId;
    // userdata.forEach((de) => {
    //   if (de.select) {
    //     arrayids.push(de._id);
    //   }
    // });

    axios
      .post(`${SERVER_URL}/deletecustomers`, {
        arrayids,
      })
      .then((resultdeleteds) => {
        // console.log("FSE deleted", resultdeleteds);
        let Status = resultdeleteds.data.status;
        if (Status === "success") {
          window.$("#MultipleDelete").modal("hide");
          window.$("#SuccessfullyDeleted").modal("show");
          getUsers();
        } else {
          // alert("Internal Server Error");
          toast.error("Internal Server Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => alert(err));
  };

  // For Assigning To
  const setassignes = (e, ids) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };

    // SetVerifystat(e.target.value);
    let selectt = e.target.value;
    const statverified = {
      is_name: selectt,
    };

    // assigncs?_id=622c7b69fd029bd2d20687fd
    axios
      .post(`${SERVER_URL}/assigntoIS?_id=${ids}`, statverified, {
        headers,
      })
      .then((resultsverified) => {
        // console.log("verified orr", resultsverified);
        let Status = resultsverified.data.status;
        if (Status === "success") {
          // alert("Partner Assigned Successfully");
          toast.success("Leads Assigned Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // alert("Error");
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  // OnChange Function for Date Range Picker of Construction Date
  const onChange = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);

      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start > end) {
        setStartDate(end);
        setEndDate(start);
      } else {
        setStartDate(start);
        setEndDate(end);
      }
    } else {
      // console.log("empty");
      setStartDate("");
      setEndDate("");
    }
  };

  const handleJoining = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];

      // Converting it to format dd/mm/yyyy
      // setConstructionDateStart(start.toISOString().slice(0, 10));
      // setConstructionDateEnd(end.toISOString().slice(0, 10));
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start < end) {
        setStartDate(start);
        setEndDate(end);
      } else {
        setStartDate(end);
        setEndDate(start);
      }
    } else {
      // console.log("empty");
      setConstructionDateStart("");
      setConstructionDateEnd("");
    }
  };

  const AllLeadFilters = (data) => {
    setbtnloader(true);
    const sendData = {
      package: data.package,
      area_from: data.area_from,
      area_to: data.area_to,
      construct_from_date: ConstructionDateStart,
      construct_to_date: ConstructionDateEnd,
      persf_from: data.persf_from,
      persf_to: data.persf_to,
      is_name: data.is_name,
      project_from: data.project_from,
      project_to: data.project_to,
      DMsource: data.source,
      booking_from: data.booking_from,
      booking_to: data.booking_to,
      call_status: data.call_status,
      activity_from: startDate,
      activity_to: endDate,
      location: data.location,
      development_name: data.development_name,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/filteronDMleads`, sendData, {
        headers,
      })
      .then((res) => {
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#Add_Ref_popup").modal("hide");
          setPage(1);
          Setuserdata(res.data.Leads);
          setbtnloader(false);
          // remove
          if (res.data.Leads.length !== 0) {
            var number = document.getElementsByClassName(
              "react-bootstrap-table-page-btns-ul"
            )[0];
            var firstnum = number.querySelectorAll("li, a")[1];
            firstnum.click();
          }
        } else {
          setbtnloader(false);
          toast.error("Something Went Wrong...try Again!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          Setuserdata("");
        }
      });
  };

  // New
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const leadCallStatus = (cell) => {
    switch (cell) {
      case "Not Connected":
        return (
          <div>
            <button className="call_not_connected">Not Connected</button>
          </div>
        );
      case "Follow Up":
        return (
          <div>
            <button className="call_follow_up">Follow Up</button>
          </div>
        );
      case "DNR":
        return (
          <div>
            <button className="call_dnr">DNR</button>
          </div>
        );
      case "Interested":
        return (
          <div>
            <button className="call_intersted">Interested</button>
          </div>
        );
      case "Meeting Fixed":
        return (
          <div>
            <button className="call_meeting">Meeting Fixed</button>
          </div>
        );
      case "Qualified":
        return (
          <div>
            <button className="call_qualified">Qualified</button>
          </div>
        );
      case "Not Intersted":
        return (
          <div>
            <button className="call_not_intersted">Not Intersted</button>
          </div>
        );
      case "Meeting Done":
        return (
          <div>
            <button className="call_meet_done">Meeting Done</button>
          </div>
        );
      case "Deal Lost":
        return (
          <div>
            <button className="call_deal_lost">Deal Lost</button>
          </div>
        );
      case "Booking Amt. Received":
        return (
          <div>
            <button className="call_book_amount">Booking Amt. Received</button>
          </div>
        );
      case "Quotation Sent":
        return (
          <div>
            <button className="call_quotation">Quotation Sent</button>
          </div>
        );
      case "Dummy":
        return (
          <div>
            <button className="call_dummy">Dummy</button>
          </div>
        );
      case "Out of scope":
        return (
          <div>
            <button className="call_out_of_scope">Out of scope</button>
          </div>
        );
      default:
        return "-";
    }
  };

  // function onSelectAll(isSelected, rows) {
  //   alert(`is select all: ${isSelected}`);
  //   if (isSelected) {
  //     alert("Current display and selected data: ");
  //   } else {
  //     alert("unselect rows: ");
  //   }
  //   for (let i = 0; i < rows.length; i++) {
  //     alert(rows[i]._id);
  //   }
  // }

  const leadAssign = (cell, row) => {
    return (
      <select
        className="p-2 select_dropdown_assign_to"
        // style={{ width: "100%" }}
        onChange={(e) => {
          setassignes(e, row._id);
        }}
      >
        <option value="">{row.handled_by ? row.handled_by : "Select"}</option>

        {assignOption != ""
          ? assignOption.map((item, i) => {
              if (item.Name != row.assigned_to) {
                return (
                  <option key={i} value={item.Name}>
                    {item.Name}
                  </option>
                );
              }
            })
          : "No Data"}
      </select>
    );
  };

  const covertCreationDate = (cell, row) => {
    let datetime = new Date(row.creationdate).toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
    let CreationDate = datetime.split("/").join("-");
    return <span>{CreationDate !== "" ? CreationDate : ""}</span>;
  };

  const selectRow = {
    mode: "checkbox",
    // onSelectAll: onSelectAll,

    // clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      Setuserdata((rows) => {
        return rows.map((r) => {
          if (r._id !== row._id) {
            return r;
          }
          return { ...r, isSelect };
        });
      });
    },

    onSelectAll: (isSelect, rows, e) => {
      let array = [];
      for (let i = 0; i < rows.length; i++) {
        array.push(rows[i]._id);
        // console.log("arra", array);
        if (array.length > 0) {
          setenabledelete(true);
        } else {
          setenabledelete(false);
        }
        setSelectedId(array);
      }
      // selecting all data
      // Setleaddata((row) => {
      //   return row.map((rows) => {
      //     return { ...rows, isSelect };
      //   });
      // });
    },
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "Lead Name",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "contact_no",
      text: "Mobile Number",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "email",
      text: "Email",
      // sort: true,
      formatter: (cell) => (!cell ? "-" : cell),
      searchable: false,
    },
    {
      dataField: "lead_id",
      text: "DM ID",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "call_status",
      text: "Status",
      // formatter: (cell) => (!cell ? "-" : cell),
      formatter: leadCallStatus,
    },
    {
      dataField: "creationdate",
      text: "Creation Date",
      // formatter: (cell) => (!cell ? "-" : cell),
      formatter: covertCreationDate,
      searchable: false,
    },
    {
      dataField: "handled_by",
      text: "Assigned To",
      formatter: leadAssign,
    },
    {
      dataField: "activityDateTime",
      text: "Last Activity Date",
      formatter: (cell) => (!cell ? "-" : cell),
      sort: true,
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell) => (
        <Link
          to={`/dmleaddetails/${cell}/${Page}`}
          // target="_blank" // remove afterwards
          className="viewmore"
        >
          <CgArrowRightR />
        </Link>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "activityDateTime",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log("row", row);
      let getCurrentCellIndex = e.target.cellIndex;
      // console.log("row", getCurrentCellIndex);
      let getLastCellIndex = document.querySelector(
        "table tr:last-child td:nth-child(8)"
      ).cellIndex;
      // console.log("rows", getLastCellIndex);
      // As this event is for the hole ROW, we need to exclude last one, as we have different events there. You can exclude no matter which one, even pass it as parameter.

      if (
        getCurrentCellIndex !== getLastCellIndex &&
        getCurrentCellIndex !== undefined
      ) {
        // old
        history.push(`/dmleaddetails/${row._id}/${Page}`);
        //new
        // window.open(`/dmleaddetails/${row._id}/${Page}`, "_blank");
        // console.log(`----> ${JSON.stringify(row)} |||| ${rowIndex}`);
      }
    },
  };

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    page: parseInt(Page),
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    // remove these two
    sizePerPage: 50,
    hideSizePerPage: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <div>
      {isloading === false ? (
        <div>
          <div>
            <AdminNav />
          </div>
          <div className="main">
            <div className="content-tabs">
              <ToolkitProvider
                keyField="_id"
                data={userdata}
                columns={columns}
                search={{ afterSearch }}
                defaultSortDirection={{ defaultSorted }}
              >
                {(props) => (
                  <div>
                    <div className="top_menu_bar">
                      <div>
                        <div>
                          <span className="CS_head_txt">
                            Leads From Digital Marketing
                          </span>
                        </div>
                        <span className="total_count_txt">
                          (
                          {searchcount === 0
                            ? searchcount
                            : searchcount > 0
                            ? searchcount
                            : userdata.length}{" "}
                          leads)
                        </span>
                      </div>

                      <div>
                        <div
                          className="d-flex justify-content-end"
                          style={{ gap: "15px" }}
                        >
                          <div className="search_div" style={{ width: "50%" }}>
                            <SearchIcon />
                            <SearchBar
                              {...props.searchProps}
                              className="top_menu_search"
                              placeholder="Search Here..."
                            />
                            {/* <input
                              type="text"
                              className="top_menu_search"
                              placeholder="Search Here.."
                              onChange={(event) => {
                                Setsearchterm(event.target.value);
                              }}
                            /> */}
                          </div>

                          <div>
                            <button
                              className="add_btn"
                              data-toggle="modal"
                              data-target=".Add_Ref_popup"
                            >
                              <img src={Filter} />
                              Filter
                            </button>
                          </div>

                          <button
                            className="add_btn"
                            data-toggle="modal"
                            data-target="#MultipleDelete"
                            style={{ padding: "7px" }}
                          >
                            <DeleteIcon className="del_icons" />
                          </button>

                          {/* <div>
                    <select
                      style={{ border: "none", height: "100%" }}
                      className="form-select select-form"
                      onChange={handleSalesFilter}
                    >
                      <option disabled selected>
                        Select
                      </option>
                      <option value="All">All</option>
                      <option value="sales.cendrol.com">
                        Sales.cendrol.com
                      </option>
                      <option value="build.cendrol.com">
                        Build.cendrol.com
                      </option>
                      <option value="facebook.com">Sales Facebook</option>
                    </select>
                  </div> */}

                          <div
                            id="MultipleDelete"
                            className="modal fade exampleModal"
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-sm modal-dialog-centered ">
                              <div
                                className="modal-content modal-sm modal_content_whole"
                                style={{ background: "white" }}
                              >
                                <div className="modal-body">
                                  <div>
                                    <div className="popup_icon_center">
                                      <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                                    </div>
                                    <div className="popup_text_center">
                                      <span className="popup_addbankdetails_text">
                                        Are you sure want to Delete{" "}
                                        {LeadSelectedId.length !== 0
                                          ? LeadSelectedId.length
                                          : SelectedId.length !== 0
                                          ? SelectedId.length
                                          : ""}{" "}
                                        Leads ?
                                      </span>
                                    </div>
                                    <div className="pt-4 m-0 d-flex justify-content-center">
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="popup_btn_unlinkaccount"
                                          onClick={() => {
                                            deletemultipleuserrecords();
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <button
                                          // type="button"
                                          className="popup_btn_close"
                                          data-dismiss="modal"
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Sucuesfully deleted */}
                          <div
                            className="modal fade popup-bank-details-deleted"
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                            id="SuccessfullyDeleted"
                          >
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                              <div
                                className="modal-content modal-sm modal_content_whole"
                                style={{ background: "white" }}
                              >
                                <div className="modal-body">
                                  <div>
                                    <div className="popup_icon_center">
                                      <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                                    </div>
                                    <div className="pt-2 popup_text_center">
                                      <span className="popup_addbankdetails_text">
                                        Leads Deleted Successfully
                                      </span>
                                      <div className="pt-2 m-0 d-flex justify-content-center">
                                        <div
                                          style={{
                                            width: "50%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <button
                                            // type="button"
                                            className="popup_btn_linkaccount"
                                            data-dismiss="modal"
                                          >
                                            OK
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="table_div">
                        {userdata != "" ? (
                          <BootstrapTable
                            {...props.baseProps}
                            selectRow={selectRow}
                            noDataIndication={emptyDataMessage}
                            pagination={paginationFactory(paginationoptions)}
                            filter={filterFactory()}
                            rowEvents={rowEvents}
                            // classes={"scroll_table"}
                            wrapperClasses="table-responsive"
                          />
                        ) : (
                          <div className="d-flex">
                            <div>
                              <WarningRoundedIcon className="no_data_alert" />
                            </div>
                            <div>
                              <h3>No records found for Customers</h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>

          {/* Filter Pop Up start */}
          <div>
            <div
              id="Add_Ref_popup"
              className="modal fade Add_Ref_popup"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div
                  className="modal-content modal_content_whole"
                  style={{ background: "white" }}
                >
                  <div className="modal-body" style={{ background: "#FFFFFF" }}>
                    <div className="pb-3">
                      <span className="add_hot_lead_label">Apply Filters</span>
                      <span
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="CloseAddHotLeadPopup"
                        style={{
                          background: "transparent",
                          float: "right",
                          color: "#221e1f",
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                    <Formik
                      initialValues={{
                        package: "",
                        area_from: "",
                        area_to: "",
                        persf_from: "",
                        persf_to: "",
                        is_name: "",
                        project_from: "",
                        project_to: "",
                        source: "",
                        booking_from: "",
                        booking_to: "",
                        call_status: "",
                        location: "",
                        development_name: "",
                      }}
                      // validationSchema={validate}
                      onSubmit={(values, { resetForm }) => {
                        AllLeadFilters(values);
                      }}
                    >
                      {(formik) => (
                        <div>
                          <Form>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Package
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="package"
                                  >
                                    <option disabled selected value="">
                                      Select Package
                                    </option>
                                    <option value="Standard">Standard</option>
                                    <option value="Cendrol Plus">
                                      Cendrol Plus
                                    </option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Area(SQ.FT)
                                  </label>
                                  <div className="d-flex">
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="0sq.ft"
                                        id="from"
                                        name="area_from"
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="align-self-center to_txt">
                                      to
                                    </div>
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="100sq.ft"
                                        id="from"
                                        name="area_to"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Construction Start Date
                                  </label>
                                  <DateRangePicker
                                    placeholder="Select Date Range"
                                    format="dd/MM/yyyy"
                                    character="-"
                                    onChange={handleJoining}
                                    ranges={ConstructionDate}
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Per SQ.FT. Amount
                                  </label>
                                  <div className="d-flex">
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 0"
                                        id="from"
                                        name="persf_from"
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="align-self-center to_txt">
                                      to
                                    </div>
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 100"
                                        id="from"
                                        name="persf_to"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Inside Sales
                                  </label>
                                  <div className="input-group_add_hot_lead ">
                                    <Field
                                      as="select"
                                      className="field_input_txt"
                                      name="is_name"
                                    >
                                      <option disabled selected value="">
                                        Select User
                                      </option>
                                      {assignOption != ""
                                        ? assignOption.map((item, i) => {
                                            return (
                                              <option key={i} value={item.Name}>
                                                {item.Name}
                                              </option>
                                            );
                                          })
                                        : ""}
                                    </Field>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Project Value
                                  </label>
                                  <div className="d-flex">
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 0"
                                        id="from"
                                        name="project_from"
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="align-self-center to_txt">
                                      to
                                    </div>
                                    <div>
                                      <Field
                                        type="text"
                                        className="field_input_txt"
                                        placeholder="Rs 100"
                                        id="from"
                                        name="project_to"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Lead Source
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="source"
                                  >
                                    <option disabled selected value="">
                                      Select Source
                                    </option>
                                    <option value="cendrol.com">
                                      Cendrol.com
                                    </option>
                                    <option value="sales.cendrol.com">
                                      Sales.cendrol.com
                                    </option>
                                    <option value="build.cendrol.com">
                                      Build.cendrol.com
                                    </option>
                                    <option value="facebook.com">
                                      Sales.Facebook
                                    </option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Booking Amount
                                </label>
                                <div className="d-flex">
                                  <div>
                                    <Field
                                      type="text"
                                      className="field_input_txt"
                                      placeholder="Rs 0"
                                      id="from"
                                      name="booking_from"
                                      autoComplete="off"
                                    />
                                  </div>
                                  <div className="align-self-center to_txt">
                                    to
                                  </div>
                                  <div>
                                    <Field
                                      type="text"
                                      className="field_input_txt"
                                      placeholder="Rs 100"
                                      id="from"
                                      name="booking_to"
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Call Status
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="call_status"
                                  >
                                    <option disabled selected value="">
                                      Select Call Status
                                    </option>
                                    <option value="Not Connected">
                                      Not Connected
                                    </option>
                                    <option value="Follow Up">Follow Up</option>
                                    <option value="DNR">DNR</option>
                                    <option value="Interested">
                                      Interested
                                    </option>
                                    <option value="Meeting Fixed">
                                      Meeting Fixed
                                    </option>
                                    <option value="Qualified">Qualified</option>
                                    <option value="Not Intersted">
                                      Not Intersted
                                    </option>
                                    <option value="Dummy">Dummy</option>
                                    <option value="Out of scope">
                                      Out of scope
                                    </option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Last Activity Date
                                  </label>
                                  <DateRangePicker
                                    placeholder="Select Date Range"
                                    format="dd/MM/yyyy"
                                    character="-"
                                    onChange={onChange}
                                    ranges={LastActivityDate}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Plot Location
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    type="text"
                                    className="field_input_txt"
                                    placeholder="Enter Plot Location"
                                    id="from"
                                    name="location"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Development Name
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    type="text"
                                    className="field_input_txt"
                                    placeholder="Enter Development Name"
                                    id="from"
                                    name="development_name"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row pt-4">
                              <div className="col-12 col-md-6">
                                <button
                                  type="reset"
                                  className="btn btn-lg btn_resetall"
                                >
                                  Reset All
                                </button>
                              </div>
                              <div className="col-12 col-md-6">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn_apply_filter"
                                >
                                  {btnloader === true ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    ""
                                  )}
                                  Apply Filter
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Filter Pop Up End */}
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default DMLeadsList;
