import React from "react";
import { Field } from "formik";

const LeadStage2Details = ({
  values,
  errors,
  touched,
  showtarget2,
  creditedstat2,
  paymentstage2,
  stage2,
  releaserangefrom2,
  releaserangeto2,
  paymentdate2,
  paymentmode2,
  constStage2,
}) => {
  return (
    <>
      {!showtarget2 && (
        <div>
          <div
            className=""
            style={{
              background: "#f4f4f4",
              padding: "15px",
              borderRadius: "10px",
              paddingBottom: "25px",
            }}
          >
            <div className="row">
              <div className="col-6 col-md-3">
                <div>
                  <p className="m-0 admin_type_heading">Amount</p>
                  <span className="admin_type_value">
                    {paymentstage2 != "" ? paymentstage2 : "0"}
                    /-
                  </span>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div>
                  <p className="m-0 admin_type_heading">Construction Stage</p>
                  <span className="admin_type_value">
                    {constStage2 != "" ? constStage2 : "-"}
                  </span>
                </div>
              </div>
              <div className="col-6 col-md-2">
                <div>
                  <p className="m-0 admin_type_heading">Release %</p>
                  <span className="admin_type_value">33</span>
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div>
                  <p className="m-0 admin_type_heading">Release Range</p>
                  <span className="admin_type_value">
                    {releaserangefrom2 != "" &&
                    releaserangefrom2 != undefined &&
                    releaserangeto2 != "" &&
                    releaserangeto2 != undefined ? (
                      <span>
                        {releaserangefrom2
                          .slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("-")}{" "}
                        -{" "}
                        {releaserangeto2
                          .slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("-")}
                      </span>
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
              </div>
              <div className="col-6 col-md-3 mt-3">
                <div>
                  <p className="m-0 admin_type_heading">Payment Date</p>
                  <span className="admin_type_value">
                    {paymentdate2 != ""
                      ? paymentdate2.slice(0, 10).split("-").reverse().join("-")
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="col-6 col-md-3 mt-3">
                <div>
                  <p className="m-0 admin_type_heading">Payment Mode</p>
                  <span className="admin_type_value">
                    {paymentmode2 != "" ? paymentmode2 : "-"}
                  </span>
                </div>
              </div>
              <div className="col-6 col-md-2 mt-3">
                <div>
                  <p className="m-0 admin_type_heading">Stage</p>
                  <span className="admin_type_value">
                    {stage2 != "" ? stage2 : "-"}
                  </span>
                </div>
              </div>
              <div className="col-6 col-md-4 mt-3">
                <div>
                  <p className="m-0 admin_type_heading">Credited</p>
                  <span className="admin_type_value">
                    {creditedstat2 == true ? "Yes" : "No"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showtarget2 && (
        <div className="edit_details">
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="inputName" className="label">
                  Amount
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="inputName"
                  name="amount"
                  value={values.amount}
                  //   onChange={(e) => {
                  //     Setpaymentstage2(e.target.value);
                  //   }}
                />
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="comp" className="label">
                  Construction Stage
                </label>
                {/* <input
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="comp"
                  name="company_name"
                  value={leadconstructionstage}
                  onChange={(e) => {
                    Setleadconstructionstage(e.target.value);
                  }}
                /> */}
                <Field
                  as="select"
                  // style={{ width: "100%" }}
                  //   onChange={(e) => SetconstStage2(e.target.value)}
                  name="construction_stage"
                  value={values.construction_stage}
                  className="input-area admin_partner_det_input"
                >
                  <option defaultValue={""}>Select</option>
                  <option value="Post-Design">Post-Design</option>
                  <option value="Post-Mobilization">Post-Mobilization</option>
                  <option value="Post-Foundation">Post-Foundation</option>
                  <option value="Post-First meeting">Post-First meeting</option>
                  <option value="Post-Ground Floor slab">
                    Post-Ground Floor slab
                  </option>
                  <option value="Post-First Floor">Post-First Floor</option>
                </Field>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="comp" className="label">
                  Release Range From
                </label>
                <Field
                  type="date"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="comp"
                  name="payment_date_from"
                  value={values.payment_date_from}
                  //   onChange={(e) => {
                  //     Setreleaserangefrom2(e.target.value);
                  //   }}
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="comp" className="label">
                  Release Range To
                </label>
                <Field
                  type="date"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="comp"
                  name="payment_date_to"
                  value={values.payment_date_to}
                  //   onChange={(e) => {
                  //     Setreleaserangeto2(e.target.value);
                  //   }}
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="comp" className="label">
                  Payment Date
                </label>
                <Field
                  type="date"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="comp"
                  name="payment_date"
                  value={values.payment_date}
                  //   onChange={(e) => {
                  //     Setpaymentdate2(e.target.value);
                  //   }}
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="comp" className="label">
                  Payment Mode
                </label>
                {/* <input
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="comp"
                  name="payment_mode"
                  value={paymentmode2}
                  onChange={(e) => {
                    Setpaymentmode2(e.target.value);
                  }}
                /> */}
                <Field
                  as="select"
                  id="comp"
                  name="payment_mode"
                  value={values.payment_mode}
                  className="input-area admin_partner_det_input"
                  defaultValue={"select"}
                  //   onChange={(e) => {
                  //     Setpaymentmode2(e.target.value);
                  //   }}
                >
                  <option selected value="">
                    Select
                  </option>
                  <option value="Cash">Cash</option>
                  <option value="NEFT">NEFT</option>
                </Field>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="comp" className="label">
                  Stage
                </label>
                {/* <input
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="comp"
                  name="company_name"
                  value={stage2}
                  onChange={(e) => {
                    Setstage2(e.target.value);
                  }}
                /> */}
                <Field
                  id="comp"
                  as="select"
                  name="stage"
                  value={values.stage}
                  className="input-area admin_partner_det_input"
                  defaultValue={"select"}
                  //   onChange={(e) => {
                  //     Setstage2(e.target.value);
                  //   }}
                >
                  <option selected value="">
                    Select
                  </option>
                  <option value="Stage 2">Stage 2</option>
                </Field>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <label htmlFor="comp" className="label">
                Credited
              </label>
              <br />
              <Field
                as="select"
                name="credited"
                value={values.credited}
                // onChange={(e) => Setcreditedstat2(e.target.value)}
                className="input-area admin_partner_det_input"
              >
                <option defaultValue={""}>Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadStage2Details;
