import React, { useEffect, useState } from "react";
import "./stepProgress.css";
import tick from "../../../Assets/tick.png";

import AddIcon from "@mui/icons-material/Add";
// import fs from "fs";
import DownloadIcon from "@mui/icons-material/Download";
import receipt from "../../../Assets/receipt-text.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { ContentPaste } from "@mui/icons-material";
import { SelectField } from "../../Fields/SelectField";
import { useSearch } from "rsuite/esm/Picker";
import NewFloorRow from "./NewFloorRow";
import NextFloors from "./NextFloors";
import InclusionDetails from "./InclusionDetails";
import { useHistory } from "react-router-dom";
import axios from "axios";
let FloorDetails = [];
var sizearray = [];
var alldata = [];
var calval;
var inclu = [];
var areadetails = [];
var TotalConstructionCost;
var AllFloorDetails = [];
var Alldetailsarray = [];
function StepProgress({ bdeid }) {
  console.log("BDE ID", bdeid);
  console.log("FloorDetails", FloorDetails);
  // Setting all the values to state
  const [progressColor, setProgressColor] = useState("0%");
  const [toggleState, setToggleState] = useState(1);
  const stepperArr = ["0", "25", "50", "75", "100"];
  const [counter2, updateCounter1] = useState(0);
  const [active, setActive] = useState("");
  const [counter, setCounter] = useState(1);
  const [counter1, setCounter1] = useState(1);
  const [caldivs, Setcaldivs] = useState(false);
  const [arealength, Setarealength] = useState("");
  const [areawidth, Setareawidth] = useState("");
  const [totalsqft, Settotalsqft] = useState("");
  const [builtuparea, Setbuiltuparea] = useState("");
  const [arealengthfiled, Setarealengthfiled] = useState(false);
  const [Pack, SetPack] = useState("");
  const [SecondStepSize, SetSecondStepSize] = useState("");
  const [SecondStepDesc, SetSecondStepDesc] = useState("");
  const [remainingarea, Setremainingarea] = useState("");
  const [ty, Setty] = useState([]);
  const [documentname, Setdocumentname] = useState([]);

  const [viewpdfbtn, Setviewpdfbtn] = useState(false);
  //states to set values for final pdf from lead details
  const [name, Setname] = useState("");
  const [Mobilenumber, SetMobilenumber] = useState("");
  const [Email, SetEmail] = useState("");
  const [Customerloc, SetCustomerloc] = useState("");
  const [Plotlocation, SetPlotlocation] = useState("");
  const [Packageval, SetPackageval] = useState([]);
  const [Qid, SetQid] = useState("");

  const stepLabel = [
    "Area Details",
    "Floor Details",
    "Inclusion Details",
    "Package Details",
    "Documents Inclusion",
  ];
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  var builtupar;

  let history = useHistory();
  useEffect(() => {
    // to get ACTIVE Packages
    axios.get(`${SERVER_URL}/getActivePackages`).then((resule) => {
      console.log("rt12", resule);
      SetPackageval(resule.data.result);
    });

    //to get all document names
    axios.get(`${SERVER_URL}/getdocumentnames`).then((result) => {
      console.log("Document names", result.data.result);
      Setdocumentname(result.data.result);
      // to get details for lead by id
      axios.get(`${SERVER_URL}/leadbyid?_id=${bdeid}`).then((reps) => {
        console.log("bde", reps.data.listofcustomers);
        Setname(reps.data.listofcustomers.name);
        SetMobilenumber(reps.data.listofcustomers.contact_no);
        SetEmail(reps.data.listofcustomers.email);
        SetCustomerloc(reps.data.listofcustomers.location);
        SetPlotlocation(reps.data.listofcustomers.plot_location);
        // Setting all the lead detail values into local storage
      });
    });

    // taking width and length to calculate total area
    const total = arealength * areawidth;
    // Taking 80% of total area and setting it to builtup area
    builtupar = (total / 100) * 80;
    Settotalsqft(total);
    Setbuiltuparea(builtupar);
  }, [areawidth, arealength]);

  const generatenewrow = (e) => {
    e.preventDefault();
    setCounter(counter + 1);
    // console.log(counter);
  };
  const generatenewrowforinclusion = (e) => {
    e.preventDefault();
    setCounter1(counter1 + 1);
    // console.log(counter);
  };

  // 3rd Counter for no of floors
  function handleIncrement1() {
    updateCounter1(counter2 + 1);
    console.log("counter val", counter2 + 1);
  }

  function handleDecrement1() {
    updateCounter1(counter2 - 1 <= 0 ? 0 : counter2 - 1);
    console.log("counter val", counter2);
  }
  function handleProgress(perc) {
    const percVal = parseInt(perc);
    setProgressColor(percVal + "%");
    // Parent component function
  }
  const toggleTab = (index) => {
    setToggleState(index);
  };
  function handleNext(perc, index) {
    const percVal = parseInt(perc) + 25;
    setProgressColor(percVal + "%");
  }

  const validatelength = (e) => {
    let art = e.target.value;
    console.log("VALSO", art);
    if (!art) {
      // Setarealengthfiled(true);
    }
    return true;
  };

  // Function to take user values from first step (len,width,totalbuiltup,total size)
  const validData = () => {
    if (
      arealength == "" ||
      areawidth == "" ||
      totalsqft == "" ||
      builtuparea == ""
      // counter2 === 0
    ) {
      alert("Please Fill all the details");
    } else {
      // pushing all the details to areadetails array
      areadetails = {
        area_length: arealength,
        area_width: areawidth,
        total_area: totalsqft,
        builtup_area: builtuparea,
        no_of_floors: counter2,
      };

      // Total area multiplying builtup area with no of Floors(counter2) variable and setting to localstorage
      if (counter2 == 0) {
        var totalcost = builtuparea * 1;
        localStorage.setItem("areadetls", JSON.stringify(areadetails));
        localStorage.setItem("totalcost", totalcost);
        handleNext(progressColor, "1");
      } else if (counter2 == 1) {
        var totalcost = builtuparea * 2;
        localStorage.setItem("areadetls", JSON.stringify(areadetails));
        localStorage.setItem("totalcost", totalcost);
        handleNext(progressColor, "1");
      } else if (counter2 == 2) {
        var totalcost = builtuparea * 3;
        localStorage.setItem("areadetls", JSON.stringify(areadetails));
        localStorage.setItem("totalcost", totalcost);
        handleNext(progressColor, "1");
      } else {
        var totalcost = builtuparea * 4;
        localStorage.setItem("areadetls", JSON.stringify(areadetails));
        localStorage.setItem("totalcost", totalcost);
        handleNext(progressColor, "1");
      }

      // var totalcost = builtuparea * (counter2 != 0 ? counter2 : 1) ;
      // localStorage.setItem("areadetls", JSON.stringify(areadetails));
      // localStorage.setItem("totalcost", totalcost);
      // // Setarealengthfiled(false);
      // handleNext(progressColor, "1");
    }
  };

  // Function to save extra inclusion values it could be multiple valued array {[...],[...]}
  const saveinclusion = (inc, amt) => {
    inclu.push({
      inclusions: inc,
      amount: amt,
    });
    localStorage.setItem("Inclusion", JSON.stringify(inclu));
    console.log("inclusion value", inclu);
  };

  // function to set selected package (standard or cendrol plus)
  const SetpackageSelected = (packageval) => {
    localStorage.setItem("package", packageval);
    const totalval = localStorage.getItem("totalcost");
    TotalConstructionCost = totalval * packageval;
    console.log("After package", TotalConstructionCost);
    localStorage.setItem("constructioncost", TotalConstructionCost);
  };

  // Function to store the selected documents into an array called "document selected"
  function printChecked(isChecked, value) {
    var items = document.getElementsByName("tr");
    var documentselected = [];
    for (var i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox" && items[i].checked == true)
        documentselected.push(items[i].value);
    }
    localStorage.setItem("docselected", JSON.stringify(documentselected));
  }

  // Button  Function of No of Floors +/-
  function Btn({ children, onClick }) {
    return (
      <button
        style={{
          paddingRight: "5px",
          paddingLeft: "5px",
          background:
            "linear-gradient(162.29deg, #FFE185 -6.85%, #F7BA00 100.08%)",
          borderRadius: "2px",
          border: "none",
          height: "20px",
          fontSize: "20px",
          fontFamily: "gb",
        }}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  const AllDataToPost = () => {
    const Area_Details = JSON.parse(localStorage.getItem("areadetls"));
    var AllFloor_Data = JSON.parse(localStorage.getItem("AllFloordata"));
    const Inclusion_Details = JSON.parse(localStorage.getItem("Inclusion"));
    const package_selected = JSON.parse(localStorage.getItem("package"));
    const Document_Selected = JSON.parse(localStorage.getItem("docselected"));
    const construction_cost = JSON.parse(
      localStorage.getItem("constructioncost")
    );
    Alldetailsarray = {
      lead_name: name,
      lead_email: Email,
      lead_mobile: Mobilenumber,
      lead_location: Customerloc,
      plot_location: Plotlocation,
      selected_documents: Document_Selected,
      ...Area_Details,
      floor_details: AllFloor_Data,
      inclusion_details: Inclusion_Details,
      package_selected,
      construction_cost: construction_cost,
    };

    console.log("Area_Details", Alldetailsarray);

    axios
      .post(`${SERVER_URL}/generatequote?lead_id=${bdeid}`, Alldetailsarray)
      .then((resps) => {
        let Status = resps.data.status;
        if (Status === "success") {
          Setviewpdfbtn(true);
          // history.push(`/quotepdf/${resps.data.Quote._id}`);
          localStorage.removeItem("AllFloordata");
        }
        console.log("tt122", resps.data.Quote._id);
        SetQid(resps.data.Quote._id);
      });
    localStorage.clear();
  };

  const viewnsavepdf = () => {
    axios.get(`${SERVER_URL}/saveQuote?lead_id=${Qid}`).then((res) => {
      let Status = res.data.status;
      if (Status === "success") {
        Setviewpdfbtn(true);
        window.open(`/quotepdfview/${res.data.PDFname}`, "_blank");
        localStorage.removeItem("AllFloordata");
      }
      console.log("pdf viewfile", res.data.PDFname);
    });
    localStorage.clear();
  };

  return (
    <>
      <div className="table_div d-flex justify-content-center">
        <div id="parentContainer" className="">
          <div id="bar">
            <div id="progressColor" style={{ width: progressColor }} />
          </div>
          {stepperArr.map((perc, index) => {
            const percVal = perc + "%";
            let bgcolor = "grey";
            let imgsrc = "";
            const tickImgPerc = parseInt(perc) + 20;
            parseInt(progressColor) + 25 > parseInt(tickImgPerc)
              ? (bgcolor = "#FCC216")
              : (bgcolor = "white");
            parseInt(progressColor) > parseInt(tickImgPerc)
              ? (imgsrc = tick)
              : (imgsrc = "none");

            // console.log("PERV", perc, progressColor);
            return (
              <div
                className="stepper"
                onClick={() => {
                  handleProgress(perc);
                  toggleTab(index + 1);
                }}
                // style={{ pointerEvents: "none" }}
              >
                {imgsrc !== "none" && <img src={imgsrc} className="tickImg" />}
                <div className="dot" style={{ backgroundColor: bgcolor }} />
                {/* {bgcolor !== "white" && ( */}
                {/* <p className="stepperLabel">{stepLabel[index]}</p> */}
                {/* )} */}
              </div>
            );
          })}
        </div>

        {/* Labels */}
        <div className="d-flex justify-content-around labels_div">
          <div>
            <p className="stepperLabel">Area Details</p>
          </div>
          <div>
            <p className="stepperLabel">Floor Details</p>
          </div>
          <div>
            <p className="stepperLabel">Inclusion Details</p>
          </div>
          <div>
            <p className="stepperLabel">Package Details</p>
          </div>
          <div>
            <p className="stepperLabel">Documents Inclusion</p>
          </div>
        </div>
      </div>
      <br />
      <div className="table_div">
        <div
          className={
            progressColor === "0%" ? "content1  active-content1" : "content1"
          }
        >
          <div className="mt-3 p-2">
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-2 col-md-2">
                <label className="area_details_label">Area Length</label>
                <input
                  type="number"
                  className="input_fields_quote"
                  placeholder="Enter Area Length"
                  onChange={(e) => {
                    Setarealength(e.target.value);
                    // validData();
                    // validatelength(e.target.value);
                  }}
                  required
                />
                {/* {arealengthfiled ? (
                  <div className="pt-2 error">please enter the details</div>
                ) : (
                  " "
                )} */}
                {/* {error && <div className="pt-2 error">{error.arealength}</div>} */}
              </div>
              <div className="col-2 col-md-2">
                <label className="area_details_label">Area Width</label>
                <input
                  type="number"
                  className="input_fields_quote"
                  placeholder="Enter Area Width"
                  required
                  onChange={(e) => {
                    Setareawidth(e.target.value);
                  }}
                />
              </div>
              <div className="col-2 col-md-2">
                <label className="area_details_label">
                  Total Area In Sq.ft
                </label>
                <input
                  type="text"
                  className="input_fields_quote"
                  placeholder="Total Area In Sq.ft."
                  required
                  value={totalsqft}
                  // onChange={(e) => {
                  //   Settotalsqft(e.target.value);
                  // }}
                />
              </div>
              <div className="col-2 col-md-2">
                <label className="area_details_label">
                  Total Built Up Area
                </label>
                <input
                  type="text"
                  className="input_fields_quote"
                  placeholder="Total Built Up Area"
                  required
                  defaultValue={builtuparea}
                  // value={builtuparea}
                  // onChange={(e) => {
                  //   Setbuiltuparea(e.target.value);
                  // }}
                />
              </div>
              <div className="col-2 col-md-2">
                <label className="area_details_label">No of Floors</label>
                <div
                  className="d-flex align-items-center"
                  style={{ lineHeight: "1rem", fontFamily: "gsb" }}
                >
                  <div style={{ fontFamily: "Gsb", fontSize: "25px" }}>G+</div>
                  <div className="d-flex align-items-center floor_inc">
                    <span
                      style={{
                        fontSize: "20px",
                        padding: "10px",
                        verticalAlign: "Middle",
                      }}
                    >
                      {counter2}
                    </span>
                    <div className="d-flex flex-column" style={{ gap: "5px" }}>
                      <Btn onClick={handleIncrement1}>+</Btn>
                      <Btn onClick={handleDecrement1}>-</Btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="submit_cont_btn mt-4"
              onClick={() => {
                validData();
                // handleNext(progressColor, "1");
              }}
            >
              Submit and Continue
              <ArrowRightAltIcon />
            </button>
            {/* <button onClick={subo}>Stt</button> */}
          </div>
        </div>

        <div
          className={
            progressColor === "25%" ? "content1  active-content1" : "content1"
          }
        >
          <div className="mt-3 p-2">
            {[...Array(counter2 + 1)].map((elementInArray, index) => (
              <NextFloors
                indx={index}
                builtuparea={builtuparea}
                coun={counter2}
              />
            ))}
            <button
              className="submit_cont_btn mt-3"
              onClick={() => {
                handleNext(progressColor, "2");
                // console.log("Progress color", progressColor);
              }}
            >
              Submit and Continue
              <ArrowRightAltIcon />
            </button>
            <br />
          </div>
        </div>
        <div
          className={
            progressColor === "50%" ? "content1  active-content1" : "content1"
          }
        >
          <div className="mt-3 p-2">
            <div>
              {Array.from(Array(counter1)).map((c, counter1) => {
                return <InclusionDetails saveinclusion={saveinclusion} />;
              })}
              <div className="mt-2 mb-2">
                <button
                  className="add_new_btn"
                  onClick={generatenewrowforinclusion}
                >
                  <AddIcon className="add_icon" /> Add New
                </button>
                <div className="border_line mt-3 mb-2"></div>
              </div>
            </div>
            <button
              className="submit_cont_btn mt-3"
              onClick={() => {
                handleNext(progressColor, "2");
                console.log("Progress color", progressColor);
              }}
            >
              Submit and Continue
              <ArrowRightAltIcon />
            </button>
          </div>
        </div>
        <div
          className={
            progressColor === "75%" ? "content1  active-content1" : "content1"
          }
        >
          <div className="mt-3 p-2">
            <div className="d-flex justify-content-start pack_btn_parent">
              {Packageval != ""
                ? Packageval.map((ipo, index) => {
                    return (
                      <div className="">
                        <div
                          // className="package_btns_qg"
                          className={`${
                            active === index
                              ? "activeButton package_btns_qg"
                              : "package_btns_qg"
                          }`}
                          onClick={() => {
                            setActive(index);
                            SetpackageSelected(ipo.packages.package_price);
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{ fontFamily: "Gsb", fontSize: "20px" }}
                          >
                            Rs.{ipo.packages.package_price}/-
                            <span
                              style={{ fontFamily: "Gm", fontSize: "12px" }}
                            >
                              SBUA
                            </span>
                          </p>
                          <span className="Package_name">
                            {ipo.packages.package_name}
                          </span>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            {caldivs != false ? (
              <div className="mt-3 total_cost_div">
                <span>Total Approximate Cost</span>
                <p className="mb-0">Rs. {TotalConstructionCost}/-</p>
              </div>
            ) : (
              ""
            )}
            {caldivs == false ? (
              <button
                className="submit_cont_btn mt-3"
                onClick={() => {
                  Setcaldivs(true);
                }}
              >
                <img src={receipt} width="13%" className="mr-2" />
                Calculate Total Cost
              </button>
            ) : (
              ""
            )}
            {caldivs == true ? (
              <button
                className="submit_cont_btn mt-3 "
                onClick={() => {
                  handleNext(progressColor, "2");
                  console.log("Progress color", progressColor);
                }}
              >
                Add Documents
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={
            progressColor === "100%" ? "content1  active-content1" : "content1"
          }
        >
          <div className="p-2">
            <h4>Documents to be included</h4>

            {documentname != ""
              ? documentname.map((inds, index) => {
                  return (
                    <div className="row">
                      <div className="col-1">
                        <input
                          type="checkbox"
                          name="tr"
                          value={inds.Name}
                          onChange={(e) => {
                            printChecked(
                              e.currentTarget.checked,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="col-10">
                        <span>{inds.Name}</span>
                      </div>
                    </div>
                  );
                })
              : ""}
            <div className="border_line mt-3 mb-2"></div>
            <button
              className="submit_cont_btn mt-3"
              onClick={() => {
                // handleNext(progressColor, "2");
                AllDataToPost();
                console.log("Progress color", progressColor);
              }}
            >
              <DownloadIcon />
              Generate Pdf
            </button>
            {viewpdfbtn === true ? (
              <button
                className="submit_cont_btn mt-3"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  viewnsavepdf();
                }}
              >
                <DownloadIcon />
                View PDF
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default StepProgress;
