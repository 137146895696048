import React from "react";
import { Field } from "formik";

const PartnerDeductions = ({
  values,
  errors,
  touched,
  showreftds,
  tdsvalue,
}) => {
  return (
    <>
      {!showreftds && (
        <div className="viewing_details">
          <div className="row">
            <div className="col-6 col-md-6">
              <div>
                <p className="m-0 admin_type_heading">TDS</p>
                <span className="admin_type_value">
                  {tdsvalue === "" ? "-" : tdsvalue} %
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {showreftds && (
        <div className="edit_details">
          <div className="row">
            <div className="col-6 col-md-6">
              <div className="input-group-custom">
                <label htmlFor="loc" className="label">
                  TDS
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="inputName"
                  name="tds"
                  value={values.tds}
                  //   onChange={(e) => {
                  //     Settdsvalue(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PartnerDeductions;
