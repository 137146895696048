import React, { useState, useEffect } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import NoData from "./NoData";

import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import { startOfDay, endOfDay, subMonths, subYears } from "date-fns";

const EarningDetails = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [bargraphdata, setbargraphdata] = useState([]);

  //   Date
  var date = new Date();
  let dateValue = [
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ];

  // const [startDate, setStartDate] = useState(dateValue[0].toLocaleDateString());
  // const [endDate, setEndDate] = useState(dateValue[1].toLocaleDateString());

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    getEarnData();
  }, [startDate, endDate]);

  const getEarnData = () => {
    axios
      .get(
        `${SERVER_URL}/earninggraph?from_date=${startDate}&to_date=${endDate}`
      )
      .then((res) => {
        // console.log("researning", res);
        setbargraphdata(res.data.result);
        // if (res.data.earnings.length === 1) {
        //   setbookingAmount(res.data.earnings[0].bookingamt);
        //   setamountSpent(res.data.earnings[0].partneramt);
        // } else {
        //   setbookingAmount("");
        //   setamountSpent("");
        // }
      });
    // .catch((err) => alert(err));
  };

  const options = {
    plugins: {
      legend: {
        title: {
          position: "end",
        },
        align: "end",
      },
    },

    layout: {
      padding: {
        left: 50,
      },
    },

    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          drawBorder: false, // <-- this removes y-axis line
          lineWidth: 0.5,
        },
      },
    },
  };

  const data = {
    labels: bargraphdata.map(function (r) {
      return r.monthName;
    }),
    datasets: [
      {
        label: "Booking Amount Received",
        data: bargraphdata.map(function (r) {
          return r.totalBookingAmt;
        }),
        backgroundColor: "#AAFDBC",
        barPercentage: 1.0,
        // categoryPercentage: 1.0,
      },
      {
        label: "Partner Payments",
        data: bargraphdata.map(function (r) {
          return r.totalPartnerAmt;
        }),
        backgroundColor: "#FB6F6F",
        barPercentage: 1.0,
      },
    ],
  };

  // OnChange Function for Date Range Picker
  const onChange = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start < end) {
        setStartDate(start);
        setEndDate(end);
      } else {
        setStartDate(end);
        setEndDate(start);
      }
    } else {
      //   console.log("empty");
      setStartDate("");
      setEndDate("");
    }
  };

  const Ranges = [
    {
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Month",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
    },
    {
      label: "3 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
    },
    {
      label: "6 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
    },
    {
      label: "Year",
      value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
    },
  ];

  // Graph
  // const data = [
  //   { name: "Booking Amount Received", value: bookingAmount },
  //   { name: "Partner Payments", value: amountSpent },
  // ];

  // const COLORS = ["rgba(60,214,93)", "rgba(239,59,59)"];
  // const CustomTooltip = ({ active, payload }) => {
  //   if (active) {
  //     return (
  //       <div
  //         className="subscribers-by-channel-tooltip"
  //         style={{
  //           background: "#fff",
  //           color: "#000",
  //           padding: "5px",
  //           border: "1px solid #000",
  //         }}
  //       >
  //         <p className="subscribers-by-channel-tooltip-title mb-0 mt-0">
  //           {payload[0].name}
  //         </p>
  //         <p className="subscribers-by-channel-tooltip-label mb-0 mt-0">{`Value : ${payload[0].value}`}</p>
  //         <p className="subscribers-by-channel-tooltip-intro mb-0 mt-0">
  //           {" "}
  //           {`Percentage : ${payload[0].payload.payload.percentage}`}
  //         </p>
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  return (
    <div>
      <div className="py-4 d-flex justify-content-between align-items-center">
        <span className="pie_graph_head_txt">
          Booking Amt. vs Partner Payment
        </span>
        <div>
          <DateRangePicker
            // style={{ left: "780px" }}
            name="start"
            placeholder="Select Date Range"
            format="dd/MM/yyyy"
            character="-"
            placement="bottomEnd"
            onChange={onChange}
            ranges={Ranges}
            // defaultValue={dateValue}
            // cleanable={false}
          />
        </div>
      </div>
      <div className="p-3 dashboard_line_graph">
        {bargraphdata.length !== 0 ? (
          <div>
            {/* 
               <ResponsiveContainer width={"90%"} height={280}>
              <PieChart height={280}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                // label={renderCustomizedLabel}
                // outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                startAngle={90}
                endAngle={-270}
                height={280}
                width={280}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>

              <Tooltip content={<CustomTooltip />} />
              <Legend
                align="right"
                verticalAlign="middle"
                layout="vertical"
                formatter={(value) => (
                  <span className="text-color-class">{value}</span>
                )}
              />
            </PieChart> 
            </ResponsiveContainer>*/}
            <Bar options={options} data={data} />
          </div>
        ) : (
          <div>
            <NoData />
          </div>
        )}
      </div>
    </div>
  );
};

export default EarningDetails;
