import React from "react";
import { Field } from "formik";

const PartnerSource = ({
  values,
  errors,
  touched,
  showPartnerSource,
  partnerSource,
}) => {
  return (
    <>
      {!showPartnerSource && (
        <div className="viewing_details">
          <div>
            <p className="m-0 admin_type_heading">Source</p>
            <span className="admin_type_value">
              {partnerSource === "" ? "-" : partnerSource}
            </span>
          </div>
        </div>
      )}
      {showPartnerSource && (
        <div className="edit_details">
          <div className="input-group-custom">
            <label htmlFor="source" className="label">
              Source
            </label>
            <Field
              type="text"
              className="input-area admin_partner_det_input"
              //   required
              id="source"
              name="partnersource"
              value={values.partnersource}
              //   onChange={(e) => {
              //     setpartnerSource(e.target.value);
              //   }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PartnerSource;
