import React, { useEffect } from "react";
import Footer from "../../Dashboard/Footer";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import { useHistory } from "react-router-dom";
import calendarinprogress from "../../../Assets/Calender under Progress.svg";
// import ContactUploadedvsDeal from "./ContactUploadedvsDeal";
// import CSCalendarData from "./CSCalendarData";
import MeetingProgressChart from "./MeetingProgressChart";
import BDDiscount from "./BDDiscount";
import UserNav2 from "../UserNav2";

export default function BdDashboard() {
  let history = useHistory();

  useEffect(() => {
    const valid = localStorage.getItem("BDName");
    if (valid === null) {
      history.push("/");
    }
  }, []);

  return (
    <div>
      <div>
        <UserNav2 />
      </div>
      <div className="main">
        <div className="content-tabs">
          <div>
            <div className="top_menu_bar">
              <div>
                <span className="dashboard_head_txt">Dashboard</span>
              </div>
            </div>

            <div className="pt-4 d-flex justify-content-between">
              {/* <div className="col-12 col-md-6 pie_graph_dashboard p-2">
                <MeetingProgressChart />
              </div> */}
              <div className="col-12 col-md-6 pe-2">
                <div>
                  <div
                    className="col-12 col-md-12 pie_graph_dashboard"
                    style={{ width: "100%" }}
                  >
                    <MeetingProgressChart />
                  </div>
                </div>

                <div className="pt-3">
                  <div
                    className="col-12 col-md-12 pie_graph_dashboard"
                    style={{ width: "100%" }}
                  >
                    <BDDiscount />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center pie_graph_dashboard">
                {/* <EarningDetails /> */}
                <img src={calendarinprogress} width="70%" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
}
