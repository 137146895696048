import React from "react";
import { Field } from "formik";

const LeadPersonal = ({
  values,
  errors,
  touched,
  show,
  refnames,
  leadpackagename,
  refsqft,
  refplotnumber,
  developmentName,
  nooffloor,
  refconstructiondate,
  callStatus,
  source,
  referredBy,
  plotlocation,
  leadbudget,
}) => {
  return (
    <>
      {!show && (
        <div className="viewing_details">
          <div className="row">
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Full Name</p>
                <span className="admin_type_value">
                  {refnames === "" ? "-" : refnames}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Package</p>
                <span className="admin_type_value">
                  {leadpackagename === "" ? "-" : leadpackagename}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Sq. ft.</p>
                <span className="admin_type_value">
                  {refsqft === "" ? "-" : refsqft}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Plot Number</p>
                <span className="admin_type_value">
                  {refplotnumber === "" ? "-" : refplotnumber}
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Development Name</p>
                <span className="admin_type_value">
                  {developmentName === "" ? "-" : developmentName}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Number Of Floors</p>
                <span className="admin_type_value">
                  {nooffloor === "" ? "-" : nooffloor}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Construction Start</p>
                <span className="admin_type_value">
                  {refconstructiondate === ""
                    ? "-"
                    : refconstructiondate
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("-")}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Call Status</p>
                <span className="admin_type_value">
                  {callStatus != "" ? callStatus : "-"}
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Source</p>
                <span className="admin_type_value">
                  {source === "" ? "-" : source}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Referred By</p>
                <span className="admin_type_value">
                  {referredBy === "" ? "-" : referredBy}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Plot Location</p>
                <span className="admin_type_value">
                  {plotlocation === "" ? "-" : plotlocation}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Lead Budget</p>
                <span className="admin_type_value">
                  {leadbudget === null || leadbudget === "" ? "-" : leadbudget}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* TO EDIT START */}
      {show && (
        <div className="edit_details">
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="inputName" className="label">
                  Full Name
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="inputName"
                  name="name"
                  value={values.name}
                  //   onChange={(e) => {
                  //     Setrefnames(e.target.value);
                  //   }}
                />
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="dates" className="label">
                  Package
                </label>

                <Field
                  as="select"
                  className="input-area admin_partner_det_input"
                  name="packagename"
                  label="Select"
                  defaultValue={"select"}
                  //   onChange={(e) => {
                  //     Setleadpackagename(e.target.value);
                  //   }}
                >
                  <option selected value="">
                    Select
                  </option>
                  <option value="Standard">Standard</option>
                  <option value="Cendrol Plus">Cendrol Plus</option>
                  {/* <option value="Premium">Premium</option> */}
                </Field>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="comp" className="label">
                  Sq. ft.
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="comp"
                  name="area"
                  value={values.area}
                  //   onChange={(e) => {
                  //     Setrefsqft(e.target.value);
                  //   }}
                />
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="type" className="label">
                  Plot Number
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="type"
                  name="plot_number"
                  value={values.plot_number}
                  //   onChange={(e) => {
                  //     Setrefplotnumber(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="type" className="label">
                  Development Name
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="type"
                  name="development_name"
                  value={values.development_name}
                  //   onChange={(e) => {
                  //     SetdevelopmentName(e.target.value);
                  //   }}
                />
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="type" className="label">
                  Number Of Floors
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="type"
                  name="no_of_floors"
                  value={values.no_of_floors}
                  //   onChange={(e) => {
                  //     Setnooffloor(e.target.value);
                  //   }}
                />
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="exp" className="label">
                  Construction Start
                </label>
                <Field
                  type="date"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="exp"
                  name="start_date"
                  value={values.start_date}
                  //   onChange={(e) => {
                  //     Setrefconstructiondate(e.target.value);
                  //   }}
                />
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Call status</p>
                <span className="admin_type_value">
                  {callStatus === "" ? "-" : callStatus}
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Source</p>
                <span className="admin_type_value">
                  {source === "" ? "-" : source}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Referred By</p>
                <span className="admin_type_value">
                  {referredBy === "" ? "-" : referredBy}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="type" className="label">
                  Plot Location
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="type"
                  name="plot_location"
                  value={values.plot_location}
                  //   onChange={(e) => {
                  //     setplotlocation(e.target.value);
                  //   }}
                />
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="lead_budget" className="label">
                  Lead Budget
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="lead_budget"
                  name="lead_budget"
                  value={values.lead_budget}
                  //   onChange={(e) => {
                  //     Setleadbudget(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* TO EDIT END */}
    </>
  );
};

export default LeadPersonal;
