import React, { useEffect, useState } from "react";
import IS_SideNav from "./IS_SideNav";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { CgArrowRightR } from "react-icons/cg";
import axios from "axios";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Footer from "../Dashboard/Footer";
// Pagination
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Filter from "../../Assets/Filter.svg";
import CloseIcon from "@mui/icons-material/Close";
import { JoiningDate, LastActivityDate } from "../Helpers/validation";
import { Formik, Form, Field, useField } from "formik";
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import Loader from "../Helpers/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const ISlistofPartners = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [partnerdata, Setpartnerdata] = useState([]);
  const [onboardedby, setonboardedby] = useState([]);
  const [partnerSource, setpartnerSource] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [JoiningDateStart, setJoiningDateStart] = useState("");
  const [JoiningDateEnd, setJoiningDateEnd] = useState("");
  const [isloading, setisloading] = useState(true);
  const [btnloader, setbtnloader] = useState(false);

  let ISName = localStorage.getItem("ISName");
  const { Id } = useParams();
  let history = useHistory();

  const { PageNo } = useParams();
  const { SearchBar } = Search;
  const [Page, setPage] = useState(PageNo ? PageNo : 1);
  const [PerPage, setPerPage] = useState(50);
  const [searchcount, setsearchcount] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  // console.log("REDD", Id);
  useEffect(() => {
    const valid = localStorage.getItem("ISName");
    if (valid === null) {
      history.push("/");
    }

    // Without Filters
    let ISAssigning = localStorage.getItem("ISName");

    getFilterISData(ISAssigning);

    // To Fetch Onboarded By Options DropDown
    axios.get(`${SERVER_URL}/onboarded`).then((response) => {
      // console.log("Cust", response);
      setonboardedby(response.data.Onboarded);
    });

    // To Fetch Partner Source By Options DropDown
    axios.get(`${SERVER_URL}/partnerSource`).then((response) => {
      // console.log("Cust", response);
      setpartnerSource(response.data.Source);
    });
  }, []);

  const getFilterISData = (ISAssigning) => {
    axios
      .get(`${SERVER_URL}/ISrpfilters?user_name=${ISAssigning}`)
      .then((responselistpat) => {
        Setpartnerdata(responselistpat.data.result);
        setisloading(false);
      });
  };

  // OnChange Function for Date Range Picker of Construction Date
  const onChange = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);

      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start > end) {
        setStartDate(end);
        setEndDate(start);
      } else {
        setStartDate(start);
        setEndDate(end);
      }
    } else {
      // console.log("empty");
      setStartDate("");
      setEndDate("");
    }
  };

  const handleJoining = (dates) => {
    if (dates) {
      var start = dates[0];
      var end = dates[1];

      // Converting it to format dd/mm/yyyy
      // setJoiningDateStart(start.toISOString().slice(0, 10));
      // setJoiningDateEnd(end.toISOString().slice(0, 10));
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start < end) {
        setJoiningDateStart(start);
        setJoiningDateEnd(end);
      } else {
        setJoiningDateStart(end);
        setJoiningDateEnd(start);
      }
    } else {
      // console.log("empty");
      setJoiningDateStart("");
      setJoiningDateEnd("");
    }
  };

  const RPFilters = (data) => {
    setbtnloader(true);
    const sendData = {
      joining_from_date: JoiningDateStart,
      joining_to_date: JoiningDateEnd,
      experience: data.experience,
      location: data.location,
      source: data.source,
      verified: data.verified,
      onboarded_by: data.onboarded_by,
      managed_by: data.managed_by,
      priority: data.priority,
      activity_from: startDate,
      activity_to: endDate,
    };

    // console.log("sendData", sendData);

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(
        `${SERVER_URL}/filteronpartners?partnertype=RP&user_name=${ISName}`,
        sendData,
        {
          headers,
        }
      )
      .then((res) => {
        // console.log("res", res);
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#Add_Filter_popup").modal("hide");
          setPage(1);
          Setpartnerdata(res.data.Partners);
          setbtnloader(false);
          // remove
          if (res.data.Partners.length !== 0) {
            var number = document.getElementsByClassName(
              "react-bootstrap-table-page-btns-ul"
            )[0];
            var firstnum = number.querySelectorAll("li, a")[1];
            firstnum.click();
          }
        } else {
          setbtnloader(false);
          toast.error("Something Went Wrong...try Again!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          Setpartnerdata("");
        }
      });
  };

  // New Pagination
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const partPriority = (cell) => {
    switch (cell) {
      case "High":
        return (
          <div>
            <button className="high_priority">High</button>
          </div>
        );
      case "Medium":
        return (
          <div>
            <button className="medium_priority">Medium</button>
          </div>
        );
      case "low_priority":
        return (
          <div>
            <button className="low_priority">Low</button>
          </div>
        );
      default:
        return "-";
    }
  };

  const partKYC = (cell) => {
    switch (cell) {
      case true:
        return (
          <div>
            <button className="verified_btn">Verified</button>
          </div>
        );
      case false:
        return (
          <div>
            <button className="pending_btn">Pending</button>
          </div>
        );
      default:
        return "-";
    }
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "fullname",
      text: "Partner Name",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "partnersource",
      text: "Source",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "partner_ID",
      text: "Partner ID",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "priority",
      text: "Priority",
      formatter: partPriority,
    },
    {
      dataField: "verified",
      text: "KYC",
      // formatter: (cell) => (!cell ? "-" : cell),
      formatter: partKYC,
    },
    {
      dataField: "partner_rank",
      text: "Rank",
      formatter: (cell) => (!cell ? "-" : cell),
      searchable: false,
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell) => (
        <Link
          to={`/isrpreferedleads/${cell}/${Page}/1`}
          // target="_blank" // remove afterwards
          className="viewmore"
        >
          <CgArrowRightR />
        </Link>
      ),
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log("row", row);
      // old
      history.push(`/isrpreferedleads/${row._id}/${Page}/1`);
      //new
      // window.open(`/isrpreferedleads/${row._id}/${Page}/1`, "_blank");
    },
  };

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    page: parseInt(Page),
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    // remove these two
    sizePerPage: 50,
    hideSizePerPage: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ],
  };

  return (
    <div>
      {isloading === false ? (
        <div>
          <IS_SideNav />
          <div className="main">
            <div className="content-tabs">
              <ToolkitProvider
                keyField="_id"
                data={partnerdata}
                columns={columns}
                search={{ afterSearch }}
              >
                {(props) => (
                  <div>
                    <div className="top_menu_bar">
                      <div>
                        <div>
                          <span className="CS_head_txt">
                            List Of Referral Partners
                          </span>
                        </div>
                        <div>
                          <span className="total_count_txt">
                            (
                            {searchcount === 0
                              ? searchcount
                              : searchcount > 0
                              ? searchcount
                              : partnerdata.length}{" "}
                            partners)
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex" style={{ gap: "15px" }}>
                          <div className="search_div" style={{ width: "65%" }}>
                            <SearchIcon />
                            <SearchBar
                              {...props.searchProps}
                              className="top_menu_search"
                              placeholder="Search Here..."
                              // style={{ width: "400px", height: "40px" }}
                            />
                            {/* <input
                              type="text"
                              className="top_menu_search"
                              placeholder="Search Here.."
                              onChange={(event) => {
                                Setsearchterms(event.target.value);
                              }}
                            /> */}
                          </div>

                          <div>
                            <button
                              className="add_btn"
                              data-toggle="modal"
                              data-target=".Add_Filter_popup"
                            >
                              <img src={Filter} />
                              Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="table_div">
                        {partnerdata != "" ? (
                          <div>
                            <BootstrapTable
                              {...props.baseProps}
                              noDataIndication={emptyDataMessage}
                              pagination={paginationFactory(paginationoptions)}
                              filter={filterFactory()}
                              rowEvents={rowEvents}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div>
                              <WarningRoundedIcon className="no_data_alert" />
                            </div>
                            <div>
                              <h3>No records found for Customers</h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>

          {/* Filter Pop Up start */}
          <div>
            <div
              id="Add_Filter_popup"
              className="modal fade Add_Filter_popup"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div
                  className="modal-content modal_content_whole"
                  style={{ background: "white" }}
                >
                  <div className="modal-body" style={{ background: "#FFFFFF" }}>
                    <div className="pb-3">
                      <span className="add_hot_lead_label">Apply Filters</span>
                      <span
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="CloseAddHotLeadPopup"
                        style={{
                          background: "transparent",
                          float: "right",
                          color: "#221e1f",
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                    <Formik
                      initialValues={{
                        experience: "",
                        verified: "",
                        onboarded_by: "",
                        managed_by: "",
                        priority: "",
                        location: "",
                        source: "",
                      }}
                      // validationSchema={validate}
                      onSubmit={(values, { resetForm }) => {
                        // otherLeadsFilter(values);
                        RPFilters(values);
                      }}
                    >
                      {(formik) => (
                        <div>
                          <Form>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Joining Date
                                  </label>
                                  <DateRangePicker
                                    placeholder="Select Date Range"
                                    format="dd/MM/yyyy"
                                    character="-"
                                    onChange={handleJoining}
                                    ranges={JoiningDate}
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Experience
                                  </label>
                                  <Field
                                    type="text"
                                    className="field_input_txt"
                                    placeholder="Enter Experience"
                                    id="from"
                                    name="experience"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Partner Source
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="source"
                                  >
                                    <option disabled selected value="">
                                      Select Source
                                    </option>
                                    {partnerSource != ""
                                      ? partnerSource.map((item, i) => {
                                          return (
                                            <option key={i} value={item}>
                                              {item}
                                            </option>
                                          );
                                        })
                                      : ""}
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Verification
                                  </label>
                                  <div className="input-group_add_hot_lead ">
                                    <Field
                                      as="select"
                                      className="field_input_txt"
                                      name="verified"
                                    >
                                      <option disabled selected value="">
                                        Select Status
                                      </option>
                                      <option value="true">Verified</option>
                                      <option value="false">Pending</option>
                                    </Field>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Onboarded By
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="onboarded_by"
                                  >
                                    <option disabled selected value="">
                                      Select User
                                    </option>
                                    {onboardedby != ""
                                      ? onboardedby.map((item, i) => {
                                          return (
                                            <option key={i} value={item.Name}>
                                              {item.Name}
                                            </option>
                                          );
                                        })
                                      : ""}
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Managed By
                                  </label>
                                  <div className="input-group_add_hot_lead ">
                                    <Field
                                      as="select"
                                      className="field_input_txt"
                                      name="managed_by"
                                    >
                                      <option disabled selected value="">
                                        Select User
                                      </option>
                                      {onboardedby != ""
                                        ? onboardedby.map((item, i) => {
                                            return (
                                              <option key={i} value={item.Name}>
                                                {item.Name}
                                              </option>
                                            );
                                          })
                                        : ""}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <label className="filter_input_head_txt pb-3">
                                  Priority
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    as="select"
                                    className="field_input_txt"
                                    name="priority"
                                  >
                                    <option disabled selected value="">
                                      Select Priority
                                    </option>
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="input-group_add_hot_lead">
                                  <label className="filter_input_head_txt pb-3">
                                    Last Activity Date
                                  </label>
                                  <DateRangePicker
                                    placeholder="Select Date Range"
                                    format="dd/MM/yyyy"
                                    character="-"
                                    onChange={onChange}
                                    ranges={LastActivityDate}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-12 col-md-12">
                                <label className="filter_input_head_txt pb-3">
                                  Location
                                </label>
                                <div className="input-group_add_hot_lead ">
                                  <Field
                                    type="text"
                                    className="field_input_txt"
                                    placeholder="Enter Location"
                                    id="from"
                                    name="location"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row pt-4">
                              <div className="col-12 col-md-6">
                                <button
                                  type="reset"
                                  className="btn btn-lg btn_resetall"
                                >
                                  Reset All
                                </button>
                              </div>
                              <div className="col-12 col-md-6">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn_apply_filter"
                                >
                                  {btnloader === true ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    ""
                                  )}
                                  Apply Filter
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Filter Pop Up End */}
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ISlistofPartners;
