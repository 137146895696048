import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminNav from "../AdminNavBar/AdminNav";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import DynamicInputFields from "../ChannelSales/DyamicInputFields";
import DynamicInputFieldDisplay from "../ChannelSales/DynamicInputFieldsDisplay";
import { useHistory } from "react-router-dom";
import whatsApp from "../../Assets/logos_whatsapp.svg";
import { useParams } from "react-router";
import Download from "../../Assets/Download.svg";
import Footer from "../Dashboard/Footer";
import $ from "jquery";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import fileDownload from "js-file-download";
import { Formik, Form } from "formik";
import PartnerPersnoal from "../../BasicUI/PartnerDetails/PartnerPersonal";
import PartnerContact from "../../BasicUI/PartnerDetails/PartnerContact";
import PartnerBank from "../../BasicUI/PartnerDetails/PartnerBank";
import PartnerKyc from "../../BasicUI/PartnerDetails/PartnerKyc";
import PartnerDeductions from "../../BasicUI/PartnerDetails/PartnerDeductions";
import PartnerReferralId from "../../BasicUI/PartnerDetails/PartnerReferralId";
import PartnerTarget from "../../BasicUI/PartnerDetails/PartnerTarget";
import PartnerHandledBy from "../../BasicUI/PartnerDetails/PartnerHandledBy";
import PartnerSource from "../../BasicUI/PartnerDetails/PartnerSource";
import PartnerAgrement from "../../BasicUI/PartnerDetails/PartnerAgrement";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function ChannelPartnerDetails() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const img_url = process.env.REACT_APP_IMAGES_URL;
  // var DownloadButton = require('downloadbutton/es5')
  const [show, toggleShow] = useState(false);
  const [showbank, toggleShowbank] = useState(false);
  const [showedit, toggleShowedit] = useState(false);
  const [showeditkyc, toggleShoweditkyc] = useState(false);
  const [showtarget, toggleShowtarget] = useState(false);
  const [showref, toggleShowref] = useState(false);
  const [showreftds, toggleshowreftds] = useState(false);
  const [showprofilepic, toggleShowProfilepic] = useState(false);
  const [showeditsqft, toggleshoweditsqft] = useState(false);
  const [showperprojectamt, toggleshowperprojectamt] = useState(false);
  const [showcmts, toggleshowcmts] = useState(false);
  const [showAgreement, toggleshowAgreement] = useState(false);
  // Handled by
  const [showPartnerSource, toggleshowPartnerSource] = useState(false);
  const { Id } = useParams();
  const { Page } = useParams();
  // var DownloadButton = require('downloadbutton/es5');
  let history = useHistory();

  // state to set all personal info values of partners
  const [refnames, Setrefnames] = useState("");
  const [refjoiningdate, Setrefjoiningdate] = useState("");
  const [refcompname, Setrefcompname] = useState("");
  const [reftotalexp, Setreftotalexp] = useState("");
  const [refdob, Setrefdob] = useState("");
  const [refdesg, Setrefdesg] = useState("");
  const [reftype, Setreftype] = useState("");
  const [refworkloc, Setrefworkloc] = useState("");

  // state to set contact info
  const [refemail, Setrefemail] = useState("");
  const [refmobile, Setrefmobile] = useState("");

  const [Persfamt, SetPersfamt] = useState("");
  const [agreement, Setagreement] = useState("");

  // Bank details
  const [refbankdetails, Setrefbankdetails] = useState("");
  const [refpatname, Setrefpatname] = useState("");
  const [refbankname, Setrefbankname] = useState("");
  const [refbranchname, Setrefbranchname] = useState("");
  const [refaccno, Setrefaccno] = useState("");
  const [refifsccode, Setrefifsccode] = useState("");
  const [refbankacctype, Setrefbankacctype] = useState("");

  // To set KYC Details
  const [refpanno, Setrefpanno] = useState("");
  const [refaadharno, Setrefaadharno] = useState("");
  const [refdobnew, Setrefdobnew] = useState("");

  const [photo, setSelectedPhoto] = useState("");
  const [panphoto, setSelectedPanPhoto] = useState("");
  const [aadhaarphoto, setSelectedAadharPhoto] = useState("");
  // const [isFilePicked, setIsFilePicked] = useState(false);

  const [msg, Setmsg] = useState("");
  const [referalcode, Setreferalcode] = useState([]);
  const [profilepic, setprofilepic] = useState("");

  const [FSECode, SetFSECode] = useState("");

  const [remarks, Setremarks] = useState("");

  const [verified, SetVerified] = useState("");

  // Leads Assigned to
  const [leadAssign, setleadAssign] = useState("");
  // Handledby
  const [onboarderby, setonboarderby] = useState("");
  const [managedby, setmanagedby] = useState("");
  const [partnerSource, setpartnerSource] = useState("");
  // Comments
  const [comments, Setcomments] = useState([]);
  const [CommentsData, SetCommentsData] = useState([]);

  // setaadharpic;

  // Upload docs
  const changeProfilepic = (e) => {
    setSelectedPhoto(e.target.files[0]);
    console.log("profile", e.target.files[0]);
  };
  const changePanPhoto = (e) => {
    setSelectedPanPhoto(e.target.files[0]);
    console.log("pan", e.target.files[0]);
  };

  const changeAadhaarPhoto = (e) => {
    setSelectedAadharPhoto(e.target.files[0]);
    console.log("aadhar", e.target.files[0]);
  };
  const changeprofile = (e) => {
    setprofilepic(e.target.files[0]);
    console.log("setting state", e.target.files[0]);
  };
  const saveprofilepic = () => {
    const formdata = new FormData();
    formdata.append("profile_pic", profilepic);
    axios
      .post(`${SERVER_URL}/uploadprofile?_id=${Id}`, formdata)
      .then((repos) => {
        console.log("newyear", repos);
      });
  };

  // Target
  const [targetdetails, Settargetdetails] = useState([]);
  const [noofdays, Setnoofdays] = useState([]);
  const [targettobeachieved, Settargettobeachieved] = useState([]);
  const [tdsvalue, Settdsvalue] = useState(1);
  const [Perprojectamt, SetPerprojectamt] = useState([100000]);

  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }

    getCPdata();

    getBank();

    getTarget();

    getrefcode();

    getTDS();
  }, []);

  const getrefcode = () => {
    // Fetch Referral Code
    axios.get(`${SERVER_URL}/getrefcode?_id=${Id}`).then((resultrefcode) => {
      Setreferalcode(resultrefcode.data.referralcode.slice(-1));
      // console.log("REF COde", resultrefcode);
    });
  };

  const getTDS = () => {
    // TDS Value API
    axios.get(`${SERVER_URL}/tdsbyid?_id=${Id}`).then((resultds) => {
      Setmsg(resultds.data.msg);
      // console.log("TDS", resultds);
    });
  };

  const getCPdata = () => {
    axios.get(`${SERVER_URL}/getpartnersbyid?_id=${Id}`).then((reps) => {
      // console.log("tds", reps);
      // Personal
      Setrefnames(reps.data.partners[0].fullname);
      Setrefjoiningdate(reps.data.partners[0].doj);
      Setrefcompname(reps.data.partners[0].companyname);
      Setreftotalexp(reps.data.partners[0].experience);
      Setrefdob(reps.data.partners[0].dob);
      Setrefdesg(reps.data.partners[0].designation);
      Setreftype(reps.data.partners[0].partnertype);
      Setrefworkloc(reps.data.partners[0].location);
      SetFSECode(reps.data.partners[0].referral_code);
      Setremarks(reps.data.partners[0].remarks);
      // Contact info
      Setrefemail(reps.data.partners[0].email);
      Setrefmobile(reps.data.partners[0].mobile);
      // tds
      Settdsvalue(reps.data.partners[0].tds);
      SetPersfamt(reps.data.partners[0].persfamt);
      Setagreement(reps.data.partners[0].agreement);
      // SetPerprojectamt(reps.data.partners[0].perprojectamt);
      Settargettobeachieved(reps.data.partners[0].target);
      SetVerified(reps.data.partners[0].verified);
      // console.log("Persfamt", reps.data.partners);
      // Leads Assign TO
      setleadAssign(reps.data.partners[0].assigned_to);
      // handled by
      setonboarderby(reps.data.partners[0].onboarded_by);
      setmanagedby(reps.data.partners[0].managed_by);
      setpartnerSource(reps.data.partners[0].partnersource);
      // Comments
      Setcomments(reps.data.partners[0].comments);
      setCounter1(reps.data.partners[0].comments.length);
    });
  };

  const getBank = () => {
    axios.get(`${SERVER_URL}/getbankdetailsbyid?_id=${Id}`).then((res) => {
      var bankdetailslength = res.data;
      var lengthofbank = res.data.documents.length;
      localStorage.setItem("lengthofbank", lengthofbank);
      if (lengthofbank > 0 && lengthofbank != "") {
        Setrefbankdetails(res.data.documents[0]);
        Setrefpatname(res.data.documents[0].partnerName);
        Setrefbankname(res.data.documents[0].bankName);
        Setrefbranchname(res.data.documents[0].branch);
        Setrefaccno(res.data.documents[0].accountNumber);
        Setrefifsccode(res.data.documents[0].ifscCode);
        Setrefbankacctype(res.data.documents[0].accountType);
        Setrefpanno(res.data.documents[0].panNumber);
        Setrefaadharno(res.data.documents[0].adharNumber);
        Setrefdobnew(res.data.documents[0].dob);
        // console.log("NEWDOBB", res.data.documents[0].dob);
      } else {
        Setrefbankdetails("");
        Setrefpatname("");
        Setrefbankname("");
        Setrefbranchname("");
        Setrefaccno("");
        Setrefifsccode("");
        Setrefbankacctype("");
        Setrefpanno("");
        Setrefaadharno("");
      }
    });
  };

  const getTarget = () => {
    axios.get(`${SERVER_URL}/target?_id=${Id}`).then((respons) => {
      Settargetdetails(respons.data);
      Setnoofdays(respons.data.noOfdays);
      // Settargettobeachieved(respons.data.noOfTarget);
    });
  };

  const setstat = (e) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };
    // SetVerifystat(e.target.value);
    let selectt = e.target.value;
    const statverified = {
      verifystatus: selectt,
    };

    // console.log("123", statverified);
    axios
      .post(`${SERVER_URL}/updateverified?_id=${Id}`, statverified, {
        headers,
      })
      .then((resultsverified) => {
        // console.log("verified orr", resultsverified);
        let Status = resultsverified.data.status;
        if (Status === "success") {
          // alert("Partner Assigned Successfully");
          toast.success("Partner Verified Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getCPdata();
        } else {
          // alert("Error");
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  const deletepartnerdetails = () => {
    axios.delete(`${SERVER_URL}/deletepartner?_id=${Id}`).then((res) => {
      let Status = res.data.status;
      if (Status === "success") {
        window.$("#deletePartner").modal("hide");
        window.$("#popup-deleted-successfully").modal("show");
        window.setTimeout(function () {
          $(".modal-backdrop").remove();
          history.push(`/chlist/${Page}`);
        }, 3000);
      } else {
        toast.error("Internal Error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const [counter, setCounter] = useState(1);
  const [counter1, setCounter1] = useState(1);

  const handleClick = (e) => {
    e.preventDefault();
    if (counter1 < 14) {
      setCounter((prevcounter) => prevcounter + 1);
      setCounter1((prevcounter1) => prevcounter1 + 1);
    }
  };

  // update Comments
  const commentsData = (comment_date, call_status, comment, index) => {
    const labarray = CommentsData;
    labarray.splice(index, 1, {
      comment_date: comment_date,
      call_status: call_status,
      comment: comment,
    });

    SetCommentsData([]);
    SetCommentsData(labarray);
  };

  const saveComments = () => {
    if (CommentsData.length > 0) {
      const comment = {
        data: CommentsData,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(`${SERVER_URL}/addpartnercomments?_id=${Id}`, comment, {
          headers,
        })
        .then((res) => {
          let Status = res.data.status;
          if (Status === "success") {
            toast.success("Comments are Added successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getCPdata();
            // setCounter(1);
            // setCounter1(1);
            // window.setTimeout(function () {
            //   window.location.reload();
            // }, 100);
          } else if (Status === "failed") {
            toast.error("Comments are Already Exists", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Some Internal Error", err);
        });
    } else {
      alert("please fill all the details");
    }
  };

  return (
    <div>
      <div>
        <AdminNav />
      </div>

      <div className="main">
        <div className="content-tabs">
          <div>
            <div className="top_menu_bar">
              {/* <div className="">
                  <Link to="/chlist" className="partner_back_btn">
                    <span>
                      <ArrowBackIosNewIcon />
                      Back
                    </span>
                  </Link>
                </div> */}
              <div className="">
                <Link to={`/chlist/${Page}`} className="partner_back_btn">
                  <div>
                    <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                    <span>Back</span>
                  </div>
                </Link>
              </div>

              <div>
                <div
                  className="d-flex justify-content-around"
                  style={{ gap: "20px" }}
                >
                  {/* <div
                    className="admin_patrner_personal_info"
                    style={{ padding: "10px" }}
                  >
                    <div className="d-flex" style={{ gap: "10px" }}>
                      <div
                        className="edit_icon align-self-center"
                        onClick={() => toggleshoweditsqft(!showeditsqft)}
                      >
                        {!showeditsqft && (
                          <div>
                            <EditIcon className="search_icons" />
                          </div>
                        )}

                        {showeditsqft && (
                          <div>
                            <button
                              type="submit"
                              className="edit_icon"
                              onClick={mydetails}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                      {!showeditsqft && (
                        <div className="contact_info_">
                          <div>
                            <span>
                              Rs{Persfamt != undefined ? Persfamt : 30} Sq.ft
                            </span>
                          </div>
                        </div>
                      )}

                      {showeditsqft && (
                        <div className="contact_info_admin">
                          <div className="input-group-custom">
                            <input
                              type="number"
                              className="input-area"
                              required
                              id="email"
                              name="email"
                              value={Persfamt}
                              onChange={(e) => {
                                SetPersfamt(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}

                  {/* <button
                      className="add_btn"
                      data-toggle="modal"
                      data-target=".bd-example-modal-lg_ref3"
                    >
                      <div
                        className="edit_icon"
                        onClick={() =>
                          toggleshowperprojectamt(!showperprojectamt)
                        }
                      >
                        {!showperprojectamt && (
                          <div>
                            {Perprojectamt != undefined
                              ? Perprojectamt
                              : 100000}
                            /Per Project Amount
                            <EditIcon className="search_icons" />
                          </div>
                        )}
                        {showperprojectamt && (
                          <div>
                            <input
                              type="text"
                              value={Perprojectamt}
                              onChange={(e) => {
                                SetPerprojectamt(e.target.value);
                              }}
                            />
                            <button
                              type="submit"
                              className="edit_icon"
                              onClick={mydetails}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                    </button> */}

                  <div>
                    {verified === false ? (
                      <select
                        className="form-select select-form"
                        onChange={setstat}
                        style={{ border: "none", height: "100%" }}
                      >
                        <option value="" disabled selected>
                          Verify Status
                        </option>
                        <option value="verify">Verified</option>
                        <option value="pending">Pending</option>
                      </select>
                    ) : (
                      <div
                        style={{
                          gap: "10px",
                          height: "fit-content",
                          alignSelf: "center",
                          padding: "10px",
                        }}
                        className="dropdown verify_btn"
                      >
                        <span>
                          <CheckCircleIcon className="verify_icons" />
                        </span>
                        Verified
                      </div>
                    )}
                  </div>

                  <button
                    className="delete_btn"
                    data-toggle="modal"
                    data-target=".partner_details_delete"
                  >
                    <DeleteIcon className="del_icons" />
                    Delete
                  </button>

                  <div
                    id="deletePartner"
                    className="modal fade partner_details_delete"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-sm modal-dialog-centered ">
                      <div
                        className="modal-content modal-sm modal_content_whole"
                        style={{ background: "white" }}
                      >
                        <div className="modal-body">
                          <div>
                            <div className="popup_icon_center">
                              <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                            </div>
                            <div className="pt-2 popup_text_center">
                              <span className="popup_addbankdetails_text">
                                Are you sure want to Delete the Partners ?
                              </span>
                            </div>
                            <div className="pt-4 m-0 d-flex justify-content-center">
                              <div
                                style={{ width: "50%", textAlign: "center" }}
                              >
                                <button
                                  type="button"
                                  className="popup_btn_unlinkaccount"
                                  // onClick={deletebankdetails}
                                  onClick={deletepartnerdetails}
                                >
                                  Delete
                                </button>
                              </div>
                              <div
                                style={{ width: "50%", textAlign: "center" }}
                              >
                                <button
                                  // type="button"
                                  className="popup_btn_close"
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="popup-deleted-successfully"
                    className="modal fade bd-lead-added-successfully"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                      <div
                        className="modal-content modal-sm modal_content_whole"
                        style={{ background: "white" }}
                      >
                        <div className="modal-body">
                          <div>
                            <div className="popup_icon_center">
                              <CheckCircleIcon className="popup_addbankdetails_icon" />
                            </div>
                            <div className="popup_text_center">
                              <p className="pt-2 popup_addbankdetails_text">
                                Partners Deleted Successfully
                              </p>
                              <div className="pt-2 m-0 d-flex justify-content-center">
                                <div
                                  style={{
                                    width: "50%",
                                    textAlign: "center",
                                  }}
                                >
                                  <button
                                    // type="button"
                                    className="popup_btn_linkaccount"
                                    data-dismiss="modal"
                                    onClick={() => {
                                      history.push(`/chlist/${Page}`);
                                    }}
                                  >
                                    OK
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="partner_details_edit_sec">
              <form>
                <div className="row">
                  <div className="col-md-8">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        fullname: refnames,
                        doj: refjoiningdate,
                        companyname: refcompname,
                        experience: reftotalexp,
                        dob: refdob,
                        designation: refdesg,
                        partnertype: reftype,
                        location: refworkloc,
                      }}
                      onSubmit={(values) => {
                        // alert("hello");
                        // console.log("val", values);
                        const headers = {
                          "Content-Type": "application/json",
                        };

                        axios
                          .post(
                            `${SERVER_URL}/updatepartner?_id=${Id}`,
                            values,
                            {
                              headers,
                            }
                          )
                          .then((res) => {
                            // console.log("Hello Don", res);
                            let Status = res.data.status;
                            if (Status === "success") {
                              // alert("Profile Details are edited sucessfully");
                              toast.success(
                                "Profile Details are edited sucessfully",
                                {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                }
                              );
                              getCPdata();
                              toggleShow(false);
                              // window.setTimeout(function () {
                              //   window.location.reload();
                              // }, 100);
                            } else if (Status === "failed") {
                              toggleShow(false);
                              // alert("Profile Details are already exists");
                              toast.error("Profile Details Already Exists", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            }
                          })
                          .catch((err) => {
                            // console.log(err);
                            alert("Some Internal Error", err);
                          });
                      }}
                    >
                      {({ errors, touched, handleSubmit, values }) => (
                        <Form>
                          <div className="p-3 admin_patrner_personal_info">
                            <div className="d-flex justify-content-between head_txt head_txt_margin">
                              <p>Partner Personal Info</p>
                              <div
                                className="edit_icon"
                                onClick={() => toggleShow(!show)}
                              >
                                {!show && (
                                  <div>
                                    <EditIcon className="search_icons" />
                                    <span> Edit</span>
                                  </div>
                                )}
                                {show && (
                                  <div>
                                    <button
                                      type="submit"
                                      className="edit_icon"
                                      onClick={handleSubmit}
                                    >
                                      Save
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <PartnerPersnoal
                              show={show}
                              values={values}
                              refnames={refnames}
                              refjoiningdate={refjoiningdate}
                              refcompname={refcompname}
                              reftotalexp={reftotalexp}
                              refdob={refdob}
                              refdesg={refdesg}
                              reftype={reftype}
                              refworkloc={refworkloc}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="col-md-4">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        email: refemail,
                        mobile: refmobile,
                      }}
                      onSubmit={(values) => {
                        // console.log("val", values);
                        const headers = {
                          "Content-Type": "application/json",
                        };

                        axios
                          .post(
                            `${SERVER_URL}/updatepartner?_id=${Id}`,
                            values,
                            {
                              headers,
                            }
                          )
                          .then((res) => {
                            let Status = res.data.status;
                            if (Status === "success") {
                              toggleShowedit(false);
                              toast.success(
                                "Contact Details are Updated sucessfully",
                                {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                }
                              );
                              getCPdata();
                            } else if (Status === "failed") {
                              toggleShowedit(false);
                              toast.error(
                                "Contact Details are already exists",
                                {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                }
                              );
                            }
                          })
                          .catch((err) => {
                            alert("Some Internal Error", err);
                          });
                      }}
                    >
                      {({ errors, touched, handleSubmit, values }) => (
                        <Form>
                          <div className="p-3 admin_patrner_personal_info">
                            <div className="d-flex justify-content-between head_txt head_txt_margin">
                              <p>Partner Contact Info</p>
                              <div
                                className="edit_icon"
                                onClick={() => toggleShowedit(!showedit)}
                              >
                                {!showedit && (
                                  <div>
                                    <EditIcon className="search_icons" />
                                    <span> Edit</span>
                                  </div>
                                )}
                                {showedit && (
                                  <div>
                                    <button
                                      type="submit"
                                      className="edit_icon"
                                      onClick={handleSubmit}
                                    >
                                      Save
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <PartnerContact
                              values={values}
                              errors={errors}
                              touched={touched}
                              showedit={showedit}
                              refemail={refemail}
                              refmobile={refmobile}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-8">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        bankName: refbankname,
                        branch: refbranchname,
                        accountNumber: refaccno,
                        ifscCode: refifsccode,
                        // panNumber: refpanno,
                        // adharNumber: refaadharno,
                        // target: targettobeachieved,
                      }}
                      onSubmit={(values) => {
                        // console.log("val", values);
                        var length = localStorage.getItem("lengthofbank");

                        if (length > 0) {
                          const headers = {
                            "Content-Type": "application/json",
                          };

                          axios
                            .post(
                              `${SERVER_URL}/updatedocument?_id=${Id}`,
                              values,
                              {
                                headers,
                              }
                            )
                            .then((respon) => {
                              // console.log("Hello Don", respon);
                              let Status = respon.data.status;
                              if (Status === "success") {
                                toggleShowbank(false);
                                // alert("Bank Details are edited sucessfully");
                                toast.success(
                                  "Bank Details are Updtead sucessfully",
                                  {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                                getBank();
                                // window.setTimeout(function () {
                                //   window.location.reload();
                                // }, 100);
                              } else if (Status === "failed") {
                                toggleShowbank(false);
                                // alert("Profile Details are already exists");
                                toast.error("Bank Details Already Exists", {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              }
                            })
                            .catch((err) => {
                              // console.log(err);
                              alert("Some Internal Error", err);
                            });
                        } else {
                          const bankdata = {
                            bank_name: values.bankName,
                            branch_name: values.branch,
                            account_no: values.accountNumber,
                            ifscCode: values.ifscCode,

                            // accountType: refbankacctype,
                            // panNumber: refpanno,
                            // adharNumber: refaadharno,
                          };
                          // console.log("bank", bankdata);

                          const headers = {
                            "Content-Type": "application/json",
                          };

                          axios
                            .post(
                              `${SERVER_URL}/adddocumentsbyid?_id=${Id}`,
                              bankdata,
                              {
                                headers,
                              }
                            )
                            .then((results) => {
                              // console.log(results);
                              let Status = results.data.status;
                              if (Status === "success") {
                                toggleShowbank(false);
                                // alert("Details added suceesfully");
                                toast.success(
                                  "Bank Details are added sucessfully",
                                  {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                                getBank();
                                // $(".popup-add_hot-lead-added").show();
                                // $(".bd-example-modal-lg_ref3").modal("hide");
                              } else if (Status === "failed") {
                                toggleShowbank(false);
                                // alert("Details are already Exists");
                                toast.error("Bank Details Already Exists", {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              }
                            });
                        }
                      }}
                    >
                      {({ errors, touched, handleSubmit, values }) => (
                        <Form>
                          <div className="p-3 admin_patrner_personal_info">
                            <div className="d-flex justify-content-between head_txt head_txt_margin">
                              <p>Bank Details</p>
                              <div
                                className="edit_icon"
                                onClick={() => toggleShowbank(!showbank)}
                              >
                                {!showbank && (
                                  <div>
                                    <EditIcon className="search_icons" />
                                    <span> Edit</span>
                                  </div>
                                )}
                                {showbank && (
                                  <div>
                                    <button
                                      type="submit"
                                      className="edit_icon"
                                      onClick={handleSubmit}
                                    >
                                      Save
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <PartnerBank
                              values={values}
                              errors={errors}
                              touched={touched}
                              showbank={showbank}
                              refbankname={refbankname}
                              refbranchname={refbranchname}
                              refaccno={refaccno}
                              refifsccode={refifsccode}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="col-md-4">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        panNumber: refpanno,
                        // password: userpassword,
                      }}
                      onSubmit={(values) => {
                        // alert("hello");
                        // console.log("val", values);
                        var length = localStorage.getItem("lengthofbank");
                        if (length > 0) {
                          // console.log("data", updbankdatas);
                          const headers = {
                            "Content-Type": "application/json",
                          };

                          axios
                            .post(
                              `${SERVER_URL}/updatedocument?_id=${Id}`,
                              values,
                              {
                                headers,
                              }
                            )
                            .then((respon) => {
                              toggleShoweditkyc(false);
                              // console.log("Hello Don", respon);
                              let Status = respon.data.status;
                              if (Status === "success") {
                                // alert("Bank Details are edited sucessfully");
                                toast.success(
                                  "KYC Details are edited sucessfully",
                                  {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                                getBank();
                                // window.setTimeout(function () {
                                //   window.location.reload();
                                // }, 100);
                              } else if (Status === "failed") {
                                toggleShoweditkyc(false);
                                // alert("Profile Details are already exists");
                                toast.error("KYC Details Already Exists", {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              }
                            })
                            .catch((err) => {
                              // console.log(err);
                              alert("Some Internal Error", err);
                            });
                        } else {
                          const formdata = new FormData();
                          formdata.append("panNumber", refpanno);
                          formdata.append("adharNumber", refaadharno);
                          formdata.append("dob", refdob);
                          formdata.append("profilePic", photo);
                          formdata.append("panCard", panphoto);
                          formdata.append("adharCard", aadhaarphoto);

                          const headers = {
                            "Content-Type": "application/json",
                          };

                          axios
                            .post(
                              `${SERVER_URL}/addKycDetails?_id=${Id}`,
                              formdata,
                              {
                                headers,
                              }
                            )
                            .then((results) => {
                              // console.log(results);
                              let Status = results.data.status;
                              if (Status === "success") {
                                toggleShoweditkyc(false);
                                // alert("Details added suceesfully");
                                toast.success(
                                  "KYC Details are edited sucessfully",
                                  {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                                getBank();
                                // $(".popup-add_hot-lead-added").show();
                                // $(".bd-example-modal-lg_ref3").modal("hide");
                              } else if (Status === "failed") {
                                toggleShoweditkyc(false);
                                // alert("Details are already Exists");
                                toast.error("KYC Details Already Exists", {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              }
                            });
                        }
                      }}
                    >
                      {({ errors, touched, handleSubmit, values }) => (
                        <Form>
                          <div className="p-3 admin_patrner_personal_info">
                            <div className="d-flex justify-content-between head_txt head_txt_margin">
                              <p>KYC Details</p>
                              <div
                                className="edit_icon"
                                onClick={() => toggleShoweditkyc(!showeditkyc)}
                              >
                                {!showeditkyc && (
                                  <div>
                                    <EditIcon className="search_icons" />
                                    <span> Edit</span>
                                  </div>
                                )}
                                {showeditkyc && (
                                  <div>
                                    <button
                                      type="submit"
                                      className="edit_icon"
                                      onClick={handleSubmit}
                                    >
                                      Save
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <PartnerKyc
                              values={values}
                              errors={errors}
                              touched={touched}
                              showeditkyc={showeditkyc}
                              refpanno={refpanno}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-4">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            tds: tdsvalue,
                          }}
                          onSubmit={(values) => {
                            // console.log("val", values);
                            const headers = {
                              "Content-Type": "application/json",
                            };

                            axios
                              .post(
                                `${SERVER_URL}/updatetds?_id=${Id}`,
                                values,
                                {
                                  headers,
                                }
                              )
                              .then((resul) => {
                                // console.log("Hello Don", resul);
                                let Status = resul.data.status;
                                if (Status === "success") {
                                  // alert("Profile Details are edited sucessfully");
                                  toast.success("Tds are edited sucessfully", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                  getCPdata();
                                  toggleshowreftds(false);
                                  // window.setTimeout(function () {
                                  //   window.location.reload();
                                  // }, 100);
                                } else if (Status === "failed") {
                                  toggleshowreftds(false);
                                  // alert("Profile Details are already exists");
                                  toast.error("Tds Already Exists", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                }
                              })
                              .catch((err) => {
                                alert("Some Internal Error", err);
                              });
                          }}
                        >
                          {({ errors, touched, handleSubmit, values }) => (
                            <Form>
                              <div className="p-3 admin_patrner_personal_info">
                                <div className="d-flex justify-content-between head_txt head_txt_margin">
                                  <p>Deductions</p>
                                  <div
                                    className="edit_icon"
                                    onClick={() =>
                                      toggleshowreftds(!showreftds)
                                    }
                                  >
                                    {!showreftds && (
                                      <div>
                                        <EditIcon className="search_icons" />
                                        <span> Edit</span>
                                      </div>
                                    )}
                                    {showreftds && (
                                      <div>
                                        <button
                                          type="submit"
                                          className="edit_icon"
                                          // onClick={updatetdsval}
                                          onClick={handleSubmit}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <PartnerDeductions
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  showreftds={showreftds}
                                  tdsvalue={tdsvalue}
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>

                      <div className="col-md-4">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            referralcode: referalcode,
                          }}
                          onSubmit={(values) => {
                            // console.log("val", values);

                            const headers = {
                              "Content-Type": "application/json",
                            };

                            axios
                              .post(
                                `${SERVER_URL}/updaterefcode?_id=${Id}`,
                                values,
                                {
                                  headers,
                                }
                              )
                              .then((res) => {
                                // console.log("Hello Don", res);
                                let Status = res.data.status;
                                if (Status === "success") {
                                  toggleShowref(false);
                                  // alert("Profile Details are edited sucessfully");
                                  toast.success(
                                    "Referral Code are Updated sucessfully",
                                    {
                                      position: "top-right",
                                      autoClose: 5000,
                                      hideProgressBar: true,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                  getrefcode();
                                  // window.setTimeout(function () {
                                  //   window.location.reload();
                                  // }, 100);
                                } else if (Status === "failed") {
                                  toggleShowref(false);
                                  // alert("Profile Details are already exists");
                                  toast.error("Referral Code Already Exists", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                }
                              })
                              .catch((err) => {
                                alert("Some Internal Error", err);
                              });
                          }}
                        >
                          {({ errors, touched, handleSubmit, values }) => (
                            <Form>
                              <div
                                className="p-3 admin_patrner_personal_info"
                                style={{ height: "100%" }}
                              >
                                <div className="d-flex justify-content-between head_txt head_txt_margin">
                                  <p>Referral ID</p>
                                  <div
                                    className="edit_icon"
                                    onClick={() => toggleShowref(!showref)}
                                  >
                                    {!showref && (
                                      <div>
                                        <EditIcon className="search_icons" />
                                        <span> Edit</span>
                                      </div>
                                    )}
                                    {showref && (
                                      <div>
                                        <button
                                          type="submit"
                                          className="edit_icon"
                                          onClick={handleSubmit}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <PartnerReferralId
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  showref={showref}
                                  referalcode={referalcode}
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>

                      <div className="col-md-4">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            target: targettobeachieved,
                          }}
                          onSubmit={(values) => {
                            // console.log("val", values);

                            const headers = {
                              "Content-Type": "application/json",
                            };

                            axios
                              .post(
                                `${SERVER_URL}/updatetarget?_id=${Id}`,
                                values,
                                {
                                  headers,
                                }
                              )
                              .then((res) => {
                                // console.log("Hello Don", res);
                                let Status = res.data.status;
                                if (Status === "success") {
                                  toggleShowtarget(false);
                                  // alert("Profile Details are edited sucessfully");
                                  toast.success(
                                    "Target Details Updated sucessfully",
                                    {
                                      position: "top-right",
                                      autoClose: 5000,
                                      hideProgressBar: true,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                  getCPdata();
                                  getTarget();
                                  // window.setTimeout(function () {
                                  //   window.location.reload();
                                  // }, 100);
                                } else if (Status === "failed") {
                                  toggleShowtarget(false);
                                  // alert("Profile Details are already exists");
                                  toast.error("Target Details Already Exists", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                }
                              })
                              .catch((err) => {
                                alert("Some Internal Error", err);
                              });
                          }}
                        >
                          {({ errors, touched, handleSubmit, values }) => (
                            <Form>
                              <div className="p-3 admin_patrner_personal_info">
                                <div className="d-flex justify-content-between head_txt head_txt_margin">
                                  <p>Target</p>
                                  <div
                                    className="edit_icon"
                                    onClick={() =>
                                      toggleShowtarget(!showtarget)
                                    }
                                  >
                                    {!showtarget && (
                                      <div>
                                        <EditIcon className="search_icons" />
                                        <span>Edit</span>
                                      </div>
                                    )}
                                    {showtarget && (
                                      <div>
                                        <button
                                          type="submit"
                                          className="edit_icon"
                                          onClick={handleSubmit}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <PartnerTarget
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  showtarget={showtarget}
                                  targettobeachieved={targettobeachieved}
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="p-3 admin_patrner_personal_info"
                      style={{ height: "100%" }}
                    >
                      <div>
                        <p className="head_txt head_txt_margin">
                          Partner Handled By
                        </p>
                      </div>

                      <PartnerHandledBy
                        onboarderby={onboarderby}
                        managedby={managedby}
                      />
                    </div>

                    {/* <div className="col-md-4">
                        <div
                          className="p-3 admin_patrner_personal_info"
                          style={{ height: "100%" }}
                        >
                          <div className="d-flex justify-content-between head_txt head_txt_margin">
                            <p>Lead Assign To IS</p>
                          </div>
                          <div className="viewing_details">
                            <div className="row">
                              <div className="col-12 col-md-12">
                                <div>
                                  <p className="m-0 admin_type_heading">
                                    Assigned To
                                  </p>
                                  <span className="admin_type_value">
                                    {leadAssign === "" ? "-" : leadAssign}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-8">
                    <div
                      className="p-3 admin_patrner_personal_info"
                      // style={{ height: "100%" }}
                    >
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Comments</p>
                        <div
                          className="edit_icon"
                          onClick={() => toggleshowcmts(!showcmts)}
                        >
                          {!showcmts && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span> Edit</span>
                            </div>
                          )}
                          {showcmts && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={saveComments}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!showcmts && (
                        <div className="viewing_details">
                          {comments != "" ? (
                            comments.map((cust) => {
                              return (
                                <div className="pt-2 row">
                                  <div className="col-12 col-md-4">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Date
                                      </p>
                                      <span className="admin_type_value">
                                        {cust.comment_date
                                          .slice(0, 10)
                                          .split("-")
                                          .reverse()
                                          .join("-")}
                                        {/* {comment_date === "" ? "NA" : comment_date} */}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-4">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Call Status
                                      </p>
                                      <span className="admin_type_value">
                                        {cust.call_status}
                                        {/* {comment_date === "" ? "NA" : comment_date} */}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-4">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Comments
                                      </p>
                                      <span className="admin_type_value">
                                        {cust.comment}
                                        {/* {remark === "" ? "NA" : remark} */}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div>
                              <div>NA</div>
                            </div>
                          )}
                        </div>
                      )}
                      {showcmts && (
                        <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                          {comments.length != 0 ? (
                            <div>
                              <DynamicInputFieldDisplay comments={comments} />
                            </div>
                          ) : (
                            ""
                          )}

                          {Array.from(Array(counter)).map((c, counter) => {
                            return (
                              <DynamicInputFields
                                data={commentsData}
                                counter={counter}
                              />
                            );
                          })}

                          <div className="pt-4">
                            {/* <button>Add Comments</button> */}
                            <button
                              className="add_btn btn_add_comments_inc"
                              onClick={handleClick}
                            >
                              <AddIcon className="search_icons" />
                              Add Comment
                            </button>
                          </div>
                        </div>

                        // <div className="edit_details">
                        //   <div className="row">
                        //     <div className="col-12 col-md-12">
                        //       <div className="input-group-custom">

                        //         <textarea
                        //           rows="4"
                        //           cols="8"
                        //           className="input-area admin_partner_det_input"
                        //           required
                        //           id="inputName"
                        //           name="name"
                        //           value={remarks}
                        //           onChange={(e) => {
                        //             Setremarks(e.target.value);
                        //           }}
                        //         ></textarea>
                        //       </div>
                        //     </div>
                        //   </div>
                        // </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-12">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            partnersource: partnerSource,
                          }}
                          onSubmit={(values) => {
                            // console.log("val", values);

                            const headers = {
                              "Content-Type": "application/json",
                            };

                            axios
                              .post(
                                `${SERVER_URL}/updatepartner?_id=${Id}`,
                                values,
                                {
                                  headers,
                                }
                              )
                              .then((res) => {
                                // console.log("Hello Don", res);
                                let Status = res.data.status;
                                if (Status === "success") {
                                  toggleshowPartnerSource(false);
                                  // alert("Profile Details are edited sucessfully");
                                  toast.success(
                                    "Partner Source are edited sucessfully",
                                    {
                                      position: "top-right",
                                      autoClose: 5000,
                                      hideProgressBar: true,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                  getCPdata();
                                  // window.setTimeout(function () {
                                  //   window.location.reload();
                                  // }, 100);
                                } else if (Status === "failed") {
                                  toggleshowPartnerSource(false);
                                  // alert("Profile Details are already exists");
                                  toast.error("Partner Source Already Exists", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                }
                              })
                              .catch((err) => {
                                // console.log(err);
                                alert("Some Internal Error", err);
                              });
                          }}
                        >
                          {({ errors, touched, handleSubmit, values }) => (
                            <Form>
                              <div className="p-3 admin_patrner_personal_info">
                                <div className="d-flex justify-content-between head_txt head_txt_margin">
                                  <p>Partner Source</p>
                                  <div
                                    className="edit_icon"
                                    onClick={() =>
                                      toggleshowPartnerSource(
                                        !showPartnerSource
                                      )
                                    }
                                  >
                                    {!showPartnerSource && (
                                      <div>
                                        <EditIcon className="search_icons" />
                                        <span> Edit</span>
                                      </div>
                                    )}
                                    {showPartnerSource && (
                                      <div>
                                        <button
                                          type="submit"
                                          className="edit_icon"
                                          onClick={handleSubmit}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <PartnerSource
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  showPartnerSource={showPartnerSource}
                                  partnerSource={partnerSource}
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>

                      <div className="col-md-12 mt-3">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            agreement: agreement,
                            persfamt: Persfamt,
                          }}
                          onSubmit={(values) => {
                            // alert("hello");
                            // console.log("val", values);

                            const formdata = new FormData();
                            formdata.append("agreement", values.agreement);
                            formdata.append("persfamt", values.persfamt);
                            const headers = {
                              "Content-Type": "application/json",
                            };

                            axios
                              .post(
                                `${SERVER_URL}/updateagreement?_id=${Id}`,
                                formdata,
                                {
                                  headers,
                                }
                              )
                              .then((res) => {
                                // console.log("Hello Don", res);
                                let Status = res.data.status;
                                if (Status === "success") {
                                  // alert("Profile Details are edited sucessfully");
                                  toast.success(
                                    "Agreement Details are Updated sucessfully",
                                    {
                                      position: "top-right",
                                      autoClose: 5000,
                                      hideProgressBar: true,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                  getCPdata();
                                  toggleshowAgreement(false);
                                  // window.setTimeout(function () {
                                  //   window.location.reload();
                                  // }, 100);
                                } else if (Status === "failed") {
                                  // alert("Profile Details are already exists");
                                  toast.error("Internal Error", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                  toggleshowAgreement(false);
                                }
                              });
                          }}
                        >
                          {({ errors, touched, handleSubmit, values }) => (
                            <Form>
                              <div className="p-3 admin_patrner_personal_info">
                                <div className="d-flex justify-content-between head_txt head_txt_margin">
                                  <p>Agreement % Per Sq.ft Amt.</p>
                                  <div
                                    className="edit_icon"
                                    onClick={() =>
                                      toggleshowAgreement(!showAgreement)
                                    }
                                  >
                                    {!showAgreement && (
                                      <div>
                                        <EditIcon className="search_icons" />
                                        <span> Edit</span>
                                      </div>
                                    )}
                                    {showAgreement && (
                                      <div>
                                        <button
                                          type="submit"
                                          className="edit_icon"
                                          onClick={handleSubmit}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <PartnerAgrement
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  Persfamt={Persfamt}
                                  agreement={agreement}
                                  showAgreement={showAgreement}
                                  Setagreement={Setagreement}
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="mt-3 d-flex justify-content-between"
                  style={{ width: "32%" }}
                >
                  <div>
                    <Link to={`/cprefleads/${Id}/${Page}/1`}>
                      <button className="refered_lead_btn">
                        Referred Leads <ChevronRightIcon />
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
}
