import React from "react";
import PieChartIcon from "@mui/icons-material/PieChart";
import GroupIcon from "@mui/icons-material/Group";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import adminprofile from "../../Assets/adminprofile.png";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function AdminNavInner() {
  let history = useHistory();

  function logout() {
    localStorage.removeItem("email");
    history.push("/");
  }

  return (
    <div>
      <div className="sidenav">
        <div>
          <div className="d-flex">
            <div>
              <img
                src={adminprofile}
                className="profile_pic_sidenav"
                alt="profile"
              />
            </div>
            <div className="p-2 profile_details">
              <span>Robert Fox </span>
              <p className="mb-0">robert12@gmail.com</p>
            </div>
          </div>
          <div className="pt-5">
            <div className="">
              <Link
                to="/dashforadmin"
                className="d-flex side_menu_dashboardbtn"
              >
                <div className="row">
                  <div className="col-3">
                    <PieChartIcon className="" />
                  </div>
                  <div className="col-9 align-self-center">
                    <span>Dashboard</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="mt-3">
            <div className="dropdown">
              <button
                className="btn side_menu_partnersbtn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <GroupIcon />
                Partners
                {/* <KeyboardArrowDownIcon /> */}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ background: "#f4f4f4" }}
              >
                <Link to="/rplistforadmin" className="">
                  <div className="row">
                    <div className="col-9">Referral Partners</div>
                    <div className="col-3">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </Link>

                <Link to="/chdetails" className="">
                  <div className="row">
                    <div className="col-10">Channel Partners</div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="pt-3">
            <Link
              to="/fselistforadmin"
              className="d-flex side_menu_dashboardbtn1"
            >
              <div className="row">
                <div className="col-3">
                  <PieChartIcon className="" />
                </div>
                <div className="col-9 align-self-center">
                  <span className="p-2">FSE{""}</span>
                </div>
              </div>
            </Link>
          </div> */}
          <div className="pt-3">
            {/* <Link to="/login" className="side_menu_dashboardbtn1" onClick={logout}> */}
            <div onClick={logout} className="side_menu_dashboardbtn1">
              <div className="row">
                <div className="col-3">
                  <LogoutIcon />
                </div>
                <div className="col-9 align-self-center">
                  <span>Logout</span>
                </div>
              </div>
              {/* <LogoutIcon />
              Logout */}
            </div>

            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
