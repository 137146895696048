import React from "react";
import { Field } from "formik";

const PartnerTarget = ({
  values,
  errors,
  touched,
  showtarget,
  targettobeachieved,
}) => {
  return (
    <>
      {!showtarget && (
        <div className="viewing_details">
          <div className="row">
            <div className="col-12 col-md-12">
              <div>
                <p className="m-0 admin_type_heading">Target Assigned</p>
                <span className="admin_type_value">
                  {targettobeachieved === "" ? "-" : targettobeachieved}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {showtarget && (
        <div className="edit_details">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="input-group-custom">
                <label htmlFor="comp" className="label">
                  Target Assigned
                </label>
                <Field
                  type="number"
                  className="input-area admin_partner_det_input"
                  //   required
                  id="comp"
                  name="target"
                  value={values.target}
                  //   onChange={(e) => {
                  //     Settargettobeachieved(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PartnerTarget;
