import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { useHistory } from "react-router-dom";
import AdminNav from "../AdminNavBar/AdminNav";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import { Link } from "react-router-dom";
import Contact from "./ContactUploaded";
import EarningDetails from "./EarningDetails";
import axios from "axios";
import RPCPOnbareded from "./RPCP_onboarded";
import Package from "./Package";
import LeaderBoard from "./LeaderBoard";
import Loader from "../Helpers/Loader";
import Footer from "./Footer";
import ISPerformance from "./ISPerformance";
import LGSPerformance from "./LGSPerformance";

export default function Dashboard() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  let history = useHistory();
  const [totalRP, settotalRP] = useState("");
  const [totalCP, settotalCP] = useState("");
  const [totalLeads, settotalLeads] = useState("");

  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    const valid = localStorage.getItem("email");
    // console.log("dsdsdsdsdsds", valid);
    if (valid === null) {
      history.push("/");
    }

    getTotalCount();
  });

  const getTotalCount = () => {
    axios.get(`${SERVER_URL}/dashboardrps`).then((resCount) => {
      settotalRP(resCount.data.Referral_partners);
      settotalCP(resCount.data.Channel_partners);
      settotalLeads(resCount.data.Converted_leads);
      setisloading(false);
    });
    // .catch((err) => alert(err));
  };

  return (
    <div>
      {isloading === false ? (
        <div>
          <div>
            <AdminNav />
          </div>
          <div className="main">
            <div className="content-tabs">
              <div>
                <div className="top_menu_bar">
                  <div>
                    <span className="dashboard_head_txt">Dashboard</span>
                  </div>
                </div>

                <div className="pt-4 d-flex justify-content-between">
                  <div className="dashboard_user_count">
                    <div className="dashboard_user_icon">
                      <GroupRoundedIcon />
                    </div>
                    <div>
                      <Link to={"/rplist"}>
                        <span className="dashboard_total_count_no">
                          {totalRP != "" ? totalRP : 0}
                        </span>
                        <br />
                        <span className="dashboard_total_count_sub_txt">
                          Total Number of Referral Partners
                        </span>
                      </Link>
                    </div>
                  </div>

                  <div className="dashboard_user_count">
                    <div className="dashboard_user_icon">
                      <GroupRoundedIcon />
                    </div>
                    <div>
                      <Link to={"/chlist"}>
                        <span className="dashboard_total_count_no">
                          {totalCP != "" ? totalCP : 0}
                        </span>
                        <br />
                        <span className="dashboard_total_count_sub_txt">
                          Total Number of Channel Partners
                        </span>
                      </Link>
                    </div>
                  </div>

                  <div className="dashboard_user_count">
                    <div className="dashboard_user_icon">
                      <GroupRoundedIcon />
                    </div>
                    <div>
                      <span className="dashboard_total_count_no">
                        {totalLeads != "" ? totalLeads : 0}
                      </span>
                      <br />
                      <span className="dashboard_total_count_sub_txt">
                        Total Number of Leads Converted
                      </span>
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <RPCPOnbareded />
                </div>

                <div className="pt-4 d-flex justify-content-between">
                  <div className="col-12 col-md-6 pie_graph_dashboard">
                    <Contact />
                  </div>
                  <div className="col-12 col-md-6 pie_graph_dashboard">
                    <Package />
                  </div>
                </div>

                <div className="pt-4">
                  <EarningDetails />
                </div>

                <div className="pt-4">
                  <LeaderBoard />
                </div>

                <div className="pt-4 d-flex justify-content-between">
                  <div className="col-12 col-md-12 ">
                    <ISPerformance />
                  </div>
                </div>

                <div className="pt-4 d-flex justify-content-between">
                  <div className="col-12 col-md-12 ">
                    <LGSPerformance />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="bottom">Hello World</div> */}
          <div className="bottom">
            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
}
