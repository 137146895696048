import React from "react";
import "./login.css";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Logos from "../../Assets/Group 488889.png";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function Login() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [userid, Setuserid] = useState("");
  const [passwords, Setpassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  let history = useHistory();
  function logind() {
    const headers = {
      "Content-Type": "application/json",
    };
    const cred = {
      username: userid,
      password: passwords,
    };
    axios
      .post(`${SERVER_URL}/userlogin`, cred, {
        headers,
      })
      .then((responsedata) => {
        // console.log("res", responsedata);
        // console.log("NEW STATUS", responsedata.data.status);

        let Status = responsedata.data.status;
        if (Status === "success") {
          let roless = responsedata.data.role;
          // console.log("roles", roless);
          if (roless === "FSE") {
            toast.success("Login Success", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            localStorage.setItem("username", userid);
            localStorage.setItem("prof_ID", responsedata.data._id);
            history.push("/listofpartners");
          } else if (roless === "BDE") {
            toast.success("Login Success", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            localStorage.setItem("username", userid);
            localStorage.setItem("bde_ID", responsedata.data._id);
            localStorage.setItem("BDName", responsedata.data.Name);
            localStorage.setItem("user_code", responsedata.data.user_code);
            history.push("/bdashboard");
          } else if (roless === "AD") {
            toast.success("Login Success", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            localStorage.setItem("email", userid);
            localStorage.setItem("isAuthenticated", "true");
            localStorage.setItem("prof_ID", responsedata.data._id);
            history.push("/dash");
          } else if (roless === "LGS") {
            toast.success("Login Success", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            localStorage.setItem("username", userid);
            localStorage.setItem("CSName", responsedata.data.Name);
            localStorage.setItem("user_code", responsedata.data.user_code);
            localStorage.setItem("prof_ID", responsedata.data._id);
            history.push("/csdash");
          } else if (roless === "IS") {
            toast.success("Login Success", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            localStorage.setItem("username", userid);
            localStorage.setItem("ISName", responsedata.data.Name);
            localStorage.setItem("user_code", responsedata.data.user_code);
            localStorage.setItem("prof_ID", responsedata.data._id);
            history.push("/isdash");
          } else {
            // alert("user type does not exitst");
            toast.error("User Type does not Exists", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          // alert("Login success..!!");
        } else if (Status == "failed") {
          // alert("Login Failed Please Check the Credentials");
          toast.error("Login Failed Please Check the Credentials", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  }

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="Login_bg">
      <div className="login ">
        <div className="container">
          <div
            id="login-row"
            className="row justify-content-center align-items-center"
          >
            <div
              id="login-column"
              className="col-md-5"
              style={{
                padding: "30px",
                borderRadius: "19px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div id="login-box" className="col-md-12 formlogin">
                <div className="d-flex justify-content-center">
                  <img src={Logos} width="35%" style={{ marginTop: "-90px" }} />
                </div>
                {/* <form className="form formlogin" action="" method="post"> */}
                <div className="text-dark text-center pb-4">
                  <span className="login_txt">CRM Login</span>
                </div>
                <div className="form-group">
                  <label htmlFor="username" className="pb-3 label_txt">
                    User ID
                  </label>
                  <br />
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="form-control login_input_fileds"
                    onChange={(e) => Setuserid(e.target.value)}
                    placeholder="Enter User ID"
                    // autoComplete="off"
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="password" className="pb-3 label_txt">
                    Password
                  </label>
                  <br />

                  <div className="password_wrapper">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="password_field"
                      onChange={(e) => Setpassword(e.target.value)}
                      placeholder="Enter Your Password"
                    />
                    <span onClick={togglePassword}>
                      {passwordShown === true ? (
                        <VisibilityIcon className="password_eye" />
                      ) : (
                        <VisibilityOff className="password_eye" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  {/* <label htmlFor="remember-me" className="pt-3 pb-3 text-info">
                    <span>
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                      />
                    </span>
                    <span className="ps-2 remember_me_txt">Remember me</span> 
                  </label> */}
                  <br />
                  <button onClick={logind} className="btn btn-lg submit_btn">
                    Login
                  </button>
                </div>
                {/* <div id="register-link" className="text-right">
                    <a href="#" className="text-info">
                      Register here
                    </a>
                  </div> */}
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
