import react, { useState, useEffect } from "react";
import "./YtVideos.css";
import { GrClose } from "react-icons/gr";
import Switch from "../Switch/Switch";


function YtVideos() {
  const axios = require("axios").default;
  const [videoLinks, setVideoLinks] = useState([]);
  const [urlInput, setUrlInput] = useState("");
  const [videoEdit, setVideoEdit] = useState(false);
  const [ranks, setRanks] = useState([]);
  const [eleStatus, setEleStatus] = useState([]);

  useEffect(() => {
    getAllData();
  }, []);

  async function getAllData() {
    axios.get(`${process.env.REACT_APP_BASE_URL}/getvideo`).then(function (response) {
      setVideoLinks(response.data.result);

      response.data.result.map((youtube, index) => {
        handleRankChange(index + 1, youtube._id, index);
        handleStatusChange(youtube.status, youtube._id, index);
      });
    });
  }

  function addVideo() {
    axios.post(`${process.env.REACT_APP_BASE_URL}/addvideo`, {
      video_link: urlInput,
    });
  }

  function handleURLinput(url) {
    setUrlInput(url);
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  function handleRankChange(rank, id, index) {
    var rankArrObj = ranks;
    rankArrObj.splice(index, 1, { rank: parseInt(rank), _id: id });
    setRanks(rankArrObj);
  }

  function handleStatusChange(eleStatusArr, id, index) {
    var statusArrObj = eleStatus;
    statusArrObj.splice(index, 1, { status: eleStatusArr, _id: id });
    setEleStatus(statusArrObj);
  }

  async function updateVideos() {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/updatevideo`, {
        data: ranks,
      })
      .then(
        axios.post(`${process.env.REACT_APP_BASE_URL}/updatevideostatus`, {
          data: eleStatus,
        })
      );

    setTimeout(() => {
      getAllData();
    }, 500);
  }

  function saveVideoStatus() {
    setVideoEdit(false);
    updateVideos();
  }

  return (
    <div className="contentDiv">
      <div
        className="modal fade"
        id="addVideoModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ width: "450px" }}
        >
          <div className="modal-content popupBox">
            <div className="popupHeader">
              <h5
                className="modal-title popupHeadText"
                id="exampleModalLongTitle"
              >
                Add Video
              </h5>
              <button className="popupClose" data-dismiss="modal">
                <GrClose />
              </button>
            </div>
            <div className="modal-body popupBody">
              <p className="popupLabel">URL of YouTube video</p>
              <input
                className="popupInput"
                style={{ width: "100%" }}
                onChange={(e) => handleURLinput(e.target.value)}
              />

              <button
                className="popupSubmit"
                onClick={() => addVideo()}
                data-dismiss="modal"
              >
                + Add Video
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="contHead">
        <h6 id="setHeader">YouTube Videos</h6>
        {!videoEdit ? (
          <div className="btns">
            <button className="editBtn" onClick={() => setVideoEdit(true)}>
              Edit View
            </button>
            <button
              type="button"
              className="addBtn"
              data-toggle="modal"
              data-target="#addVideoModal"
            >
              + Add Video
            </button>
          </div>
        ) : (
          <div>
            <button
              onClick={() => saveVideoStatus()}
              type="button"
              className="addBtn"
            >
              Save
            </button>
          </div>
        )}
      </div>
      {!videoEdit ? (
        <div className="viewContentDiv">
          {videoLinks.map((youtube) => {
            return (
              <div className="ytRedirect">
                <div
                  className="cellDiv"
                  onClick={() =>
                    openInNewTab(
                      `https://www.youtube.com/watch?v=${youtube.video_id}/`
                    )
                  }
                  //https://www.youtube.com/watch?v=EHTWMpD6S_0&ab_channel=ThapaTechnical
                >
                  {/* <iframe width="197" height="116" src={youtube.video_link} /> */}
                  <img
                    src={youtube.thumbnail_url}
                    alt="thumbnail"
                    className="tyThumbnail"
                  />
                </div>
                <div className="ytTitleDiv">
                  <p className="ytTitle">{youtube.youtube_title}</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="viewContentDiv">
          {videoLinks.map((youtube, index) => {
            var rankVal = index + 1;
            return (
              <div className="cellRowDiv">
                <div className="rowRanknProjName">
                  <input
                    className="rankInput"
                    min="0"
                    max="99"
                    type={"number"}
                    defaultValue={rankVal}
                    onChange={(e) => {
                      handleRankChange(e.target.value, youtube._id, index);
                    }}
                  />
                  <div
                    className="rowInfoText"
                    onClick={() =>
                      openInNewTab(
                        `https://www.youtube.com/watch?v=${youtube.video_id}/`
                      )
                    }
                  >
                    <p className="rowInfoText">{youtube.youtube_title}</p>
                  </div>
                </div>
                <Switch
                  status={youtube.status}
                  _id={youtube._id}
                  index={index}
                  toggle={handleStatusChange}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default YtVideos;
