import React from "react";
import { Field } from "formik";
import EditIcon from "@mui/icons-material/Edit";

const PartnerPersnoal = ({
  show,
  values,
  refnames,
  refjoiningdate,
  refcompname,
  reftotalexp,
  refdob,
  refdesg,
  reftype,
  refworkloc,
}) => {
  return (
    <>
      {!show && (
        <div className="viewing_details">
          <div className="row">
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Full Name</p>
                <span className="admin_type_value">
                  {refnames === "" ? "-" : refnames}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Joining Date</p>
                <span className="admin_type_value">
                  {refjoiningdate === ""
                    ? "-"
                    : refjoiningdate
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("-")}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Company Name</p>
                <span className="admin_type_value">
                  {refcompname === "" ? "-" : refcompname}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Total Experience</p>
                <span className="admin_type_value">
                  {reftotalexp === "" ? "-" : reftotalexp}
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Date of Birth</p>
                <span className="admin_type_value">
                  {refdob === ""
                    ? "-"
                    : refdob.slice(0, 10).split("-").reverse().join("-")}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Designation</p>
                <span className="admin_type_value">
                  {refdesg === "" ? "-" : refdesg}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Type of Partner</p>
                <span className="admin_type_value">
                  {reftype === "" ? "-" : reftype}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Work Location</p>
                <span className="admin_type_value">
                  {refworkloc === "" ? "-" : refworkloc}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {show && (
        <div className="edit_details">
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="inputName" className="label">
                  Full Name
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  // required
                  id="inputName"
                  name="fullname"
                  value={values.fullname}
                  // onChange={(e) => {
                  //   Setrefnames(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="date" className="label">
                  Joining Date
                </label>
                <Field
                  type="date"
                  className="input-area admin_partner_det_input"
                  name="doj"
                  value={values.doj}
                  // onChange={(e) => {
                  //   Setrefjoiningdate(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="comp" className="label">
                  Company Name
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="comp"
                  name="companyname"
                  value={values.companyname}
                  // onChange={(e) => {
                  //   Setrefcompname(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="exp" className="label">
                  Total Experience
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="exp"
                  name="experience"
                  value={values.experience}
                  // onChange={(e) => {
                  //   Setreftotalexp(e.target.value);
                  // }}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="dob" className="label">
                  Date of Birth
                </label>
                <Field
                  type="date"
                  className="input-area admin_partner_det_input"
                  required
                  id="dob"
                  name="dob"
                  value={values.dob}
                  // onChange={(e) => {
                  //   Setrefdob(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="des" className="label">
                  Designation
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="des"
                  name="designation"
                  value={values.designation}
                  // onChange={(e) => {
                  //   Setrefdesg(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="type" className="label">
                  Type of Partner
                </label>
                <br />
                <span>{reftype}</span>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="loc" className="label">
                  Work Location
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="loc"
                  name="location"
                  value={values.location}
                  // onChange={(e) => {
                  //   Setrefworkloc(e.target.value);
                  // }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PartnerPersnoal;
