import React, { useState } from "react";
import "../RPList/file.css";
import { Formik, Form, Field, useField } from "formik";
import Dropzone from "react-dropzone";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function FileTesting() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [fileNames, setFileNames] = useState([]);
  const handleDrop = (acceptedFiles) =>
    // setfile(acceptedFiles.map((file) => file));
    // console.log("CS", file);
    // setFileNames(acceptedFiles.map((file) => file[0]));
    setFileNames(acceptedFiles[0]);
  // console.log("CS", fileNames);
  const updatecsvfile = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("file", fileNames);
    let verify = localStorage.getItem("_id");
    // console.log(verify);
    // console.log("Data", formdata);
    // const headers = {
    //   "content-type": "multipart/form-data",
    // };
    axios.post(`${SERVER_URL}/uploadpartnerdata`, formdata).then((res) => {
      // console.log("res", res.data.status);
      let Status = res.data.status;
      if (Status === "success") {
        // alert("Details Added successfuly");
        toast.success("Details Added successfuly", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        // alert("Details are already Exists");
        toast.error("Details are already Exists", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <Formik
      initialValues={{
        fileNames: "",
      }}
      onSubmit={(data) => {
        // console.log("123", data);
        // updatecsvfile();
      }}
    >
      <div className="App">
        <Form>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input name="fileNames" {...getInputProps()} />
                <p>Drag & Drop files, or click to select files</p>
              </div>
            )}
          </Dropzone>
          <div>
            <strong>Files:</strong>
            <ul>
              {/* {fileNames.map((fileName) => ( */}
              <li>{fileNames.name}</li>
              {/* ))} */}
            </ul>
            <button
              className="btn_add_hot_lead"
              type="submit"
              onClick={updatecsvfile}
            >
              Upload
            </button>
          </div>
        </Form>
      </div>
    </Formik>
  );
}
