import react, { useState, useEffect } from "react";
import "./CendrolCollections.css";
import { GrClose } from "react-icons/gr";
import gripper from "../../../Assets/gripper.png";
import hoverDelBtn from "../../../Assets/Hover.png";
import { MdAddCircleOutline } from "react-icons/md";


function CendrolCollections() {
  const axios = require("axios").default;
  const [cendrolCollections, setCendrolCollections] = useState([]);
  const [files, setFiles] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [buildUpArea, setBuildUpArea] = useState("");
  const [size, setSize] = useState("");
  const [noOfbathrooms, setNoOfBathrooms] = useState("");
  const [projectValue, setProjectValue] = useState("");
  const [description, setDescription] = useState("");
  const [projRankEdit, setProjRankEdit] = useState(false);
  const [detailView, setDetailView] = useState(false);

  useEffect(() => {
    getAllData();
  }, []);

  function removeImg(img) {
    var tempFilesArr = [...files];
    setFiles(tempFilesArr.filter((imgName) => imgName[0] != img[0]));
  }

  function handleImageUpload(e) {
    var tempFilesArr = [...files];
    // tempFilesArr.push([URL.createObjectURL(e.target.files[0])]);
    tempFilesArr.push(e.target.files[0]);
    console.log(tempFilesArr);
    setFiles(tempFilesArr);
  }

  async function addProject() {
    // console.log(files);
    // var imgsToSend = [];
    // [...files].forEach((image) => {
    // const reader = new FileReader();
    // reader.readAsBinaryString(image);
    // imgsToSend.push(image);
    // });

    var addProjData = new FormData();

    files.forEach((image) => {
      addProjData.append("projectimages", image);
    });

    addProjData.append("project_name", projectName);
    addProjData.append("builtup_area", buildUpArea);
    addProjData.append("size", size);
    addProjData.append("no_of_bathrooms", noOfbathrooms);
    addProjData.append("project_value", projectValue);
    addProjData.append("project_description", description);
    // addProjData.append("projectimages", imgsToSend);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/addcollection`, addProjData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        getAllData();
        clearData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getAllData() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getcollections`)
      .then(function (response) {
        setCendrolCollections(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function showDets(collection) {
    setDetailView(true);
    setProjectName(collection.project_name);
    setBuildUpArea(collection.builtup_area);
    setSize(collection.size);
    setNoOfBathrooms(collection.no_of_bathrooms);
    setProjectValue(collection.project_value);
    setDescription(collection.description);
    window.$("#addCollectionModal").modal("show");
  }

  function clearData() {
    setDetailView(false);
    setProjectName("");
    setBuildUpArea("");
    setSize("");
    setNoOfBathrooms("");
    setProjectValue("");
    setDescription("");
  }

  function deleteFromCategory(id) {
    console.log(`${process.env.REACT_APP_BASE_URL}/deleteCollections?_id=${id}`);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/deleteCollections?_id=${id}`)
      .then((res) => console.log(res));

    getAllData();
  }

  return (
    <div className="contentDiv">
      <div
        className="modal fade"
        id="addCollectionModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div
            className="modal-content popupBox"
            style={{ marginBottom: "15px" }}
          >
            <div className="popupHeader">
              <h5
                className="modal-title popupHeadText"
                id="exampleModalLongTitle"
              >
                Add Project to Collection
              </h5>
              <button className="popupClose" data-dismiss="modal">
                <GrClose />
              </button>
            </div>
            <div className="modal-body popupBody">
              <div className="popupBiSectionDiv">
                <div className="popupFormDiv">
                  <div className="popupFormRow">
                    <span className="marginR15">
                      <p className="popupLabel">Project Name</p>
                      <input
                        className="popupInput"
                        style={{ width: "150%" }}
                        defaultValue={projectName}
                        onChange={(e) => {
                          setProjectName(e.target.value);
                        }}
                      />
                    </span>
                  </div>
                  <div className="popupFormRow">
                    <span className="marginR15">
                      <p className="popupLabel">Build Up Area</p>
                      <input
                        className="popupInput"
                        defaultValue={buildUpArea}
                        onChange={(e) => {
                          setBuildUpArea(e.target.value);
                        }}
                      />
                    </span>
                    <span className="marginR15">
                      <p className="popupLabel">Size</p>
                      <input
                        className="popupInput"
                        defaultValue={size}
                        onChange={(e) => {
                          setSize(e.target.value);
                        }}
                      />
                    </span>
                  </div>
                  <div className="popupFormRow">
                    <span className="marginR15">
                      <p className="popupLabel">No. Of Bathrooms</p>
                      <input
                        className="popupInput"
                        defaultValue={noOfbathrooms}
                        onChange={(e) => {
                          setNoOfBathrooms(e.target.value);
                        }}
                      />
                    </span>
                    <span className="marginR15">
                      <p className="popupLabel">Project Value</p>
                      <input
                        className="popupInput"
                        defaultValue={projectValue}
                        onChange={(e) => {
                          setProjectValue(e.target.value);
                        }}
                      />
                    </span>
                  </div>
                  <p className="popupLabel">Description</p>
                  <textarea
                    placeholder="Enter Description Here"
                    className="popupInputLg"
                    defaultValue={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>

                <div className="popupImgDiv">
                  <p className="popupLabel" style={{ width: "100%" }}>
                    Project Images
                  </p>
                  {files.map((img) => {
                    var ImageSource = URL.createObjectURL(img);
                    return (
                      <div className="imgParent" onClick={() => removeImg(img)}>
                        <img className="hoverDel" src={hoverDelBtn} />
                        <img className="imgPrev" src={ImageSource} />
                      </div>
                    );
                  })}
                  {files.length < 6 && !detailView && (
                    <div class="upload-btn-wrapper">
                      <button class="addimgbtn">
                        <MdAddCircleOutline />
                        <p className="addProjText">Add Photo</p>
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        onChange={(e) => handleImageUpload(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
              {!detailView && (
                <button
                  className="popupSubmitSm"
                  onClick={() => addProject()}
                  data-dismiss="modal"
                >
                  + Add Project
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="contHead">
        <h6 id="setHeader">Cendrol Collections</h6>
        {!projRankEdit ? (
          <div className="btns">
            <button className="editBtn" onClick={() => setProjRankEdit(true)}>
              Edit View
            </button>
            <button
              className="addBtn"
              data-toggle="modal"
              data-target="#addCollectionModal"
              onClick={() => clearData()}
            >
              + Add Project
            </button>
          </div>
        ) : (
          <div className="btns">
            <button className="addBtn" onClick={() => setProjRankEdit(false)}>
              Close
            </button>
          </div>
        )}
      </div>
      {!projRankEdit ? (
        <div className="viewContentDiv">
          {cendrolCollections.slice(0, 4).map((collection, index) => {
            return (
              <div className="cellDiv" onClick={() => showDets(collection)}>
                <div
                  className="imgDiv"
                  style={{
                    backgroundImage: `url("${process.env.REACT_APP_IMAGES_URL}/${collection.project_images[0]}")`,
                  }}
                >
                  <div className="details">
                    <div style={{ padding: "15px" }}>
                      <p className="projName">{collection.project_name}</p>
                      <p className="viewDets">View Details</p>
                    </div>
                    <p className="rank">{index + 1}</p>
                  </div>
                </div>
              </div>
            );
          })}
          {cendrolCollections.slice(4, 100).map((collection, index) => {
            return (
              <div className="cellRowDiv">
                <div className="rowRanknProjName">
                  <img src={gripper} className="gripper" />
                  <p className="rank projRowRank">{index + 5}</p>
                  <div className="rowInfoText">
                    <p className="rowLabels">Project Name</p>
                    <p className="rowInfoText">{collection.project_name}</p>
                  </div>
                </div>
                <div>
                  <p className="rowLabels">Build Up Area</p>
                  <p className="rowInfoText">{collection.builtup_area}</p>
                </div>
                <div className="projectValue">
                  <p className="rowInfoText">{collection.project_value}/-</p>
                </div>
                <div
                  className="viewDetsRowBtn"
                  onClick={() => showDets(collection)}
                >
                  <p className="viewDets" style={{ color: "black" }}>
                    View Details
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="viewContentDiv">
          {cendrolCollections.map((collection, index) => {
            return (
              <div className="cellRowDiv">
                <div className="rowRanknProjName">
                  <img src={gripper} className="gripper" />
                  <input
                    className="rankInput"
                    min="0"
                    max="99"
                    type={"number"}
                    defaultValue={index + 1}
                    // onChange={(e) => {
                    //   handleRankChange(e.target.value, youtube._id, index);
                    // }}
                  />
                  <div className="rowInfoText">
                    <p className="rowLabels">Project Name</p>
                    <p className="rowInfoText">{collection.project_name}</p>
                  </div>
                </div>
                <div>
                  <p className="rowLabels">Build Up Area</p>
                  <p className="rowInfoText">{collection.builtup_area}</p>
                </div>
                <div className="projectValue">
                  <p className="rowInfoText">{collection.project_value}/-</p>
                </div>
                <button
                  className="catDelete"
                  onClick={() => deleteFromCategory(collection._id)}
                >
                  Delete
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default CendrolCollections;
