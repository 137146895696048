import React from "react";
import profilepic from "../../Assets/profilepic.png";
import PieChartIcon from "@mui/icons-material/PieChart";
import GroupIcon from "@mui/icons-material/Group";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useHistory } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Link, NavLink } from "react-router-dom";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import adminprofile from "../../Assets/adminprofile.png";
import LogoutIcon from "@mui/icons-material/Logout";

export default function UserNav2() {
  let history = useHistory();
  let email = localStorage.getItem("username");
  let name = localStorage.getItem("BDName");

  function logout() {
    localStorage.removeItem("username");
    localStorage.removeItem("user_code");
    localStorage.removeItem("BDName");
    localStorage.clear();
    history.push("/");
  }

  const profile = () => {
    history.push("/profilebd");
  };

  return (
    <div>
      <div className="sidenav">
        <div>
          <div className="d-flex">
            <div style={{ cursor: "pointer" }}>
              <img
                // src={adminprofile}
                src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                className="profile_pic_sidenav"
                onClick={profile}
              />
            </div>
            <div className="p-2 profile_details">
              <span>{name}</span>
              <p className="mb-0">{email}</p>
            </div>
          </div>

          <div className="pt-3">
            <NavLink
              to="/bdashboard"
              className="side_menu_dashboardbtn1"
              style={{ justifyContent: "start" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <PieChartIcon />
                </div>
                <div>
                  <span>Dashboard</span>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="pt-3">
            <NavLink
              to="/listofcustomer/1"
              className="d-flex side_menu_dashboardbtn1"
              style={{ justifyContent: "start" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <GroupIcon />
                </div>
                <div>
                  <span>Leads</span>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="pt-3">
            <div
              className="d-flex side_menu_dashboardbtn1"
              data-toggle="modal"
              data-target="#exampleModal"
              style={{ justifyContent: "start" }}
            >
              {/* <div className="row">
                <div className="col-3">
                  <LogoutIcon />
                </div>
                <div className="col-9 align-self-center">
                  <span>Logout</span>
                </div>
              </div> */}
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <LogoutIcon />
                </div>
                <div>
                  <span>Logout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                </div>
                <div className="popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Logout ?
                  </span>
                </div>
                <div className="pt-3 d-flex">
                  <div style={{ width: "50%", textAlign: "center" }}>
                    <button
                      type="button"
                      className="popup_btn_unlinkaccount"
                      // onClick={deletebankdetails}
                      onClick={logout}
                      data-dismiss="modal"
                    >
                      Yes
                    </button>
                  </div>
                  <div style={{ width: "50%", textAlign: "center" }}>
                    <button
                      // type="button"
                      className="popup_btn_close"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
