import AdminNav from "../AdminNavBar/AdminNav";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import axios from "axios";
import React, { useState, useEffect } from "react";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { SelectField } from "../Fields/SelectField";
import Footer from "../Dashboard/Footer";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Helpers/Loader";
import { Link } from "react-router-dom";
import { CgArrowRightR } from "react-icons/cg";
// User PopUp Import
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Formik, Form } from "formik";
import { TextField } from "../Fields/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function ManageUser() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [userdata, Setuserdata] = useState([]);
  let history = useHistory();
  const [isloading, setisloading] = useState(true);
  const [btnloader, setbtnloader] = useState(false);

  const { PageNo } = useParams();
  const { SearchBar } = Search;
  const [Page, setPage] = useState(PageNo ? PageNo : 1);
  const [PerPage, setPerPage] = useState(50);
  const [searchcount, setsearchcount] = useState([]);
  const [enabledelete, setenabledelete] = useState(false);
  // console.log("enabledelete", enabledelete);
  const [SelectedId, setSelectedId] = useState([]);

  const afterSearch = (newResult) => {
    setsearchcount(newResult.length);
  };

  useEffect(() => {
    const valid = localStorage.getItem("email");
    if (valid === null) {
      history.push("/");
    }
    getUsers();
  }, []);

  const getUsers = () => {
    axios
      .get(`${SERVER_URL}/getusers`)
      .then((resusers) => {
        let customer = resusers.data.Users;
        Setuserdata(resusers.data.Users);
        setisloading(false);
        // console.log("userdataaa", resusers.data.Users);
        customer.map((de) => {
          return {
            select: false,
            id: de._id,
            name: de.name,
            userId: de.userId,
            password: de.password,
            role: de.role,
            user_code: de.user_code,
            permision: de.permissions,
          };
        });
      })
      .catch((err) => alert(err));
  };

  const selectedUserRows = userdata.filter((row) => row.isSelect);
  // console.log("selected", selectedUserRows);
  const LeadSelectedUserId = selectedUserRows.map((row) => row._id);
  // console.log("select", LeadSelectedUserId);

  const deletemultipleuserrecords = () => {
    let userids = [];
    // userdata.forEach((de) => {
    //   if (de.select) {
    //     userids.push(de._id);
    //   }
    // });
    userids = LeadSelectedUserId.length === 0 ? SelectedId : LeadSelectedUserId;

    axios
      // .post(`https://pure-wave-48602.herokuapp.com/deletemore`, {
      .post(`${SERVER_URL}/deletemultipleusers`, {
        userids,
      })
      .then((resultdeleteds) => {
        // console.log("FSE deleted", resultdeleteds);
        let Status = resultdeleteds.data.status;
        if (Status === "success") {
          window.$("#MultipleDelete").modal("hide");
          window.$("#SuccessfullyDeleted").modal("show");
          getUsers();
        } else {
          // alert("Internal Server Error");
          toast.error("Internal Server Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => alert(err));
  };

  // User POP UP Code
  const [passwordShown, setPasswordShown] = useState(false);
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  // Validation Individual Start\
  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is Required";
    } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
      error = "Enter a Valid Name It should contain only Aplhabets";
    } else if (value.length > 20) {
      error = "Name Should not more than 20";
    } else if (value.length < 3) {
      error = "Name Should not less than 3 Characters";
    }
    return error;
  }

  function validateEmpID(value) {
    let error;
    if (!value) {
      error = "Employee ID is Required";
    } else if (!/^([A-Z]{1,5}[0-9]{3})+$/i.test(value)) {
      error = "Enter a Valid Employee ID";
    } else if (value.length > 8) {
      error = "Employee ID Should not more than 8 Characters";
    } else if (value.length < 8) {
      error = "Employee ID Should not less than 8 Characters";
    }
    return error;
  }

  function validateMobileNumber(value) {
    let error;
    if (!value) {
      error = "Mobile Number is Required";
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
    ) {
      error = "Invalid Mobile Number";
    }
    return error;
  }

  function validatePassword(value) {
    let error;
    if (!value) {
      error = "Password is Required";
    }
    return error;
  }

  function validateUserId(value) {
    let error;
    if (!value) {
      error = "Email is Required";
    } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validateType(value) {
    let error;
    if (!value) {
      error = "User Type is Required";
    }
    return error;
  }

  function validatedateofjoining(value) {
    let error;
    if (!value) {
      error = "Date of Joining is Required";
    }
    return error;
  }

  // New Pagination
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const selectRow = {
    mode: "checkbox",
    // onSelectAll: onSelectAll,

    // clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      Setuserdata((rows) => {
        return rows.map((r) => {
          if (r._id !== row._id) {
            return r;
          }
          return { ...r, isSelect };
        });
      });
    },

    onSelectAll: (isSelect, rows, e) => {
      let array = [];
      for (let i = 0; i < rows.length; i++) {
        array.push(rows[i]._id);
        console.log("arra", array);
        if (array.length > 0) {
          setenabledelete(true);
        } else {
          setenabledelete(false);
        }
        setSelectedId(array);
      }
      // selecting all data
      // Setleaddata((row) => {
      //   return row.map((rows) => {
      //     return { ...rows, isSelect };
      //   });
      // });
    },
  };

  const columns = [
    {
      dataField: "Sl. No.",
      text: "SL. No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (Page - 1) * PerPage + rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "Full Name",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "user_code",
      text: "User Code.",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "role",
      text: "Role",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "userId",
      text: "UserId",
      formatter: (cell) => (!cell ? "-" : cell),
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell) => (
        <Link to={`/userdetails/${cell}/${Page}`} className="viewmore">
          <CgArrowRightR />
        </Link>
      ),
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <button
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ width: "100px", background: "none" }}
        className="text-start"
      >
        {text}
      </button>
    </li>
  );

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log("row", row);
      history.push(`/userdetails/${row._id}/${Page}`);
    },
  };

  const paginationoptions = {
    onPageChange: (page, sizePerPage) => {
      setPage(page);
      setPerPage(sizePerPage);
    },
    page: parseInt(Page),
    sizePerPageOptionRenderer,
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    // remove these two
    sizePerPage: 50,
    hideSizePerPage: true,
    sizePerPageList: [
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "90",
        value: 90,
      },
      {
        text: "120",
        value: 120,
      },
    ],
  };

  return (
    <div>
      {isloading === false ? (
        <div>
          <div>
            <AdminNav />
          </div>
          <div className="main">
            <div className="content-tabs">
              <ToolkitProvider
                keyField="_id"
                data={userdata}
                columns={columns}
                search={{ afterSearch }}
              >
                {(props) => (
                  <div>
                    <div className="top_menu_bar">
                      <div>
                        <div>
                          <span className="CS_head_txt">Manage Users</span>
                        </div>
                        <span className="total_count_txt">
                          (
                          {searchcount === 0
                            ? searchcount
                            : searchcount > 0
                            ? searchcount
                            : userdata.length}{" "}
                          users)
                        </span>
                      </div>
                      <div>
                        <div
                          className="d-flex justify-content-end"
                          style={{ gap: "15px" }}
                        >
                          <div className="search_div" style={{ width: "50%" }}>
                            <SearchIcon />
                            <SearchBar
                              {...props.searchProps}
                              className="top_menu_search"
                              placeholder="Search Here..."
                              // style={{ width: "400px", height: "40px" }}
                            />
                            {/* <input
                              type="text"
                              className="top_menu_search"
                              placeholder="Search Here.."
                              onChange={(event) => {
                                Setsearchterm(event.target.value);
                              }}
                            /> */}
                          </div>

                          <div>
                            {/* Add User POP UP starting  */}
                            {/* <AddUserPopup /> */}
                            <div>
                              {/* Add User Button Main Page Start */}
                              <button
                                className="add_btn"
                                data-toggle="modal"
                                data-target=".bd-example-modal-user"
                              >
                                <AddIcon className="search_icons" />
                                Add User
                              </button>
                              {/* Add User Button Main Page End */}
                            </div>

                            <div>
                              {/* Add User Pop Up Content start */}
                              <Formik
                                initialValues={{
                                  name: "",
                                  emp_ID: "",
                                  doj: "",
                                  mobile: "",
                                  userId: "",
                                  password: "",
                                  role: "",
                                }}
                                onSubmit={(values, { resetForm }) => {
                                  setbtnloader(true);
                                  // console.log("val", values);
                                  let verify = localStorage.getItem("_id");

                                  const addusers = {
                                    name: values.name,
                                    emp_ID: values.emp_ID,
                                    doj: values.doj,
                                    mobile: values.mobile,
                                    userId: values.userId,
                                    password: values.password,
                                    role: values.role,
                                  };

                                  const headers = {
                                    "Content-Type": "application/json",
                                  };

                                  axios
                                    // .post(`https://pure-wave-48602.herokuapp.com/addfse`, datafse, {
                                    .post(
                                      `${SERVER_URL}/createuser`,
                                      addusers,
                                      {
                                        headers,
                                      }
                                    )
                                    .then((res) => {
                                      // console.log(res);
                                      let Status = res.data.status;
                                      if (Status === "success") {
                                        window.$("#AddUserPopUp").modal("hide");
                                        window
                                          .$("#UserAddedSuccessfully")
                                          .modal("show");
                                        setbtnloader(false);
                                        resetForm({ values: "" });
                                        getUsers();
                                        // history.push("/fselist");
                                        // $(".popup-add_hot-lead-added").show();
                                      } else if (Status === "failed") {
                                        setbtnloader(false);
                                        // alert("Details are already Exists");
                                        toast.error(
                                          "Details are Already Exists",
                                          {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                          }
                                        );
                                        resetForm({ values: "" });
                                      }
                                    });
                                }}
                              >
                                {(formik) => (
                                  <div>
                                    {/* {console.log(formik)} */}
                                    <Form>
                                      <div
                                        id="AddUserPopUp"
                                        className="modal fade bd-example-modal-user"
                                        role="dialog"
                                        aria-labelledby="myLargeModalLabel"
                                        aria-hidden="true"
                                      >
                                        <div className="modal-dialog modal-dialog-centered  ">
                                          <div
                                            className="modal-content modal_content_whole"
                                            style={{ background: "white" }}
                                          >
                                            {/*  */}
                                            <div className="modal-body">
                                              <div className="pb-3">
                                                <span className="add_hot_lead_label">
                                                  {/* <LocalFireDepartmentIcon
                                            style={{
                                              color: "red",
                                            }}
                                          /> */}
                                                  Add Users
                                                </span>
                                                <span
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  id="CloseAddHotLeadPopup"
                                                  style={{
                                                    background: "transparent",
                                                    float: "right",
                                                    color: "red",
                                                  }}
                                                >
                                                  <span aria-hidden="true">
                                                    X Close
                                                  </span>
                                                </span>
                                              </div>
                                              <div className="d-flex justify-content-between">
                                                <div
                                                  className="p-3"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div>
                                                    <div className="p-3 add_hot_lead_content">
                                                      <div className="row">
                                                        <div className="col-md-12">
                                                          <div className="input-group_add_hot_lead ">
                                                            <TextField
                                                              type="text"
                                                              name="name"
                                                              label="Full Name"
                                                              validate={
                                                                validateName
                                                              }
                                                              placeholder="Enter Full Name"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="row">
                                                        <div className="col-6 col-md-6">
                                                          <div className="input-group_add_hot_lead ">
                                                            <TextField
                                                              type="text"
                                                              name="emp_ID"
                                                              id="emp_ID"
                                                              label="Employee ID"
                                                              validate={
                                                                validateEmpID
                                                              }
                                                              placeholder="Enter Employee ID"
                                                            />
                                                          </div>
                                                        </div>

                                                        <div className="col-6 col-md-6">
                                                          <div className="input-group_add_hot_lead ">
                                                            <TextField
                                                              type="text"
                                                              name="mobile"
                                                              label="Phone Number"
                                                              validate={
                                                                validateMobileNumber
                                                              }
                                                              placeholder="Enter Phone Number"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="row pb-3">
                                                        <div className="col-6 col-sm-6">
                                                          <div className="input-group_add_hot_lead ">
                                                            <TextField
                                                              type="email"
                                                              name="userId"
                                                              label="User ID"
                                                              validate={
                                                                validateUserId
                                                              }
                                                              placeholder="Enter User ID"
                                                            />
                                                          </div>
                                                        </div>

                                                        <div className="col-6 col-sm-6">
                                                          <div className="input-group_add_hot_lead">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                              <div>
                                                                <TextField
                                                                  type={
                                                                    passwordShown
                                                                      ? "text"
                                                                      : "password"
                                                                  }
                                                                  name="password"
                                                                  validate={
                                                                    validatePassword
                                                                  }
                                                                  label="Password"
                                                                  placeholder="Enter Password"
                                                                />
                                                              </div>
                                                              <div className="pt-3">
                                                                <VisibilityIcon
                                                                  onClick={
                                                                    togglePassword
                                                                  }
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-6 col-sm-6">
                                                          <div className="input-group_add_hot_lead">
                                                            <TextField
                                                              type="date"
                                                              name="doj"
                                                              label="Joining Date"
                                                              validate={
                                                                validatedateofjoining
                                                              }
                                                            />
                                                          </div>
                                                        </div>

                                                        <div className="col-6 col-sm-6">
                                                          <div className="input-group_add_hot_lead ">
                                                            <SelectField
                                                              className="form-select"
                                                              name="role"
                                                              label="User Type"
                                                              validate={
                                                                validateType
                                                              }
                                                              // autoComplete="off"
                                                            >
                                                              <option
                                                                selected
                                                                disabled
                                                                value=""
                                                              >
                                                                Select User
                                                              </option>
                                                              <option value="AD">
                                                                AD
                                                              </option>
                                                              <option value="BDE">
                                                                BDE
                                                              </option>
                                                              <option value="FSE">
                                                                FSE
                                                              </option>
                                                              <option value="IS">
                                                                IS
                                                              </option>
                                                              <option value="LGS">
                                                                LGS
                                                              </option>
                                                            </SelectField>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <button
                                                          className="btn_add_hot_lead"
                                                          type="submit"
                                                        >
                                                          {btnloader ===
                                                          true ? (
                                                            <span
                                                              className="spinner-border spinner-border-sm"
                                                              role="status"
                                                              aria-hidden="true"
                                                            ></span>
                                                          ) : (
                                                            ""
                                                          )}
                                                          Add User
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Form>
                                  </div>
                                )}
                              </Formik>
                              {/* Add User Pop Up Content end */}
                            </div>

                            {/* 2nd Model */}
                            <div
                              id="UserAddedSuccessfully"
                              className="modal fade popup-add_hot-lead-added"
                              role="dialog"
                              aria-labelledby="myLargeModalLabel"
                              aria-hidden="true"

                              // style={{display:"none"}}
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div
                                  className="modal-content modal_content_whole"
                                  style={{ background: "white" }}
                                >
                                  <div className="modal-body">
                                    <div>
                                      <div className="popup_icon_center">
                                        <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                                      </div>
                                      <div className="popup_text_center">
                                        <span className="popup_addbankdetails_text">
                                          User Added Successfuly
                                        </span>
                                        <div className="pt-3 d-flex justify-content-center">
                                          <div
                                            style={{
                                              width: "50%",
                                              textAlign: "center",
                                            }}
                                          >
                                            <button
                                              // type="button"
                                              className="popup_btn_linkaccount"
                                              data-dismiss="modal"
                                            >
                                              OK
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Add User POP UP Ending  */}
                          </div>

                          <button
                            className="add_btn"
                            data-toggle="modal"
                            data-target="#MultipleDelete"
                            style={{ padding: "7px" }}
                          >
                            <DeleteIcon className="del_icons" />
                          </button>

                          <div
                            id="MultipleDelete"
                            className="modal fade exampleModal"
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-sm modal-dialog-centered ">
                              <div
                                className="modal-content modal-sm modal_content_whole"
                                style={{ background: "white" }}
                              >
                                <div className="modal-body">
                                  <div>
                                    <div className="popup_icon_center">
                                      <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                                    </div>
                                    <div className="popup_text_center">
                                      <span className="popup_addbankdetails_text">
                                        Are you sure want to Delete{" "}
                                        {LeadSelectedUserId.length !== 0
                                          ? LeadSelectedUserId.length
                                          : SelectedId.length !== 0
                                          ? SelectedId.length
                                          : ""}{" "}
                                        Users ?
                                      </span>
                                    </div>
                                    <div className="pt-4 m-0 d-flex justify-content-center">
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="popup_btn_unlinkaccount"
                                          onClick={() => {
                                            deletemultipleuserrecords();
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <button
                                          // type="button"
                                          className="popup_btn_close"
                                          data-dismiss="modal"
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Sucuesfully deleted */}
                          <div
                            className="modal fade popup-bank-details-deleted"
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                            id="SuccessfullyDeleted"
                          >
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                              <div
                                className="modal-content modal-sm modal_content_whole"
                                style={{ background: "white" }}
                              >
                                <div className="modal-body">
                                  <div>
                                    <div className="popup_icon_center">
                                      <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                                    </div>
                                    <div className="pt-2 popup_text_center">
                                      <span className="popup_addbankdetails_text">
                                        User Deleted Successfully
                                      </span>
                                      <div className="pt-2 m-0 d-flex justify-content-center">
                                        <div
                                          style={{
                                            width: "50%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <button
                                            // type="button"
                                            className="popup_btn_linkaccount"
                                            data-dismiss="modal"
                                          >
                                            OK
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="table_div">
                        {userdata != "" ? (
                          <div>
                            <BootstrapTable
                              {...props.baseProps}
                              selectRow={selectRow}
                              noDataIndication={emptyDataMessage}
                              pagination={paginationFactory(paginationoptions)}
                              filter={filterFactory()}
                              rowEvents={rowEvents}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div>
                              <WarningRoundedIcon className="no_data_alert" />
                            </div>
                            <div>
                              <h3>No records found for Customers</h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
}
