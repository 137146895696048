import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import PieChartIcon from "@mui/icons-material/PieChart";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import GroupIcon from "@mui/icons-material/Group";
import InsightsSharpIcon from "@mui/icons-material/InsightsSharp";

const CS_SideNav = () => {
  let history = useHistory();
  let email = localStorage.getItem("username");
  let name = localStorage.getItem("CSName");

  const logout = () => {
    localStorage.removeItem("user_code");
    localStorage.removeItem("username");
    localStorage.removeItem("CSName");
    localStorage.clear();
    history.push("/");
  };

  const profile = () => {
    history.push("/profilecs");
  };

  return (
    <div>
      <div className="sidenav">
        <div>
          <div className="d-flex">
            <div style={{ cursor: "pointer" }}>
              <img
                src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                className="profile_pic_sidenav"
                onClick={profile}
              />
            </div>
            <div className="p-2 profile_details">
              <span>{name}</span>
              <p className="mb-0">{email}</p>
            </div>
          </div>

          <div className="pt-3">
            <NavLink
              to="/csdash"
              className="side_menu_dashboardbtn1"
              style={{ justifyContent: "start" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <PieChartIcon />
                </div>
                <div>
                  <span>Dashboard</span>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="mt-3">
            <div className="dropdown">
              <button
                className="btn side_menu_partnersbtn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <GroupIcon />
                Lead Source
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ background: "#f4f4f4" }}
              >
                <NavLink to="/cslistpartner/1" className="">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Referral Partners
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/cslistchpartner/1" className="">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Channel Partners
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/csotherleads/1" className="">
                  <div className="row">
                    <div className="col-10" style={{ alignSelf: "center" }}>
                      Others
                    </div>
                    <div className="p-0 col-2">
                      <ChevronRightIcon />
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="pt-3">
            <NavLink
              to="/dmlistCS"
              className="side_menu_dashboardbtn1"
              style={{ justifyContent: "start" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <InsightsSharpIcon />
                </div>
                <div>
                  <span>Digital Marketing</span>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="pt-3">
            <div
              className="side_menu_dashboardbtn1"
              data-toggle="modal"
              data-target=".channel_sales_logout"
              style={{ justifyContent: "start" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <LogoutIcon />
                </div>
                <div>
                  <span>Logout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade channel_sales_logout"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered ">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                </div>
                <div className="popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Logout?
                  </span>
                </div>
                <div className="pt-3 d-flex">
                  <div style={{ width: "50%", textAlign: "center" }}>
                    <button
                      type="button"
                      className="popup_btn_unlinkaccount"
                      // onClick={deletepartnerdetails}
                      data-toggle="modal"
                      data-target=".partner-details-deleted-success"
                      data-dismiss="modal"
                      onClick={logout}
                    >
                      Logout
                    </button>
                  </div>
                  <div style={{ width: "50%", textAlign: "center" }}>
                    <button
                      // type="button"
                      className="popup_btn_close"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CS_SideNav;
