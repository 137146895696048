import React from "react";
import { Field } from "formik";

const PartnerBank = ({
  showbank,
  refbankname,
  refbranchname,
  refaccno,
  refifsccode,
  errors,
  touched,
  values,
}) => {
  return (
    <div>
      {!showbank && (
        <div className="viewing_details">
          <div className="row">
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Bank Name</p>
                <span className="admin_type_value">
                  {refbankname === "" ? "-" : refbankname}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Branch Name</p>
                <span className="admin_type_value">
                  {refbranchname === "" ? "-" : refbranchname}
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">Account Number</p>
                <span className="admin_type_value">
                  {refaccno === null || refaccno === "" ? "-" : refaccno}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div>
                <p className="m-0 admin_type_heading">IFSC Code</p>
                <span className="admin_type_value">
                  {refifsccode === "" ? "-" : refifsccode}
                </span>
              </div>
            </div>
            {/* <div className="col-6 col-md-4">
              <div>
                <p className="m-0 admin_type_heading">Type of Account</p>
                <span className="admin_type_value">{refbankacctype}</span>
              </div>
            </div> */}
          </div>
        </div>
      )}
      {showbank && (
        <div className="edit_details">
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="comp" className="label">
                  Bank Name
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="comp"
                  name="bankName"
                  value={values.bankName}
                  //   onChange={(e) => {
                  //     Setrefbankname(e.target.value);
                  //   }}
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="exp" className="label">
                  Branch Name
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="exp"
                  name="branch"
                  value={values.branch}
                  //   onChange={(e) => {
                  //     Setrefbranchname(e.target.value);
                  //   }}
                />
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="dob" className="label">
                  Account Number
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="dob"
                  name="accountNumber"
                  value={values.accountNumber}
                  //   onChange={(e) => {
                  //     Setrefaccno(e.target.value);
                  //   }}
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="input-group-custom">
                <label htmlFor="des" className="label">
                  IFSC Code
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="des"
                  name="ifscCode"
                  value={values.ifscCode}
                  //   onChange={(e) => {
                  //     Setrefifsccode(e.target.value);
                  //   }}
                />
              </div>
            </div>
            {/* <div className="col-6 col-md-4">
              <div className="input-group-custom">
                <label htmlFor="type" className="label">
                  Type of Account
                </label>
                <input
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="type"
                  name="accountType"
                  value={refbankacctype}
                  onChange={(e) => {
                    Setrefbankacctype(e.target.value);
                  }}
                />
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerBank;
