import React from "react";
import { Field } from "formik";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactTooltip from "react-tooltip";

const LeadDealDetails = ({
  values,
  errors,
  touched,
  showref,
  Persfamt,
  projectValue,
  leadbookingamt,
}) => {
  return (
    <>
      {!showref && (
        <div className="viewing_details">
          <div>
            <div className="d-flex" style={{ gap: "6px" }}>
              <p className="m-0 admin_type_heading">Per Square feet Amount</p>
              <InfoOutlinedIcon
                className="info_icon"
                data-tip="Partner Agreed amount for this Lead"
              />
              <ReactTooltip />
            </div>

            <span className="admin_type_value">
              Rs{Persfamt != undefined ? Persfamt : 30} Sq.ft
            </span>
          </div>

          <div className="row mt-3">
            {/* <div className="col-6 col-md-6">
              <div>
                <p className="m-0 admin_type_heading">Lead Budget</p>
                <span className="admin_type_value">
                  {leadbudget === null || leadbudget === "" ? "-" : leadbudget}
                </span>
              </div>
            </div> */}
            <div className="col-12 col-md-12">
              <div>
                <p className="m-0 admin_type_heading">Project Value</p>
                <span className="admin_type_value">
                  {projectValue === null ? "-" : projectValue}
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 col-md-12">
              <div>
                <p className="m-0 admin_type_heading">Booking Amount</p>
                <span className="admin_type_value">
                  {leadbookingamt === "" || leadbookingamt === null
                    ? "0"
                    : leadbookingamt}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {showref && (
        <div className="edit_details">
          <div className="row">
            <div className="input-group-custom">
              <label htmlFor="loc" className="label">
                Per Square feet Amount
              </label>
              <Field
                type="number"
                className="input-area admin_partner_det_input"
                required
                id="sqft"
                name="persfamt"
                value={values.persfamt}
                // onChange={(e) => {
                //   SetPersfamt(e.target.value);
                // }}
              />
            </div>
          </div>

          <div className="mt-3 row">
            {/* <div className="col-6 col-md-6">
              <div className="input-group-custom">
                <label htmlFor="loc" className="label">
                  Lead Budget
                </label>
                <input
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="inputName"
                  name="name"
                  value={leadbudget}
                  onChange={(e) => {
                    setleadbudget(e.target.value);
                  }}
                />
              </div>
            </div> */}

            <div className="col-12 col-md-12">
              <div className="input-group-custom">
                <label htmlFor="type" className="label">
                  Project Value
                </label>
                <Field
                  type="text"
                  className="input-area admin_partner_det_input"
                  required
                  id="type"
                  name="project_value"
                  value={values.project_value}
                  //   onChange={(e) => {
                  //     SetprojectValue(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="input-group-custom">
              <label htmlFor="bookingamt" className="label">
                Booking Amount
              </label>
              <Field
                type="number"
                className="input-area admin_partner_det_input"
                required
                id="dob"
                name="booking_amt"
                value={values.booking_amt}
                // onChange={(e) => {
                //   Setleadbookingamt(e.target.value);
                // }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadDealDetails;
