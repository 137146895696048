import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import IS_SideNav from "../IS_SideNav";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import whatsApp from "../../../Assets/logos_whatsapp.svg";
import DynamicInputFields from "../../ChannelSales/DyamicInputFields";
import DynamicInputFieldDisplay from "../../ChannelSales/DynamicInputFieldsDisplay";
import DownloadIcon from "@mui/icons-material/Download";
import Footer from "../../Dashboard/Footer";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field, useField } from "formik";
import LeadPersonal from "../../../BasicUI/LeadDetails/LeadPersonal";
import LeadContact from "../../../BasicUI/LeadDetails/LeadContact";
import LeadMeeting from "../../../BasicUI/LeadDetails/LeadMeeting";
import {
  validateMeetingDate,
  validateBDEAssigne,
  validateFromTime,
  validateToTime,
  validateMeetingLink,
  validateMeetingLocation,
} from "../../Helpers/validation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const ISCPReferedLeadDetails = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [show, toggleShow] = useState(false);
  const [showedit, toggleShowedit] = useState(false);
  const [showeditcmt, toggleShoweditcmt] = useState(false);
  const [showleadproedit, toggleshowleadproedit] = useState(false);
  const [showmeetedit, toggleshowmeetedit] = useState(false);
  const [btnloader, setbtnloader] = useState(false);

  const { Id } = useParams();
  const { Ping } = useParams();
  const { Page } = useParams();

  let history = useHistory();

  // state to set all personal info values of partners
  const [refnames, Setrefnames] = useState("");
  const [reflocation, Setreflocation] = useState("");
  const [refsqft, Setrefsqft] = useState("");
  const [refconstructiondate, Setrefconstructiondate] = useState("");
  const [refplotnumber, Setrefplotnumber] = useState("");
  const [developmentName, SetdevelopmentName] = useState("");
  const [leadbudget, Setleadbudget] = useState("");
  const [nooffloor, Setnooffloor] = useState("");
  const [leadpackagename, Setleadpackagename] = useState("");
  const [source, setsource] = useState("");
  const [referredBy, setreferredBy] = useState("");
  const [plotlocation, setplotlocation] = useState("");

  // state to set contact info
  const [refemail, Setrefemail] = useState("");
  const [refmobile, Setrefmobile] = useState("");

  // Comments
  const [comment_date, Setcomment_date] = useState("");
  const [remark, Setremark] = useState("");
  const [partnerids, Setpartnerids] = useState("");
  const [CommentsData, SetCommentsData] = useState([]);

  // Call Status
  const [callStatus, setcallStatus] = useState("");
  // Meeting
  const [meetdate, setmeetdate] = useState("");
  const [meetstarttime, setmeetstarttime] = useState("");
  const [meetendttime, setmeetendttime] = useState("");
  const [meetassignbde, setmeetassignbde] = useState("");
  const [meetlink, setmeetlink] = useState("");
  const [meetdesc, setmeetdesc] = useState("");
  const [meetloc, setmeetloc] = useState("");
  const [updmeetloc, setupdmeetloc] = useState("");
  const [updmeetlink, setupdmeetlink] = useState("");
  // bde Assign
  const [bdeassignOption, setbdeassignOption] = useState([]);

  // Customer Status
  const [custStatus, SetcustStatus] = useState("");

  const [comments, Setcomments] = useState([]);
  // console.log("comments", comments);

  const [profilepic, setprofilepic] = useState("");

  useEffect(() => {
    const valid = localStorage.getItem("ISName");
    if (valid === null) {
      history.push("/");
    }

    getData();

    // To Fetch Assign To Options DropDown for BDE
    axios.get(`${SERVER_URL}/bdlist`).then((response) => {
      // console.log("Cust", response);
      setbdeassignOption(response.data.Assignees);
      // console.log("Customer", Customer);
    });
  }, []);

  const getData = () => {
    axios.get(`${SERVER_URL}/leadbyid?_id=${Id}`).then((reps) => {
      // console.log("res", reps);

      // Personal
      Setpartnerids(reps.data.listofcustomers.partner_id);
      Setrefnames(reps.data.listofcustomers.name);
      Setreflocation(reps.data.listofcustomers.location);
      Setrefsqft(reps.data.listofcustomers.area);
      Setrefconstructiondate(reps.data.listofcustomers.start_date);
      Setrefplotnumber(reps.data.listofcustomers.plot_number);
      SetdevelopmentName(reps.data.listofcustomers.development_name);
      Setleadbudget(reps.data.listofcustomers.lead_budget);
      Setnooffloor(reps.data.listofcustomers.no_of_floors);
      Setleadpackagename(reps.data.listofcustomers.packagename);

      setsource(reps.data.listofcustomers.lead_source);
      setreferredBy(reps.data.listofcustomers.partner_name);
      setplotlocation(reps.data.listofcustomers.plot_location);

      // Contact info
      Setrefemail(reps.data.listofcustomers.email);
      Setrefmobile(reps.data.listofcustomers.contact_no);

      // Call status
      setcallStatus(reps.data.listofcustomers.call_status);
      // Meeting
      setmeetdate(reps.data.listofcustomers.meeting_date);
      // setmeettime(reps.data.listofcustomers.meeting_time);
      setmeetstarttime(reps.data.listofcustomers.meeting_from_time);
      setmeetendttime(reps.data.listofcustomers.meeting_to_time);
      setmeetassignbde(reps.data.listofcustomers.assigned_bde);
      setmeetlink(reps.data.listofcustomers.meeting_link);
      setmeetdesc(reps.data.listofcustomers.meeting_description);
      setmeetloc(reps.data.listofcustomers.meeting_location);
      setupdmeetlink(reps.data.listofcustomers.meeting_link);
      setupdmeetloc(reps.data.listofcustomers.meeting_location);

      // Comments
      Setcomment_date(reps.data.listofcustomers.comment_date);
      Setremark(reps.data.listofcustomers.comments);
      // Customer Status
      SetcustStatus(reps.data.listofcustomers.customer_status);

      Setcomments(reps.data.listofcustomers.comments);
      setCounter1(reps.data.listofcustomers.comments.length);
      // console.log("commentsNew", reps.data.listofcustomers.comments);
    });
  };

  const [counter, setCounter] = useState(1);
  const [counter1, setCounter1] = useState(1);
  // console.log("counter", counter);
  // console.log("counter1", counter1);

  const handleClick = (e) => {
    e.preventDefault();
    if (counter1 < 14) {
      setCounter((prevcounter) => prevcounter + 1);
      setCounter1((prevcounter1) => prevcounter1 + 1);
      // console.log(counter);
    }
  };

  const setPrority = (e, ids) => {
    // console.log("id", ids);
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };

    let selectPrority = e.target.value;
    const Prority = {
      customer_status: selectPrority,
    };

    axios
      .post(`${SERVER_URL}/updateleadstatus?_id=${ids}`, Prority, {
        headers,
      })
      .then((resultsverified) => {
        // console.log("verified orr", resultsverified);
        let Status = resultsverified.data.status;
        if (Status === "success") {
          toast.success("Lead Status Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  const ScheduleMeeting = (e) => {
    e.preventDefault();

    let meeting = e.target.value;
    if (meeting === "Online") {
      window.$("#Schedule_Online_meeting").modal("show");
    } else if (meeting === "Offline") {
      window.$("#Schedule_Offline_meeting").modal("show");
    }
  };

  const commentsData = (comment_date, call_status, comment, index) => {
    const labarray = CommentsData;
    labarray.splice(index, 1, {
      comment_date: comment_date,
      call_status: call_status,
      comment: comment,
    });

    SetCommentsData([]);
    SetCommentsData(labarray);
  };

  const saveComments = () => {
    if (CommentsData.length > 0) {
      const comment = {
        data: CommentsData,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(`${SERVER_URL}/updatecomments?_id=${Id}`, comment, {
          headers,
        })
        .then((res) => {
          let Status = res.data.status;
          if (Status === "success") {
            toast.success("Comments are Added successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getData();
            // setCounter(1);
            // setCounter1(1);
            window.setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else if (Status === "failed") {
            toast.error("Comments are Already Exists", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Some Internal Error", err);
        });
    } else {
      alert("please fill all the details");
    }
  };

  return (
    <div>
      <div>
        <IS_SideNav />
      </div>

      <div className="main">
        <div className="content-tabs">
          <div>
            <div className="top_menu_bar">
              <div>
                <Link
                  to={`/ischreferedleads/${partnerids}/${Ping}/${Page}`}
                  className="partner_back_btn"
                >
                  <div>
                    <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                    <span>Back</span>
                  </div>
                </Link>
              </div>

              <div>
                <div className="d-flex" style={{ gap: "15px" }}>
                  <div>
                    {meetlink !== "" || meetloc !== "" ? (
                      ""
                    ) : callStatus === "Meeting Fixed" ? (
                      <div>
                        {/* <div className="d-flex justify-content-around"> */}
                        <select
                          onChange={(e) => {
                            ScheduleMeeting(e);
                          }}
                          style={{ border: "none", height: "47px" }}
                          className="form-select select-form"
                        >
                          <option value="">Schedule Meeting</option>
                          <option value="Online">Online Meeting</option>
                          <option value="Offline">Offline Meeting</option>
                        </select>

                        {/* </div> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="partner_details_edit_sec">
              <form>
                <div className="row">
                  <div className="col-md-8">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        name: refnames,
                        packagename: leadpackagename,
                        area: refsqft,
                        plot_number: refplotnumber,
                        development_name: developmentName,
                        no_of_floors: nooffloor,
                        start_date: refconstructiondate,
                        //  status: CustomerStatus,
                        plot_location: plotlocation,
                        lead_budget: leadbudget,
                      }}
                      onSubmit={(values) => {
                        const headers = {
                          "Content-Type": "application/json",
                        };

                        axios
                          .post(
                            `${SERVER_URL}/updatecustomer?_id=${Id}`,
                            values,
                            {
                              headers,
                            }
                          )
                          .then((res) => {
                            // console.log("Hello Don", res);
                            let Status = res.data.status;
                            if (Status === "success") {
                              toast.success(
                                "Lead Profile Details are edited successfully",
                                {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                }
                              );
                              getData();
                              toggleShow(false);
                            } else if (Status === "failed") {
                              toggleShow(false);
                              toast.error(
                                "Lead Profile Details are Already Exists",
                                {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                }
                              );
                            }
                          })
                          .catch((err) => {
                            alert("Some Internal Error", err);
                          });
                      }}
                    >
                      {({ errors, touched, handleSubmit, values }) => (
                        <Form>
                          <div className="p-3 admin_patrner_personal_info">
                            <div className="d-flex justify-content-between head_txt head_txt_margin">
                              <p>Lead Personal Info</p>
                              {updmeetlink === "" && updmeetloc === "" ? (
                                <div
                                  className="edit_icon"
                                  onClick={() => toggleShow(!show)}
                                >
                                  {!show && (
                                    <div>
                                      <EditIcon className="search_icons" />
                                      <span> Edit</span>
                                    </div>
                                  )}
                                  {show && (
                                    <div>
                                      <button
                                        type="submit"
                                        className="edit_icon"
                                        onClick={handleSubmit}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <LeadPersonal
                              values={values}
                              errors={errors}
                              touched={touched}
                              show={show}
                              refnames={refnames}
                              leadpackagename={leadpackagename}
                              refsqft={refsqft}
                              refplotnumber={refplotnumber}
                              developmentName={developmentName}
                              nooffloor={nooffloor}
                              refconstructiondate={refconstructiondate}
                              callStatus={callStatus}
                              source={source}
                              referredBy={referredBy}
                              plotlocation={plotlocation}
                              leadbudget={leadbudget}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="col-md-4">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        email: refemail,
                        contact_no: refmobile,
                        location: reflocation,
                      }}
                      onSubmit={(values) => {}}
                    >
                      {({ errors, touched, handleSubmit, values }) => (
                        <Form>
                          <div className="p-3 admin_patrner_personal_info">
                            <div>
                              <p className="head_txt head_txt_margin">
                                Lead Contact Info
                              </p>
                            </div>
                            <LeadContact
                              values={values}
                              errors={errors}
                              touched={touched}
                              showedit={showedit}
                              refemail={refemail}
                              refmobile={refmobile}
                              reflocation={reflocation}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 mt-3">
                    {/* <DynamicInput /> */}
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Comments</p>
                        {updmeetlink === "" && updmeetloc === "" ? (
                          <div
                            className="edit_icon"
                            onClick={() => toggleShoweditcmt(!showeditcmt)}
                          >
                            {!showeditcmt && (
                              <div>
                                <EditIcon className="search_icons" />
                                <span> Edit</span>
                              </div>
                            )}
                            {showeditcmt && (
                              <div>
                                <button
                                  type="submit"
                                  className="edit_icon"
                                  onClick={saveComments}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {!showeditcmt && (
                        <div className="contact_info_">
                          {comments != "" ? (
                            comments.map((cust) => {
                              return (
                                <div className="pt-2 row">
                                  <div className="col-12 col-md-4">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Date
                                      </p>
                                      <span className="admin_type_value">
                                        {cust.comment_date
                                          .slice(0, 10)
                                          .split("-")
                                          .reverse()
                                          .join("-")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Call Status
                                      </p>
                                      <span className="admin_type_value">
                                        {cust.call_status}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Comments
                                      </p>
                                      <span className="admin_type_value">
                                        {cust.comment}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div>
                              <div>NA</div>
                            </div>
                          )}
                        </div>
                      )}
                      {showeditcmt && (
                        <div className="d-flex justify-content-between flex-wrap contact_info_admin">
                          {comments.length != 0 ? (
                            <div>
                              <DynamicInputFieldDisplay comments={comments} />
                            </div>
                          ) : (
                            ""
                          )}

                          {Array.from(Array(counter)).map((c, counter) => {
                            return (
                              <DynamicInputFields
                                data={commentsData}
                                counter={counter}
                              />
                            );
                          })}

                          <div className="pt-4">
                            <button
                              className="add_btn btn_add_comments_inc"
                              onClick={handleClick}
                            >
                              <AddIcon className="search_icons" />
                              Add Comment
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-3 mt-3">
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between">
                        <p className="head_txt">Profile Pic</p>
                      </div>
                      {!showleadproedit && (
                        <div className="viewing_details">
                          <div className="row">
                            <div className="col-6 col-md-6">
                              <button className="download_btn">
                                <DownloadIcon />
                                Photo
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}

                  {updmeetlink !== "" || updmeetloc !== "" ? (
                    <div className="col-md-4 mt-3">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          meeting_date: meetdate,
                          assign_bde: meetassignbde,
                          // meeting_time: meettime,
                          meeting_from_time: meetstarttime,
                          meeting_to_time: meetendttime,
                          meeting_location: meetloc,
                          meeting_link: meetlink,
                          meeting_description: meetdesc,
                        }}
                        onSubmit={(values) => {
                          // console.log("val", values);
                          const headers = {
                            "Content-Type": "application/json",
                          };

                          axios
                            .post(
                              `${SERVER_URL}/updatemeeting?_id=${Id}`,
                              values,
                              {
                                headers,
                              }
                            )
                            .then((res) => {
                              // console.log("Hello Don", res);
                              let Status = res.data.status;
                              if (Status === "success") {
                                // alert("Profile Details are edited sucessfully");
                                toast.success(
                                  "Meeting Details are Updated successfully",
                                  {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                                getData();
                                toggleshowmeetedit(false);
                                // window.setTimeout(function () {
                                //   window.location.reload();
                                // }, 100);
                              } else if (Status === "failed") {
                                toggleshowmeetedit(false);
                                // alert("Profile Details are already exists");
                                toast.error(
                                  "Some Internal Error Try after Some Time...",
                                  {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                              }
                            });
                        }}
                      >
                        {({ errors, touched, handleSubmit, values }) => (
                          <Form>
                            <div className="p-3 admin_patrner_personal_info">
                              <div className="d-flex justify-content-between head_txt head_txt_margin">
                                <p>Meeting Details</p>
                                {/* Toggle State to edit and save start */}
                                <div
                                  className="edit_icon"
                                  onClick={() =>
                                    toggleshowmeetedit(!showmeetedit)
                                  }
                                >
                                  {!showmeetedit && (
                                    <div>
                                      <EditIcon className="search_icons" />
                                      <span> Edit</span>
                                    </div>
                                  )}
                                  {showmeetedit && (
                                    <div>
                                      <button
                                        type="submit"
                                        className="edit_icon"
                                        onClick={handleSubmit}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {/* Toggle State to edit and save end */}
                              </div>

                              <LeadMeeting
                                values={values}
                                errors={errors}
                                touched={touched}
                                showmeetedit={showmeetedit}
                                meetdate={meetdate}
                                meetassignbde={meetassignbde}
                                meetstarttime={meetstarttime}
                                meetendttime={meetendttime}
                                meetloc={meetloc}
                                meetlink={meetlink}
                                meetdesc={meetdesc}
                                updmeetlink={updmeetlink}
                                updmeetloc={updmeetloc}
                                bdeassignOption={bdeassignOption}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>

      {/* Pop Up Schedule Online Meeting Start*/}
      <div
        id="Schedule_Online_meeting"
        className="modal fade Schedule_Online_meeting"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body" style={{ background: "#FFFFFF" }}>
              <div className="pb-3">
                <span className="add_hot_lead_label">
                  Schedule Online Meeting
                </span>
                <span
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="CloseAddHotLeadPopup"
                  style={{
                    background: "transparent",
                    float: "right",
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
              <Formik
                initialValues={{
                  meeting_date: "",
                  assign_bde: "",
                  meeting_from_time: "",
                  meeting_to_time: "",
                  meeting_link: "",
                  meeting_description: "",
                }}
                // validationSchema={validate}
                onSubmit={(values, { resetForm }) => {
                  setbtnloader(true);
                  // console.log("val", values);
                  const headers = {
                    "Content-Type": "application/json",
                  };
                  axios
                    .post(`${SERVER_URL}/onlinemeeting?_id=${Id}`, values, {
                      headers,
                    })
                    .then((res) => {
                      let Status = res.data.status;
                      if (Status === "success") {
                        window.$("#Schedule_Online_meeting").modal("hide");
                        setbtnloader(false);
                        toast.success(
                          "Online Meeting Scheduled Successfully...!!",
                          {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          }
                        );
                        getData();
                        // getLeadData();
                      } else {
                        setbtnloader(false);
                        toast.error("Something Went Wrong...try Again!!!", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    });
                }}
              >
                {({ errors, touched }) => (
                  <div>
                    <Form>
                      <div className="row pt-3">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting Date
                          </label>
                          <div className="">
                            <Field
                              // as="date"
                              type="date"
                              className="field_input_txt"
                              name="meeting_date"
                              validate={validateMeetingDate}
                              placeholder="Meeting Date"
                            />
                            {errors.meeting_date && touched.meeting_date && (
                              <div className="pt-2 error">
                                {errors.meeting_date}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Assign To
                          </label>
                          <div className="">
                            <Field
                              as="select"
                              className="field_input_txt"
                              name="assign_bde"
                              validate={validateBDEAssigne}
                              defaultValue={"select"}
                            >
                              <option selected value="">
                                Select BDE
                              </option>
                              {bdeassignOption != ""
                                ? bdeassignOption.map((item, i) => {
                                    return (
                                      <option key={i} value={item.Name}>
                                        {item.Name}
                                      </option>
                                    );
                                  })
                                : "No Data"}
                            </Field>
                            {errors.assign_bde && touched.assign_bde && (
                              <div className="pt-2 error">
                                {errors.assign_bde}
                              </div>
                            )}
                            {/* Assignees */}
                          </div>
                        </div>
                      </div>

                      <div className="row pt-4">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting From
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_from_time"
                              validate={validateFromTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_from_time &&
                              touched.meeting_from_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_from_time}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting To
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_to_time"
                              validate={validateToTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_to_time &&
                              touched.meeting_to_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_to_time}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Link
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            className="field_input_txt"
                            name="meeting_link"
                            validate={validateMeetingLink}
                            placeholder="Post The Meeting Link Here"
                          />
                          {errors.meeting_link && touched.meeting_link && (
                            <div className="pt-2 error">
                              {errors.meeting_link}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Description(If Any)
                        </label>
                        <div className="">
                          <Field
                            style={{ height: "100%" }}
                            as="textarea"
                            rows="5"
                            // type="text"
                            className="field_input_txt"
                            name="meeting_description"
                            placeholder="Write Description Here...."
                          />
                        </div>
                      </div>

                      <div className="pt-4">
                        <button
                          type="submit"
                          className="btn btn-lg btn_apply_filter"
                        >
                          {btnloader === true ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                          Schedule Meeting
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up Schedule Online Meeting End*/}

      {/* Pop Up Schedule Offline Meeting Start*/}
      <div
        id="Schedule_Offline_meeting"
        className="modal fade Schedule_Offline_meeting"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body" style={{ background: "#FFFFFF" }}>
              <div className="pb-3">
                <span className="add_hot_lead_label">
                  Schedule Offline Meeting
                </span>
                <span
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="CloseAddHotLeadPopup"
                  style={{
                    background: "transparent",
                    float: "right",
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
              <Formik
                initialValues={{
                  meeting_date: "",
                  assign_bde: "",
                  meeting_from_time: "",
                  meeting_to_time: "",
                  meeting_location: "",
                  meeting_description: "",
                }}
                // validationSchema={validate}
                onSubmit={(values, { resetForm }) => {
                  // console.log("val", values);
                  const headers = {
                    "Content-Type": "application/json",
                  };
                  axios
                    .post(`${SERVER_URL}/offlinemeeting?_id=${Id}`, values, {
                      headers,
                    })
                    .then((res) => {
                      setbtnloader(true);
                      // console.log("online meeting", res);
                      let Status = res.data.status;
                      if (Status === "success") {
                        window.$("#Schedule_Offline_meeting").modal("hide");
                        setbtnloader(false);
                        toast.success(
                          "Offline Meeting Scheduled Successfully...!!",
                          {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          }
                        );
                        getData();
                      } else {
                        setbtnloader(false);
                        toast.error("Something Went Wrong...try Again!!!", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    });
                }}
              >
                {({ errors, touched }) => (
                  <div>
                    <Form>
                      <div className="row pt-3">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting Date
                          </label>
                          <div className="">
                            <Field
                              // as="date"
                              type="date"
                              className="field_input_txt"
                              name="meeting_date"
                              validate={validateMeetingDate}
                              placeholder="Meeting Date"
                            />
                            {errors.meeting_date && touched.meeting_date && (
                              <div className="pt-2 error">
                                {errors.meeting_date}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Assign To
                          </label>
                          <div className="">
                            <Field
                              as="select"
                              className="field_input_txt"
                              name="assign_bde"
                              validate={validateBDEAssigne}
                              defaultValue={"select"}
                            >
                              <option selected value="">
                                Select BDE
                              </option>
                              {bdeassignOption != ""
                                ? bdeassignOption.map((item, i) => {
                                    return (
                                      <option key={i} value={item.Name}>
                                        {item.Name}
                                      </option>
                                    );
                                  })
                                : "No Data"}
                            </Field>
                            {errors.assign_bde && touched.assign_bde && (
                              <div className="pt-2 error">
                                {errors.assign_bde}
                              </div>
                            )}
                            {/* Assignees */}
                          </div>
                        </div>
                      </div>

                      <div className="row pt-4">
                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting From
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_from_time"
                              validate={validateFromTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_from_time &&
                              touched.meeting_from_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_from_time}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="filter_input_head_txt pb-3">
                            Meeting To
                          </label>
                          <div className="">
                            <Field
                              type="time"
                              className="field_input_txt"
                              name="meeting_to_time"
                              validate={validateToTime}
                              placeholder="Meeting Time"
                            />
                            {errors.meeting_to_time &&
                              touched.meeting_to_time && (
                                <div className="pt-2 error">
                                  {errors.meeting_to_time}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Location
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            className="field_input_txt"
                            name="meeting_location"
                            validate={validateMeetingLocation}
                            placeholder="Enter Full Location"
                          />
                          {errors.meeting_location &&
                            touched.meeting_location && (
                              <div className="pt-2 error">
                                {errors.meeting_location}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="pt-4">
                        <label className="filter_input_head_txt pb-3">
                          Meeting Description(If Any)
                        </label>
                        <div className="">
                          <Field
                            style={{ height: "100%" }}
                            as="textarea"
                            rows={5}
                            // type="text"
                            className="field_input_txt"
                            name="meeting_description"
                            placeholder="Write Description Here...."
                          />
                        </div>
                      </div>

                      <div className="pt-4">
                        <button
                          type="submit"
                          className="btn btn-lg btn_apply_filter"
                        >
                          {btnloader === true ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                          Schedule Meeting
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up Schedule Offline Meeting End*/}
    </div>
  );
};

export default ISCPReferedLeadDetails;
