import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UserNav2 from "./UserNav2";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import axios from "axios";
import whatsApp from "../../Assets/logos_whatsapp.svg";
import Thumbs from "../../Assets/thumbsup.svg";
import UploadImage from "../../Assets/Upload.svg";
import DownloadImage from "../../Assets/Download.svg";
import EditIcon from "@mui/icons-material/Edit";
import UserRemove from "../../Assets/user-remove.svg";
import AddIcon from "@mui/icons-material/Add";
import UserRemovePopUp from "../../Assets/user-remove-popup.svg";
import UserTick from "../../Assets/user-tick.svg";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function LeadDetailsView() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const Images = process.env.REACT_APP_IMAGES_URL;
  var bdeID = localStorage.getItem("bde_ID");
  const [btnloader, setbtnloader] = useState(false);
  // console.log("bde", bdeID);
  const { Id } = useParams();
  const { Page } = useParams();
  // console.log("id", Id);

  let history = useHistory();

  // state to set all personal info values of partners
  const [refnames, Setrefnames] = useState("");
  const [reflocation, Setreflocation] = useState("");
  const [refsqft, Setrefsqft] = useState("");
  const [refconstructiondate, Setrefconstructiondate] = useState("");
  const [refplotnumber, Setrefplotnumber] = useState("");
  const [developmentName, SetdevelopmentName] = useState("");
  const [projectValue, SetprojectValue] = useState("");

  // state to set contact info
  const [refemail, Setrefemail] = useState([]);
  const [refmobile, Setrefmobile] = useState([]);
  // TAsk Bde
  const [taskBDE, settaskBDE] = useState([]);
  const [taskStatus, settaskStatus] = useState("");
  const [bdeleadstauts, setbdeleadstauts] = useState("");
  const [Picture, setPicture] = useState(null);
  const [transProof, settransProof] = useState("");

  // Deal Lost
  const [lostReason, setlostReason] = useState("");
  const [dealnotes, setdealnotes] = useState("");
  // Call Status
  const [callStatus, setcallStatus] = useState("");
  // Booking Amount
  const [leadbookingamt, Setleadbookingamt] = useState("");
  const [leaddiscount, setleaddiscount] = useState("");

  // Meeting
  const [meetdate, setmeetdate] = useState("");
  const [meetstarttime, setmeetstarttime] = useState("");
  const [meetendttime, setmeetendttime] = useState("");
  const [meetlink, setmeetlink] = useState("");
  const [meetdesc, setmeetdesc] = useState("");
  const [meetloc, setmeetloc] = useState("");
  const [meetcomments, setmeetcomments] = useState("");
  const [showbookingAmount, togglebookingAmount] = useState(false);

  const [AllQuotes, SetAllQuotes] = useState([]);

  useEffect(() => {
    const valid = localStorage.getItem("username");
    if (valid === null) {
      history.push("/");
    }

    getLeadData();

    getTaskData();

    getquotedata();
    // disableButtons();
  }, []);

  const getLeadData = () => {
    axios.get(`${SERVER_URL}/leadbyid?_id=${Id}`).then((reps) => {
      // console.log("bde", reps.data);
      // Personal
      Setrefnames(reps.data.listofcustomers.name);
      Setreflocation(reps.data.listofcustomers.location);
      Setrefsqft(reps.data.listofcustomers.area);
      Setrefconstructiondate(reps.data.listofcustomers.start_date);
      Setrefplotnumber(reps.data.listofcustomers.plot_number);
      SetdevelopmentName(reps.data.listofcustomers.development_name);
      SetprojectValue(reps.data.listofcustomers.project_value);
      setleaddiscount(reps.data.listofcustomers.discount);

      // Contact info
      Setrefemail(reps.data.listofcustomers.email);
      Setrefmobile(reps.data.listofcustomers.contact_no);

      // Call status
      setcallStatus(reps.data.listofcustomers.call_status);
      settaskStatus(reps.data.listofcustomers.task_status);

      // deal Lost
      setlostReason(reps.data.listofcustomers.dealLost_reason);
      setdealnotes(reps.data.listofcustomers.dealLost_notes);
      setbdeleadstauts(reps.data.listofcustomers.BDElead_status);
      settransProof(reps.data.listofcustomers.transaction_proof);
      // Meeting
      setmeetdate(reps.data.listofcustomers.meeting_date);
      // setmeettime(reps.data.listofcustomers.meeting_time);
      setmeetstarttime(reps.data.listofcustomers.meeting_from_time);
      setmeetendttime(reps.data.listofcustomers.meeting_to_time);
      setmeetlink(reps.data.listofcustomers.meeting_link);
      setmeetdesc(reps.data.listofcustomers.meeting_description);
      setmeetloc(reps.data.listofcustomers.meeting_location);
      setmeetcomments(reps.data.listofcustomers.meeting_comments);
      // booking Amount
      Setleadbookingamt(reps.data.listofcustomers.booking_amt);
    });
  };

  const getTaskData = () => {
    axios
      .get(`${SERVER_URL}/leadtasks?_id=${bdeID}&lead_id=${Id}`)
      .then((reps) => {
        // console.log("bdetasl", reps.data);
        settaskBDE(reps.data.Tasks);
      });
  };

  const getquotedata = () => {
    axios.get(`${SERVER_URL}/getquotes?lead_id=${Id}`).then((respo) => {
      console.log("Generated quotes", respo.data.Quote);
      SetAllQuotes(respo.data.Quote);
    });
  };

  // const disableButtons = () => {
  //   if (callStatus === "Not Connected" || callStatus === "Meeting Done") {
  //     console.log("disk");
  //     document.getElementById("disable_meet_btn").style.display = "none";
  //   }
  // };

  const BookingAmount = (data, resetForm) => {
    setbtnloader(true);
    const formData = new FormData();
    formData.append("proof", Picture);
    formData.append("booking_amt", data.booking_amt);
    formData.append("discount", data.discount);

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/bookingamt?_id=${Id}`, formData, {
        headers,
      })
      .then((res) => {
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#popup_bookingAmount").modal("hide");
          getLeadData();
          setbtnloader(false);
          resetForm();
          toast.success("Booking Amount Recieved Successfully!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setbtnloader(false);
          window.$("#popup_bookingAmount").modal("hide");
          resetForm();
          toast.error("Something Went wrong try after some Time!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const updatebooking = (e) => {
    e.preventDefault();
    const udata = {
      booking_amt: leadbookingamt,
      project_value: projectValue,
      discount: leaddiscount,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${SERVER_URL}/updatecustomer?_id=${Id}`, udata, {
        headers,
      })
      .then((resul) => {
        let Status = resul.data.status;
        if (Status === "success") {
          toast.success("Booking Amount Updated successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getLeadData();
          // window.setTimeout(function () {
          //   window.location.reload();
          // }, 100);
        } else if (Status === "failed") {
          // alert("Profile Details are already exists");
          toast.error("Something went wrong try again...", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  function validateMeetingComments(value) {
    let error;
    if (!value) {
      error = "Meeting Comments is Required";
    }
    return error;
  }

  function validateDescription(value) {
    let error;
    if (!value) {
      error = "Description is Required";
    }
    return error;
  }

  function validateTaskTitle(value) {
    let error;
    if (!value) {
      error = "Task Title is Required";
    }
    return error;
  }

  function validateDate(value) {
    let error;
    if (!value) {
      error = "Date is Required";
    }
    return error;
  }

  function validateReminder(value) {
    let error;
    if (!value) {
      error = "Reminder is Required";
    }
    return error;
  }

  function validateTime(value) {
    let error;
    if (!value) {
      error = "Time is Required";
    }
    return error;
  }

  function validateReasons(value) {
    let error;
    if (!value) {
      error = "Deal Lost Reason is Required";
    }
    return error;
  }

  function validateBookingAmount(value) {
    let error;
    if (!value) {
      error = "Booking Amount is Required";
    }
    return error;
  }

  function validateDiscount(value) {
    let error;
    if (!value) {
      error = "Discount is Required";
    }
    return error;
  }

  function validatePhoto(value) {
    let error;
    if (Picture === null) {
      error = "Profile Image is Required";
    }
    return error;
  }

  const loadFile = (e) => {
    setPicture(e.target.files[0]);
  };

  return (
    <div>
      <div>
        <UserNav2 />
      </div>
      <div>
        <div className="main">
          <div className="content-tabs">
            <div>
              <div className="top_menu_bar">
                <div>
                  <Link
                    to={`/listofcustomer/${Page}`}
                    className="partner_back_btn"
                  >
                    <span>
                      <ArrowBackIosNewIcon style={{ fontSize: "18px" }} />
                      Back
                    </span>
                  </Link>
                </div>

                <div>
                  <div
                    className="d-flex justify-content-around"
                    style={{ gap: "10px" }}
                  >
                    <div>
                      <Link
                        to={`/quotenew/${Id}/${Page}`}
                        className="partner_back_btn"
                      >
                        <button className="add_task_btn">
                          <AddIcon className="add_task_icon" />
                          Generate Quote
                        </button>
                      </Link>
                    </div>
                    <div>
                      <button
                        className="add_task_btn"
                        data-toggle="modal"
                        data-target="#popup_addTask"
                      >
                        <AddIcon className="add_task_icon" />
                        Add Task
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="partner_details_edit_sec">
                <div className="row">
                  <div className="col-md-8">
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Lead Personal Info</p>
                      </div>
                      <div className="viewing_details">
                        <div className="row">
                          <div className="col-6 col-md-3">
                            <div>
                              <p className="m-0 admin_type_heading">
                                Full Name
                              </p>
                              <span className="admin_type_value">
                                {refnames === "" ? "-" : refnames}
                              </span>
                            </div>
                          </div>
                          <div className="col-6 col-md-3">
                            <div>
                              <p className="m-0 admin_type_heading">Location</p>
                              <span className="admin_type_value">
                                {reflocation === "" ? "-" : reflocation}
                              </span>
                            </div>
                          </div>
                          <div className="col-6 col-md-2">
                            <div>
                              <p className="m-0 admin_type_heading">Sq. ft.</p>
                              <span className="admin_type_value">
                                {refsqft === "" ? "-" : refsqft}
                              </span>
                            </div>
                          </div>
                          <div className="col-6 col-md-4">
                            <div>
                              <p className="m-0 admin_type_heading">
                                Construction Start Date
                              </p>
                              <span className="admin_type_value">
                                {refconstructiondate === ""
                                  ? "-"
                                  : refconstructiondate
                                      .slice(0, 10)
                                      .split("-")
                                      .reverse()
                                      .join("-")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-6 col-md-3">
                            <div>
                              <p className="m-0 admin_type_heading">
                                Development Name
                              </p>
                              <span className="admin_type_value">
                                {developmentName === "" ? "-" : developmentName}
                              </span>
                            </div>
                          </div>

                          <div className="col-6 col-md-3">
                            <div>
                              <p className="m-0 admin_type_heading">
                                Project Value
                              </p>
                              <span className="admin_type_value">
                                {projectValue === null ? "-" : projectValue}
                              </span>
                            </div>
                          </div>

                          <div className="col-6 col-md-2">
                            <div>
                              <p className="m-0 admin_type_heading">
                                Plot Number
                              </p>
                              <span className="admin_type_value">
                                {refplotnumber === "" ? "-" : refplotnumber}
                              </span>
                            </div>
                          </div>

                          <div className="col-6 col-md-4">
                            {callStatus === "Not Connected" ? (
                              <div>
                                <button className="btn btn-lg Dead">
                                  {callStatus}
                                </button>
                              </div>
                            ) : callStatus !== "" ? (
                              <div>
                                <button className="btn btn-lg call_status">
                                  {callStatus}
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="p-3 admin_patrner_personal_info"
                      style={{ height: "100%" }}
                    >
                      <div>
                        <p className="head_txt head_txt_margin">
                          Lead Contact Info
                        </p>
                      </div>

                      <div className="contact_info_">
                        <div>
                          <p className="m-0 admin_type_heading">Email</p>
                          <span className="admin_type_value">
                            {refemail === "" ? "-" : refemail}
                          </span>
                        </div>
                        <div className="ml-4 mt-2">
                          <div className="d-flex justify-content-between">
                            <div>
                              <p className="m-0 admin_type_heading">
                                Mobile No.
                              </p>
                              <span className="admin_type_value">
                                {refmobile === "" ? "-" : refmobile}
                              </span>
                            </div>

                            {refmobile !== "" ? (
                              <div className="whats_app_div">
                                <a
                                  href={`https://wa.me/${refmobile}`}
                                  className="whatsapp_float"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img src={whatsApp} />
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-8">
                    <div className="p-3 admin_patrner_personal_info">
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Meeting Link</p>

                        {bdeleadstauts === "High Priority" ? (
                          <div>
                            <button className="btn btn-lg bde_high_priority">
                              {bdeleadstauts}
                            </button>
                          </div>
                        ) : bdeleadstauts === "Medium Priority" ? (
                          <div>
                            <button className="btn btn-lg bde_medium_priority">
                              {bdeleadstauts}
                            </button>
                          </div>
                        ) : bdeleadstauts === "Low Priority" ? (
                          <div>
                            <button className="btn btn-lg bde_low_priority">
                              {bdeleadstauts}
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="viewing_details">
                        <div className="row">
                          <div className="col-6 col-md-3">
                            <div>
                              <p className="m-0 admin_type_heading">
                                Meeting Date
                              </p>
                              <span className="admin_type_value">
                                {meetdate === ""
                                  ? "-"
                                  : meetdate
                                      .slice(0, 10)
                                      .split("-")
                                      .reverse()
                                      .join("-")}
                              </span>
                            </div>
                          </div>

                          <div className="col-6 col-md-3">
                            <div>
                              <p className="m-0 admin_type_heading">Time</p>
                              <span className="admin_type_value">
                                {meetstarttime === "" ? "-" : meetstarttime}-
                                {meetendttime === "" ? "-" : meetendttime}
                              </span>
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            {meetlink !== "" ? (
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Meeting Link
                                </p>
                                <div className="meeting_link">
                                  <div
                                    style={{ width: "60%", padding: "10px" }}
                                  >
                                    <p className="admin_type_value">
                                      {meetlink === "" ? "-" : meetlink}
                                    </p>
                                  </div>
                                  <div style={{ width: "40%" }}>
                                    <a
                                      href={meetlink}
                                      type="button"
                                      className="btn_join_meeting"
                                      target="_blank"
                                    >
                                      Join Meeting
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ) : meetloc !== "" ? (
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Meeting Location
                                </p>
                                <span className="admin_type_value">
                                  {meetloc === "" ? "-" : meetloc}
                                </span>
                              </div>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>

                        <div className="row pt-3">
                          <div className="col-6 col-md-4">
                            <div>
                              <p className="m-0 admin_type_heading">
                                Meeting Description
                              </p>
                              <span className="admin_type_value">
                                {meetdesc === "" ? "-" : meetdesc}
                              </span>
                            </div>
                          </div>

                          {meetcomments !== "" ? (
                            <div className="col-md-8">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Meeting Comments
                                </p>
                                <span className="admin_type_value">
                                  {meetcomments === "" ? "-" : meetcomments}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div
                      className="p-3 admin_patrner_personal_info"
                      style={{ height: "100%" }}
                    >
                      <div className="d-flex justify-content-between head_txt head_txt_margin">
                        <p>Deal Details</p>
                        <div
                          className="edit_icon"
                          onClick={() =>
                            togglebookingAmount(!showbookingAmount)
                          }
                        >
                          {!showbookingAmount && (
                            <div>
                              <EditIcon className="search_icons" />
                              <span>Edit</span>
                            </div>
                          )}
                          {showbookingAmount && (
                            <div>
                              <button
                                type="submit"
                                className="edit_icon"
                                onClick={updatebooking}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!showbookingAmount && (
                        <div className="viewing_details">
                          <div className="row">
                            <div className="col-6 col-md-6">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Total Deal Value
                                </p>
                                <span className="admin_type_value">
                                  {projectValue === null ? "-" : projectValue}
                                </span>
                              </div>
                            </div>

                            <div className="col-6 col-md-6">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Discount
                                </p>
                                <span className="admin_type_value">
                                  {leaddiscount === "" ? "-" : leaddiscount}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-12 col-md-6">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Booking Amount
                                </p>
                                <span className="admin_type_value">
                                  {leadbookingamt === null
                                    ? "-"
                                    : leadbookingamt}
                                </span>
                              </div>
                            </div>

                            {transProof !== "" ? (
                              <div className="col-12 col-md-6 align-self-center">
                                <div className="btn_tran_prof">
                                  <a
                                    href={
                                      `${Images}/${transProof}` + "?force=true"
                                    }
                                    download
                                  >
                                    <img src={DownloadImage} /> Transaction
                                    Proof
                                  </a>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      )}
                      {showbookingAmount && (
                        <div className="edit_details">
                          <div className="row">
                            <div className="col-6 col-md-6">
                              <div className="input-group-custom">
                                <label htmlFor="type" className="label">
                                  Total Deal Value
                                </label>
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="type"
                                  name="plot_number"
                                  value={projectValue}
                                  onChange={(e) => {
                                    SetprojectValue(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-6 col-md-6">
                              <div className="input-group-custom">
                                <label htmlFor="discount" className="label">
                                  Discount
                                </label>
                                <input
                                  type="discount"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="type"
                                  name="discount"
                                  value={leaddiscount}
                                  onChange={(e) => {
                                    setleaddiscount(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-12 col-md-12">
                              <div className="input-group-custom">
                                <label htmlFor="booking" className="label">
                                  Booking Amount
                                </label>
                                <input
                                  type="text"
                                  className="input-area admin_partner_det_input"
                                  required
                                  id="booking"
                                  name="booking_amount"
                                  defaultValue={leadbookingamt}
                                  onChange={(e) => {
                                    Setleadbookingamt(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {taskStatus === true ? (
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="p-3 admin_patrner_personal_info">
                        <div>
                          <p
                            className="head_txt head_txt_margin"
                            style={{ marginBottom: "0px" }}
                          >
                            Task List
                          </p>
                        </div>

                        <div className="viewing_details">
                          {taskBDE !== "" ? (
                            taskBDE.map((task, index) => {
                              return (
                                <div className="row pt-3" key={index}>
                                  <div className="col-6 col-md-3">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Task Title
                                      </p>
                                      <span className="admin_type_value">
                                        {task.task_title === ""
                                          ? "-"
                                          : task.task_title}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-6 col-md-2">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Due Date
                                      </p>
                                      <span className="admin_type_value">
                                        {task.due_date === ""
                                          ? "-"
                                          : task.due_date
                                              .slice(0, 10)
                                              .split("-")
                                              .reverse()
                                              .join("-")}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-6 col-md-2">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Due Time
                                      </p>
                                      <span className="admin_type_value">
                                        {task.due_time === ""
                                          ? "-"
                                          : task.due_time}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-6 col-md-2">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Reminder
                                      </p>
                                      <span className="admin_type_value">
                                        {task.reminder === ""
                                          ? "-"
                                          : task.reminder}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-6 col-md-3">
                                    <div>
                                      <p className="m-0 admin_type_heading">
                                        Extra Description
                                      </p>
                                      <span className="admin_type_value">
                                        {task.description === ""
                                          ? "-"
                                          : task.description}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div>
                              <h3>No records found for Lead Data</h3>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {AllQuotes != "" ? (
                  <div className="viewing_details mt-3">
                    <div className="p-3 admin_patrner_personal_info">
                      <div>
                        <p
                          className="head_txt head_txt_margin"
                          style={{ marginBottom: "0px" }}
                        >
                          Quotation Details
                        </p>
                      </div>
                      <div className="pt-3">
                        <table style={{ width: "100%" }}>
                          <thead>
                            <tr className="p-3">
                              <th className="padding_for_table">Sl.No</th>
                              <th className="padding_for_table">
                                Quotation Name
                              </th>
                              <th className="padding_for_table">
                                Date Of Creation
                              </th>
                              <th className="padding_for_table">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {AllQuotes != ""
                              ? AllQuotes.map((ips, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{ips.quote}</td>
                                      <td>
                                        {/* {ips.creationdate
                                          .slice(0, 10)
                                          .split("-")
                                          .reverse()
                                          .join("-")} */}
                                        {ips.quote_date
                                          .slice(0, 10)
                                          .split("-")
                                          .reverse()
                                          .join("-")}
                                      </td>
                                      <td>
                                        <Link
                                          to={`/quotepdf/${ips._id}`}
                                          target="_blank"
                                          className=""
                                        >
                                          <button className="view_doc_btn text-dark">
                                            View Document
                                          </button>
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {callStatus === "Meeting Fixed" ? (
                  <div id="disable_meet_btn" className="mt-3">
                    <div>
                      <button
                        className="d-flex align-items-center btn_meeting_done"
                        style={{ gap: "10px" }}
                        data-toggle="modal"
                        data-target="#popup_meetingDone"
                      >
                        <img src={Thumbs} />
                        Meeting Done
                      </button>
                    </div>
                  </div>
                ) : callStatus === "Meeting Done" ? (
                  <div
                    id="disable_meet_btn"
                    className="d-flex mt-3"
                    style={{ gap: "10px" }}
                  >
                    <div>
                      <button
                        className="btn_deal_lost"
                        data-toggle="modal"
                        data-target="#popup_deallost"
                      >
                        Deal Lost
                      </button>
                    </div>
                    <div>
                      <button
                        className="btn_meeting_done"
                        style={{ height: "41px" }}
                        data-toggle="modal"
                        data-target="#popup_bookingAmount"
                      >
                        Booking Amt. Recieved
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {callStatus === "Deal Lost" ? (
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <div className="p-3 admin_patrner_personal_info">
                        <div>
                          <p className="head_txt head_txt_margin">
                            Deal Lost Reason
                          </p>
                        </div>

                        <div className="viewing_details">
                          <div className="row">
                            <div className="col-6 col-md-5">
                              <div>
                                <p className="m-0 admin_type_heading">Reason</p>
                                <span className="admin_type_value">
                                  {lostReason === "" ? "-" : lostReason}
                                </span>
                              </div>
                            </div>

                            <div className="col-6 col-md-7">
                              <div>
                                <p className="m-0 admin_type_heading">
                                  Extra Notes
                                </p>
                                <span className="admin_type_value">
                                  {dealnotes === "" ? "-" : dealnotes}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pop Up for Deal Lost start */}
      <div
        id="popup_deallost"
        className="modal fade popup_meet"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="pb-3">
                  <div className="d-flex justify-content-between form_head_txt">
                    <div className="">
                      <span>Add Reason For Loosing The Lead</span>
                    </div>
                    <div type="button" data-dismiss="modal" aria-label="Close">
                      <CloseIcon />
                    </div>
                  </div>

                  <Formik
                    initialValues={{
                      reason: "",
                      notes: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      setbtnloader(true);
                      const headers = {
                        "Content-Type": "application/json",
                      };
                      axios
                        .post(
                          `${SERVER_URL}/deallostUpdate?_id=${Id}`,
                          values,
                          {
                            headers,
                          }
                        )
                        .then((reps) => {
                          let Status = reps.data.status;
                          if (Status === "success") {
                            setbtnloader(false);
                            window.$("#popup_deallost").modal("hide");
                            getLeadData();
                            resetForm();
                            toast.success(
                              "Deal Lost Reason Added Successfully!!!",
                              {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                          } else {
                            setbtnloader(false);
                            window.$("#popup_deallost").modal("hide");
                            resetForm();
                            toast.error(
                              "Something Went wrong try after some Time!!!",
                              {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                          }
                        });
                    }}
                  >
                    {({ errors, touched }) => (
                      <div>
                        <Form>
                          <div className="pt-4">
                            <div>
                              <label className="filter_input_head_txt pb-3">
                                Reason
                              </label>
                              <div className="">
                                <Field
                                  as="select"
                                  className="field_input_txt"
                                  name="reason"
                                  validate={validateReasons}
                                >
                                  <option disabled selected value="">
                                    Choose The Reason
                                  </option>
                                  <option value="Price">Price</option>
                                  <option value="Not Inersted">
                                    Not Intersted
                                  </option>
                                  <option value="Competitors">
                                    Competitors
                                  </option>
                                </Field>
                                {errors.reason && touched.reason && (
                                  <div className="pt-2 error">
                                    {errors.reason}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="pt-4">
                            <div>
                              <label className="filter_input_head_txt pb-3">
                                Extra Notes (If Any)
                              </label>
                              <div className="">
                                <Field
                                  style={{ height: "100%" }}
                                  as="textarea"
                                  rows="5"
                                  // type="text"
                                  className="field_input_txt"
                                  name="notes"
                                  placeholder="Write Notes Here...."
                                />
                                {/* {errors.notes && touched.notes && (
                                  <div className="pt-2 error">
                                    {errors.notes}
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>

                          <div className="pt-3">
                            <button
                              type="submit"
                              className="btn btn-lg btn_apply_filter"
                            >
                              {btnloader === true ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                              Submit
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
              {/* <div>
                <div className="popup_icon_center">
                  <img src={UserRemovePopUp} />
                </div>
                <div className="popup_text_center">
                  <p className="pt-4 pop_up_meeting_txt">
                    Are you sure want to <br />
                    Make this Contact As Deal Lost ?
                  </p>
                  <div className="pt-2 m-0 d-flex justify-content-center">
                    <div
                      style={{
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <button
                        className="btn_meeting_notIntersetd"
                        onClick={dealLost}
                      >
                        Deal Lost
                      </button>
                    </div>

                    <div
                      style={{
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <button
                        className="btn_meeting_cancel"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up for Deal Lost End */}

      {/* Pop Up for Add Task start */}
      <div
        id="popup_addTask"
        className="modal fade popup_meet"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="pb-3">
                  <div className="d-flex justify-content-between form_head_txt">
                    <div className="">
                      <span>Add Task</span>
                    </div>
                    <div type="button" data-dismiss="modal" aria-label="Close">
                      <CloseIcon />
                    </div>
                  </div>

                  <Formik
                    initialValues={{
                      task_title: "",
                      due_date: "",
                      due_time: "",
                      reminder: "",
                      description: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      setbtnloader(true);
                      const headers = {
                        "Content-Type": "application/json",
                      };
                      axios
                        .post(
                          `${SERVER_URL}/addBDEtask?_id=${bdeID}&lead_id=${Id}`,
                          values,
                          {
                            headers,
                          }
                        )
                        .then((reps) => {
                          let Status = reps.data.status;
                          if (Status === "success") {
                            setbtnloader(false);
                            toast.success("Task Added Successfully", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                            window.$("#popup_addTask").modal("hide");
                            getLeadData();
                            getTaskData();
                            resetForm();
                          } else {
                            setbtnloader(false);
                            window.$("#popup_addTask").modal("hide");
                            resetForm();
                            toast.error(
                              "Something Went wrong try after some Time!!!",
                              {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                          }
                        });
                    }}
                  >
                    {({ errors, touched }) => (
                      <div>
                        <Form>
                          <div className="row pt-3">
                            <div className="col-12 col-md-12">
                              <label className="filter_input_head_txt pb-3">
                                Task Title
                              </label>
                              <div className="">
                                <Field
                                  type="text"
                                  className="field_input_txt"
                                  name="task_title"
                                  validate={validateTaskTitle}
                                  placeholder="Enter Title"
                                />
                                {errors.task_title && touched.task_title && (
                                  <div className="pt-2 error">
                                    {errors.task_title}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row pt-4">
                            <div className="col-12 col-md-6">
                              <label className="filter_input_head_txt pb-3">
                                Due Date
                              </label>
                              <div className="">
                                <Field
                                  type="date"
                                  className="field_input_txt"
                                  name="due_date"
                                  validate={validateDate}
                                  placeholder="Select Date"
                                />
                                {errors.due_date && touched.due_date && (
                                  <div className="pt-2 error">
                                    {errors.due_date}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              <label className="filter_input_head_txt pb-3">
                                Due Time
                              </label>
                              <div className="">
                                <Field
                                  type="time"
                                  className="field_input_txt"
                                  name="due_time"
                                  validate={validateTime}
                                  placeholder="Meeting Time"
                                />
                                {errors.due_time && touched.due_time && (
                                  <div className="pt-2 error">
                                    {errors.due_time}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row pt-4">
                            <div className="col-12 col-md-12">
                              <label className="filter_input_head_txt pb-3">
                                Add Reminder
                              </label>
                              <div className="">
                                <Field
                                  as="select"
                                  className="field_input_txt"
                                  name="reminder"
                                  validate={validateReminder}
                                  defaultValue={"select"}
                                >
                                  <option selected value="">
                                    Select Reminder For Task
                                  </option>
                                  <option value="Before 10 Minutes">
                                    Before 10 Minutes
                                  </option>
                                  <option value="Before 30 Minutes">
                                    Before 30 Minutes
                                  </option>
                                  <option value="Before 1 Hour">
                                    Before 1 Hour
                                  </option>
                                </Field>
                                {errors.reminder && touched.reminder && (
                                  <div className="pt-2 error">
                                    {errors.reminder}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="pt-4">
                            <label className="filter_input_head_txt pb-3">
                              Extra Description (If Any)
                            </label>
                            <div className="">
                              <Field
                                style={{ height: "100%" }}
                                as="textarea"
                                rows="5"
                                // type="text"
                                // validate={validateDescription}
                                className="field_input_txt"
                                name="description"
                                placeholder="Write Description Here...."
                              />
                              {errors.description && touched.description && (
                                <div className="pt-2 error">
                                  {errors.description}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="pt-3">
                            <button
                              type="submit"
                              className="btn btn-lg btn_apply_filter"
                            >
                              {btnloader === true ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                              <AddIcon className="add_task_icon" />
                              Add Task
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up for Add Task End*/}

      {/* Pop Up for Meeting Done start */}
      <div
        id="popup_meetingDone"
        className="modal fade popup_meet"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="pb-3">
                  <div className="d-flex justify-content-between form_head_txt">
                    <div className="">
                      <span> Add Comment For Meeting</span>
                    </div>
                    <div type="button" data-dismiss="modal" aria-label="Close">
                      <CloseIcon />
                    </div>
                  </div>

                  <Formik
                    initialValues={{
                      meeting_comments: "",
                      BDElead_status: "",
                      callstatus: "Meeting Done",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      setbtnloader(true);
                      axios
                        .post(`${SERVER_URL}/callstatus?_id=${Id}`, values)
                        .then((reps) => {
                          let Status = reps.data.status;
                          if (Status === "success") {
                            window.$("#popup_meetingDone").modal("hide");
                            getLeadData();
                            setbtnloader(false);
                            resetForm();
                            toast.success(
                              "Meeting Comments added Successfully",
                              {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                          } else {
                            setbtnloader(false);
                            window.$("#popup_meetingDone").modal("hide");
                            resetForm();
                            toast.error(
                              "Something Went wrong try after some Time!!!",
                              {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                          }
                        });
                    }}
                  >
                    {({ errors, touched }) => (
                      <div>
                        <Form>
                          <div className="pt-4">
                            <div>
                              <label className="filter_input_head_txt pb-3">
                                Follow Up Status
                              </label>
                              <div className="">
                                <Field
                                  as="select"
                                  className="field_input_txt"
                                  name="BDElead_status"
                                >
                                  <option disabled selected value="">
                                    Select Follow Up Status
                                  </option>
                                  <option value="High Priority">
                                    High Priority
                                  </option>
                                  <option value="Medium Priority">
                                    Medium Priority
                                  </option>
                                  <option value="Low Priority">
                                    Low Priority
                                  </option>
                                </Field>
                                {errors.BDElead_status &&
                                  touched.BDElead_status && (
                                    <div className="pt-2 error">
                                      {errors.BDElead_status}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className="pt-4">
                            <div>
                              <label className="filter_input_head_txt pb-3">
                                Comment For Meeting
                              </label>
                              <div className="">
                                <Field
                                  style={{ height: "100%" }}
                                  as="textarea"
                                  rows="5"
                                  // type="text"
                                  validate={validateMeetingComments}
                                  className="field_input_txt"
                                  name="meeting_comments"
                                  placeholder="Write Comment Here...."
                                />
                                {errors.meeting_comments &&
                                  touched.meeting_comments && (
                                    <div className="pt-2 error">
                                      {errors.meeting_comments}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className="pt-3">
                            <button
                              type="submit"
                              className="btn btn-lg btn_apply_filter"
                            >
                              {btnloader === true ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                              Meeting Done
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up for Meeting Done End*/}

      {/* Pop Up for Booking Amount Recieved Start */}
      <div
        id="popup_bookingAmount"
        className="modal fade popup_meet"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="pb-3">
                  <div className="d-flex justify-content-between form_head_txt">
                    <div className="">
                      <span>Booking Amount Details</span>
                    </div>
                    <div type="button" data-dismiss="modal" aria-label="Close">
                      <CloseIcon />
                    </div>
                  </div>

                  <Formik
                    initialValues={{
                      booking_amt: "",
                      discount: "",
                      proof: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      BookingAmount(values, resetForm);
                    }}
                  >
                    {({ errors, touched }) => (
                      <div>
                        <Form>
                          <div className="row pt-4">
                            <div className="col-12 col-md-6">
                              <div>
                                <label className="filter_input_head_txt pb-3">
                                  Booking Amount
                                </label>
                                <div>
                                  <Field
                                    style={{ height: "44px" }}
                                    // type="text"
                                    validate={validateBookingAmount}
                                    className="field_input_txt"
                                    name="booking_amt"
                                    placeholder="Enter Booking Amount"
                                  />
                                  {errors.booking_amt &&
                                    touched.booking_amt && (
                                      <div className="pt-2 error">
                                        {errors.booking_amt}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              <div>
                                <label className="filter_input_head_txt pb-3">
                                  Discount
                                </label>
                                <div>
                                  <Field
                                    style={{ height: "44px" }}
                                    // type="text"
                                    validate={validateDiscount}
                                    className="field_input_txt"
                                    name="discount"
                                    placeholder="Enter Discount"
                                  />
                                  {errors.discount && touched.discount && (
                                    <div className="pt-2 error">
                                      {errors.discount}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="pt-4">
                            <div>
                              <label className="filter_input_head_txt pb-3">
                                Upload Transaction Proof
                              </label>
                              <div>
                                <Field
                                  style={{ height: "44px" }}
                                  className="custom_file_input"
                                  name="proof"
                                  placeholder="Choose Image to Upload"
                                  type="File"
                                  id="fileToUpload"
                                  title="foo"
                                  validate={validatePhoto}
                                  accept=".png, .jpg, .jpeg"
                                  onChange={(e) => loadFile(e)}
                                />

                                <label
                                  for="fileToUpload"
                                  className="d-flex justify-content-between field_input_txt"
                                  style={{ padding: "14px", cursor: "pointer" }}
                                >
                                  {Picture === null
                                    ? "Choose Image to Upload"
                                    : Picture.name}
                                  <img
                                    src={UploadImage}
                                    style={{ height: "20px" }}
                                  />
                                </label>
                                {errors.proof && touched.proof && (
                                  <div className="pt-2 error">
                                    {errors.proof}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="pt-4">
                            <button
                              type="submit"
                              className="btn btn-lg btn_apply_filter"
                            >
                              {btnloader === true ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                              Booking Amount Recieved
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
              {/* <div>
                <div className="popup_icon_center">
                  <img src={UserTick} />
                </div>
                <div className="popup_text_center">
                  <p className="pt-4 pop_up_meeting_txt">
                    Are you sure want to <br />
                    Make this Contact As Amount Recieved ?
                  </p>
                  <div className="pt-2 m-0 d-flex justify-content-center">
                    <div
                      style={{
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <button
                        style={{ fontSize: "15px" }}
                        className="btn_popup_meeting_done"
                        onClick={bookingAmount}
                      >
                        Amount Recieved
                      </button>
                    </div>

                    <div
                      style={{
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <button
                        className="btn_meeting_cancel"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Pop Up for Booking Amount Recieved End */}
    </div>
  );
}
