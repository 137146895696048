import React, { useState } from "react";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Formik, Form, Field, useField } from "formik";
import { TextField } from "../Fields/TextField";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import $ from "jquery";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const AddFsePopup = ({ ids }) => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;

  // Validation Individual Start\
  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is Required";
    } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
      error = "Enter a Valid Name It should contain only Aplhabets";
    } else if (value.length > 20) {
      error = "Name Should not more than 20";
    } else if (value.length < 3) {
      error = "Name Should not less than 3 Characters";
    }
    return error;
  }

  function validateDesignation(value) {
    let error;
    if (!value) {
      error = "Designation is Required";
    } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
      error = "Enter a Valid Designation It should contain only Aplhabets";
    } else if (value.length > 20) {
      error = "Name Should not more than 20";
    } else if (value.length < 3) {
      error = "Name Should not less than 3 Characters";
    }
    return error;
  }

  function validateEmpID(value) {
    let error;
    if (!value) {
      error = "Employee ID is Required";
    } else if (!/^[0-9a-zA-Z\s]+$/i.test(value)) {
      error = "Enter a Valid Employee ID";
    } else if (value.length > 6) {
      error = "Employee ID Should not more than 6";
    } else if (value.length < 6) {
      error = "Employee ID Should not less than 6 Characters";
    }
    return error;
  }

  function validateFSE(value) {
    let error;
    if (!value) {
      error = "FSE is Required";
    } else if (!/^[0-9a-zA-Z\s]+$/i.test(value)) {
      error = "Enter a Valid FSE Code";
    } else if (value.length > 6) {
      error = "FSE Code Should not more than 6";
    } else if (value.length < 6) {
      error = "FSE Code Should not less than 6 Characters";
    }
    return error;
  }

  function validateMobileNumber(value) {
    let error;
    if (!value) {
      error = "Mobile Number is Required";
    } else if (value.length > 10) {
      error = "Mobile Number Should not more than 10";
    } else if (value.length < 10) {
      error = "Mobile Number Should not less than 10 ";
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
    ) {
      error = "Invalid Mobile Number...Enter Numeric";
    }
    return error;
  }

  function validateDOJ(value) {
    let error;
    if (!value) {
      error = "Date of Joining is Required";
    }
    return error;
  }

  return (
    <div>
      <div>
        <button
          className="add_btn"
          data-toggle="modal"
          data-target=".bd-example-modal-fse"
        >
          <AddIcon className="search_icons" />
          Add
        </button>
      </div>

      <Formik
        initialValues={{
          full_name: "",
          mobileno: "",
          designation: "",
          empid: "",
          fsecode: "",
          joindate: "",
        }}
        // validationSchema={validate}
        onSubmit={(values) => {
          console.log("REDDDDD122", ids);
          console.log(values);
          let verify = localStorage.getItem("_id");
          console.log(verify);

          const datafse = {
            name: values.full_name,
            mobile: values.mobileno,
            employee_id: values.empid,
            designation: values.designation,
            doj: values.joindate,
            fsecode: values.fsecode,
          };

          console.log("Data", datafse);

          const headers = {
            "Content-Type": "application/json",
          };

          axios
            // .post(`https://pure-wave-48602.herokuapp.com/addfse`, datafse, {
            .post(`${SERVER_URL}/addfse`, datafse, {
              headers,
            })
            .then((res) => {
              console.log(res);
              let Status = res.data.status;
              if (Status === "success") {
                window.$("#addFSE").modal("hide");
                window.$("#FseAddedSuccessfully").modal("show");
                // $(".popup-add_hot-lead-added").show();
                // history.push("/fselist");
              } else if (Status === "failed") {
                // alert(" Details are already Exists");
                toast.error("Profile Details are Already Exists", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
        }}
      >
        {(formik) => (
          <div>
            {console.log(formik)}
            <Form>
              <div>
                <div
                  id="addFSE"
                  className="modal fade bd-example-modal-fse"
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered  ">
                    <div
                      className="modal-content modal_content_whole"
                      style={{ background: "white" }}
                    >
                      {/*  */}
                      <div className="modal-body">
                        <div className="pb-3">
                          <span className="add_hot_lead_label">
                            {/* <LocalFireDepartmentIcon
                        style={{
                          color: "red",
                        }}
                      /> */}
                            Add Field Sales Executive
                          </span>
                          <span
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            id="CloseAddHotLeadPopup"
                            style={{
                              background: "transparent",
                              float: "right",
                              color: "red",
                            }}
                          >
                            <span aria-hidden="true">X Close</span>
                          </span>
                        </div>

                        {/*Add FSE popup Content Start */}
                        <div className="d-flex justify-content-between">
                          <div className="p-3" style={{ width: "100%" }}>
                            <div>
                              <div className="p-3 add_hot_lead_content">
                                <div className="row ">
                                  <div className="col-md-12">
                                    <div className="input-group_add_hot_lead ">
                                      <TextField
                                        type="text"
                                        // className="input-area_add_hot_lead"
                                        // required
                                        // id="inputName"
                                        name="full_name"
                                        label="Full Name"
                                        validate={validateName}
                                        // autoComplete="off"
                                        // value={formik.values.name}
                                        // onChange={formik.handleChange}
                                      />
                                      {/* <label
                                        htmlFor="inputName"
                                        className="label_add_hot_lead"
                                      >
                                        Name
                                      </label> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="row pb-3">
                                  <div className="col-6 col-sm-6">
                                    <div className="input-group_add_hot_lead ">
                                      <TextField
                                        type="text"
                                        // className="input-area_add_hot_lead"
                                        // required
                                        // id="inputEmail"
                                        name="designation"
                                        label="Designation"
                                        validate={validateDesignation}
                                        // autoComplete="off"
                                      />
                                      {/* <label
                                        htmlFor="inputEmail"
                                        className="label_add_hot_lead"
                                      >
                                        Email
                                      </label> */}
                                    </div>
                                  </div>

                                  <div className="col-6 col-sm-6">
                                    <div className="input-group_add_hot_lead ">
                                      <TextField
                                        type="text"
                                        // className="input-area_add_hot_lead"
                                        // required
                                        // id="inputNuber"
                                        name="empid"
                                        label="Employee ID"
                                        validate={validateEmpID}
                                        // autoComplete="off"
                                      />
                                      {/* <label
                                        htmlFor="inputNumber"
                                        className="label_add_hot_lead"
                                      >
                                        Phone Number
                                      </label> */}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-6 col-sm-6">
                                    <div className="input-group_add_hot_lead ">
                                      <TextField
                                        type="text"
                                        // className="input-area_add_hot_lead"
                                        // required
                                        // id="inputPlotSize"
                                        name="fsecode"
                                        label="FSE Code"
                                        validate={validateFSE}
                                        // autoComplete="off"
                                      />
                                      {/* <label
                                        htmlFor="inputPlotSize"
                                        className="label_add_hot_lead"
                                      >
                                        Plot Size
                                      </label> */}
                                    </div>
                                  </div>

                                  <div className="col-6 col-sm-6">
                                    <div className="input-group_add_hot_lead ">
                                      <TextField
                                        type="tel"
                                        // className="input-area_add_hot_lead"
                                        // required
                                        // id="inputDevelopmentName"
                                        name="mobileno"
                                        label="Mobile Number"
                                        validate={validateMobileNumber}
                                        // autoComplete="off"
                                      />
                                      {/* <label
                                        htmlFor="inputDevelopmentName"
                                        className="label_add_hot_lead"
                                      >
                                        Development Name
                                      </label> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6 col-sm-6">
                                    <div className="input-group_add_hot_lead ">
                                      <TextField
                                        type="date"
                                        // className="input-area_add_hot_lead"
                                        // required
                                        // id="inputLocation"
                                        name="joindate"
                                        label="Joining Date"
                                        validate={validateDOJ}
                                        // autoComplete="off"
                                      />
                                      {/* <label
                                        htmlFor="inputLocation"
                                        className="label_add_hot_lead"
                                      >
                                        Location
                                      </label> */}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <button
                                    className="btn_add_hot_lead"
                                    type="submit"
                                    // data-1toggle="modal"
                                    // data-target=".bd-lead-added-successfully"
                                    // data-toggle="modal"
                                    // data-target=".popup-add_hot-lead-added"
                                    // data-toggle="modal"
                                    // data-target="popup-add_hot-lead-added"
                                    // data-dismiss="modal"
                                  >
                                    Add FSE
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*Add FSE popup Content End */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>

      {/* 2nd Model */}
      <div
        id="FseAddedSuccessfully"
        className="modal fade popup-add_hot-lead-added"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"

        // style={{display:"none"}}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <CheckCircleRoundedIcon className="popup_addbankdetails_icon" />
                </div>
                <div className="popup_text_center">
                  <span className="popup_addbankdetails_text">
                    FSE Data Added Successfuly
                  </span>
                  <div className="pt-3 d-flex justify-content-center">
                    <div
                      style={{
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <button
                        // type="button"
                        className="popup_btn_linkaccount"
                        data-dismiss="modal"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFsePopup;
