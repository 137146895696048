import React, { useEffect, useState } from "react";
import CS_SideNav from "../CS_SideNav";
import { useHistory } from "react-router-dom";
import Footer from "../../Dashboard/Footer";
import first_icon_dashboard from "../../../Assets/call-calling.svg";
import sec_icon_dashboard from "../../../Assets/call-received.svg";
import thrid_icon_dashboard from "../../../Assets/like-shapes.svg";
import four_icon_dashboard from "../../../Assets/emoji-happy.svg";
import five_icon_dashboard from "../../../Assets/call-received1.svg";
import calendarinprogress from "../../../Assets/Calender under Progress.svg";
import ContactUploadedvsDeal from "./ContactUploadedvsDeal";
import Loader from "../../Helpers/Loader";
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DateRangePicker from "rsuite/DateRangePicker";
import { startOfDay, endOfDay, subMonths, subYears } from "date-fns";
import axios from "axios";
import TotalPartnersCalled from "./TotalPartnersCalled";
import PartnerCallStatus from "./PartnerCallStatus";
import LeadCallStatus from "./LeadCallStatus";
// import CSCalendarData from "./CSCalendarData";

export default function CSDashboard() {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isloading, setisloading] = useState(true);
  const [totalcalls, settotalcalls] = useState("");
  const [connectedcalls, setconnectedcalls] = useState("");
  const [followup, setfollowup] = useState("");
  const [Interested, setInterested] = useState("");
  const [Onboarded, setOnboarded] = useState("");

  const LGSName = localStorage.getItem("CSName");

  let history = useHistory();

  useEffect(() => {
    const valid = localStorage.getItem("user_code");
    if (valid === null) {
      history.push("/");
    }

    getalldata(valid);
  }, [startDate, endDate]);

  //   Date
  var date = new Date();
  const [dateValue, setdateValue] = useState([
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ]);

  const getalldata = (valid) => {
    axios
      .get(
        `${SERVER_URL}/LGScallLogs?lgs_name=${LGSName}&from_date=${startDate}&to_date=${endDate}&is_name=${valid}`
      )
      .then((res) => {
        // console.log("res", res);
        settotalcalls(res.data.data.totalcalls);
        setconnectedcalls(res.data.data.connected);
        setfollowup(res.data.data.followup);
        setInterested(res.data.data.interested);
        setOnboarded(res.data.data.onboarded);
        setisloading(false);
      });
    // .catch((err) => alert(err));
  };

  // OnChange Function for Date Range Picker
  const onChange = (dates) => {
    // console.log("dates", dates);
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      // start = start.toISOString().slice(0, 10);
      // end = end.toISOString().slice(0, 10);
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start > end) {
        setStartDate(end);
        setEndDate(start);
      } else {
        setStartDate(start);
        setEndDate(end);
      }

      // Converting it to format dd/mm/yyyy
      // setStartDate(start.toISOString().slice(0, 10));
      // setEndDate(end.toISOString().slice(0, 10));
      // console.log("StartDate", startDate);
      // console.log("EndDate", endDate);
    } else {
      setStartDate("");
      setEndDate("");
    }
    // getalldata();

    // Setallstats(startDate, endDate);
  };

  const Ranges = [
    {
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Month",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
    },
    {
      label: "3 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
    },
    {
      label: "6 Months",
      value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
    },
    {
      label: "Year",
      value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
    },
  ];

  return (
    <div>
      {isloading === false ? (
        <div>
          <div>
            <CS_SideNav />
          </div>

          <div className="main">
            <div className="content-tabs">
              <div>
                <div className="top_menu_bar">
                  <div className="d-flex justify-content-between w-100">
                    <div>
                      <span className="dashboard_head_txt">Dashboard</span>
                    </div>
                    <div>
                      <DateRangePicker
                        // style={{ left: "780px" }}
                        name="start"
                        placeholder="Select Date Range"
                        format="dd/MM/yyyy"
                        character="-"
                        placement="bottomEnd"
                        onChange={onChange}
                        ranges={Ranges}
                        // defaultValue={dateValue}
                      />
                    </div>
                  </div>
                </div>

                <div className="pt-4 d-flex justify-content-between">
                  <div style={{ width: "19%" }}>
                    <div className="d-flex align-items-center bg-light">
                      <div className="first_icon_bg">
                        <img
                          src={first_icon_dashboard}
                          width="50%"
                          className="first_icon"
                        />
                      </div>
                      <div>
                        <span>{totalcalls === "" ? "0" : totalcalls}</span>
                        <p className="mb-0">Total Call</p>
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "19%" }}>
                    <div className="d-flex align-items-center bg-light">
                      <div className="second_icon_bg">
                        <img
                          src={sec_icon_dashboard}
                          width="50%"
                          className="first_icon"
                        />
                      </div>
                      <div>
                        <span>
                          {connectedcalls === "" ? "0" : connectedcalls}
                        </span>
                        <p className="mb-0">Connected Call</p>
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "19%" }}>
                    <div className="d-flex align-items-center  bg-light">
                      <div className="third_icon_bg">
                        <img
                          src={thrid_icon_dashboard}
                          width="50%"
                          className="first_icon"
                        />
                      </div>
                      <div>
                        <span>{followup === "" ? "0" : followup}</span>
                        <p className="mb-0">Follow Up</p>
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "19%" }}>
                    <div className="d-flex align-items-center  bg-light">
                      <div className="fourth_icon_bg">
                        <img
                          src={four_icon_dashboard}
                          width="50%"
                          className="first_icon"
                        />
                      </div>
                      <div>
                        <span>{Interested === "" ? "0" : Interested}</span>
                        <p className="mb-0">Interested</p>
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "19%" }}>
                    <div className="d-flex align-items-center bg-light">
                      <div className="fifth_icon_bg">
                        <img
                          src={five_icon_dashboard}
                          width="50%"
                          className="first_icon"
                        />
                      </div>
                      <div>
                        <span>{Onboarded === "" ? "0" : Onboarded}</span>
                        <p className="mb-0">Onboarded</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <TotalPartnersCalled />
                </div>

                <div className="pt-4 d-flex justify-content-between">
                  <div className="col-12 col-md-6 pie_graph_dashboard">
                    <PartnerCallStatus />
                  </div>
                  <div className="col-12 col-md-6 pie_graph_dashboard">
                    <LeadCallStatus />
                  </div>
                </div>

                <div className="pt-4 d-flex justify-content-between">
                  <div className="col-12 col-md-6 pie_graph_dashboard">
                    <ContactUploadedvsDeal />
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-center pie_graph_dashboard">
                    {/* <EarningDetails /> */}
                    <img src={calendarinprogress} width="70%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
}
